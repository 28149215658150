import GetKey from "./GetKey";

const loadKey = async (setPlacesKey, setKeyLoaded) =>
{
    // if (!keyLoaded)
    // {
    try
    {
        let key = await GetKey();
        //console.log("Google api key is: " + key);
        setPlacesKey(key);
        setKeyLoaded(true);
    }
    catch (error)
    {
        console.log("Error: Failed to fetch Google api key.");
    }
    // }
}

export default loadKey;