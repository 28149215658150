// Changes date or month to be 2 characters. E.g. "2022/6/2" to "2022/06/02"
function pad(n)
{
    return n < 10 ? '0' + n : n;
}

export const convertDate = (initialDate, isLoaded) => 
{
    if (isLoaded)
    {
        let timeStamp = new Date(initialDate);
        let date = timeStamp.getDate();
        let month = timeStamp.getMonth();
        let year = timeStamp.getFullYear();
        let yearMonthDate = year + "-" + pad(month + 1) + "-" + pad(date);
        return yearMonthDate;
    }
}