import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import
{
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"
import swal from "sweetalert";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } })
{
  let emailsArray = [];
  let passwordsArray = [];
  let numOfUsers = 0;

  if (sessionStorage.getItem("numOfUsers") !== undefined)
  {
    numOfUsers = JSON.parse(sessionStorage.getItem("numOfUsers"))
  }

  if (sessionStorage.getItem("emailsArray") !== undefined)
  {
    emailsArray = JSON.parse(sessionStorage.getItem("emailsArray"));
  } else
  {
    //console.log("Emails array is null");
    emailsArray = [];
  }

  if (sessionStorage.getItem("passwordsArray") !== undefined)
  {
    passwordsArray = JSON.parse(sessionStorage.getItem("passwordsArray"));
    //console.log("Passwords Array: " + passwordsArray);
  } else
  {
    //console.log("Passwords array is null");
    passwordsArray = [];
  }

  let REACT_APP_DEFAULTAUTH = "jwt"

  try
  {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase")
    {
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.password
      )
      yield put(registerUserSuccessful(response))
    }
    else if (REACT_APP_DEFAULTAUTH === "jwt")
    {
      let object = { "email": user.email, "username": user.username, "password": user.password, "emails": emailsArray, "pwords": passwordsArray, "numOfUsers": numOfUsers }
      const response = yield call(postJwtRegister, object)

      if (response.message === "User registered successfully!")
      {
        // yield put(registerUserSuccessful(response))
        swal("Success", "User successfully registered!", "success");
        window.setTimeout(function () { window.location.href = "/login"; }, 3000);
      }
      else if (response.message === "Email address already exists. Try to log in.")
      {
        swal("Error", "Email address already exists. Try to log in.", "error");
        window.setTimeout(function () { window.location.href = "/login"; }, 2000);
      }
      else
      {
        swal("Error", "Something went wrong. Please try again.", "error");
        window.setTimeout(function () { window.location.href = "/register"; }, 2000);

        //yield put(registerUserSuccessful(response));
      }

    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake")
    {
      const response = yield call(postFakeRegister, user)
      yield put(registerUserSuccessful(response))
    }
  } catch (error)
  {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister()
{
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga()
{
  yield all([fork(watchUserRegister)])
}

export default accountSaga
