import React from 'react';
import PropTypes from 'prop-types';
import
{
    Col, Button, Form, FormGroup, Label, Input, FormText, Badge
} from "reactstrap";
import { useFormik } from 'formik';
import { useState, useEffect } from "react";
import { projTypeOptions, clientType, projStatus, municipalityDeptOptions, localLabourJobTitles, docPrivacyStatus } from "../SharedFiles/listData";
import { post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { API_URL } from "../../helpers/ApiUrl";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import FetchWardNum from './FetchWardNum';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import swal from "sweetalert";
import resizeImage from '../SharedFiles/resizeImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileLines, faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import postInfo from "../SharedFiles/postInfo";
import ".../../assets/styles.css";
import { addStaffRow, removeStaffRow, handleStaffTypes, handleStaffArray } from './staffFunctions';
import changePhotoHandler from '../SharedFiles/changePhotoHandler';
import changeDocHandler from '../SharedFiles/changeDocHandler';
import GetInfo from '../SharedFiles/GetInfo';
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";
import createDocArray from './createDocArray';
import simpleFetch from '../SharedFiles/simpleFetch';

const AddProjectForm = (props) =>
{
    // Constants
    const timerSeconds = 600;
    const timerOff = -1;

    // Misc hooks
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [isLoading, setLoading] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [tried, setTried] = useState(false);
    const [tried2, setTried2] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [validated, setValidated] = useState(false);
    const [counter, setCounter] = useState(timerOff);
    const [validEndDate, setValidEndDate] = useState(true);
    const [validExtEndDate, setValidExtEndDate] = useState(false);
    const [chosenClientType, setChosenClientType] = useState("");
    const [missingInfoMsg, setMissingInfoMsg] = useState("");

    // Google Place Autocomplete hooks
    const [Lat, setLat] = useState("");
    const [Long, setLong] = useState("");
    const [value, setValue] = useState(null);
    const [address, setAddress] = useState('');
    const [geoResult, setGeoResult] = useState('');
    const [addressChosen, setAddressChosen] = useState(false);

    // Staff hooks
    const [staffRows, setStaffRows] = useState(1);
    const [staffArray, setStaffArray] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [staffTypes, setStaffTypes] = useState([]);
    const [staffObject, setStaffObject] = useState({});

    // Document hooks
    const [numOfDocs, setNumOfDocs] = useState(0);
    const [docUrls, setDocUrls] = useState([]);
    const [docNames, setDocNames] = useState([]);
    const [docObjects, setDocObjects] = useState([]);
    const [docPrivacyArray, setDocPrivacyArray] = useState([]);

    // Image hooks
    const [numOfPics, setNumOfPics] = useState(0);
    const [imgUrls, setImgUrls] = useState([]);
    const [imgObjects, setImgObjects] = useState([]);
    const [imgNames, setImgNames] = useState([]);

    let val = "";
    let val2 = "";

    // Props
    let toggleProjectInfoChanged = props.toggleProjectInfoChanged;
    let ProjectsArray = props.ProjectsArray;
    let setIsLoaded = props.setIsLoaded;
    let setIsLoadedImages = props.setIsLoadedImages;
    let setIsLoadedDocs = props.setIsLoadedDocs;
    let setLoadedStaffArrays = props.setLoadedStaffArrays;
    let setProjImagesError = props.setProjImagesError;
    let setShowAddProjAlerts = props.setShowAddProjAlerts;
    let userInfo = props.userInfo;
    let currentUser = userInfo.username;
    let activeTab = props.activeTab;
    let canPublish = userInfo.canPublish;

    const setCoords = async () =>
    {
        setLoading(true);

        if (Lat)
        {
            let myArray = [];
            let wardResult = "";
            let errorMsg = "";

            try
            {
                wardResult = await FetchWardNum(Lat, Long);
            }
            catch (error)
            {
                console.log("Error: Failed to fetch ward num from api.");
                errorMsg = "Failed to fetch ward num from api.";
            }

            if (errorMsg === "")
            {
                myArray = wardResult.split("|");
                let wardnum = myArray[0].trim();

                let wardPersonArray = await simpleFetch("getwardcouncillor", Number(wardnum));
                wardPersonArray = removeSuccessMessage(wardPersonArray, "Ward councillor info retrieved successfully.");
                let wardCouncillorName = wardPersonArray[0].first_name + " " + wardPersonArray[0].surname;
                setInputs(prevState => ({ ...prevState, ["ward_person_name"]: wardCouncillorName }));

                setInputs(prevState => ({ ...prevState, ["ward"]: Number(wardnum) }));

                let muni = `${myArray[1].trim()}, ${myArray[2].trim()}`
                setInputs(prevState => ({ ...prevState, ["municipality"]: muni }));

                let map = `${Lat}, ${Long}`;
                setInputs(prevState => ({ ...prevState, ["map_location"]: map }));

                setInputs(prevState => ({ ...prevState, ["proj_address"]: address }));
                setLoading(false);
                setAddressChosen(true);
                setCounter(timerSeconds);
            }
            else
            {
                swal("Error", "Something went wrong. Please try again.", "error");
                setLoading(false);
            }
        }
        else
        {
            swal("Error", "No such place. Please try again.", "error");
            setLoading(false);
        }

    }

    let key = props.placesKey;

    if (value !== null && !tried)
    {
        setAddress(value.label);
        geocodeByPlaceId(`${value.value.place_id}`)
            .then(results =>
            {
                setGeoResult(results);
            })
            .catch(error => console.error("Geocode error is: " + error));
        setTried(true);
    }

    if (geoResult && !tried2)
    {
        let tempvalue = geoResult[0].geometry.location;
        val = tempvalue.lat;
        setLat(val);
        val2 = tempvalue.lng;
        setLong(val2);
        setTried2(true);
    }

    const clearAddress = () =>
    {
        cancelAddProject();
        setValue(null);
        setAddress('');
        setGeoResult('');
        setLat('');
        setLong('');
        setAddressChosen(false);
        setCounter(timerOff);
        setTried(false);
        setTried2(false);
    }

    const validate = values =>
    {
        const errors = {};

        if (!values.proj_site_camp)
        {
            errors.proj_site_camp = 'Required';
        }

        if (!values.proj_type)
        {
            errors.proj_type = 'Required';
        }

        if (!values.contract_core_staff_names)
        {
            errors.contract_core_staff_names = 'Required';
        }

        if (!values.staff_type)
        {
            errors.staff_type = 'Required';
        }

        if (!values.contract_number)
        {
            errors.contract_number = 'Required';
        }

        if (!values.municipality_dept && chosenClientType !== "Private Client")
        {
            errors.municipality_dept = 'Required';
        }

        if (!values.client_type && chosenClientType !== "Private Client")
        {
            errors.client_type = 'Required';
        }

        if (!values.client_name)
        {
            errors.client_name = 'Required';
        }

        if (!values.proj_engineer)
        {
            errors.proj_engineer = 'Required';
        }

        if (!values.contractor)
        {
            errors.contractor = 'Required';
        }

        if (!values.proj_manager)
        {
            errors.proj_manager = 'Required';
        }

        if (!values.proj_start_date)
        {
            errors.proj_start_date = 'Required';
        }

        if (!values.proj_end_date_projected)
        {
            errors.proj_end_date_projected = 'Required';
        }
        else if (values.proj_end_date_projected && !validEndDate)
        {
            errors.proj_end_date_projected = 'End date must be AFTER project start date.';
        }

        if (values.proj_ext_date && !validExtEndDate)
        {
            errors.proj_ext_date = 'Extended end date must be AFTER projected end date.';
        }

        if (!values.proj_status)
        {
            errors.proj_status = 'Required';
        }

        if (!values.proj_descrip)
        {
            errors.proj_descrip = 'Required';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            proj_site_camp: '',
            proj_type: '',
            contract_core_staff_names: '',
            contract_core_staff_types: '',
            contract_number: '',
            municipality_dept: '',
            client_type: '',
            client_name: '',
            proj_engineer: '',
            contractor: '',
            proj_manager: '',
            proj_start_date: '',
            proj_end_date_projected: '',
            proj_ext_date: '',
            proj_status: '',
            proj_descrip: '',
        },
        validate,
        onSubmit: values =>
        {
            setValidated(true);
        },
    });

    const cancelAddProject = () =>
    {
        setCounter(timerOff);
        setAddressChosen(false);
        setStaffArray([]);
        setStaffRows(1);
        setInputs({});
        setValue(null);
        setStaffTypes([]);

        setNumOfPics(0);
        setImgObjects([]);
        setImgUrls([]);
        setImgNames([]);
        setIsLoadedImages(false);

        setDocUrls([]);
        setDocNames([]);
        setNumOfDocs(0);
        setDocObjects({});
        setIsLoadedDocs(false);
        setDocPrivacyArray([]);

        setValidated(false);
        setSubmitClicked(false);
        setFormSubmitted(false);
        setSubmitting(false);
        setIsLoaded(false);
        setLoadedStaffArrays(false);
        document.forms["addNewProjectForm"].reset();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        toggleProjectInfoChanged();
    }

    const handleStaffArr = (name, index) =>
    {
        handleStaffArray(name, index, staffTypes, staffArray, staffObject, setInputs, setStaffObject, setStaffArray, staffRows);
    }

    const handleStaffTyp = (type, index) =>
    {
        handleStaffTypes(type, index, staffArray, staffObject, setInputs, setStaffObject, staffTypes, setStaffTypes);
    }

    const addStaffRw = () =>
    {
        addStaffRow(staffArray, currentIndex, staffTypes, staffRows, setCurrentIndex, setStaffRows);
    }

    const removeStaffRw = (index) =>
    {
        removeStaffRow(index, staffRows, setCurrentIndex, setStaffRows, staffArray, setStaffArray, staffTypes, setStaffTypes, staffObject, inputs, setInputs, setStaffObject);
    }

    let staffRow;
    let array = [];

    for (let i = 0; i < staffRows; ++i)
    {
        staffRow = (<div key={i} className='row mb-2 mb-sm-2 mb-md-2'>
            <Col className="col-sm-8 col-12 mb-2 mb-sm-0">
                <Input
                    id="coreStaffNames"
                    name="contract_core_staff_names"
                    placeholder="Enter name of staff member"
                    type="text"
                    value={staffArray[i] || ''}
                    onChange={(e) => { handleStaffArr(e.target.value, i); formik.handleChange(e); }}
                    onBlur={(e) => { formik.handleBlur(e); }}
                    invalid={formik.errors.contract_core_staff_names === 'Required' && formik.touched.contract_core_staff_names ?
                        true :
                        formik.errors.contract_core_staff_names === 'Required' && submitClicked === true ?
                            true :
                            false}
                />
            </Col>
            <Col className="col-sm-3 col-12">
                <Input
                    invalid={formik.errors.staff_type === 'Required' && formik.touched.staff_type ?
                        true :
                        formik.errors.staff_type === 'Required' && submitClicked === true ?
                            true :
                            false}
                    id="staff_type"
                    name="staff_type"
                    type="select"
                    onChange={(e) => { handleStaffTyp(e.target.value, i); formik.handleChange(e); }}
                    onBlur={(e) => { formik.handleBlur(e) }}
                    value={staffTypes[i] || ''}
                >
                    {localLabourJobTitles.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </Input>
            </Col>
            <Col className="my-sm-0 my-2">
                <Button color="danger" onClick={() => removeStaffRw(i)}>
                    <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                </Button>
            </Col>
        </div>);

        array.push(staffRow);
    }

    const removeDoc = (e, index) => 
    {
        let tempDocUrls = docUrls;
        tempDocUrls.splice(index, 1);
        setDocUrls(tempDocUrls);
        let tempDocObjects = docObjects;
        tempDocObjects.splice(index, 1);
        setDocObjects(tempDocObjects);
        setNumOfDocs(docUrls.length);
        let tempDocNames = docNames;
        tempDocNames.splice(index, 1);
        setDocNames(tempDocNames);

        let tempArray = docPrivacyArray;
        tempArray.splice(index, 1);

        if (tempArray.length === 0)
        {
            setDocPrivacyArray([]);
        }
        else
        {
            setDocPrivacyArray(tempArray);
        }
    }

    const handleSubmitDoc = (msgFromPhotos) => 
    {
        if (docUrls.length === 0)
        {
            console.log("No docs to upload.")
            return HandleSubmitProj(msgFromPhotos, "no docs");
        }

        const formData = new FormData();

        docObjects.forEach((file, i) =>
        {
            formData.append(i, file)
        });

        postInfo(formData, "projdocsupload", "POST", HandleSubmitProj, msgFromPhotos);
    }

    const removeImage = (e, index) => 
    {
        let tempImgUrls = imgUrls;
        tempImgUrls.splice(index, 1);
        setImgUrls(tempImgUrls);

        let tempImgObjects = imgObjects;
        tempImgObjects.splice(index, 1);
        setImgObjects(tempImgObjects);
        setNumOfPics(imgUrls.length);

        let tempImgNames = imgNames;
        tempImgNames.splice(index, 1);
        setImgNames(tempImgNames);
    }

    const handleSubmitPhoto = async (e) =>
    {
        setSubmitting(true);

        if (imgUrls.length === 0)
        {
            console.log("No images to upload.");
            return handleSubmitDoc("no photos");
        }

        const formData = new FormData();

        for (const files of imgObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };

            const resizedImage = await resizeImage(config);
            formData.append("project-image", resizedImage, files.name);
        }

        postInfo(formData, "imageupload", "POST", handleSubmitDoc, "");
    };

    const HandleSubmitProj = async (imgUploadMessage, docUploadMessage) =>
    {
        let myObject = {};
        let latestProjId = 0;
        latestProjId = await GetInfo("gettotalnumprojects", "");

        let array = JSON.parse(JSON.stringify(ProjectsArray));
        array = removeSuccessMessage(array, "Projects retrieved successfully.");

        if (canPublish === 1)
        {
            myObject["isActive"] = 1;
        }
        else
        {
            myObject["isActive"] = 0;
        }

        myObject["proj_id"] = latestProjId + 1;
        myObject["user"] = currentUser;

        Object.assign(myObject, inputs);

        let imgUrlArray = [];
        let imgInfoObject = {};
        let imgFailedArray = [];

        // code to save uploaded image urls to database
        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no photos")
        {
            let imagePathsArray = Object.keys(imgUploadMessage);
            let imageUploadStatusArray = Object.values(imgUploadMessage);

            imagePathsArray.forEach((img, i) =>
            {
                if (imageUploadStatusArray[i] === "Success")
                {
                    let fullPath = `${API_URL}/${img}`;
                    imgUrlArray.push(fullPath);
                }
                else if (imageUploadStatusArray[i] === "Failed")
                {
                    imgFailedArray.push(img);
                }
            });

            if (imgFailedArray.length > 0)
            {
                console.log(imgFailedArray.length + " image/s failed to upload.");
                setProjImagesError(imgFailedArray.length + " image/s failed to upload.");
            }
            else
            {
                setProjImagesError("");
            }

            imgInfoObject["isActive"] = 1;
            imgInfoObject["proj_id"] = myObject.proj_id;
            imgInfoObject["added_by_user"] = currentUser;
            imgInfoObject["imageUrlArray"] = imgUrlArray;

            try
            {
                const response = await post(url.ADD_NEW_PROJECT_IMAGES, imgInfoObject);

                if (response.message === "New project images have been added to database.") 
                {
                    console.log("Images uploaded");
                }
                else
                {
                    console.log("Something went wrong");
                }
            }
            catch (error)
            {
                console.log("This is the try catch error msg for uploaded images: " + error);
            }
        }

        // code to save uploaded document urls and names to database
        let docUrlArray = [];
        let docInfoObject = {};

        if (docUploadMessage !== "Error" && docUploadMessage !== "no docs")
        {
            let docPathsArray = Object.keys(docUploadMessage);
            let docUploadStatusArray = Object.values(docUploadMessage);

            docPathsArray.forEach((doc, i) =>
            {
                if (docUploadStatusArray[i] === "Success")
                {
                    let fullPath = `${API_URL}/${doc}`;
                    docUrlArray.push(fullPath);
                }
            });

            docInfoObject["isActive"] = 1;
            docInfoObject["proj_id"] = myObject.proj_id;
            docInfoObject["added_by_user"] = currentUser;
            docInfoObject["docNamesArray"] = docNames;
            docInfoObject["docUrlArray"] = docUrlArray;

            let returnedDocArray = createDocArray(docUrlArray, docNames, docPrivacyArray);
            docInfoObject["docArray"] = returnedDocArray;

            try
            {
                const response = await post(url.ADD_NEW_PROJECT_DOCS, docInfoObject);

                if (response.message === "New project documents have been added to database.")
                {
                    console.log("Documents uploaded");
                }
                else
                {
                    console.log("Something went wrong");
                }
            }
            catch (error)
            {
                console.log("This is the try catch error msg for uploaded documents: " + error);
            }
        }

        // code to save project data to database
        try
        {
            const response = await post(url.ADD_NEW_PROJECT, myObject);

            if (response.message === "The project has been added")
            {
                setAddressChosen(false);
                setCounter(timerOff);
                setSubmitting(false);
                setInputs({});
                setValue(null);
                setStaffArray([]);
                setStaffRows(1);
                setNumOfPics(0);
                setImgObjects([]);
                setImgNames([]);
                setImgUrls([]);
                setValidated(false);
                setFormSubmitted(false);
                setDocNames([]);
                setNumOfDocs(0);
                setDocObjects([]);
                setDocUrls([]);
                setDocPrivacyArray([]);
                document.forms["addNewProjectForm"].reset();
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setIsLoaded(false);
                setIsLoadedImages(false);
                setIsLoadedDocs(false);
                setLoadedStaffArrays(false);
                toggleProjectInfoChanged();
                setShowAddProjAlerts(true);
                swal("Success", "The project has been added", "success");
            } else
            {
                setSubmitting(false);
                setInputs({});
                document.forms["addNewProjectForm"].reset();
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                swal("Error", "Something went wrong. Please try again.", "error");
            }
        }
        catch (error)
        {
            swal("Error", "Something went wrong.", "error");
            setSubmitting(false);
            console.log("This is the try catch error msg: " + error);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    const handleClientType = (e) =>
    {
        setChosenClientType(e.target.value);

        if (e.target.value === "Private Client")
        {
            setInputs(prevState => ({ ...prevState, ["municipality_dept"]: "" }));
        }
        handleChange(e);
        formik.handleChange(e);
    }

    const handleSiteCampValues = (e, section) =>
    {
        if (section === "site_camp")
        {
            setInputs(prevState => ({ ...prevState, ["site_camp_separate"]: undefined }));
            if (inputs.site_camp_where !== undefined)
            {
                setInputs(prevState => ({ ...prevState, ["site_camp_where"]: undefined }));
            }
        }
        else if (section === "site_camp_where")
        {
            setInputs(prevState => ({ ...prevState, ["site_camp_where"]: undefined }));
        }

        formik.handleChange(e);
        handleChange(e);
    }

    const checkEndDate = (date, extDate) =>
    {
        if (inputs.proj_start_date !== undefined && date !== "")
        {
            let endDate = new Date(date);
            let startDate = new Date(inputs.proj_start_date);

            if (startDate > endDate) 
            {
                setValidEndDate(false);
            }
            else
            {
                setValidEndDate(true);
            }
        }

        if (inputs.proj_ext_date !== undefined && inputs.proj_end_date_projected !== undefined && extDate !== "")
        {
            let extEndDate = new Date(extDate);
            let endDate = new Date(inputs.proj_end_date_projected);
            if (endDate > extEndDate) 
            {
                setValidExtEndDate(false);
            }
            else
            {
                setValidExtEndDate(true);
            }
        }
    }

    // const createDocArray = (urlsArray, namesArray, privacyArray) =>
    // {
    //     let tempDocArray = [];

    //     urlsArray.forEach((url, index) =>
    //     {
    //         let tempObject = { name: "", url: "", privacy: "" };
    //         tempObject.name = namesArray[index];
    //         tempObject.url = url;
    //         tempObject.privacy = privacyArray[index];
    //         tempDocArray.push(tempObject);
    //     });

    //     return tempDocArray;
    // }

    const handleDocPrivacyChange = (e, index) =>
    {
        let privacyStatus = e.target.value;
        let tempDocPrivacyArray = docPrivacyArray;
        tempDocPrivacyArray[index] = privacyStatus;

        setDocPrivacyArray(tempDocPrivacyArray);
    }

    useEffect(() =>
    {
        let message = "Missing information above. Please fill in and then click the 'Submit' button again.";

        if (submitClicked && validated === false)
        {
            setMissingInfoMsg(message);
        }
        else if (submitClicked && validated === true)
        {
            setMissingInfoMsg("");
        }
    }, [submitClicked, validated]);

    useEffect(() =>
    {
        if (validated === true && formSubmitted === false)
        {
            handleSubmitPhoto();
            setFormSubmitted(true);
        }
    }, [validated]);

    useEffect(() =>
    {
        if (submitClicked && formik.isValid === true)
        {
            setValidated(true);
        }
    }, [submitClicked, formik.isValid]);

    useEffect(() =>
    {
        if (counter === 120)
        {
            swal("Warning", "2 minutes left", "info");
        }

        let interval = null;
        if (counter === 0)
        {
            cancelAddProject();
        }

        if (counter !== timerOff && addressChosen)
        {
            interval = setTimeout(() => setCounter(counter => counter - 1), 1000);
        }
        else if (!addressChosen && counter !== 0)
        {
            clearTimeout(interval);
        }

        return () => clearTimeout(interval);

    }, [addressChosen, counter]);

    useEffect(() =>
    {
        if (addressChosen && Object.keys(inputs).length !== 0)
        {
            setCounter(timerSeconds);
        }
    }, [inputs]);

    useEffect(() =>
    {
        if (activeTab !== "2")
        {
            cancelAddProject();
        }
    }, [activeTab]);

    return (
        <div id="newProject">
            {/* <p>Counter: {counter}</p> */}
            {/* <p>Inputs: {JSON.stringify(inputs, null, 4)}</p>
            <p>doc privacy array: {JSON.stringify(docPrivacyArray, null, 4)}</p> */}
            {/* <p>Doc urls: {JSON.stringify(docUrls, null, 4)}</p>
            <p>Doc names: {JSON.stringify(docNames, null, 4)}</p> */}
            {/* <p>Address: {address}</p> */}
            {/* <p>activeTab: {activeTab}, type: {typeof activeTab}</p>
            <p>submitClicked {submitClicked.toString()}, validated {validated.toString()}</p>
            <p>staffTypes: </p>
            <pre>{JSON.stringify(staffTypes)}</pre> */}

            {counter > 0 && counter >= 60
                ? <p className='redText' style={{ textAlign: "right", fontWeight: "bold" }}>Time left: <span style={{ color: 'black' }}>{Math.ceil(counter / 60)} minute/s</span></p>
                : counter > 0
                    ? <p className='redText' style={{ textAlign: "right", fontWeight: "bold" }}>Time left: <span style={{ color: 'black' }}>{counter} seconds</span></p>
                    : null}
            <Form
                name="addNewProjectForm"
                onSubmit={(e) => { formik.handleSubmit(e); setSubmitClicked(true); e.preventDefault(); }}
                autoComplete="off"
            >
                <FormGroup row>
                    <Label
                        for="projAddress"
                        sm={2}
                    >
                        Project Location (Street Address)
                    </Label>
                    <Col sm={6} className="col-sm-6 col-md-7">
                        <GooglePlacesAutocomplete
                            apiKey={key}
                            name="projAddress"
                            selectProps={{
                                value,
                                onChange: setValue,
                            }}
                            apiOptions={{ language: 'en', region: 'ZA' }}
                            onLoadFailed={(error) => (
                                console.error("Could not inject Google script:", error))}
                        />
                    </Col>
                    <Col className="col-sm-2 col-8 col-md-3 mt-sm-0 mt-2">
                        <Button
                            color="primary"
                            className='me-1 mb-1'
                            disabled={isLoading}
                            onClick={() => setCoords()}
                        >
                            {isLoading ? 'Loading…' : 'Use Address'}
                        </Button>
                        <Button
                            color="danger"
                            className='me-1 mb-1'
                            onClick={() => clearAddress()}
                        >
                            Clear
                        </Button>
                    </Col>
                </FormGroup>

                {addressChosen ? <>
                    {address !== "" ?
                        <FormGroup row>
                            <Label
                                for="proj_descrip"
                                sm={2}
                            >
                                Project Description (short)
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="proj_descrip"
                                    name="proj_descrip"
                                    placeholder="e.g. Widening of Narnia Road"
                                    type="text"
                                    value={inputs.proj_descrip || ''}
                                    invalid={(formik.errors.proj_descrip === 'Required' && formik.touched.proj_descrip) ||
                                        (formik.errors.proj_descrip === 'Required' && submitClicked === true)
                                        ? true
                                        : false}
                                    onChange={(e) => { handleChange(e); formik.handleChange(e); }}
                                    onBlur={formik.handleBlur}
                                />
                            </Col>
                            {(formik.errors.proj_descrip === 'Required' && formik.touched.proj_descrip) ||
                                (formik.errors.proj_descrip === 'Required' && submitClicked === true)
                                ? <div className="redText">{formik.errors.proj_descrip}</div>
                                : null}
                        </FormGroup>
                        : null}

                    <FormGroup row>
                        <Label
                            for="proj_descrip"
                            sm={2}
                        >
                            Project Description (detailed)
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="proj_descrip"
                                name="proj_descrip_long"
                                placeholder="Provide a more detailed description of the project "
                                type="textarea"
                                value={inputs.proj_descrip_long || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="municipality"
                            sm={2}
                        >
                            Municipality
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="municipality"
                                name="municipality"
                                placeholder="Municipality"
                                type="text"
                                disabled
                                value={inputs.municipality || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label
                            for="wardNum"
                            sm={2}
                        >
                            Ward Number
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="wardNum"
                                name="ward"
                                placeholder="Ward"
                                type="number"
                                disabled
                                value={inputs.ward || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label
                            for="ward_person_name"
                            sm={2}
                        >
                            Ward councillor
                        </Label>
                        <Col sm={10}>
                            <Input
                                name="ward_person_name"
                                placeholder="Ward councillor name"
                                type="text"
                                disabled
                                value={inputs.ward_person_name || ''}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label
                            for="mapCoords"
                            sm={2}
                        >
                            Map Coordinates (Lat, Long)
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="mapCoords"
                                name="map_location"
                                placeholder="Latitude and Longitude"
                                type="text"
                                disabled
                                value={inputs.map_location || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.proj_site_camp || ''}
                    >
                        <Label
                            for="proj_site_camp"
                            sm={2}
                        >
                            Project Site Camp
                        </Label>
                        <legend className="col-form-label col-sm-3">
                            Is there a project site camp?
                        </legend>
                        <Col sm={4} className={formik.errors.proj_site_camp && submitClicked === true ? "redBorder" : ""}>
                            <Button color={inputs.proj_site_camp === "true"
                                ? "success"
                                : "secondary"} className='me-1' name="proj_site_camp" onClick={(e) => { formik.handleChange(e); handleChange(e); }} value={true}>
                                Yes
                            </Button>
                            <Button color={inputs.proj_site_camp === "false"
                                ? "danger"
                                : "secondary"} className='me-1' name="proj_site_camp" onClick={(e) => { handleSiteCampValues(e, "site_camp") }} value={false}>
                                No
                            </Button>
                        </Col>
                        {formik.errors.proj_site_camp && submitClicked === true ? (
                            <div className="redText">{formik.errors.proj_site_camp}</div>
                        ) : null}
                    </FormGroup>

                    {inputs.proj_site_camp === "true" ?
                        <FormGroup
                            row
                            tag="fieldset"
                            value={inputs.site_camp_separate || ''}
                        >
                            <Label
                                for="site_camp_separate"
                                className="col-sm-2"
                            >
                                {" "}
                            </Label>
                            <legend className="col-form-label col-sm-3">
                                Is the site camp separate from the project site?
                            </legend>
                            <Col sm={4}>
                                <Button color={inputs.site_camp_separate === "true"
                                    ? "success"
                                    : "secondary"} className='me-1' name="site_camp_separate" onClick={handleChange} value={true}>
                                    Yes
                                </Button>
                                <Button color={inputs.site_camp_separate === "false"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="site_camp_separate" onClick={(e) => { handleSiteCampValues(e, "site_camp_where") }} value={false}>
                                    No
                                </Button>
                            </Col>
                        </FormGroup> : null}

                    {inputs.site_camp_separate === "true" ? <FormGroup row>
                        <Label
                            for="site_camp_where"
                            sm={2}
                        >
                            Briefly explain where the site camp is?
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="siteCampLocation"
                                name="site_camp_where"
                                type="textarea"
                                value={inputs.site_camp_where || ''}
                                onChange={(e) => { handleChange(e); }}
                            />
                        </Col>
                    </FormGroup> : null}

                    <FormGroup row>
                        <Label
                            for="proj_type"
                            sm={2}
                        >
                            Project Type
                        </Label>
                        <Col sm={10}>
                            <Input
                                invalid={formik.errors.proj_type === 'Required' && formik.touched.proj_type ?
                                    true :
                                    formik.errors.proj_type === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                id="projType"
                                name="proj_type"
                                type="select"
                                value={inputs.proj_type || ''}
                                onChange={(e) => { handleChange(e); formik.handleChange(e); }}
                                onBlur={formik.handleBlur}

                            > {projTypeOptions.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                            </Input>
                        </Col>
                        {formik.errors.proj_type === 'Required' && formik.touched.proj_type ? (
                            <div className="redText">{formik.errors.proj_type}</div>
                        ) : formik.errors.proj_type === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.proj_type}</div>
                        ) : null}
                    </FormGroup>

                    {/* <p>staff array:</p>
                    <pre>
                        {JSON.stringify(staffArray, null, 2)}
                    </pre>

                    <p>staff types:</p>
                    <pre>
                        {JSON.stringify(staffTypes, null, 2)}
                    </pre>

                    <p>staff object:</p>
                    <pre>
                        {JSON.stringify(staffObject, null, 2)}
                    </pre>

                    <p>inputs object:</p>
                    <pre>
                        {JSON.stringify(inputs, null, 2)}
                    </pre> */}

                    <FormGroup row>
                        <div className="mb-2 col-12 col-sm-5 col-md-6 align-middle">
                            <Label
                                for="contract_core_staff_names"
                                className="col-sm-5 col-8"
                            >
                                Local Staff (names)
                            </Label>
                        </div>

                        {array}

                        {formik.errors.contract_core_staff_names === 'Required' && formik.touched.contract_core_staff_names ? (
                            <div className="redText">{formik.errors.contract_core_staff_names}</div>
                        ) : formik.errors.contract_core_staff_names === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.contract_core_staff_names}</div>
                        ) : null}

                        {formik.errors.staff_type === 'Required' && formik.touched.staff_type ? (
                            <div className="redText">{formik.errors.staff_type}</div>
                        ) : formik.errors.staff_type === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.staff_type}</div>
                        ) : null}

                        <Button className="ms-2 ms-sm-3 col-2 col-sm-1" onClick={addStaffRw}>Add</Button>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="contract_core_staff_num"
                            sm={2}
                        >
                            Local Staff (number)
                        </Label>
                        <Col sm={4}>
                            <Input
                                id="coreStaffNum"
                                name="contract_core_staff_num"
                                placeholder="How many core staff members are there?"
                                type="number"
                                disabled
                                value={
                                    staffArray.length > 0 && staffArray[0] !== ""
                                        ? staffArray.length
                                        : staffArray[0] === ""
                                            ? "0"
                                            : "0"}
                                onChange={(e) => { handleChange(e); }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="contract_number"
                            sm={2}
                        >
                            Contract Number
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="contractNum"
                                name="contract_number"
                                placeholder="e.g. 1D-45368"
                                type="text"
                                value={inputs.contract_number || ''}
                                invalid={formik.errors.contract_number === 'Required' && formik.touched.contract_number ?
                                    true :
                                    formik.errors.contract_number === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                onChange={(e) => { formik.handleChange(e); handleChange(e); }}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        {formik.errors.contract_number === 'Required' && formik.touched.contract_number ? (
                            <div className="redText">{formik.errors.contract_number}</div>
                        ) : formik.errors.contract_number === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.contract_number}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="client_type"
                            sm={2}
                        >
                            Client Type
                        </Label>
                        <Col sm={10}>
                            <Input
                                invalid={formik.errors.client_type === 'Required' && formik.touched.client_type ?
                                    true :
                                    formik.errors.client_type === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                id="clientType"
                                name="client_type"
                                type="select"
                                value={inputs.client_type || ''}
                                onChange={(e) => { handleClientType(e); }}
                                onBlur={formik.handleBlur}
                            >
                                {clientType.map((option, index) => (
                                    <option key={index} value={option.value}>{option.label}</option>
                                ))}
                            </Input>
                        </Col>
                        {formik.errors.client_type === 'Required' && formik.touched.client_type ? (
                            <div className="redText">{formik.errors.client_type}</div>
                        ) : formik.errors.client_type === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.client_type}</div>
                        ) : null}
                    </FormGroup>

                    {chosenClientType !== "Private Client" ? <FormGroup row>
                        <Label
                            for="municipality_dept"
                            sm={2}
                        >
                            Municipality Department
                        </Label>
                        <Col sm={10}>
                            <Input
                                invalid={formik.errors.municipality_dept === 'Required' && formik.touched.municipality_dept ?
                                    true :
                                    formik.errors.municipality_dept === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                id="muniDept"
                                name="municipality_dept"
                                type="select"
                                value={inputs.municipality_dept || ''}
                                onChange={(e) => { handleChange(e); formik.handleChange(e); }}
                                onBlur={formik.handleBlur}
                            >
                                {municipalityDeptOptions.map((option, index) => (
                                    <option key={index} value={option.value}>{option.label}</option>
                                ))}
                            </Input>
                        </Col>
                        {formik.errors.municipality_dept === 'Required' && formik.touched.municipality_dept && chosenClientType !== "Private Client" ? (
                            <div className="redText">{formik.errors.municipality_dept}</div>
                        ) : formik.errors.municipality_dept === 'Required' && submitClicked === true && chosenClientType !== "Private Client" ? (
                            <div className="redText">{formik.errors.municipality_dept}</div>
                        ) : null}
                    </FormGroup>
                        : null}

                    <FormGroup row>
                        <Label
                            for="client_name"
                            sm={2}
                        >
                            Client Name
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="clientName"
                                name="client_name"
                                placeholder="What is the client name?"
                                type="text"
                                value={inputs.client_name || ''}
                                invalid={formik.errors.client_name === 'Required' && formik.touched.client_name ?
                                    true :
                                    formik.errors.client_name === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                onChange={(e) => { formik.handleChange(e); handleChange(e); }}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        {formik.errors.client_name === 'Required' && formik.touched.client_name ? (
                            <div className="redText">{formik.errors.client_name}</div>
                        ) : formik.errors.client_name === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.client_name}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_engineer"
                            sm={2}
                        >
                            Project Engineer
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projEngineer"
                                name="proj_engineer"
                                placeholder="What is the Project Engineer's Name?"
                                type="text"
                                value={inputs.proj_engineer || ''}
                                invalid={formik.errors.proj_engineer === 'Required' && formik.touched.proj_engineer ?
                                    true :
                                    formik.errors.proj_engineer === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                onChange={(e) => { formik.handleChange(e); handleChange(e); }}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        {formik.errors.proj_engineer === 'Required' && formik.touched.proj_engineer ? (
                            <div className="redText">{formik.errors.proj_engineer}</div>
                        ) : formik.errors.proj_engineer === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.proj_engineer}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="clerk_of_works"
                            sm={2}
                        >
                            Clerk of Works
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="clerkWorks"
                                name="clerk_of_works"
                                placeholder="Name of the Clerk of Works?"
                                type="text"
                                value={inputs.clerk_of_works || ''}
                                onChange={(e) => { handleChange(e); }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="contractor"
                            sm={2}
                        >
                            Contractor
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="contractor"
                                name="contractor"
                                placeholder="Contractor company name?"
                                type="text"
                                value={inputs.contractor || ''}
                                invalid={formik.errors.contractor === 'Required' && formik.touched.contractor ?
                                    true :
                                    formik.errors.contractor === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                onChange={(e) => { formik.handleChange(e); handleChange(e); }}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        {formik.errors.contractor === 'Required' && formik.touched.contractor ? (
                            <div className="redText">{formik.errors.contractor}</div>
                        ) : formik.errors.contractor === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.contractor}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_manager"
                            sm={2}
                        >
                            Project Manager
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projManager"
                                name="proj_manager"
                                placeholder="What is the Project Manager's name?"
                                type="text"
                                value={inputs.proj_manager || ''}
                                invalid={formik.errors.proj_manager === 'Required' && formik.touched.proj_manager ?
                                    true :
                                    formik.errors.proj_manager === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                onChange={(e) => { formik.handleChange(e); handleChange(e); }}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        {formik.errors.proj_manager === 'Required' && formik.touched.proj_manager ? (
                            <div className="redText">{formik.errors.proj_manager}</div>
                        ) : formik.errors.proj_manager === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.proj_manager}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_start_date"
                            sm={2}
                        >
                            Project Start Date
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projStart"
                                name="proj_start_date"
                                placeholder="What is the start date for the project?"
                                type="date"
                                value={inputs.proj_start_date || ''}
                                invalid={formik.errors.proj_start_date === 'Required' && formik.touched.proj_start_date ?
                                    true :
                                    formik.errors.proj_start_date === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                onChange={(e) => { formik.handleChange(e); handleChange(e); }}
                                onBlur={formik.handleBlur}
                            />
                            <small className="w-100">E.g. 2022/10/04 is 4 Oct 2022 </small>
                        </Col>
                        {formik.errors.proj_start_date === 'Required' && formik.touched.proj_start_date ? (
                            <div className="redText">{formik.errors.proj_start_date}</div>
                        ) : formik.errors.proj_start_date === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.proj_start_date}</div>
                        ) : null}

                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_end_date_projected"
                            sm={2}
                        >
                            Project End Date (projected)
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projEndProjected"
                                name="proj_end_date_projected"
                                placeholder="What is the projected/planned end date for the project?"
                                type="date"
                                value={inputs.proj_end_date_projected || ''}
                                invalid={(formik.errors.proj_end_date_projected === 'Required' && formik.touched.proj_end_date_projected) ||
                                    (formik.errors.proj_end_date_projected === 'End date must be AFTER project start date.' && formik.touched.proj_end_date_projected) ||
                                    (formik.errors.proj_end_date_projected === 'Required' && submitClicked === true)
                                    ? true
                                    : false}
                                onChange={(e) => { handleChange(e); checkEndDate(e.target.value, ""); formik.handleChange(e); }}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        {(formik.errors.proj_end_date_projected === 'Required' && formik.touched.proj_end_date_projected)
                            || (formik.errors.proj_end_date_projected === 'End date must be AFTER project start date.' && formik.touched.proj_end_date_projected)
                            || (formik.errors.proj_end_date_projected === 'Required' && submitClicked === true)
                            ? <div className="redText">{formik.errors.proj_end_date_projected}</div>
                            : null}
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_ext_date"
                            sm={2}
                        >
                            Project Extended Date (if applicable)
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projExtendedDate"
                                name="proj_ext_date"
                                placeholder="What is the extended end date for the project (if there is one)?"
                                type="date"
                                value={inputs.proj_ext_date || ''}
                                invalid={(formik.errors.proj_ext_date === 'Extended end date must be AFTER projected end date.' && formik.touched.proj_ext_date) ||
                                    (formik.errors.proj_ext_date === 'Extended end date must be AFTER projected end date.' && submitClicked === true)
                                    ? true
                                    : false}
                                onChange={(e) => { handleChange(e); checkEndDate("", e.target.value); formik.handleChange(e); }}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        {(formik.errors.proj_ext_date === 'Extended end date must be AFTER projected end date.' && formik.touched.proj_ext_date)
                            || (formik.errors.proj_ext_date === 'Extended end date must be AFTER projected end date.' && submitClicked === true)
                            ? <div className="redText">{formik.errors.proj_ext_date}</div>
                            : null}
                    </FormGroup>

                    {inputs.proj_ext_date !== undefined ? <FormGroup row>
                        <Label
                            for="proj_ext_date_comment"
                            sm={2}
                        >
                            Comment
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projExtDateComment"
                                name="proj_ext_date_comment"
                                placeholder='Enter comment about project end date being extended...'
                                type="textarea"
                                value={inputs.proj_ext_date_comment || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </FormGroup> : null}

                    <FormGroup row>
                        <Label
                            for="proj_status"
                            sm={2}
                        >
                            Status of Project
                        </Label>
                        <Col sm={10}>
                            <Input
                                invalid={formik.errors.proj_status === 'Required' && formik.touched.proj_status ?
                                    true :
                                    formik.errors.proj_status === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                onChange={(e) => { handleChange(e); formik.handleChange(e); }}
                                onBlur={formik.handleBlur}
                                id="projStatus"
                                name="proj_status"
                                type="select"
                                value={inputs.proj_status || ''}

                            > {projStatus.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                            </Input>
                        </Col>
                        {formik.errors.proj_status === 'Required' && formik.touched.proj_status ? (
                            <div className="redText">{formik.errors.proj_status}</div>
                        ) : formik.errors.proj_status === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.proj_status}</div>
                        ) : null}
                    </FormGroup>

                    {inputs.proj_status === "Complete" ? <FormGroup row>
                        <Label
                            for="proj_completed_date"
                            sm={2}
                        >
                            Completion Date
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projExtendedDate"
                                name="proj_completed_date"
                                placeholder="When was the project completed?"
                                type="date"
                                value={inputs.proj_completed_date || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </FormGroup>
                        : null}

                    <FormGroup row>
                        <Label
                            for="addPhoto"
                            sm={2}
                        >
                            <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                            &nbsp;&nbsp;Upload Photo/s
                        </Label>
                        <Col className="col-sm-10 col-12 mb-1">

                            <Input
                                id="addPhoto"
                                name="addPhoto"
                                type="file"
                                onChange={(e) => changePhotoHandler(e, setNumOfPics, setImgObjects, setImgUrls, setImgNames)}
                                multiple
                            />
                        </Col>
                    </FormGroup>

                    <div className='m-2 row'>
                        {numOfPics > 0 ? <div className='mb-3 col-12'>Number of photos to be added: {numOfPics}</div> : null}
                        {
                            imgUrls.length > 0
                                ? imgUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y"
                                            style={{ right: "0.05rem" }} color="danger"
                                            onClick={(e) => removeImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                        <div className='p-1'>{imgNames[index]}</div>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    <FormGroup row>
                        <Label
                            for="addDoc"
                            sm={2}
                        >
                            <FontAwesomeIcon icon={faFileLines} transform="grow-10" />&nbsp;&nbsp;Upload Document/s
                        </Label>
                        <Col className="col-sm-10 col-12 mb-1">
                            <Input
                                id="addDoc"
                                name="addDoc"
                                type="file"
                                onChange={(e) => { changeDocHandler(e, setDocNames, setNumOfDocs, setDocObjects, setDocUrls, setDocPrivacyArray); }}
                                multiple
                            />
                            <FormText>
                            </FormText>
                        </Col>
                    </FormGroup>

                    {/* New docs section */}
                    <div className='p-2 mb-1 row'>
                        {numOfDocs > 0 ? <div className='mb-2 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfDocs}
                                </Badge> New
                            </Button>
                        </div> : null}
                        {
                            docUrls.length > 0
                                ? docUrls.map((name, index) => (
                                    <div key={index} className="row mt-1 mb-1 col-12 col-sm-12 d-flex align-items-center">
                                        <Col className='col-12 col-md-7 mb-2 p-2'>
                                            <a href={name} target="_blank" rel="noreferrer">
                                                <FontAwesomeIcon className="ms-2 me-2" icon={faFileLines} transform="grow-10" />
                                                {docNames[index].length >= 35 ? docNames[index].substr(0, 35) + "..." : docNames[index]}
                                            </a>
                                        </Col>

                                        <div className='col-12 col-md-5 mb-1 row'>
                                            <Col className='col-10 col-md-6 mb-1'>
                                                <Input
                                                    onChange={(e) => { handleDocPrivacyChange(e, index) }}
                                                    name="doc_privacy"
                                                    type="select"
                                                    value={docPrivacyArray[index] || ''}

                                                >  <option key="a" value="" className="py-2" disabled>Document Privacy</option>
                                                    {docPrivacyStatus.map((option, index) => (
                                                        <option key={index} value={option.value} className="py-2 px-1">{option.label}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                            <Col className='col-2 col-md-3 mb-1'>
                                                <Button color="danger" className="ms-2" onClick={(e) => removeDoc(e, index)}>
                                                    <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                                </Button>
                                            </Col>
                                        </div>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    {/* {submitClicked === true && validated === false
                        ? <p className='redText'>Missing information above. Please fill in and then click the &quot;Submit&quot; button again.</p>
                        : null} */}

                    {missingInfoMsg !== ""
                        ? <p className='redText'>{missingInfoMsg}</p>
                        : null}

                    <FormGroup
                        row
                    >
                        <Col
                            sm={{
                                offset: 0,
                                size: 12,
                                marginBottom: "1rem"
                            }}
                        >
                            <Button color="primary" disabled={isSubmitting} type="submit">
                                {isSubmitting ? 'Submitting…' : 'Submit'}
                            </Button>
                            <Button className="ms-1" color="danger" onClick={() => cancelAddProject()}>
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>
                </> : null}
            </Form>
        </div>
    );
}

AddProjectForm.propTypes = {
    CreateAlert: PropTypes.func,
    placesKey: PropTypes.string,
    ProjectsArray: PropTypes.array,
    toggleProjectInfoChanged: PropTypes.func,
    setIsLoaded: PropTypes.func,
    setIsLoadedImages: PropTypes.func,
    setIsLoadedDocs: PropTypes.func,
    setLoadedStaffArrays: PropTypes.func,
    setProjImagesError: PropTypes.func,
    setShowAddProjAlerts: PropTypes.func,
    activeTab: PropTypes.string,
    userInfo: PropTypes.object,
};

export default AddProjectForm;