export const addWardRow = (wardArray, index, wardRows, setIndex, setWardRows) =>
{
    if (wardArray.length > 0)
    {
        if (wardArray[index] === "" || wardArray[index] === undefined || wardArray[index] === 0)
        {
            swal("Error", "Please add a ward before adding another.", "error");
        }
        else if (Number(wardArray[index]) < 0)
        {
            swal("Error", "Ward number must be greater than zero.", "error");
        }
        else
        {
            let row = wardRows;
            row++;
            setIndex(row - 1);
            setWardRows(row);
        }
    }
    else if (wardArray.length === 0)
    {
        swal("Error", "Please add a ward first.", "error");
    }
}

export const removeWard = (wardRows, setWardRows, setIndex, index, wardArray, setWardArray, inputs, setInputs) =>
{
    if (wardRows > 1)
    {
        let row = wardRows;
        row--;

        if (index - 1 > 0)
        {
            setIndex(index - 1);
        }
        else
        {
            setIndex(0);
        }

        setWardRows(row);
        let tempwardarray = wardArray;
        tempwardarray.splice(index, 1);
        setWardArray(tempwardarray);
        setInputs(prevState => ({ ...prevState, ["wards"]: tempwardarray }));
    }
    else if (wardRows === 1 && wardArray.length > 0)
    {
        // let select_box = document.getElementById("wards");
        // select_box.selectedIndex = 0;

        let tempwardarray = wardArray;
        tempwardarray.splice(index, 1);
        setWardArray(tempwardarray);
        setIndex(0);
        if (inputs.wards)
        {
            setInputs(prevState => ({ ...prevState, ["wards"]: undefined }));
        }
    }
}

export const handleWards = (index, ward, wardArray, setWardArray, setInputs) =>
{
    let count = 0;

    wardArray.forEach((wd, index) =>
    {
        if (wd === ward)
        {
            ++count;
        }
    });

    if (ward !== undefined)
    {
        let tempwardarray = [];

        if (wardArray.length === 0)
        {
            tempwardarray.push(Number(ward));
            setWardArray(tempwardarray);
            setInputs(prevState => ({ ...prevState, ["wards"]: tempwardarray }));
        }
        else if (wardArray.length === index && count === 0)
        {
            tempwardarray = wardArray;
            tempwardarray.push(Number(ward));
            setWardArray(tempwardarray);
            setInputs(prevState => ({ ...prevState, ["wards"]: tempwardarray }));
        }
        else
        {
            if (count === 0 && wardArray[index] !== undefined && ward !== undefined)
            {
                tempwardarray = wardArray;
                tempwardarray[index] = Number(ward);
                setInputs(prevState => ({ ...prevState, ["wards"]: tempwardarray }));
                setWardArray(tempwardarray);
            }
        }
    }
}

export const loadWardArray = (loginsArray, currentIndex, setWardArray, setWardRows, setWardArrayLoaded) =>
{
    if (loginsArray.length > 0 && currentIndex !== -1)
    {
        let array = loginsArray[currentIndex].wards !== undefined ? loginsArray[currentIndex].wards : [];
        setWardArray(array);

        if (array.length <= 1)
        {
            setWardRows(1);
        }
        else
        {
            setWardRows(array.length);
        }
    }
    setWardArrayLoaded(true);
}

