import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import
{
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper"
import swal from "sweetalert";

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } })
{
  try
  {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase")
    {
      const response = yield call(fireBaseBackend.forgetPassword, user.email)
      if (response)
      {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt")
    {
      // "/jwt-forgot-pwd", 
      const response = yield call(postJwtForgetPwd, {
        email: user.email,
      })
      if (response.message === "Password reset email sent.") 
      {
        swal("Success", "Password reset link has been sent to your email inbox.", "success");
        window.setTimeout(function () { window.location.href = "/login"; }, 2000);
      }
      else if (response.message === "Email address does not exist")
      {
        swal("Error", "Email address does not exist. Please register as a new user.", "error");
        window.setTimeout(function () { window.location.href = "/register"; }, 2000);
      }
      else if (response.message === "Something went wrong")
      {
        swal("Error", "Something went wrong. Please try again.", "error");
        window.setTimeout(function () { window.location.href = "/forgot-password"; }, 2000);
      }
    }
    else
    {
      const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
        email: user.email,
      })
      if (response)
      {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    }
  } catch (error)
  {
    yield put(userForgetPasswordError(error))
  }
}

export function* watchUserPasswordForget()
{
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga()
{
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
