// USER
export const POST_UPDATED_USER = "/postupdateduser"

// REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"
export const POST_REGISTER = "/register"

// WARD COUNCILLORS
export const GET_COUNCILLORS = "/getwardcouncillors"
export const GET_COUNCILLOR = "/getwardcouncillor"
export const ADD_COUNCILLOR = "/addwardcouncillor"

// CVS
export const GET_CVS = "/getcvs"

// SUCCESS STORIES
export const ADD_STORY = "/add/story"
export const GET_STORIES = "/getstories"

// PROJECTS
export const GET_PROJECTS = "/getprojects"
export const GET_PROJECT_SUM = "/getprojectsum"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

// VISITS
export const GET_VISITS = "/getvisits"
export const GET_VISIT_DETAIL = "/visit"
export const ADD_NEW_VISIT = "/add/visit"
export const UPDATE_VISIT = "/update/visit"
export const DELETE_VISIT = "/delete/visit"
export const GET_VISIT_SUM = "/getvisitsum"

// IMAGES - PROJECT
export const ADD_NEW_PROJECT_IMAGES = "/addprojectimages"
export const GET_PROJECT_IMAGES = "/getprojectimages"
export const UPDATE_PROJECT_IMAGES = "/update/project/images"
export const DELETE_PROJECT_IMAGES = "/deleteprojectimages"

// DOCUMENTS - PROJECT
export const ADD_NEW_PROJECT_DOCS = "/addprojectdocs"
export const GET_PROJECT_DOCS = "/getprojectdocs"
export const UPDATE_PROJECT_DOCS = "/update/project/docs"
export const DELETE_PROJECT_DOCS = "/deleteprojectdocs"

// IMAGES - SITE VISIT
export const ADD_NEW_VISIT_IMAGES = "/addvisitimages"
export const GET_VISIT_IMAGES = "/getvisitimages"
export const UPDATE_VISIT_IMAGES = "/update/visit/images"
export const DELETE_VISIT_IMAGES = "/deletevisitimages"

// DOCUMENTS - SITE VISIT
export const ADD_NEW_VISIT_DOCS = "/addvisitdocs"
export const GET_VISIT_DOCS = "/getvisitdocs"
export const UPDATE_VISIT_DOCS = "/update/visit/docs"
export const DELETE_VISIT_DOCS = "/deletevisitdocs"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const GET_JWT_AUTH = "/get-jwt-auth"
export const GET_LOGINS = "/getcmslogins"
export const GET_ALL_LOGINS = "/getcmsloginsinactive"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forgot-pwd"
export const SOCIAL_LOGIN = "/social-login"

// -------------------------------------------------------------------------------

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"
