import { API_URL } from "../../helpers/ApiUrl";

const fetchAProject = async (id, setProjectArray, setProjectMsgFromServer) =>
{
    let proj = { proj_id: id };

    await fetch(
        `${API_URL}/getprojectdetail`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(proj),
        }
    )
        .then((response) => response.json())
        .then((result) =>
        {
            let array = JSON.parse(result.message);
            setProjectArray(array);
            setProjectMsgFromServer("Project retrieved successfully.")
            return "Project retrieved successfully.";
        })
        .catch((error) =>
        {
            console.error('Error:', error.message);
            setProjectMsgFromServer("Caught error")
            return "Caught error";
        });
}

export default fetchAProject;