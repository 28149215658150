export const addActivityRow = (activityArray, index, activityRows, setIndex, setActivityRows) =>
{
    if (activityArray.length > 0)
    {
        if (activityArray[index] === "" || activityArray[index] === undefined)
        {
            swal("Error", "Please select an activity before adding another.", "error");
        }
        else
        {
            let row = activityRows;
            row++;
            setIndex(row - 1);
            setActivityRows(row);
        }
    }
    else if (activityArray.length === 0)
    {
        swal("Error", "Please select an activity first.", "error");
    }
}

export const removeActivity = (activityRows, setActivityRows, setIndex, index, activityArray, setActivityArray, inputs, setInputs) =>
{
    if (activityRows > 1)
    {
        let row = activityRows;
        row--;

        if (index - 1 > 0)
        {
            setIndex(index - 1);
        }
        else
        {
            setIndex(0);
        }

        setActivityRows(row);
        let tempactivityarray = activityArray;
        tempactivityarray.splice(index, 1);
        setActivityArray(tempactivityarray);
        setInputs(prevState => ({ ...prevState, ["activities_onsite"]: tempactivityarray }));
    }
    else if (activityRows === 1 && activityArray.length > 0)
    {
        let select_box = document.getElementById("siteActivities");
        select_box.selectedIndex = 0;

        let tempactivityarray = activityArray;
        tempactivityarray.splice(index, 1);
        setActivityArray(tempactivityarray);
        setIndex(0);
        if (inputs.activities_onsite)
        {
            setInputs(prevState => ({ ...prevState, ["activities_onsite"]: undefined }));
        }
    }
}

export const handleActivity = (index, activity, activityArray, setActivityArray, setInputs) =>
{
    let count = 0;

    activityArray.forEach((activ, index) =>
    {
        if (activ === activity)
        {
            ++count;
        }
    });

    if (activity !== "" && activity !== undefined)
    {
        let tempactivityarray = [];

        if (activityArray.length === 0)
        {
            tempactivityarray.push(activity);
            setActivityArray(tempactivityarray);
            setInputs(prevState => ({ ...prevState, ["activities_onsite"]: tempactivityarray }));
        }
        else if (activityArray.length === index && count === 0)
        {
            tempactivityarray = activityArray;
            tempactivityarray.push(activity);
            setActivityArray(tempactivityarray);
            setInputs(prevState => ({ ...prevState, ["activities_onsite"]: tempactivityarray }));
        }
        else
        {
            if (count === 0 && activityArray[index] !== undefined && activity !== undefined)
            {
                tempactivityarray = activityArray;
                tempactivityarray[index] = activity;
                setInputs(prevState => ({ ...prevState, ["activities_onsite"]: tempactivityarray }));
                setActivityArray(tempactivityarray);
            }
        }
    }
}

export const loadActivityArray = (dropdownOn, chosenVisitArray, setActivityArray, setActivityRows, setActivityArrayLoaded) =>
{
    if (!dropdownOn && chosenVisitArray.length > 0)
    {
        let array = chosenVisitArray[0].activities_onsite;
        setActivityArray(array);

        if (array.length <= 1)
        {
            setActivityRows(1);
        }
        else
        {
            setActivityRows(array.length);
        }
    }
    setActivityArrayLoaded(true);
}

