import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";

const sortProjectImages = (isLoadedImages, isLoaded, projectId, projectImagesArray, setImageUrls, setNumOfImages) =>
{
    if (isLoadedImages && isLoaded && projectId !== 0)
    {
        let images = JSON.parse(JSON.stringify(projectImagesArray));
        let projectImageServerMessages = ["Project images retrieved successfully.",
            "Some error occurred while retrieving images.",
            "No project images have been saved yet.",
            "Something went wrong while finding a project images!"]
        images = removeSuccessMessage(images, projectImageServerMessages);

        let projectImageArray = images.filter(images => images.proj_id === projectId);

        if (projectImageArray.length > 0)
        {
            let imgUrlArray = projectImageArray[0].imageUrlArray;
            let numOfPics = imgUrlArray.length;
            setImageUrls(imgUrlArray);
            setNumOfImages(numOfPics);
        }
    }
}

export default sortProjectImages;