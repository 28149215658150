export const loadStaffArrays = (
    isLoaded,
    loadedStaffArrays,
    setStaffArray,
    setStaffRows,
    setLoadedStaffArrays,
    setProjectsExist,
    setStaffTypes,
    setStaffObject,
    projectToEdit
) =>
{
    if (isLoaded && !loadedStaffArrays && projectToEdit !== undefined && Object.keys(projectToEdit).length > 0)
    {
        setStaffArray([]);
        setStaffTypes([]);
        setStaffRows(1);
        setStaffObject({});

        console.log("Loaded staff arrays has run");
        let obj = {};

        // if (projectsArray[projectIndex].contract_core_staff !== undefined)
        // {
        //     obj = projectsArray[projectIndex].contract_core_staff;
        // }

        if (projectToEdit.contract_core_staff !== undefined)
        {
            obj = projectToEdit.contract_core_staff;
        }

        if (Object.keys(obj).length > 0 && obj.constructor === Object) 
        {
            let tempStaffArray = Object.keys(obj);
            setStaffArray(tempStaffArray);
            setStaffRows(tempStaffArray.length);
            setStaffObject(obj);
            let tempStaffTypes = Object.values(obj);
            setStaffTypes(tempStaffTypes);
            setProjectsExist(true);

            // Pop server success msg off end of array for "Choose projects to edit" dropdown
            // let tempArray = JSON.parse(JSON.stringify(projectsArray));

            // let length = tempArray.length - 1;
            // if (tempArray[length] === "Projects retrieved successfully.")
            // {
            //     tempArray.pop();
            // }

            // let projectsUserCanEdit = [];
            // tempArray.forEach((proj, index) =>
            // {
            //     if (isAdmin || userWard === proj.ward)
            //     {
            //         projectsUserCanEdit.push(proj);
            //     }
            // });

            //setProjDropdownArray(tempArray);
            setLoadedStaffArrays(true);
        }
        else if (Object.keys(obj).length === 0 && obj.constructor === Object)
        {
            let array = [];

            // if (projectsArray[projectIndex].contract_core_staff_names !== undefined)
            // {
            //     array = projectsArray[projectIndex].contract_core_staff_names;
            // }

            if (projectToEdit.contract_core_staff_names !== undefined)
            {
                array = projectToEdit.contract_core_staff_names;
            }

            array.forEach((name, i) =>
            {
                obj[name] = "";
            });

            setStaffObject(obj);
            setProjectsExist(true);

            // Set initial values for "contract core staff (names)"
            setStaffArray(array);
            if (array.length !== 0)
            {
                setStaffRows(array.length);
            }
            else
            {
                setStaffRows(1);
            }

            // Pop server success msg off end of array for "Choose projects to edit" dropdown
            // let tempArray = [];

            // if (projectsArray.length !== 0) 
            // {
            //     tempArray = JSON.parse(JSON.stringify(projectsArray));
            //     let length = tempArray.length - 1;
            //     if (tempArray[length] === "Projects retrieved successfully.")
            //     {
            //         tempArray.pop();
            //     }
            // }

            // let projectsUserCanEdit = [];
            // tempArray.forEach((proj, index) =>
            // {
            //     if (isAdmin || userWard === proj.ward)
            //     {
            //         projectsUserCanEdit.push(proj);
            //     }
            // });

            //setProjDropdownArray(tempArray);
            setLoadedStaffArrays(true);
        }
        else
        {
            console.log("Error - Staff arrays could not be loaded.");
        }
    }
}