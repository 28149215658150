// Post request to get ward number after searching for a specific street address
async function FetchWardNum(lat, long)
{
    const response = await fetch("https://geoapi.www-www.co.za/Api/Ward", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: `[${long},${lat}]`,
    })
        .then((res) => res.json())
        .then(
            (result) =>
            {
                //console.log("Ward number is: " + result);
                return result;
            },
            (error) =>
            {
                console.log("Error from fetching ward number is: " + error);
                return "An error has occurred.";
            }
        );

    return response;
}

export default FetchWardNum;