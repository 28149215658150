// {
//     label: "Status",
//     value: "",
// },

const projStatusFilterOptions = [
    {
        label: "All",
        value: "",
    },
    {
        label: "Not Started Yet",
        value: "Not Started Yet",
    },
    {
        label: "Active",
        value: "Active",
    },
    {
        label: "Work Stoppage",
        value: "Work Stoppage",
    },
    {
        label: "Complete",
        value: "Complete",
    },
];

const isActiveFilterOptions = [
    {
        label: "Both",
        value: -1,
    },
    {
        label: "Active",
        value: 1,
    },
    {
        label: "inActive",
        value: 0,
    },
]

const imagesFilterOptions = [
    {
        label: "Both",
        value: "",
    },
    {
        label: "Has Images",
        value: "images",
    },
    {
        label: "No Images",
        value: "no images",
    },
]

const docsFilterOptions = [
    {
        label: "Both",
        value: "",
    },
    {
        label: "Has Documents",
        value: "docs",
    },
    {
        label: "No Documents",
        value: "no docs",
    },
]

export { projStatusFilterOptions, isActiveFilterOptions, imagesFilterOptions, docsFilterOptions }