const projTypeOptions = [
    {
        label: "Choose Option",
        value: "",
    },
    {
        label: "Road Repair",
        value: "Road Repair",
    },
    {
        label: "Sidewalk Installation",
        value: "Sidewalk Installation",
    },
    {
        label: "Sidewalk Upgrade",
        value: "Sidewalk Upgrade",
    },
    {
        label: "Stormwater Repair",
        value: "Stormwater Repair",
    },
    {
        label: "Building",
        value: "Building",
    },
    {
        label: "Speedhumps",
        value: "Speedhumps",
    },
    {
        label: "Cleaning and Clearing",
        value: "Cleaning and Clearing",
    },
    {
        label: "Grass Cutting",
        value: "Grass Cutting",
    },
    {
        label: "Parks and Gardens",
        value: "Parks and Gardens",
    },
    {
        label: "Water Supply",
        value: "Water Supply",
    },
    {
        label: "Sewage Repair",
        value: "Sewage Repair",
    },
    {
        label: "Road Marking Painting",
        value: "Road Marking Painting",
    },
    {
        label: "Electrical Repair",
        value: "Electrical Repair",
    },
    {
        label: "Bus Laybye Lane",
        value: "Bus Laybye Lane",
    },
    {
        label: "Bus Shelter",
        value: "Bus Shelter",
    },
    {
        label: "Road Works",
        value: "Road Works",
    },
    {
        label: "Monitoring and Evaluation",
        value: "Monitoring and Evaluation",
    }
];

const clientType = [
    {
        label: "Choose Option",
        value: "",
    },
    {
        label: "Municipality",
        value: "Municipality",
    },
    {
        label: "Provincial Government",
        value: "Provincial Government",
    },
    {
        label: "National Government",
        value: "National Government",
    },
    {
        label: "State-owned Enterprise (SOE)",
        value: "SOE",
    },
    {
        label: "Private Client",
        value: "Private Client",
    }
];

const projStatus = [
    {
        label: "Choose Option",
        value: "",
    },
    {
        label: "Not Started Yet",
        value: "Not Started Yet",
    },
    {
        label: "Active",
        value: "Active",
    },
    {
        label: "Work Stoppage",
        value: "Work Stoppage",
    },
    {
        label: "Complete",
        value: "Complete",
    },
];

const incidentType = [
    {
        label: "Choose Option",
        value: "",
    },
    {
        label: "Work Stoppage",
        value: "Work Stoppage",
    },
    {
        label: "Interruption",
        value: "Interruption",
    },
    {
        label: "Resident Complaint",
        value: "Resident Complaint",
    },
    {
        label: "Accident",
        value: "Accident",
    },
    {
        label: "Altercation",
        value: "Altercation",
    }
];

const siteActivitiesOptions = [
    {
        label: "Choose Option",
        value: "",
    },
    {
        label: "Excavations",
        value: "Excavations",
    },
    {
        label: "Concrete Works",
        value: "Concrete Works",
    },
    {
        label: "Asphalt Works",
        value: "Asphalt Works",
    },
    {
        label: "Bricklaying",
        value: "Bricklaying",
    },
    {
        label: "Rubble Removal",
        value: "Rubble Removal",
    },
    {
        label: "Clearing and Grubbing",
        value: "Clearing and Grubbing",
    },
    {
        label: "No Work (rain day)",
        value: "No Work (rain day)",
    },
    {
        label: "Grass Cutting",
        value: "Grass Cutting",
    },
    {
        label: "Induction",
        value: "Induction",
    },
    {
        label: "Training",
        value: "Training",
    }
];

const plantEquipmentOptions = [
    {
        label: "Choose Option",
        value: "",
    },
    {
        label: "TLB",
        value: "TLB",
    },
    {
        label: "Excavator",
        value: "Excavator",
    },
    {
        label: "Roller",
        value: "Roller",
    },
    {
        label: "Saw Cutter",
        value: "Saw Cutter",
    },
    {
        label: "Bobcat",
        value: "Bobcat",
    },
    {
        label: "Jackhammer",
        value: "Jackhammer",
    },
    {
        label: "Generator",
        value: "Generator",
    },
    {
        label: "General Tools",
        value: "General Tools",
    }
];

const municipalityDeptOptions = [
    {
        label: "Choose Option",
        value: "",
    },
    {
        label: "Roads and Stormwater",
        value: "Roads and Stormwater",
    },
    {
        label: "Coastal Stormwater and Catchment Management",
        value: "Coastal Stormwater and Catchment Management",
    },
    {
        label: "Environmental Planning and Climate Protection (EPCPD)",
        value: "Environmental Planning and Climate Protection (EPCPD)",
    },
    {
        label: "Development Engineering",
        value: "Development Engineering",
    },
    {
        label: "Roads Provision",
        value: "Roads Provision",
    },
    {
        label: "Transport Authority",
        value: "Transport Authority",
    },
    {
        label: "Parks and Recreation",
        value: "Parks and Recreation",
    },
    {
        label: "Water and Sanitation",
        value: "Water and Sanitation",
    },
    {
        label: "Parks Department",
        value: "Parks Department",
    },
    {
        label: "Human Settlements",
        value: "Human Settlements",
    },
    {
        label: "Social Development",
        value: "Social Development",
    },
    {
        label: "Electricity",
        value: "Electricity",
    },
    {
        label: "Agro Ecology",
        value: "Agro Ecology",
    },
    {
        label: "Waste Management",
        value: "Waste Management",
    },
    {
        label: "Architecture",
        value: "Architecture",
    },
    {
        label: "Urban Planning",
        value: "Urban Planning",
    },
    {
        label: "Area Based Management",
        value: "Area Based Management",
    },
    {
        label: "Other Department",
        value: "Other Department",
    },
];

// Roads and Stormwater
// Coastal Stormwater and Catchment Management
// Environmental Planning & Climate Protection (EPCPD)
// Development Engineering
// Roads Provision
// Transport Authority
// Parks and Recreation
// Water and Sanitation
// Parks Department
// Human Settlements
// Social Development
// Electricity
// Agro Ecology
// Waste Management
// Area Based Management
// Architecture
// Urban Planning
// Other Department

const localLabourJobTitles = [
    {
        label: "Choose staff type",
        value: "",
    },
    {
        label: "General Worker",
        value: "General Worker",
    },
    {
        label: "Assistant",
        value: "Assistant",
    },
    {
        label: "Traffic Controller",
        value: "Traffic Controller",
    },
    {
        label: "Cleaner/Janitor",
        value: "Cleaner/Janitor",
    },
    {
        label: "Security",
        value: "Security",
    },
    {
        label: "Store Manager",
        value: "Store Manager",
    },
    {
        label: "Team Leader",
        value: "Team Leader",
    },
    {
        label: "Semi-skilled",
        value: "Semi-skilled",
    },
    {
        label: "Skilled",
        value: "Skilled",
    },
    {
        label: "Other",
        value: "Other",
    },
];

// General Worker
// Assistant
// Traffic Controller
// Cleaner/Janitor
// Security
// Store Manager
// Team Leader
// Semi-skilled
// Skilled
// Other

const payIntervalOptions = [
    {
        label: "Choose Option",
        value: "",
    },
    {
        label: "Monthly",
        value: "monthly",
    },
    {
        label: "Anually",
        value: "anually",
    },
]

const userRoles = [
    {
        label: "Choose Option",
        value: "",
    },
    {
        label: "Admin",
        value: "admin",
    },
    {
        label: "CLO",
        value: "clo",
    },
    {
        label: "Guest",
        value: "guest",
    },
];

const docPrivacyStatus = [
    {
        label: "Public document",
        value: "public",
    },
    {
        label: "Private document",
        value: "private",
    },
];

export
{
    projTypeOptions,
    clientType,
    projStatus,
    incidentType,
    siteActivitiesOptions,
    plantEquipmentOptions,
    municipalityDeptOptions,
    localLabourJobTitles,
    payIntervalOptions,
    userRoles,
    docPrivacyStatus
}


