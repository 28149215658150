import React from "react"
import { useState, useEffect } from "react";
import { Table, Button } from "reactstrap";
import PropTypes from 'prop-types';
import ".../../assets/styles.css";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import swal from "sweetalert";
import { post } from "../../helpers/api_helper";
import getElementId from "../SharedFiles/getElementId";

const ViewBanner = (props) =>
{
    // Props
    let bannerArray = props.bannerArray;
    let setChosenBanner = props.setChosenBanner;
    let setActiveTab = props.setActiveTab;
    let toggleBannersChanged = props.toggleBannersChanged;
    let currentUser = props.currentUser;
    let bannersLoaded = props.bannersLoaded;

    // Hooks
    const [bannerToDisplay, setBannerToDisplay] = useState([]);
    const [showLightbox, setShowLightbox] = useState(false);
    const [image, setImage] = useState("");
    const [isEnding, setIsEnding] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [displayNumberOfBanners, setDisplayNumberOfBanners] = useState("");

    const toggleShowInactive = () =>
    {
        setShowInactive(current => !current);
    }

    const editBanner = (id, name) =>
    {
        let object = { "id": id, "bannerName": name };
        setChosenBanner(object);
        setActiveTab("3");
    }

    // const getElementId = (event) =>
    // {
    //     let elemId = event.target.id;
    //     let elementRef = document.getElementById(elemId);
    //     return elementRef;
    // }

    const endBanner = async (event, bannerId) =>
    {
        setIsEnding(true);
        let btn = getElementId(event);
        btn.textContent = "Ending...";
        let endDate = new Date();
        let myObject = { "_id": bannerId, "updated_by_user": currentUser, "isActive": false, "end_date": endDate, "isExpired": true };

        const response = await post("/updatebanner", myObject);

        if (response.message !== undefined && response.message === "Banner updated!")
        {
            setIsEnding(false);
            btn.textContent = "End now";
            toggleBannersChanged();
            swal("Success", "Banner ended successfully!", "success");
        }
        else
        {
            setIsEnding(false);
            btn.textContent = "End now";
            swal("Error", " Some error occurred while ending banner.", "error");
        }
    }

    const sortInfo = () =>
    {
        if (bannerArray.length > 0)
        {
            let tempBannerArray = JSON.parse(JSON.stringify(bannerArray));
            let activeBanners = tempBannerArray.filter(banner => banner.isActive === true);
            let numOfActiveBanners = activeBanners.length;
            let numOfBanners = tempBannerArray.length;
            let numOfInactiveBanners = numOfBanners - numOfActiveBanners;
            let bannerStartDates = [];

            tempBannerArray.forEach((banner, index) =>
            {
                let date = new Date(banner.start_date);
                date = date.toDateString();
                bannerStartDates.push(date);
            });

            let bannerEndDates = [];
            tempBannerArray.forEach((banner, index) =>
            {
                if (banner.end_date !== undefined)
                {
                    let date = new Date(banner.end_date);
                    date = date.toDateString();
                    bannerEndDates.push(date);
                }
                else
                {
                    bannerEndDates.push("-");
                }
            });

            let numOfBannersToDisplay = "";

            if (numOfBanners > 0)
            {
                numOfBannersToDisplay = (<div className="mb-2">
                    Total banners: <b>{numOfBanners}</b> <br />
                    Active banners: <b>{numOfActiveBanners}</b> <br />
                    Inactive banners: <b>{numOfInactiveBanners}</b> <span style={showInactive ? { color: "red" } : { color: "black" }}>{showInactive ? "(Red)" : "(Hidden)"}</span>
                </div>);
            }
            else if (numOfBanners === 0)
            {
                numOfBannersToDisplay = (<div className="redText">No banners saved yet.</div>);
            }

            setDisplayNumberOfBanners(numOfBannersToDisplay);

            let theBanner = (
                <div className="border border-dark mb-4 table-responsive">
                    <Table striped className="table table-stripy mb-0">
                        <thead>
                            <tr className="col-12 table-light">
                                <th>Sponsor</th>
                                <th>Banner</th>
                                <th>Start date</th>
                                <th>End date</th>
                                <th>Link</th>
                                <th>Expired</th>
                                <th>Image</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tempBannerArray.map((banner, index) => (
                                    (showInactive || (!showInactive && banner.isActive === true))
                                        ? <tr key={index + "a"}>
                                            <td style={banner.isActive === false ? { color: 'red' } : null}>{banner.sponsor_name}</td>
                                            <td style={banner.isActive === false ? { color: 'red' } : null}>{banner.banner_name}</td>
                                            <td style={banner.isActive === false ? { color: 'red' } : null}>{bannerStartDates[index]}</td>
                                            <td style={banner.isActive === false ? { color: 'red' } : null}>{bannerEndDates[index]}</td>
                                            <td style={banner.isActive === false ? { color: 'red' } : null}>{banner.link}</td>
                                            <td style={banner.isActive === false ? { color: 'red' } : null}>
                                                {banner.isExpired === true
                                                    ? "Yes"
                                                    : banner.isExpired === undefined
                                                        ? "No"
                                                        : "No"}</td>
                                            <td>{banner.banner_url !== "" && banner.banner_url !== undefined
                                                ? <span style={{ color: "blue" }}>Yes</span>
                                                : "No"}
                                            </td>
                                            <td>
                                                {banner.banner_url !== "" && banner.banner_url !== undefined
                                                    ? <Button
                                                        className="py-1 me-1 mb-1"
                                                        color="primary"
                                                        onClick={() => { setImage(banner.banner_url); setShowLightbox(true); }}
                                                    >
                                                        View
                                                    </Button>
                                                    : null}

                                                <Button className="py-1 me-1 mb-1" color="primary" onClick={() => { editBanner(banner._id, banner.banner_name) }}>Edit</Button>

                                                {banner.isExpired === false || banner.isExpired === undefined
                                                    ? <Button
                                                        className="py-1 me-1 mb-1"
                                                        id="endNowButton"
                                                        color="danger"
                                                        disabled={isEnding}
                                                        onClick={(e) => { endBanner(e, banner._id) }}
                                                    >
                                                        End now
                                                    </Button>
                                                    : null}
                                            </td>
                                        </tr>
                                        : null
                                ))
                            }
                            {numOfActiveBanners === 0 ? <tr><td><span className="redText">No active banners.</span></td></tr> : null}
                        </tbody>
                    </Table>
                </div>
            );

            setBannerToDisplay(theBanner);
        }
        else
        {
            let theBanner = (
                <div className="redText">No banners saved yet.</div>
            );
            setBannerToDisplay(theBanner);
        }
    }

    useEffect(() =>
    {
        if (bannerArray.length > 0)
        {
            sortInfo();
        }
    }, [bannerArray, showInactive]);

    return (
        <div>
            {showLightbox ? (
                <Lightbox
                    mainSrc={image}
                    enableZoom={true}
                    onCloseRequest={() =>
                    {
                        setShowLightbox(false);
                    }}
                />
            ) : null}
            {bannersLoaded && bannerArray.length > 0
                ? <>
                    <div className="d-flex justify-content-between align-items-center">

                        {displayNumberOfBanners}

                        <Button
                            className="py-1 mb-1"
                            color={showInactive ? "secondary" : "primary"}
                            onClick={() => toggleShowInactive()}>
                            {showInactive ? "Hide inactive" : "Show inactive"}
                        </Button>


                    </div>
                    {bannerToDisplay}
                </>
                : bannersLoaded && bannerArray.length === 0
                    ? <p className="redText">No banners saved yet.</p>
                    : !bannersLoaded
                        ? <p>Loading...</p>
                        : null}

        </div>
    );
}

export default ViewBanner;

ViewBanner.propTypes = {
    sponsorsArray: PropTypes.array,
    currentUser: PropTypes.string,
    bannerArray: PropTypes.array,
    setChosenBanner: PropTypes.func,
    setActiveTab: PropTypes.func,
    setBannerEnded: PropTypes.func,
    toggleBannersChanged: PropTypes.func,
    currentUser: PropTypes.string,
    bannersLoaded: PropTypes.bool,
};