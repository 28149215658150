const CryptoJS = require("crypto-js");

const useCrypto = (action, msg) => 
{
    // Setup for object input and output
    const secretKey = process.env.REACT_APP_CRYPTOJS_KEY;

    if (action === "encrypt")
    {
        // Encrypt
        let encoded = CryptoJS.AES.encrypt(msg, secretKey).toString();
        return encoded;
    }

    if (action === "decrypt")
    {
        // Decrypt
        let decoded = CryptoJS.AES.decrypt(msg, secretKey);
        let originalText = JSON.parse(decoded.toString(CryptoJS.enc.Utf8));
        return originalText;
    }
}

export default useCrypto;