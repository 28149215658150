import React from "react"
import { useState, useEffect } from "react";
import { Input, Button, Label, Form, FormGroup, Col } from "reactstrap";
import PropTypes from 'prop-types';
import ".../../assets/styles.css";
import "react-image-lightbox/style.css";
import swal from "sweetalert";
import { post } from "../../helpers/api_helper";
import { API_URL } from "../../helpers/ApiUrl";
import changePhotoHandler from '../SharedFiles/changePhotoHandler';
import resizeImage from '../SharedFiles/resizeImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import postInfo from "pages/SharedFiles/postInfo";
import { convertDate } from "../SharedFiles/convertDate";

const AddBanner = (props) =>
{
    // Props
    let sponsorsArray = props.sponsorsArray;
    let currentUser = props.currentUser;
    let toggleBannersChanged = props.toggleBannersChanged;
    let activeTab = props.activeTab;

    // Hooks
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [isLoading, setIsLoading] = useState(false);
    const [endDateError, setEndDateError] = useState(false);

    // Image hooks
    const [imgObjects, setImgObjects] = useState([]);
    const [imgUrls, setImgUrls] = useState([]);

    const removeImage = (e, index) => 
    {
        let tempImgObjects = imgObjects;
        tempImgObjects.splice(index, 1);
        setImgObjects(tempImgObjects);

        let tempImgUrls = imgUrls;
        tempImgUrls.splice(index, 1);
        if (tempImgUrls.length === 0)
        {
            setImgUrls([]);
        }
        else
        {
            setImgUrls(tempImgUrls);
        }
    }

    const cancelAddBanner = () =>
    {
        setInputs({});
        setImgObjects([]);
        setImgUrls([]);
        sponsorsArray.length > 0 ? document.forms["addBannerForm"].reset() : null;
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const handleChooseSponsor = (e) =>
    {
        let both = e.target.value;
        let array = [];
        let id = "";
        let name = "";

        if (both !== "-1")
        {
            array = both.split("-");
            id = array[0];
            name = array[1];

            setInputs(prevState => ({ ...prevState, ["sponsor_id"]: id }));
            setInputs(prevState => ({ ...prevState, ["sponsor_name"]: name }));
        }
        else
        {
            setInputs(prevState => ({ ...prevState, ["sponsor_id"]: undefined }));
            setInputs(prevState => ({ ...prevState, ["sponsor_name"]: undefined }));
        }
    }

    const handleSubmitPhoto = async (e) =>
    {
        setIsLoading(true);

        if (imgUrls.length === 0)
        {
            console.log("No images to upload.");
            return handleSubmit("no images");
        }

        const formData = new FormData();

        for (const files of imgObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };

            const resizedImage = await resizeImage(config);
            formData.append("banner-image", resizedImage);
        }

        postInfo(formData, "uploadbannerimage", "POST", handleSubmit, "");
    };

    const handleSubmit = async (imgUploadMessage) =>
    {
        let myObject = {};
        let imgUrl = "";
        let bannerEndDate = inputs.end_date !== undefined ? new Date(inputs.end_date) : "";
        let todaysDate = new Date();

        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no images")
        {
            let imagePathsArray = Object.keys(imgUploadMessage);
            let imageUploadStatusArray = Object.values(imgUploadMessage);

            imagePathsArray.forEach((img, i) =>
            {
                if (imageUploadStatusArray[i] === "Success")
                {
                    imgUrl = `${API_URL}/${img}`;
                }
            });

            myObject["banner_url"] = imgUrl;
        }
        else
        {
            myObject["banner_url"] = "";
        }

        if (inputs.link === undefined)
        {
            myObject["link"] = "";
        }

        // code to save project data to database
        //&& Object.keys(myObject).length === 0

        if (Object.keys(inputs).length === 0)
        {
            swal("Error", "Nothing changed.", "error");
            setIsLoading(false);
        }
        else if (bannerEndDate !== "" && bannerEndDate < todaysDate)
        {
            setEndDateError(true);
            swal("Error", "Banner end date cannot be in the past.", "error");
            setIsLoading(false);
        }
        else
        {
            myObject["added_by_user"] = currentUser;

            if (Object.keys(inputs).length !== 0)
            {
                Object.assign(myObject, inputs);
            }

            // console.log("my object:");
            // console.log(myObject);

            const response = await post("/addbanner", myObject);

            if (response.message !== undefined && response.message === "New banner has been added to database.")
            {
                setIsLoading(false);
                setInputs({});
                setImgObjects([]);
                setImgUrls([]);
                toggleBannersChanged();
                document.forms["addBannerForm"].reset();
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                swal("Success", "New banner added successfully!", "success");
            }
            else
            {
                setIsLoading(false);
                swal("Error", " Some error occurred while adding new banner.", "error");
            }
        }
    }

    const handleEndDate = (e) =>
    {
        let tempdate = e.target.value;
        let name = e.target.name;
        let endDate = new Date(tempdate);

        endDate.setHours(endDate.getHours() + 21);
        endDate.setMinutes(endDate.getMinutes() + 59);
        endDate = new Date(endDate);
        console.log(endDate);

        setInputs(prevState => ({ ...prevState, [name]: endDate }));
    }

    useEffect(() =>
    {
        if (inputs.end_date !== undefined && new Date(inputs.end_date) > new Date())
        {
            setEndDateError(false);
        }
        else if (inputs.end_date !== undefined && new Date(inputs.end_date) < new Date())
        {
            setEndDateError(true);
        }
    }, [inputs.end_date]);

    useEffect(() =>
    {
        if (activeTab !== "2")
        {
            cancelAddBanner();
        }
    }, [activeTab]);

    let bannerEnd = '';
    if (inputs.end_date !== undefined)
    {
        bannerEnd = inputs.end_date === undefined
            ? convertDate(chosenBannerInfo.end_date, true)
            : inputs.end_date !== undefined
                ? convertDate(inputs.end_date, true)
                : null;
    }

    return (
        <div>
            {/* Inputs: <pre>{JSON.stringify(inputs)}</pre> */}

            {sponsorsArray.length > 0
                ? <Form
                    name="addBannerForm"
                    onSubmit={(e) => { handleSubmitPhoto(); e.preventDefault(); }}
                    autoComplete="off"
                >
                    <FormGroup row>
                        <Label
                            for="sponsor_id"
                            sm={1}
                        >
                            Sponsor
                        </Label>
                        <Col sm={11}>
                            <Input
                                onChange={(e) => { handleChooseSponsor(e); }}
                                id="sponsorId"
                                name="sponsor_id"
                                type="select"
                                value={`${inputs.sponsor_id}-${inputs.sponsor_name}` || "-1"}
                            >
                                <option key={"first"} value={"-1"}>Choose Option</option>
                                {sponsorsArray.map((sponsor, index) => (
                                    <option
                                        key={index}
                                        value={`${sponsor._id}-${sponsor.company_name}`}>
                                        {sponsor.company_name}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row className="mb-1" value={inputs.isActive || ''}>
                        <Label
                            for="isActive"
                            sm={1}
                        >
                            Is Active
                        </Label>
                        <Col sm={4}>
                            <Button color={inputs.isActive === undefined
                                ? "secondary"
                                : inputs.isActive === "1"
                                    ? "success"
                                    : "secondary"} className='me-1' name="isActive" onClick={(e) => { handleChange(e); }} value={1}>
                                Yes
                            </Button>
                            <Button color={inputs.isActive === undefined
                                ? "secondary"
                                : inputs.isActive === "0"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="isActive" onClick={(e) => { handleChange(e); }} value={0}>
                                No
                            </Button>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="banner_name"
                            sm={1}
                        >
                            Banner name
                        </Label>
                        <Col className="col-sm-6 col-md-11">
                            <Input
                                name="banner_name"
                                placeholder="..."
                                type="text"
                                value={inputs.banner_name || ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="start_date"
                            sm={1}
                        >
                            Start Date
                        </Label>
                        <Col sm={11}>
                            <Input
                                id="bannerStart"
                                name="start_date"
                                placeholder="What is the start date for the banner?"
                                type="date"
                                value={inputs.start_date || ''}
                                onChange={(e) => { handleChange(e); }}
                            />
                            <small className="w-100">E.g. 2022/10/04 is 4 Oct 2022 </small>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="end_date"
                            sm={1}
                        >
                            End Date
                        </Label>
                        <Col sm={11}>
                            <Input
                                id="bannerEnd"
                                name="end_date"
                                placeholder="What is the end date for the banner?"
                                type="date"
                                value={bannerEnd || ''}
                                onChange={(e) => { handleEndDate(e); }}
                            />
                            {endDateError ? <span className="redText">End date cannot be in the past.</span> : null}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="addBanner"
                            sm={1}
                        >
                            Upload image
                        </Label>
                        <Col className="col-sm-11 mb-1">
                            <Input
                                id="addBanner"
                                name="addBanner"
                                type="file"
                                onChange={(e) => changePhotoHandler(e, "", setImgObjects, setImgUrls, "")}
                            />
                        </Col>
                    </FormGroup>

                    <div id="bannerImageSection" className='row'>

                        {/* New banner image section */}
                        {
                            imgUrls.length > 0
                                ? imgUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-2 mt-2 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y"
                                            style={{ right: "0.05rem" }} color="danger"
                                            onClick={(e) => removeImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        }

                        {imgUrls.length > 0
                            ? <p className="ms-1 mb-4" style={{ color: "blue" }}>New banner image</p>
                            : null}

                        {imgUrls.length > 0 ? <div><hr /></div> : null}
                    </div>

                    <FormGroup row>
                        <Label
                            for="link"
                            sm={1}
                        >
                            Link
                        </Label>
                        <Col sm={6} className="col-sm-6 col-md-11">
                            <Input
                                name="link"
                                type="text"
                                placeholder="Link to go to when banner is clicked"
                                value={inputs.link || ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        check
                        row
                    >
                        <Col
                            sm={{
                                offset: 0,
                                size: 12
                            }}
                        >
                            <Button color="primary" disabled={isLoading} type="submit">
                                {isLoading ? 'Submitting…' : 'Submit'}
                            </Button>
                            <Button className="ms-1" color="danger"
                                onClick={() => cancelAddBanner()}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
                : <p className="redText">No company sponsors saved yet. First add a sponsor, then return here to add a banner.</p>}
        </div>
    );
}

export default AddBanner;

AddBanner.propTypes = {
    sponsorsArray: PropTypes.array,
    currentUser: PropTypes.string,
    bannerArray: PropTypes.array,
    toggleBannersChanged: PropTypes.func,
    activeTab: PropTypes.string,
};