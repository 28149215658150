import React from "react";
import { useState, useEffect } from "react";
import { Button, Table, Col, Input, Row, Form } from "reactstrap";
import PropTypes from "prop-types";
import ".../../assets/styles.css";
import { calcTimeBetween } from "pages/SharedFiles/calcTimeBetween";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";
import chooseFilter from "pages/SharedFiles/chooseFilter";
import chooseFilterMedia from "pages/SharedFiles/chooseFilterMedia";
import { isActiveFilterOptions, imagesFilterOptions, docsFilterOptions } from "../SharedFiles/projectFilters";
import swal from "sweetalert";
function ViewVisitSum(props)
{
    // Props
    let visitSumArray = props.visitSumArray;
    let visitSumMsgFromServer = props.visitSumMsgFromServer;
    // let visitArray = props.singleVisitArray;
    // let setVisitArray = props.setSingleVisitArray;
    // let visitMsgFromServer = props.visitMsgFromServer;
    // let setVisitMsgFromServer = props.setVisitMsgFromServer;
    // let singleVisitId = props.singleVisitId;
    // let value = props.value;
    // let setValue = props.setValue;
    // let setEditPgDropdownValue = props.setEditPgDropdownValue;
    // let singleViewPgDropdownValue = props.singleViewPgDropdownValue;
    let setActiveTab = props.setActiveTab;
    let setSingleVisitId = props.setSingleVisitId;
    let visitImagesArray = props.visitImagesArray;
    let visitDocsArray = props.visitDocsArray;
    let isAdmin = props.isAdmin;
    let setProjectId = props.setProjectId;
    let thisUser = props.thisUser;
    let setVisitDate = props.setVisitDate;
    let toggleVisitChosen = props.toggleVisitChosen;
    let visitInfoChanged = props.visitInfoChanged;
    let setVisitId = props.setVisitId;
    let isLoadedVisitSums = props.isLoadedVisitSums;
    let setSingleViewPgDropdownValue = props.setSingleViewPgDropdownValue;
    let setShowArrow = props.setShowArrow;

    // Hooks
    const [displayNumOfVisits, setDisplayNumOfVisits] = useState("");
    const [showVisits, setShowVisits] = useState("");
    const [showInactive, setShowInactive] = useState(true);
    const [showFilters, setShowFilters] = useState("");
    const [isActiveFilter, setIsActiveFilter] = useState(-1);
    const [imagesFilter, setImagesFilter] = useState("");
    const [docsFilter, setDocsFilter] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [displayFilters, setDisplayFilters] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [filterDatesChosen, setFilterDatesChosen] = useState(false);

    const toggleShowFilters = () =>
    {
        setShowFilters(current => !current);
    }

    const editAVisit = (id, projId, date) =>
    {
        setVisitId(id);
        setProjectId(projId);
        setVisitDate(date);
        setActiveTab("3");
        toggleVisitChosen();
    }

    const viewSingleVisit = (id, projId, date) =>
    {
        setProjectId(projId);
        setVisitDate(date);
        setSingleViewPgDropdownValue(id);
        setSingleVisitId(id);
        setActiveTab("4");
    }

    const filterDates = (array) =>
    {
        let result = [];

        if (filterDatesChosen && startDateFilter !== "" && endDateFilter !== "")
        {
            let startDate = new Date(startDateFilter);
            let endDate = new Date(endDateFilter);

            result = array.filter(visit => new Date(visit.timestamp) >= startDate && new Date(visit.timestamp) <= endDate);
        }
        else
        {
            swal("Error", "Both date filters must be filled in.", "error");
            setFilterDatesChosen(false);
        }

        return result;
    }

    const sortVisits = async (msg) =>
    {
        let visitSummariesReduced = [];
        if (visitSumArray.length > 0) 
        {
            visitSummariesReduced = JSON.parse(JSON.stringify(visitSumArray));
        }

        let visitDocsReduced = [];
        if (visitDocsArray.length > 0) 
        {
            visitDocsReduced = JSON.parse(JSON.stringify(visitDocsArray));
        }

        let visitImagesReduced = [];
        if (visitImagesArray.length > 0)
        {
            visitImagesReduced = JSON.parse(JSON.stringify(visitImagesArray));
        }

        // Remove server success message from array, if it exists (for visits)
        visitSummariesReduced = removeSuccessMessage(visitSummariesReduced, "Site visit summaries retrieved successfully.");

        // Remove server success message from array, if it exists (for docs)
        let visitDocsServerMessages = ["Site visit documents retrieved successfully.", "Some error occurred while retrieving documents."]
        visitDocsReduced = removeSuccessMessage(visitDocsReduced, visitDocsServerMessages);

        // Remove server success message from array, if it exists (for images)
        let visitImagesServerMessages = ["Site visit images retrieved successfully.", "Some error occurred while retrieving images."];
        visitImagesReduced = removeSuccessMessage(visitImagesReduced, visitImagesServerMessages);

        let numOfVisits = visitSummariesReduced.length;
        let NumOfVisitsToDisplay = "";

        if (msg === "Site visit summaries retrieved successfully.")
        {
            if (numOfVisits > 0)
            {
                NumOfVisitsToDisplay = (
                    <div className="mb-2 col-6 col-md-2 me-1">
                        Total Visits: <b>{numOfVisits}</b> <br />
                        Inactive visits: <span style={{ color: "red" }}>Red</span>
                    </div>);
            }
            else if (numOfVisits === 0)
            {
                NumOfVisitsToDisplay = (<div className="redText mb-2 col-6 col-md-2 me-1">No site visits saved yet.</div>);
            }

            setDisplayNumOfVisits(NumOfVisitsToDisplay);

            let visits;
            let numOfImagesObj = {};
            let numOfDocsObj = {};

            // Number of images
            visitImagesReduced.forEach((image, i) =>
            {
                let urls = [];
                { image.imageUrlArray !== undefined && image.imageUrlArray.length > 0 ? urls = image.imageUrlArray : [] };

                let numOfPics = 0;
                { urls.length > 0 ? numOfPics = urls.length : numOfPics = 0 }

                let visitRef = "none";
                { image.visit_id !== undefined ? visitRef = image.visit_id : null };

                if (numOfPics > 0)
                {
                    numOfImagesObj[visitRef] = numOfPics;
                }
            });

            // Number of documents
            visitDocsReduced.forEach((doc, i) =>
            {
                let tempDocUrls = [];
                { doc.docUrlArray !== undefined && doc.docUrlArray.length > 0 ? tempDocUrls = doc.docUrlArray : [] };
                let numOfDocs = 0;
                { tempDocUrls.length > 0 ? numOfDocs = tempDocUrls.length : numOfDocs = 0 }

                let visitRef = "none";
                { doc.visit_id !== undefined ? visitRef = doc.visit_id : null };

                if (numOfDocs > 0)
                {
                    numOfDocsObj[visitRef] = numOfDocs;
                }
            });

            let minutesSinceVisitSaved = [];

            visitSummariesReduced.forEach((visit, index) =>
            {
                // Check if it was less than 60 mins since site visit was saved, else can't edit it
                let visitTime = new Date(visit.timestamp);
                let minutesElapsed = calcTimeBetween(visitTime);
                minutesElapsed = Math.round(minutesElapsed);
                minutesSinceVisitSaved.push(minutesElapsed);
            });

            // Filters
            let filteredVisitSums = [];
            filteredVisitSums = chooseFilter(visitSummariesReduced, -1, isActiveFilter, "isActive");
            filteredVisitSums = chooseFilterMedia(filteredVisitSums, numOfImagesObj, "", imagesFilter, "images", "no images", "visit");
            filteredVisitSums = chooseFilterMedia(filteredVisitSums, numOfDocsObj, "", docsFilter, "docs", "no docs", "visit");
            filteredVisitSums = filteredVisitSums.filter(Visit => Visit.proj_descrip !== undefined ? Visit.proj_descrip.includes(searchQuery) : null);
            filteredVisitSums = filterDatesChosen === true ? filterDates(filteredVisitSums) : filteredVisitSums;

            visits = (
                <div className="border border-dark mb-4 table-responsive">
                    <Table className="mb-1 table table-stripy">
                        <thead>
                            <tr className="col-12 table-light">
                                <th>Proj ID</th>
                                <th>Added by</th>
                                <th>Added</th>
                                <th>Description</th>
                                <th>Images</th>
                                <th>Docs</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredVisitSums.length > 0 ?
                                    filteredVisitSums.map((item, index) => (
                                        <tr key={index + "a"} style={item.isActive === 0 ? { color: "red" } : null}>
                                            <td>{item.proj_id}</td>
                                            <td>{item.current_user}</td>
                                            <td>{new Date(item.timestamp).toString().replace(" GMT+0200 (South Africa Standard Time)", "")}</td>
                                            <td>
                                                {item.proj_descrip !== "" && item.proj_descrip !== undefined
                                                    ? item.proj_descrip.length > 55
                                                        ? item.proj_descrip.substr(0, 55) + "..."
                                                        : item.proj_descrip
                                                    : ""}
                                            </td>
                                            <td>
                                                {numOfImagesObj[`${item._id}`] !== undefined
                                                    ? numOfImagesObj[`${item._id}`]
                                                    : "0"}
                                            </td>
                                            <td>
                                                {numOfDocsObj[`${item._id}`] !== undefined
                                                    ? numOfDocsObj[`${item._id}`]
                                                    : "0"}
                                            </td>
                                            <td>
                                                <Button className="py-1 me-1 mb-1" color="primary" onClick={() => { viewSingleVisit(item._id, item.proj_id, item.timestamp) }}>View</Button>

                                                {isAdmin || minutesSinceVisitSaved[index] <= 60
                                                    ?
                                                    isAdmin || thisUser === item.current_user
                                                        ? <Button className="py-1 mb-1" color="primary" onClick={() => editAVisit(item._id, item.proj_id, item.timestamp)}>Edit</Button>
                                                        : null
                                                    : null
                                                }
                                            </td>
                                        </tr>
                                    ))
                                    : <tr><td className="redText" colSpan="8">&nbsp;No site visits match those filter criteria.</td></tr>
                            }
                        </tbody>
                    </Table>
                </div>
            );

            setShowVisits(visits);
        }
        else if (msg === "Something went wrong while finding site visit summary data!")
        {
            setShowVisits(<div className="redText">An error occurred while retrieving site visit summaries. Please refresh the page.</div>);
        }
        else if (msg === "No site visits returned") 
        {
            setShowVisits(<div className="redText">No site visits saved yet.</div>);
        }
        else if (!isLoadedVisitSums)
        {
            setShowVisits(<div>Loading...</div>);
        }
    }

    const resetMainFilters = () =>
    {
        setIsActiveFilter(-1);
        setImagesFilter("");
        setDocsFilter("");
        setSearchQuery("");
        // setStartDateFilter("");
        // setEndDateFilter("");
        // setFilterDatesChosen(false);
    }

    const resetDateFilters = () =>
    {
        setStartDateFilter("");
        setEndDateFilter("");
        setFilterDatesChosen(false);
    }

    const loadFilters = () =>
    {
        setDisplayFilters(
            <Form>
                <Row className="col-12 col-md-12 d-flex justify-content-between align-items-center my-2">
                    <div className="col-12 row d-flex align-items-center mx-0 mb-2 p-0">
                        <div>Find site visit done between these dates</div>
                        <div className="col-12 col-md-3 mb-1">
                            <Col>
                                <Input
                                    name="start_date_filter"
                                    placeholder="What is the start date for the project?"
                                    type="date"
                                    value={startDateFilter || ''}
                                    onChange={(e) => { setStartDateFilter(e.target.value) }}
                                />
                            </Col>
                        </div>
                        <div className="col-12 col-md-3 mb-1">
                            <Col>
                                <Input
                                    name="end_date_filter"
                                    placeholder="What is the end date for the project?"
                                    type="date"
                                    value={endDateFilter || ''}
                                    onChange={(e) => { setEndDateFilter(e.target.value) }}
                                />
                            </Col>
                        </div>
                        {/* d-flex row col-12 col-md-4 mb-1 justify-content-start border border-dark */}
                        <div className="row col-12 col-md-6 d-flex align-items-center ms-1">
                            {/* <Col className=""> */}
                            <Button className="col-4 col-md-2 py-1 me-1 mb-1" color="primary" onClick={() => setFilterDatesChosen(true)}>Find</Button>
                            {/* </Col>
                            <Col className=""> */}
                            <Button className="col-4 col-md-2 py-1 me-1 mb-1" color="primary" onClick={() => resetDateFilters()}>Reset</Button>
                            {/* </Col> */}
                        </div>
                    </div>

                    <div className="col-12 row m-0 p-0 d-flex align-items-center">
                        <Col className="col-12 col-md-4 mb-1">
                            <Input
                                name="search_query"
                                placeholder="Search description"
                                type="text"
                                value={searchQuery || ''}
                                onChange={(e) => { setSearchQuery(e.target.value) }}
                            />
                        </Col>

                        <Col className="col-12 col-md-2 mb-1">
                            <Input
                                onChange={(e) => { setIsActiveFilter(Number(e.target.value)) }}
                                name="isActive_filter"
                                type="select"
                                value={isActiveFilter}
                            > <option key="b" value={-1} className="py-2" disabled hidden>Active</option>
                                {isActiveFilterOptions.map((option, index) => (
                                    <option key={index} value={option.value} className="py-2">{option.label}</option>
                                ))}
                            </Input>
                        </Col>

                        <Col className="col-12 col-md-2 mb-1">
                            <Input
                                onChange={(e) => { setImagesFilter(e.target.value) }}
                                name="images_filter"
                                type="select"
                                value={imagesFilter}
                            > <option key="b" value="" className="py-2" disabled hidden>Images</option>
                                {imagesFilterOptions.map((option, index) => (
                                    <option key={index} value={option.value} className="py-2">{option.label}</option>
                                ))}
                            </Input>
                        </Col>

                        <Col className="col-12 col-md-2 mb-1">
                            <Input
                                onChange={(e) => { setDocsFilter(e.target.value) }}
                                name="docs_filter"
                                type="select"
                                value={docsFilter}
                            > <option key="b" value="" className="py-2" disabled hidden>Documents</option>
                                {docsFilterOptions.map((option, index) => (
                                    <option key={index} value={option.value} className="py-2">{option.label}</option>
                                ))}
                            </Input>
                        </Col>

                        {/* <Col className="col-12 col-md-1 mb-1"> */}
                        <Button className="col-4 col-md-1 py-1 me-1 mb-1 ms-3" color="primary" onClick={() => resetMainFilters()}>Reset</Button>
                        {/* </Col> */}
                    </div>
                </Row>
            </Form>
        );
    }

    useEffect(() =>
    {
        if (visitSumArray.length > 0)
        {
            loadFilters();
            sortVisits(visitSumMsgFromServer);
        }
    }, [visitSumArray,
        visitInfoChanged,
        visitSumMsgFromServer,
        showInactive,
        isActiveFilter,
        imagesFilter,
        docsFilter,
        searchQuery,
        startDateFilter,
        endDateFilter,
        filterDatesChosen]);

    useEffect(() =>
    {
        if (showFilters)
        {
            setShowArrow(false);
        }
        else
        {
            setShowArrow(true);
        }

    }, [showFilters]);

    return (
        <div>
            {/* <p>isAdmin: {isAdmin.toString()}, thisUser: {thisUser.toString()}, isloadedvisitsums: {isLoadedVisitSums.toString()}</p> */}

            {isLoadedVisitSums && visitSumArray.length > 0
                ? <>
                    <div className="row d-flex justify-content-between align-items-center">
                        {displayNumOfVisits}
                        <Button
                            className="me-3 col-5 col-md-1 mb-1"
                            color={showFilters ? "secondary" : "primary"}
                            onClick={() => toggleShowFilters()}>
                            {showFilters ? "Hide filters" : "Show filters"}
                        </Button>
                    </div>

                    {showFilters ? displayFilters : null}
                    {showVisits}
                </>
                : isLoadedVisitSums && visitSumArray.length > 0
                    ? <p className="redText">No site visits saved yet.</p>
                    : !isLoadedVisitSums
                        ? "Loading..."
                        : null}
        </div>
    )
}

export default ViewVisitSum;

ViewVisitSum.propTypes = {
    visitSumArray: PropTypes.array,
    visitSumMsgFromServer: PropTypes.string,
    setActiveTab: PropTypes.func,
    setSingleVisitId: PropTypes.func,
    visitImagesArray: PropTypes.array,
    visitDocsArray: PropTypes.array,
    isAdmin: PropTypes.bool,
    setProjectId: PropTypes.func,
    thisUser: PropTypes.string,
    setVisitDate: PropTypes.func,
    toggleVisitChosen: PropTypes.func,
    visitInfoChanged: PropTypes.bool,
    setVisitId: PropTypes.func,
    isLoadedVisitSums: PropTypes.bool,
    setSingleViewPgDropdownValue: PropTypes.func,
    setShowArrow: PropTypes.func,
}