
import useCrypto from "./useCrypto";

export const decryptUser = () =>
{
    let decryptedUserObject = {};

    if (localStorage.getItem("authUser") !== undefined)
    {
        let userinfo = localStorage.getItem("authUser");

        decryptedUserObject = useCrypto("decrypt", userinfo);
    }

    return decryptedUserObject;
}

