import { get } from "../../helpers/api_helper";

const fetchInfo = async (route, setIsLoaded, setArray, setMsgFromServer, jsonKeyName) =>
{
    try
    {
        const json = await get(route);
        let array = JSON.parse(json[`${jsonKeyName}`]);
        let lastArrayItem = array.length;
        let msgFromServer = array[lastArrayItem - 1];

        console.log(msgFromServer)
        setArray(array);
        setMsgFromServer(msgFromServer);
        setIsLoaded(true);
    }
    catch (error)
    {
        console.log(error);
        setMsgFromServer("Caught error");
    }
}

export default fetchInfo;