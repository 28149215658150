import { API_URL } from "../../helpers/ApiUrl";

async function GetInfo(route, msgName)
{
    const response = await fetch(`${API_URL}/${route}`)
        .then((res) => res.json())
        .then(
            (result) =>
            {
                if (msgName === "")
                {
                    return JSON.parse(result.message);
                }
                else
                {
                    return JSON, parse(result[`${msgName}`]);
                }
            },
            (error) =>
            {
                console.log(error);
                return "Error";
            }
        );

    return response;
}

export default GetInfo;