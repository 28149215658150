import { API_URL } from "../../helpers/ApiUrl";

async function GetKey()
{
    const response = await fetch(`${API_URL}/api`)
        .then((res) => res.json())
        .then(
            (result) =>
            {
                return result.message;
            },
            (error) =>
            {
                console.log("Error while fetching Google api key is: " + error);
                return "An error has occurred.";
            }
        );

    return response;
}

export default GetKey;