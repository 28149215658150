import React from "react"
import { useState, useEffect } from "react";
import { Input, Button, Label, Form, FormGroup, Col } from "reactstrap";
import PropTypes from 'prop-types';
import ".../../assets/styles.css";
import "react-image-lightbox/style.css";
import swal from "sweetalert";
import { post } from "../../helpers/api_helper";
import { API_URL } from "../../helpers/ApiUrl";
import changePhotoHandler from '../SharedFiles/changePhotoHandler';
import resizeImage from '../SharedFiles/resizeImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import postInfo from "pages/SharedFiles/postInfo";
import { convertDate } from "../SharedFiles/convertDate";

const EditBanner = (props) =>
{
    // Props
    let currentUser = props.currentUser;
    let chosenBanner = props.chosenBanner;
    let setChosenBanner = props.setChosenBanner;
    let bannerArray = props.bannerArray;
    let activeTab = props.activeTab;
    let toggleBannersChanged = props.toggleBannersChanged;

    // Hooks
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [isLoading, setIsLoading] = useState(false);
    const [chosenBannerInfo, setChosenBannerInfo] = useState({});
    const [bannerInfoLoaded, setBannerInfoLoaded] = useState(false);
    const [endDateError, setEndDateError] = useState(false);

    // Image hooks
    const [imgObjects, setImgObjects] = useState([]);
    const [imgUrls, setImgUrls] = useState([]);
    const [newImageUrls, setNewImageUrls] = useState([]);
    const [newImageObjects, setNewImageObjects] = useState([]);
    const [markedImages, setMarkedImages] = useState([]);

    const loadBannerInfo = () =>
    {
        let id = chosenBanner.id;
        let name = chosenBanner.bannerName;
        let tempChosenBannerInfo = [];

        bannerArray.forEach((banner, index) =>
        {
            if (banner._id === id && banner.banner_name === name)
            {
                tempChosenBannerInfo.push(banner);
            }
        });

        setChosenBannerInfo(tempChosenBannerInfo[0]);
        setBannerInfoLoaded(true);
        sortBannerImage();
    }

    const removeImage = (e, index) => 
    {
        let tempImgObjects = imgObjects;
        tempImgObjects.splice(index, 1);
        setImgObjects(tempImgObjects);

        let tempImgUrls = imgUrls;

        // Existing images marked for deletion (red text)
        let imgsMarkedForDeletion = [];
        if (markedImages.length > 0)
        {
            imgsMarkedForDeletion = markedImages;
        }
        imgsMarkedForDeletion.push(tempImgUrls[index]);
        setMarkedImages(imgsMarkedForDeletion);

        tempImgUrls.splice(index, 1);
        if (tempImgUrls.length === 0)
        {
            setImgUrls([]);
        }
        else
        {
            setImgUrls(tempImgUrls);
        }
    }

    const removeNewImage = (e, index) =>
    {
        let tempImgUrls = newImageUrls;
        tempImgUrls.splice(index, 1);
        if (tempImgUrls.length === 0)
        {
            setNewImageUrls([]);
        }
        else
        {
            setNewImageUrls(tempImgUrls);
        }

        let tempImgObjects = newImageObjects;
        tempImgObjects.splice(index, 1);
        setNewImageObjects(tempImgObjects);
    }

    const cancelEditBanner = () =>
    {
        setInputs({});
        setNewImageObjects([]);
        setNewImageUrls([]);
        setMarkedImages([]);
        setImgUrls([]);
        setChosenBanner({});
        setBannerInfoLoaded(false);
        setIsLoading(false);
        //document.forms["editBannerForm"].reset();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const handleSubmitPhoto = async (e) =>
    {
        setIsLoading(true);

        if (newImageUrls.length === 0)
        {
            console.log("No images to upload.");
            return handleSubmit("no images");
        }

        const formData = new FormData();

        for (const files of newImageObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };

            const resizedImage = await resizeImage(config);
            formData.append("banner-image", resizedImage);
        }

        postInfo(formData, "uploadbannerimage", "POST", handleSubmit, "");
    };

    const handleSubmit = async (imgUploadMessage) =>
    {
        let myObject = {};
        let imgUrl = "";
        let bannerEndDate = inputs.end_date !== undefined ? new Date(inputs.end_date) : new Date(chosenBannerInfo.end_date);
        let todaysDate = new Date();

        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no images")
        {
            let imagePathsArray = Object.keys(imgUploadMessage);
            let imageUploadStatusArray = Object.values(imgUploadMessage);

            imagePathsArray.forEach((img, i) =>
            {
                if (imageUploadStatusArray[i] === "Success")
                {
                    imgUrl = `${API_URL}/${img}`;
                }
            });

            myObject["banner_url"] = imgUrl;
        }

        if (markedImages.length > 0 && newImageUrls.length === 0)
        {
            myObject["banner_url"] = "";
        }

        // console.log("my object:");
        // console.log(myObject);

        if (inputs.isActive === "true")
        {
            myObject["isExpired"] = false;
        }
        else if (inputs.isActive === "false")
        {
            myObject["isExpired"] = true;
        }

        // code to save project data to database
        if (Object.keys(inputs).length === 0 && Object.keys(myObject).length === 0)
        {
            swal("Error", "Nothing changed.", "error");
            setIsLoading(false);
        }
        else if (bannerEndDate < todaysDate)
        {
            setEndDateError(true);
            swal("Error", "Banner end date cannot be in the past.", "error");
            setIsLoading(false);
        }
        else
        {
            myObject["_id"] = chosenBanner.id;
            myObject["updated_by_user"] = currentUser;

            if (Object.keys(inputs).length !== 0)
            {
                Object.assign(myObject, inputs);
            }

            // console.log("my object:");
            // console.log(myObject);

            const response = await post("/updatebanner", myObject);

            if (response.message !== undefined && response.message === "Banner updated!")
            {
                setIsLoading(false);
                setInputs({});
                setNewImageObjects([]);
                setNewImageUrls([]);
                setMarkedImages([]);
                toggleBannersChanged();
                document.forms["editBannerForm"].reset();
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                swal("Success", "Banner updated successfully!", "success");
            }
            else
            {
                setIsLoading(false);
                swal("Error", " Some error occurred while updating banner.", "error");
            }
        }
    }

    const handleEndDate = (e) =>
    {
        let tempdate = e.target.value;
        let name = e.target.name;
        let endDate = new Date(tempdate);

        endDate.setHours(endDate.getHours() + 21);
        endDate.setMinutes(endDate.getMinutes() + 59);
        endDate = new Date(endDate);
        console.log(endDate);

        setInputs(prevState => ({ ...prevState, [name]: endDate }));
    }

    const sortBannerImage = () =>
    {
        let tempArray = JSON.parse(JSON.stringify(chosenBannerInfo));

        if (tempArray.banner_url !== undefined && tempArray.banner_url !== "")
        {
            let tempImageUrl = tempArray.banner_url;
            let array = [];
            array.push(tempImageUrl);
            setImgUrls(array);
        }
    }

    useEffect(() =>
    {
        if (inputs.end_date !== undefined && new Date(inputs.end_date) > new Date())
        {
            setEndDateError(false);
        }
        else if (inputs.end_date !== undefined && new Date(inputs.end_date) < new Date())
        {
            setEndDateError(true);
        }
    }, [inputs.end_date]);

    useEffect(() =>
    {
        if (Object.keys(chosenBanner).length !== 0 && chosenBanner.constructor === Object)
        {
            loadBannerInfo();
        }
    }, [chosenBanner, bannerArray]);

    useEffect(() =>
    {
        if (activeTab !== "3" && Object.keys(chosenBannerInfo).length > 0 && Object.keys(chosenBanner).length > 0)
        {
            cancelEditBanner();
        }
    }, [activeTab]);

    useEffect(() =>
    {
        if (bannerInfoLoaded)
        {
            sortBannerImage();
        }
    }, [bannerArray, chosenBanner, chosenBannerInfo]);

    let bannerStart = "";
    let bannerEnd = "";

    if (bannerInfoLoaded)
    {
        bannerStart = inputs.start_date === undefined
            ? convertDate(chosenBannerInfo.start_date, bannerInfoLoaded)
            : inputs.start_date !== undefined
                ? convertDate(inputs.start_date, bannerInfoLoaded)
                : "";

        bannerEnd = inputs.end_date === undefined
            ? convertDate(chosenBannerInfo.end_date, bannerInfoLoaded)
            : inputs.end_date !== undefined
                ? convertDate(inputs.end_date, bannerInfoLoaded)
                : null;
    }

    return (
        Object.keys(chosenBanner).length > 0
            ? <div>
                {/* Inputs: <pre>{JSON.stringify(inputs)}</pre>
                img urls: {imgUrls.join(", ")} <br />
                new img urls: {newImageUrls.join(", ")}
                bannerInfoLoaded: {bannerInfoLoaded.toString()} */}

                {bannerInfoLoaded && chosenBannerInfo.isActive === false
                    ? <p style={{ color: "red" }}>This banner is currently inactive.</p>
                    : null}

                <Form
                    name="editBannerForm"
                    onSubmit={(e) => { handleSubmitPhoto(); e.preventDefault(); }}
                    autoComplete="off"
                >
                    <FormGroup row className="mb-1">
                        <Label
                            for="isActive"
                            sm={1}
                        >
                            Is Active
                        </Label>
                        <Col sm={4}>
                            <Button color={chosenBannerInfo.isActive === true && inputs.isActive === undefined
                                ? "success"
                                : inputs.isActive === "true"
                                    ? "success"
                                    : "secondary"} className='me-1' name="isActive" onClick={(e) => { handleChange(e); }} value={true}>
                                Yes
                            </Button>
                            <Button color={chosenBannerInfo.isActive === false && inputs.isActive === undefined
                                ? "danger"
                                : inputs.isActive === "false"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="isActive" onClick={(e) => { handleChange(e); }} value={false}>
                                No
                            </Button>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="sponsor_name"
                            sm={1}
                        >
                            Sponsor company
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="sponsor_name"
                                placeholder="..."
                                type="text"
                                value={inputs.sponsor_name === undefined
                                    ? chosenBannerInfo.sponsor_name !== undefined ? chosenBannerInfo.sponsor_name : ''
                                    : inputs.sponsor_name !== undefined
                                        ? inputs.sponsor_name
                                        : ''}
                                disabled
                            // onChange={(e) => handleChange(e)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="banner_name"
                            sm={1}
                        >
                            Banner name
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="banner_name"
                                placeholder="..."
                                type="text"
                                value={inputs.banner_name === undefined
                                    ? chosenBannerInfo.banner_name !== undefined ? chosenBannerInfo.banner_name : ''
                                    : inputs.banner_name !== undefined
                                        ? inputs.banner_name
                                        : ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="start_date"
                            sm={1}
                        >
                            Start Date
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                id="bannerStart"
                                name="start_date"
                                placeholder="What is the start date for the banner?"
                                type="date"
                                value={bannerStart || ''}
                                onChange={(e) => { handleChange(e); }}
                            />
                            <small className="w-100">E.g. 2022/10/04 is 4 Oct 2022 </small>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="end_date"
                            sm={1}
                        >
                            End Date
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                id="bannerEnd"
                                name="end_date"
                                placeholder="What is the end date for the banner?"
                                type="date"
                                value={bannerEnd || ''}
                                onChange={(e) => { handleEndDate(e); }}
                            />
                            {endDateError ? <span className="redText">End date cannot be in the past.</span> : null}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="link"
                            sm={1}
                        >
                            Link
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="link"
                                type="text"
                                placeholder="Link to go to when banner is clicked"
                                value={inputs.link === undefined
                                    ? chosenBannerInfo.link !== undefined ? chosenBannerInfo.link : ''
                                    : inputs.link !== undefined
                                        ? inputs.link
                                        : ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="addBanner"
                            sm={1}
                        >
                            Update image
                        </Label>
                        <Col className="col-md-11 mb-1">
                            <Input
                                id="addBanner"
                                name="addBanner"
                                type="file"
                                onChange={(e) => changePhotoHandler(e, "", setNewImageObjects, setNewImageUrls, "")}
                            />
                        </Col>
                    </FormGroup>

                    <div id="bannerImageSection" className='row'>

                        {/* Existing banner image section */}
                        {
                            imgUrls.length > 0 && newImageUrls.length === 0
                                ? imgUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-2 mt-2 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y"
                                            style={{ right: "0.05rem" }} color="danger"
                                            onClick={(e) => removeImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        }

                        {imgUrls.length > 0 && newImageUrls.length === 0
                            ? <p className="ms-1 mb-2" style={{ color: "blue" }}>Current banner image</p>
                            : null}

                        {imgUrls.length > 0 && newImageUrls.length === 0 ? <div><hr /></div> : null}

                        {/* New images section */}
                        {
                            newImageUrls.length > 0
                                ? newImageUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-2 mt-1 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y"
                                            style={{ right: "0.05rem" }} color="danger" onClick={(e) => removeNewImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        }

                        {newImageUrls.length > 0
                            ? <p className="ms-1 mb-2" style={{ color: "blue" }}>New banner image</p>
                            : null}

                        {/* Existing images marked for deletion */}
                        {
                            markedImages.length > 0
                                ? markedImages.map((url, index) => (
                                    <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button color="danger" className="col-12" disabled
                                        >
                                            Marked for Deletion
                                        </Button>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    <FormGroup
                        check
                        row
                    >
                        <Col
                            sm={{
                                offset: 0,
                                size: 12
                            }}
                        >
                            <Button color="primary" disabled={isLoading} type="submit">
                                {isLoading ? 'Updating…' : 'Update'}
                            </Button>
                            <Button className="ms-1" color="danger"
                                onClick={() => cancelEditBanner()}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
            : <div className="blueText">Choose a banner to edit on the &quot;View&quot; tab.</div>
    );
}

export default EditBanner;

EditBanner.propTypes = {
    currentUser: PropTypes.string,
    bannerArray: PropTypes.array,
    chosenBanner: PropTypes.object,
    setChosenBanner: PropTypes.func,
    activeTab: PropTypes.string,
    toggleBannersChanged: PropTypes.func,
};