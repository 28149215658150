import React from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input, Badge, FormText } from "reactstrap";
import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import { post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { API_URL } from "../../helpers/ApiUrl"
import { useFormik } from 'formik';
import { incidentType, siteActivitiesOptions, plantEquipmentOptions } from "../SharedFiles/listData";
import resizeImage from '../SharedFiles/resizeImage';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faFileLines, faTimes } from '@fortawesome/free-solid-svg-icons';
import postInfo from 'pages/SharedFiles/postInfo';
import ".../../assets/styles.css";
import { addActivityRow, handleActivity, removeActivity } from "./ActivityHelpers";
import { handleStaffRegisterButton, handleStaffArray, addStaffRow, addAllStaffRows, removeStaffRow, addManagers, minusManagers } from "./staffFunctions";
import { incrementLocalStaff } from "./incrementStaff";
import { addRow, removeRow, handleEquipArray, handleEquipCount } from "./equipFunctions";
import changePhotoHandler from '../SharedFiles/changePhotoHandler';
import changeDocHandler from '../SharedFiles/changeDocHandler';
import checkForManagers from '../SharedFiles/checkForManagers';
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";
function AddNewSiteVisit(props)
{
    // Constants
    const timerSeconds = 600;
    const timerOff = -1;

    // Misc hooks
    const [inputs, setInputs] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [validated, setValidated] = useState(false);
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [counter, setCounter] = useState(timerOff);
    const [missingInfoMsg, setMissingInfoMsg] = useState("");

    // Staff hooks
    const [staffNamesArray, setStaffNamesArray] = useState([]);
    const [staffArray, setStaffArray] = useState([]);
    const [staffPresenceArray, setStaffPresenceArray] = useState([]);
    const [staffRows, setStaffRows] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [staffObject, setStaffObject] = useState({});
    const [totalStaff, setTotalStaff] = useState(0);
    const [localStaff, setLocalStaff] = useState(0);
    const [managers, setManagers] = useState(0);
    const [currentStaffObject, setCurrentStaffObject] = useState({});
    const [staffTouched, setStaffTouched] = useState(false);

    // Equipment hooks
    const [equipRows, setEquipRows] = useState(1);
    const [equipCountArray, setEquipCountArray] = useState([]);
    const [equipArray, setEquipArray] = useState([]);
    const [equipObject, setEquipObject] = useState({});
    const [currentEquipIndex, setCurrentEquipIndex] = useState(0);

    // Activity hooks
    const [activityArray, setActivityArray] = useState([]);
    const [activityRows, setActivityRows] = useState(1);
    const [activityIndex, setActivityIndex] = useState(0);

    // Image hooks
    const [numOfPics, setNumOfPics] = useState(0);
    const [imgUrls, setImgUrls] = useState([]);
    const [imgObjects, setImgObjects] = useState([]);
    const [imgNames, setImgNames] = useState([]);

    // Document hooks
    const [numOfDocs, setNumOfDocs] = useState(0);
    const [docUrls, setDocUrls] = useState([]);
    const [docNames, setDocNames] = useState([]);
    const [docObjects, setDocObjects] = useState([]);

    // Project hooks
    const [projDropdownArray, setProjDropdownArray] = useState([]);

    // Props
    let toggleVisitInfoChanged = props.toggleVisitInfoChanged;
    let toggleVisitChosen = props.toggleVisitChosen;
    let setIsLoadedImages = props.setIsLoadedImages
    let setIsLoaded = props.setIsLoaded;
    let setIsLoadedDocs = props.setIsLoadedDocs;
    let setActiveTab = props.setActiveTab;
    let projectsArray = props.projectsArray;
    let setShowAddVisitAlerts = props.setShowAddVisitAlerts;
    let setVisitImagesError = props.setVisitImagesError;
    let setProjectCompleteError = props.setProjectCompleteError;
    let projectCompleteError = props.projectCompleteError;
    let currentUser = props.thisUser;
    let activeTab = props.activeTab;
    let projectMsgFromServer = props.projectMsgFromServer;
    let canPublish = props.canPublish;

    const removeProjSuccessMsg = () =>
    {
        let projectsReduced = JSON.parse(JSON.stringify(projectsArray));

        if (projectMsgFromServer === "Projects retrieved successfully.")
        {
            projectsReduced = removeSuccessMessage(projectsReduced, "Projects retrieved successfully.");
        }
        else if (projectMsgFromServer === "Project summaries retrieved successfully.")
        {
            projectsReduced = removeSuccessMessage(projectsReduced, "Project summaries retrieved successfully.");
        }

        // let lastArrayItem = projectsReduced.length;
        // let msgFromServer = projectsReduced[lastArrayItem - 1];

        // //if (msgFromServer === "Projects retrieved successfully.") { projectsReduced.pop(); }
        // if (msgFromServer === "Projects retrieved successfully." || msgFromServer === "Project summaries retrieved successfully.") 
        // {
        //     projectsReduced.pop();
        // }

        setProjDropdownArray(projectsReduced);
        setCurrentStaffObject(projectsReduced);
    }

    const validate = values =>
    {
        const errors = {};

        if (!values.proj_id)
        {
            errors.proj_id = 'Required';
        }

        if (!values.onsite_today)
        {
            errors.onsite_today = 'Required';
        }

        if (!values.induction_done)
        {
            errors.induction_done = 'Required';
        }

        if (!values.health_safety_check)
        {
            errors.health_safety_check = 'Required';
        }

        if (!values.incident_onsite)
        {
            errors.incident_onsite = 'Required';
        }

        if (!values.staff_register)
        {
            errors.staff_register = 'Required';
        }

        if (!values.staffPresence)
        {
            errors.staffPresence = 'Required';
        }

        if (!values.milestone)
        {
            errors.milestone = 'Required';
        }

        if (!values.activities_onsite)
        {
            errors.activities_onsite = 'Required';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            onsite_today: '',
            induction_done: '',
            health_safety_check: '',
            incident_onsite: '',
            staff_register: '',
            staffPresence: '',
            proj_id: '',
            milestone: '',
            activities_onsite: '',
        },
        validate,
        onSubmit: (values, { resetForm }) =>
        {
            setValidated(true);
            resetForm();
        },
    });

    const cancelAddSiteVisit = () =>
    {
        setCounter(timerOff);
        setInputs({});
        setStaffArray([]);
        setStaffRows(1);
        setStaffPresenceArray([]);
        setStaffObject({});

        setNumOfPics(0);
        setImgObjects([]);
        setImgUrls([]);
        setImgNames([]);

        setDocUrls([]);
        setDocNames([]);
        setNumOfDocs(0);
        setDocObjects({});

        setActivityArray([]);

        setEquipObject({});
        setEquipCountArray([]);
        setEquipArray([]);
        setEquipRows(1);

        setTotalStaff(0);
        setLocalStaff(0);
        setManagers(0);
        setProjectCompleteError("");
        setValidated(false);
        setFormSubmitted(false);
        setSubmitClicked(false);
        setIsLoading(false);
        setMissingInfoMsg("");

        window.location.assign("#top");
        document.forms["addNewSiteVisitForm"].reset();
    }

    const addEquipRow = () =>
    {
        addRow(equipArray, currentEquipIndex, equipCountArray, equipRows, setCurrentEquipIndex, setEquipRows);
    }

    const removeRw = (index) =>
    {
        removeRow(index, equipRows, setCurrentEquipIndex, setEquipRows, equipArray, setEquipArray, equipCountArray, setEquipCountArray, equipObject, setInputs, setEquipObject, inputs);
    }

    const handleEquipArr = (name, index) =>
    {
        handleEquipArray(name, index, equipCountArray, equipArray, equipObject, setInputs, setEquipObject, setEquipArray);
    }

    const handleEquipCnt = (count, index) =>
    {
        handleEquipCount(count, index, equipArray, equipObject, setInputs, setEquipObject, equipCountArray, setEquipCountArray);
    }

    let equipmentRow;
    let array = [];

    for (let i = 0; i < equipRows; ++i)
    {
        equipmentRow = (
            <div key={i} className='row mb-1 mb-sm-1 mb-md-1'>
                <Col className="col-sm-8 col-12 col-md-7">
                    <Input
                        id="plant_equip_list"
                        name="plant_equip_list"
                        type="select"
                        value={equipArray[i] || ''}
                        onChange={(e) => handleEquipArr(e.target.value, i)}
                        onBlur={(e) => { formik.handleBlur(e) }}
                    > {plantEquipmentOptions.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                    </Input>
                </Col>
                <Col className={formik.errors.plant_equip_num && submitClicked === true
                    ? "mt-2 mt-sm-0 col-6 col-sm-4 col-md-3 redBorder"
                    : "mt-2 mt-sm-0 col-6 col-sm-4 col-md-3"}>
                    <Input
                        id="plant_equip_num"
                        name="plant_equip_num"
                        placeholder="How many"
                        type="number"
                        value={equipCountArray[i] || ""}
                        onChange={(e) => handleEquipCnt(e.target.value, i)}
                        onBlur={(e) => { formik.handleBlur(e) }}
                        onWheel={(e) => e.target.blur()}
                    />
                </Col>
                <Col className="mt-2 mt-sm-0 col-6 col-sm-4 col-md-2">
                    <Button color="danger" onClick={() => removeRw(i)}>
                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                    </Button>
                </Col>
            </div>);

        array.push(equipmentRow);
    }

    const removeDoc = (e, index) => 
    {
        let tempDocUrls = docUrls;
        tempDocUrls.splice(index, 1);
        setDocUrls(tempDocUrls);
        let tempDocObjects = docObjects;
        tempDocObjects.splice(index, 1);
        setDocObjects(tempDocObjects);
        setNumOfDocs(docUrls.length);
        let tempDocNames = docNames;
        tempDocNames.splice(index, 1);
        setDocNames(tempDocNames);
    }

    const handleSubmitDoc = (msgFromPhotos) => 
    {
        if (docUrls.length === 0)
        {
            console.log("No docs to upload.")
            return HandleSubmitVisit(msgFromPhotos, "no docs");
        }

        const formData = new FormData();

        docObjects.forEach((file, i) =>
        {
            formData.append(i, file)
        });

        postInfo(formData, "visitdocsupload", "POST", HandleSubmitVisit, msgFromPhotos);
    }

    const HandleSubmitVisit = async (imgUploadMessage, docUploadMessage) =>
    {
        setIsLoading(true);

        let myObject = {};
        myObject["user"] = currentUser;

        let milestoneObject = {};
        milestoneObject["comment"] = inputs.milestone;
        milestoneObject["Current User"] = currentUser;
        milestoneObject["Timestamp"] = new Date();

        inputs.milestone = milestoneObject;

        if (imgUrls.length > 0)
        {
            myObject["photos_uploaded"] = "true";
        }
        else
        {
            myObject["photos_uploaded"] = "false";
        }

        if (canPublish === 1)
        {
            myObject["isActive"] = 1;
        }
        else
        {
            myObject["isActive"] = 0;
        }


        if (inputs.mgrs_onsite === undefined)
        {
            myObject["mgrs_onsite"] = 0;
        }

        Object.assign(myObject, inputs);

        let imgUrlArray = [];
        let imgInfoObject = {};
        let imgFailedArray = [];

        // Code to save uploaded image urls to database
        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no photos" && msgFromServer === "The site visit has been added")
        {
            let imagePathsArray = Object.keys(imgUploadMessage);
            let imageUploadStatusArray = Object.values(imgUploadMessage);

            imagePathsArray.forEach((img, i) =>
            {
                if (imageUploadStatusArray[i] === "Success")
                {
                    let fullPath = `${API_URL}/${img}`;
                    imgUrlArray.push(fullPath);
                }
                else if (imageUploadStatusArray[i] === "Failed")
                {
                    imgFailedArray.push(img);
                }
            });

            if (imgFailedArray.length > 0)
            {
                console.log(imgFailedArray.length + " image/s failed to upload.");
                setVisitImagesError(imgFailedArray.length + " image/s failed to upload.");
            }
            else
            {
                setVisitImagesError("");
            }

            imgInfoObject["isActive"] = 1;
            imgInfoObject["proj_id"] = myObject.proj_id;
            imgInfoObject["added_by_user"] = currentUser;
            imgInfoObject["imageUrlArray"] = imgUrlArray;
            imgInfoObject["visit_id"] = id;

            post(url.ADD_NEW_VISIT_IMAGES, imgInfoObject).then((response) =>
            {
                if (response.message === "New site visit images have been added to database.")
                {
                    console.log(response.message);
                }
                else
                {
                    console.log("Something went wrong with adding new site visit images");
                }
            }).catch((error) => console.log("This is the try catch error msg for uploaded site visit images: " + error));
        }

        // Code to save uploaded document urls and names to database
        let docUrlArray = [];
        let docInfoObject = {};

        if (docUploadMessage !== "Error" && docUploadMessage !== "no docs" && msgFromServer === "The site visit has been added")
        {
            let docPathsArray = Object.keys(docUploadMessage);
            let docUploadStatusArray = Object.values(docUploadMessage);

            docPathsArray.forEach((doc, i) =>
            {
                if (docUploadStatusArray[i] === "Success")
                {
                    let fullPath = `${API_URL}/${doc}`;
                    docUrlArray.push(fullPath);
                }
            });

            docInfoObject["isActive"] = 1;
            docInfoObject["proj_id"] = myObject.proj_id;
            docInfoObject["added_by_user"] = currentUser;
            docInfoObject["docNamesArray"] = docNames;
            docInfoObject["docUrlArray"] = docUrlArray;
            docInfoObject["visit_id"] = id;

            post(url.ADD_NEW_VISIT_DOCS, docInfoObject).then(() =>
            {
                if (response.message === "New site visit documents have been added to database.")
                {
                    console.log(response.message);
                }
                else
                {
                    console.log("Something went wrong with adding new site visit docs to database.");
                }
            }).catch((error) => console.log("This is the try catch error msg for adding site visit documents: " + error));
        }

        // Code to save site visit data to database
        post(url.ADD_NEW_VISIT, myObject).then((response) =>
        {
            const res = JSON.parse(response.message);
            let lastArrayItem = res.length - 1;
            let msgFromServer = res[lastArrayItem];
            let id = res[0]["visit-id"];

            if (msgFromServer === "The site visit has been added")
            {
                setCounter(timerOff);
                setIsLoading(false);
                setInputs({});
                setStaffArray([]);
                setStaffRows(1);
                setStaffPresenceArray([]);

                setNumOfPics(0);
                setImgObjects([]);
                setImgUrls([]);
                setImgNames([]);

                setActivityArray([]);
                setActivityRows(1);

                setNumOfDocs(0);
                setDocUrls([]);
                setDocNames([]);
                setDocObjects([]);

                setEquipObject({});
                setEquipCountArray([]);
                setEquipArray([]);
                setEquipRows(1);

                setTotalStaff(0);
                setLocalStaff(0);
                setManagers(0);

                setIsLoadedDocs(false);
                setIsLoadedImages(false);
                setIsLoaded(false);

                setValidated(false);
                setFormSubmitted(false);
                setSubmitClicked(false);
                toggleVisitChosen();
                toggleVisitInfoChanged();
                setActiveTab("2");
                setMissingInfoMsg("");

                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                document.forms["addNewSiteVisitForm"].reset();
                setShowAddVisitAlerts(true);
                swal("Success", "The site visit has been added.", "success");
            }
            else
            {
                setIsLoading(false);
                setValidated(false);
                setFormSubmitted(false);
                setSubmitClicked(false);
                setInputs({});
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                swal("Error", "Something went wrong. Please try again.", "error");
            }
        });
    }

    const handleStaffRegButton = (event, index) =>
    {
        handleStaffRegisterButton(event, index, staffArray, staffObject, setInputs, setStaffObject, staffPresenceArray, setStaffPresenceArray);
    }

    const sortStaffNames = () =>
    {
        let id = Number(inputs.proj_id);
        let array = JSON.parse(JSON.stringify(projDropdownArray));
        let projIndex = 0;

        array.forEach((proj, index) =>
        {
            if (proj.proj_id === id)
            {
                projIndex = index;
            }
        });

        let names = [];
        let roles = [];

        if (array[projIndex].contract_core_staff !== undefined)
        {
            let object = array[projIndex].contract_core_staff;
            names = Object.keys(object);
            roles = Object.values(object);
        }
        else
        {
            names = array[projIndex].contract_core_staff_names;
        }

        setStaffNamesArray(names);
    }

    const handleStaffArr = (name, index) =>
    {
        handleStaffArray(
            name,
            index,
            staffPresenceArray,
            staffArray,
            staffObject,
            setInputs,
            setStaffObject,
            incrementLocalStaff,
            setStaffArray,
            staffRows,
            chckForManagers);
    }

    const addStaffRw = () =>
    {
        addStaffRow(
            staffArray,
            currentIndex,
            staffPresenceArray,
            staffRows,
            setCurrentIndex,
            setStaffRows);
    }

    const addAllStaffRws = () =>
    {
        formik.values["staff_register"] = "john";
        formik.values["staffPresence"] = "full day";
        formik.touched["staff_register"] = undefined;
        addAllStaffRows(
            staffNamesArray,
            setStaffArray,
            setStaffRows,
            setCurrentIndex,
            setInputs,
            setStaffObject,
            setStaffPresenceArray,
            incrementLocalStaff,
            chckForManagers);
    }

    const removeStaffRw = (index) =>
    {
        removeStaffRow(
            index,
            staffRows,
            setCurrentIndex,
            setStaffRows,
            staffArray,
            incrementLocalStaff,
            setStaffArray,
            staffPresenceArray,
            setStaffPresenceArray,
            staffObject,
            inputs,
            setInputs,
            setStaffObject,
            setLocalStaff,
            chckForManagers,
            setTotalStaff,
            setStaffTouched);
    }

    let staffDivArray = [];
    let staffRow;

    for (let i = 0; i < staffRows; ++i)
    {
        staffRow = (
            <div key={i} className='row mb-1 mb-sm-1 mb-md-1'>
                <Col className="col-sm-8 col-12 col-md-7">
                    <Input
                        invalid={formik.errors.staff_register === 'Required' && formik.touched.staff_register ?
                            true :
                            formik.errors.staff_register === 'Required' && submitClicked === true ?
                                true :
                                false}
                        id="staff_register"
                        name="staff_register"
                        type="select"
                        onChange={(e) => { handleStaffArr(e.target.value, i); formik.handleChange(e); }}
                        onBlur={(e) => { formik.handleBlur(e) }}
                        value={staffArray[i] || ''}
                    >
                        {staffNamesArray.length > 0
                            ? <option index="0" value="">Choose staff member name</option>
                            : <option index="0" value="">First choose a project above</option>}
                        {staffNamesArray.map((item, index) => (
                            <option key={index + 1} value={item}>
                                {item}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col
                    className={formik.errors.staffPresence && submitClicked === true
                        ? "mt-2 mt-sm-0 col-12 col-sm-4 col-md-5 redBorder"
                        : "mt-2 mt-sm-0 col-12 col-sm-4 col-md-5"}
                >
                    <Button name="staffPresence"
                        color={staffPresenceArray[i] === "full day"
                            ? "success"
                            : "secondary"} className='me-1 mb-1' onClick={(e) => { handleStaffRegButton(e, i); formik.handleChange(e); }} value="full day">
                        Full Day
                    </Button>

                    <Button name="staffPresence"
                        color={staffPresenceArray[i] === "half day"
                            ? "warning"
                            : "secondary"} className='me-1 mb-1' onClick={(e) => { handleStaffRegButton(e, i); formik.handleChange(e); }} value="half day">
                        Half Day
                    </Button>

                    <Button name="staffPresence"
                        color={staffPresenceArray[i] === "absent"
                            ? "danger"
                            : "secondary"} className='me-1 mb-1' onClick={(e) => { handleStaffRegButton(e, i); formik.handleChange(e); }} value="absent">
                        Absent
                    </Button>

                    <Button color="danger" className='me-1 mb-1' onClick={() => removeStaffRw(i)}>
                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                    </Button>
                </Col>
            </div>
        );

        staffDivArray.push(staffRow);
    }

    const addActivRow = (e) =>
    {
        addActivityRow(activityArray, activityIndex, activityRows, setActivityIndex, setActivityRows);
    }

    const removeActiv = index =>
    {
        removeActivity(activityRows, setActivityRows, setActivityIndex, index, activityArray, setActivityArray, inputs, setInputs);
    }

    const handleActiv = (activity, index) =>
    {
        handleActivity(index, activity, activityArray, setActivityArray, setInputs);
    }

    let activityDivArray = [];
    let activRow;

    for (let j = 0; j < activityRows; ++j)
    {
        activRow = (
            <div key={j} className='row mb-1 mb-sm-1 mb-md-1'>
                <Col sm={11}>
                    <Input
                        id="siteActivities"
                        name="activities_onsite"
                        invalid={formik.errors.activities_onsite === 'Required' && formik.touched.activities_onsite ?
                            true :
                            formik.errors.activities_onsite === 'Required' && submitClicked === true ?
                                true :
                                false}
                        type="select"
                        onChange={(e) =>
                        { handleActiv(e.target.value, j); formik.handleChange(e); }}
                        onBlur={(e) => formik.handleBlur(e)}
                        value={activityArray[j] || ''}
                    >
                        {siteActivitiesOptions.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </Input>
                </Col>
                <Col className="col-sm-1 col-2 my-sm-0 my-2">
                    <Button color="danger" onClick={() => removeActiv(j)}>
                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                    </Button>
                </Col>
            </div>);

        activityDivArray.push(activRow);
    }

    const removeImage = (e, index) => 
    {
        let tempImgUrls = imgUrls;
        tempImgUrls.splice(index, 1);
        setImgUrls(tempImgUrls);
        let tempImgObjects = imgObjects;
        tempImgObjects.splice(index, 1);
        setImgObjects(tempImgObjects);
        setNumOfPics(imgUrls.length);
        let tempImgNames = imgNames;
        tempImgNames.splice(index, 1);
        setImgNames(tempImgNames);
    }

    const handleSubmitPhoto = async (e) =>
    {
        setIsLoading(true);

        if (imgUrls.length === 0)
        {
            console.log("No images to upload.");
            return handleSubmitDoc("no photos");
        }

        const formData = new FormData();

        for (const files of imgObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };

            const resizedImage = await resizeImage(config);
            formData.append("site-visit-image", resizedImage, files.name);
        }

        postInfo(formData, "visitimageupload", "POST", handleSubmitDoc, "");
    };

    const handleAddDescrip = (e) =>
    {
        let value = Number(e.target.value);
        let array = [];

        projDropdownArray.forEach((item, index) =>
        {
            if (item.proj_id === value)
            {
                array.push(item.proj_descrip);
            }

            if (item.proj_id === value && item.proj_status === "Complete")
            {
                setInputs({});
                setCounter(timerOff);
                setProjectCompleteError("Project is already complete. Unable to add new site visit.");
            }
            else if (item.proj_id === value && item.proj_status !== "Complete")
            {
                setProjectCompleteError("");
                setInputs(prevState => ({ ...prevState, ["proj_descrip"]: array[0] }));
            }
        });
    }

    const changeProject = (e) =>
    {
        if (e.target.value !== "-1")
        {
            setInputs({});
            setStaffArray([]);
            setStaffRows(1);
            setStaffPresenceArray([]);
            setStaffObject({});

            setNumOfPics(0);
            setImgObjects([]);
            setImgUrls([]);
            setImgNames([]);

            setDocUrls([]);
            setDocNames([]);
            setNumOfDocs(0);
            setDocObjects({});

            setActivityArray([]);

            setEquipObject({});
            setEquipCountArray([]);
            setEquipArray([]);
            setEquipRows(1);

            setTotalStaff(0);
            setLocalStaff(0);
            setManagers(0);

            formik.handleChange(e);
            handleChange(e);
            handleAddDescrip(e);
            setMissingInfoMsg("");
            setValidated(false);
            setSubmitClicked(false);
        }
        else
        {
            setInputs({});
            setCounter(timerOff);
        }
    }

    const setIncidentFalse = () =>
    {
        setInputs(prevState => ({ ...prevState, ["incident_severity"]: "" }));
        setInputs(prevState => ({ ...prevState, ["incident_type"]: "" }));
        setInputs(prevState => ({ ...prevState, ["incident_details"]: "" }));
    }

    const chckForManagers = (type, array) =>
    {
        let result = checkForManagers(
            type,
            array,
            currentStaffObject,
            setCurrentStaffObject,
            addManagers,
            managers,
            minusManagers,
            setInputs,
            setManagers);
        return result;
    }

    const setChosenProjectArray = () =>
    {
        let tempDropdownArray = JSON.parse(JSON.stringify(projDropdownArray));
        let project = tempDropdownArray.find(project => project.proj_id === Number(inputs.proj_id));
        setCurrentStaffObject(project);
    }

    /// Add to edit site visits page and projects pages
    useEffect(() =>
    {
        if (inputs.staff_register === undefined && staffTouched)
        {
            formik.values["staff_register"] = "";
            formik.touched["staff_register"] = true;
            setStaffTouched(false);
        }
    }, [inputs.staff_register]);

    /// ----------------

    useEffect(() =>
    {
        if (projectsArray.length > 0)
        {
            removeProjSuccessMsg();
        }
    }, [projectsArray]);

    useEffect(() =>
    {
        if (inputs.proj_id !== undefined && projDropdownArray.length > 0)
        {
            sortStaffNames();
        }

        if (inputs.proj_id !== undefined && projDropdownArray.length > 0 && managers === 0)
        {
            setChosenProjectArray();
        }
    }, [inputs.proj_id, managers]);

    useEffect(() =>
    {
        if (counter === 0)
        {
            cancelAddSiteVisit();
        }

        if (counter === 120)
        {
            swal("Warning", "2 minutes left", "info");
        }

        let interval = null;
        if (counter !== timerOff && inputs.proj_id)
        {
            interval = setTimeout(() => setCounter(counter => counter - 1), 1000);
        }
        else if (!inputs.proj_id && counter !== 0)
        {
            clearTimeout(interval);
        }

        return () => clearTimeout(interval);

    }, [counter, inputs]);

    useEffect(() =>
    {
        if (Object.keys(inputs).length !== 0)
        {
            setCounter(timerSeconds);
        }
    }, [inputs]);

    useEffect(() =>
    {
        if (activeTab !== "2")
        {
            cancelAddSiteVisit();
        }
    }, [activeTab]);

    /// Add to edit site visits page and projects pages
    useEffect(() =>
    {
        let message = "Missing information above. Please fill in and then click the 'Submit' button again.";

        if (submitClicked && validated === false)
        {
            setMissingInfoMsg(message);
        }
        else if (submitClicked && validated === true)
        {
            setMissingInfoMsg("");
        }
    }, [submitClicked, validated]);

    useEffect(() =>
    {
        if (submitClicked && formik.isValid === true)
        {
            setValidated(true);
        }
    }, [submitClicked, formik.isValid]);

    if (validated === true && formSubmitted === false)
    {
        handleSubmitPhoto();
        setFormSubmitted(true);
    }

    /// ----------------

    return (
        <div id="newVisit" >
            {/* <div>
                <p>Inputs:</p>
                <pre>{JSON.stringify(inputs, null, 4)}</pre>
                <p>Managers: {managers}</p>
            </div>
            <p>Counter: {counter}</p> */}
            {/* <pre>{JSON.stringify(formik, null, 4)}</pre>
            <p>Submit clicked: {submitClicked.toString()}, validated: {validated.toString()}</p> */}
            {/* <p>Inputs:</p>
            <pre>{JSON.stringify(inputs, null, 4)}</pre> */}

            {counter > 0 && counter >= 60
                ? <p className='redText' style={{ textAlign: "right", fontWeight: "bold" }}>Time left: <span style={{ color: 'black' }}>{Math.ceil(counter / 60)} minute/s</span></p>
                : counter > 0
                    ? <p className='redText' style={{ textAlign: "right", fontWeight: "bold" }}>Time left: <span style={{ color: 'black' }}>{counter} seconds</span></p>
                    : null}

            <Form name="addNewSiteVisitForm"
                onSubmit={(e) => { formik.handleSubmit(e); setSubmitClicked(true); e.preventDefault(); }} autoComplete="off">

                {projDropdownArray.length > 0
                    ?
                    <FormGroup row>
                        <Label
                            for="proj_id"
                            sm={2}
                        >
                            Choose Project
                        </Label>
                        <Col sm={10}>
                            <Input
                                invalid={formik.errors.proj_id === 'Required' && formik.touched.proj_id ?
                                    true :
                                    formik.errors.proj_id === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                name="proj_id"
                                type="select"
                                onChange={(e) => { changeProject(e) }}
                                value={inputs.proj_id || ''}
                            >
                                <option index="0" value="-1">Choose a project</option>
                                {projDropdownArray.map((item, index) => (
                                    <option key={index + 1} value={item.proj_id}>
                                        Project ID: {item.proj_id} - {item.proj_descrip !== undefined && item.proj_descrip !== ""
                                            ? item.proj_descrip.substr(0, 30) + " - "
                                            : null} {item.proj_address} - {item.proj_status}
                                    </option>
                                ))}

                            </Input>
                        </Col>
                        {formik.errors.proj_id === 'Required' && formik.touched.proj_id ? (
                            <div className="redText">{formik.errors.proj_id}</div>
                        ) : formik.errors.proj_id === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.proj_id}</div>
                        ) : null}
                    </FormGroup>
                    : <div className='redText'>No projects saved yet.</div>}

                {inputs.proj_descrip !== undefined && projectCompleteError === "" ? <>
                    <FormGroup row>
                        <Label
                            sm={2}
                        >
                            Project ID
                        </Label>
                        <Col sm={2}>
                            <Input
                                type="text"
                                disabled
                                value={inputs.proj_id || ''}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            sm={2}
                        >
                            Project Description
                        </Label>
                        <Col sm={10}>
                            <Input
                                onChange={(e) => { handleChange(e) }}
                                type="text"
                                name="proj_descrip"
                                disabled
                                value={inputs.proj_descrip || ''}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.onsite_today || ''}
                    >
                        <legend className="col-form-label col-sm-2">
                            Are you on site today?
                        </legend>
                        <Col sm={4} className={formik.errors.onsite_today && submitClicked === true ? "redBorder" : ""}>
                            <Button color={inputs.onsite_today === "true"
                                ? "success"
                                : "secondary"} className='me-1' name="onsite_today" onClick={(e) => { formik.handleChange(e); handleChange(e); }} value={true}>
                                Yes
                            </Button>
                            <Button color={inputs.onsite_today === "false"
                                ? "danger"
                                : "secondary"} className='me-1' name="onsite_today" onClick={(e) => { formik.handleChange(e); handleChange(e); }} value={false}>
                                No
                            </Button>
                        </Col>
                        {formik.errors.onsite_today && submitClicked === true ? (
                            <div className="redText">{formik.errors.onsite_today}</div>
                        ) : null}
                    </FormGroup>

                    {inputs.onsite_today === "true" ?
                        <FormGroup row>
                            <Label
                                for="photos_uploaded"
                                sm={2}
                            >
                                Upload Photos
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="photoUpload"
                                    name="photos_uploaded"
                                    type="file"
                                    onChange={(e) => changePhotoHandler(e, setNumOfPics, setImgObjects, setImgUrls, setImgNames)}
                                    multiple
                                />
                            </Col>
                        </FormGroup> : null}

                    <div className={numOfPics > 0 ? 'p-2 mb-1 row' : ""}>
                        {numOfPics > 0 ? <div className='mb-4 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfPics}
                                </Badge> New
                            </Button>
                        </div> : null}
                        {
                            imgUrls.length > 0
                                ? imgUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y"
                                            style={{ right: "0.05rem" }} color="danger"
                                            onClick={(e) => removeImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                        <div className='p-1'>{imgNames[index]}</div>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    {/* <div>
                        <p>Current index:</p>
                        <pre>{currentIndex}</pre>
                        <p>Staff rows:</p>
                        <pre>{staffRows}</pre>
                        <p>StaffPresenceArray:</p>
                        <pre>{JSON.stringify(staffPresenceArray, null, 4)}</pre>
                        <p>staffArray:</p>
                        <pre>{JSON.stringify(staffArray, null, 4)}</pre>
                        <p>staffObject:</p>
                        <pre>{JSON.stringify(staffObject, null, 4)}</pre>
                        <p>Inputs:</p>
                        <pre>{JSON.stringify(inputs, null, 4)}</pre>
                    </div> */}

                    {/* <div>
                        <p>formik</p>
                        <pre>{JSON.stringify(formik, null, 4)}</pre>
                    </div> */}

                    <FormGroup row>

                        <Label
                            for="staff_register"
                            sm={2}
                        >
                            Local Staff Head Count
                        </Label>

                        {staffDivArray}
                        <Col>
                            <Button className='me-1 mb-2' onClick={addStaffRw}>
                                Add
                            </Button>
                            <Button className='me-1 mb-2' onClick={addAllStaffRws}>
                                Add All
                            </Button>
                        </Col>

                        {formik.errors.staff_register === 'Required' && formik.touched.staff_register ? (
                            <div className="redText">{formik.errors.staff_register}</div>
                        ) : formik.errors.staff_register === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.staff_register}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup row >
                        <Label
                            sm={4}
                        >
                            How many managers were on site today?
                        </Label>
                        <Col sm={2}>
                            <Input
                                id="numOfMgrsOnsite"
                                placeholder="..."
                                type="number"
                                value={inputs.mgrs_onsite || managers}
                                disabled
                            // onWheel={(e) => e.target.blur()}
                            // onChange={(e) =>
                            //     addManagers(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            sm={4}
                        >
                            Number of local employees on site (non-management):
                        </Label>
                        <Col sm={2}>
                            <Input
                                value={inputs.local_staff_onsite || localStaff}
                                id="numOfLocalsOnsite"
                                placeholder="..."
                                type="number"
                                disabled
                                onWheel={(e) => e.target.blur()}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            sm={4}
                        >
                            Total number of staff on site today:
                        </Label>

                        <Col sm={2}>
                            <Input
                                value={inputs.total_staff_onsite || totalStaff}
                                id="numOfStaffOnsite"
                                placeholder="..."
                                type="number"
                                disabled
                                onWheel={(e) => e.target.blur()}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="milestone"
                            sm={4}
                        >
                            What milestones were achieved today?
                        </Label>
                        <Col sm={8}>
                            <Input
                                id="milestones"
                                name="milestone"
                                placeholder="Quick status update"
                                type="textarea"
                                value={inputs.milestone || ''}
                                invalid={formik.errors.milestone === 'Required' && formik.touched.milestone ?
                                    true :
                                    formik.errors.milestone === 'Required' && submitClicked === true ?
                                        true :
                                        false}
                                onChange={(e) => { formik.handleChange(e); handleChange(e); }}
                                onBlur={formik.handleBlur}
                            />
                        </Col>
                        {formik.errors.milestone === 'Required' && formik.touched.milestone ? (
                            <div className="redText">{formik.errors.milestone}</div>
                        ) : formik.errors.milestone === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.milestone}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.induction_done || ''}
                    >
                        <legend className="col-form-label col-sm-4">
                            Was the induction completed today?
                        </legend>
                        <Col sm={4} className={formik.errors.induction_done && submitClicked === true ? "redBorder" : ""}>
                            <Button color={inputs.induction_done === "true"
                                ? "success"
                                : "secondary"} className='me-1' name="induction_done" onClick={(e) => { formik.handleChange(e); handleChange(e); }} value={true}>
                                Yes
                            </Button>
                            <Button color={inputs.induction_done === "false"
                                ? "danger"
                                : "secondary"} className='me-1' name="induction_done" onClick={(e) => { formik.handleChange(e); handleChange(e); }} value={false}>
                                No
                            </Button>
                        </Col>
                        {formik.errors.induction_done && submitClicked === true ? (
                            <div className="redText">{formik.errors.induction_done}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.health_safety_check || ''}
                        onChange={handleChange}
                    >
                        <legend className="col-form-label col-sm-4">
                            Was the health and safety check completed today?
                        </legend>
                        <Col sm={4} className={formik.errors.health_safety_check && submitClicked === true ? "redBorder" : ""}>
                            <Button color={inputs.health_safety_check === "yes"
                                ? "success"
                                : "secondary"} className='me-1' name="health_safety_check" onClick={(e) => { formik.handleChange(e); handleChange(e); }} value="yes">
                                Yes
                            </Button>
                            <Button color={inputs.health_safety_check === "no"
                                ? "danger"
                                : "secondary"} className='me-1' name="health_safety_check" onClick={(e) => { formik.handleChange(e); handleChange(e); }} value="no">
                                No
                            </Button>
                            <Button color={inputs.health_safety_check === "partly"
                                ? "warning"
                                : "secondary"} className='me-1' name="health_safety_check" onClick={(e) => { formik.handleChange(e); handleChange(e); }} value="partly">
                                Partly
                            </Button>
                        </Col>
                        {formik.errors.health_safety_check && submitClicked === true ? (
                            <div className="redText">{formik.errors.health_safety_check}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.incident_onsite || ''}
                        onChange={handleChange}
                    >
                        <legend className="col-form-label col-sm-4">
                            Were there any incidents onsite today?
                        </legend>
                        <Col sm={4} className={formik.errors.incident_onsite && submitClicked === true ? "redBorder" : ""}>
                            <Button color={inputs.incident_onsite === "true"
                                ? "success"
                                : "secondary"} className='me-1' name="incident_onsite" onClick={(e) => { formik.handleChange(e); handleChange(e); }} value={true}>
                                Yes
                            </Button>
                            <Button color={inputs.incident_onsite === "false"
                                ? "danger"
                                : "secondary"} className='me-1' name="incident_onsite" onClick={(e) => { setIncidentFalse(); formik.handleChange(e); handleChange(e); }} value={false}>
                                No
                            </Button>
                        </Col>
                        {formik.errors.incident_onsite && submitClicked === true ? (
                            <div className="redText">{formik.errors.incident_onsite}</div>
                        ) : null}
                    </FormGroup>

                    {inputs.incident_onsite === "true" ? <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.incident_severity || ''}
                        onChange={handleChange}
                    >
                        <legend className="col-form-label col-sm-4">
                            Was it a major incident or a minor incident?
                        </legend>
                        <Col sm={4}>
                            <Button color={inputs.incident_severity === "major"
                                ? "danger"
                                : "secondary"} className='me-1' name="incident_severity" onClick={handleChange} value="major">
                                Major
                            </Button>
                            <Button color={inputs.incident_severity === "minor"
                                ? "warning"
                                : "secondary"} className='me-1' name="incident_severity" onClick={handleChange} value="minor">
                                Minor
                            </Button>
                        </Col>
                    </FormGroup> : null}

                    {inputs.incident_onsite === "true" ? <FormGroup row value={inputs.incident_type || ''}
                        onChange={handleChange}>
                        <Label
                            for="incident_type"
                            sm={3}
                        >
                            What type of incident was it?
                        </Label>
                        <Col sm={9}>
                            <Input
                                id="incidentType"
                                name="incident_type"
                                type="select"
                            > {incidentType.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}

                            </Input>
                        </Col>
                    </FormGroup> : null}

                    {inputs.incident_onsite === "true" ? <FormGroup row value={inputs.incident_details || ''}
                        onChange={handleChange}>
                        <Label
                            for="incident_details"
                            sm={3}
                        >
                            Explain the incident in detail
                        </Label>
                        <Col sm={9}>
                            <Input
                                autoComplete="off"
                                id="incidentDetails"
                                name="incident_details"
                                placeholder="What happened?"
                                type="textarea"
                            />
                        </Col>
                    </FormGroup> : null}

                    {/* <div>
                    <p>Activity array:</p>
                    <pre>{JSON.stringify(activityArray, null, 4)}</pre>
                    <p>Inputs:</p>
                    <pre>{JSON.stringify(inputs, null, 4)}</pre>
                </div> */}

                    <FormGroup row >
                        <Row>
                            <Label
                                for="activities_onsite"
                                className="col-sm-2 col-md-5"
                            >
                                What kinds of activities were there on site today?
                            </Label>
                            <Col>
                                <Button className='me-1 mb-2' onClick={addActivRow}>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                        {activityDivArray}

                        {formik.errors.activities_onsite === 'Required' && formik.touched.activities_onsite ? (
                            <div className="redText">{formik.errors.activities_onsite}</div>
                        ) : formik.errors.activities_onsite === 'Required' && submitClicked === true ? (
                            <div className="redText">{formik.errors.activities_onsite}</div>
                        ) : null}
                    </FormGroup>

                    {/* <div>
                        <p>Current index: {currentEquipIndex}</p>
                        <p>Equip rows: {equipRows}</p>
                        <p>Equip name array:</p>
                        <pre>{JSON.stringify(equipArray, null, 4)}</pre>
                        <p>Equip count array:</p>
                        <pre>{JSON.stringify(equipCountArray, null, 4)}</pre>
                        <p>Equip object:</p>
                        <pre>{JSON.stringify(equipObject, null, 4)}</pre>
                        <p>Inputs:</p>
                        <pre>{JSON.stringify(inputs, null, 4)}</pre>
                    </div> */}

                    {inputs.onsite_today === "true" ? <FormGroup row>
                        <div className="mb-2" >
                            <Label
                                for="plant_equip_list"
                                className="col-sm-6 col-9 col-md-5 align-middle"
                            >
                                Plant and Equipment: What was seen during your visit?
                            </Label>
                            <Button className="ms-3" onClick={addEquipRow}>Add</Button>
                        </div>
                        {array}
                    </FormGroup> : null}

                    <FormGroup row>
                        <Label
                            for="addDoc"
                            sm={2}
                        >
                            Upload Document/s
                        </Label>
                        <Col className="col-sm-10 col-12 mb-1">
                            <Input
                                id="addDoc"
                                name="addDoc"
                                type="file"
                                onChange={(e) => changeDocHandler(e, setDocNames, setNumOfDocs, setDocObjects, setDocUrls)}
                                multiple
                            />
                            <FormText> Documents must be in PDF format only. </FormText>
                        </Col>
                    </FormGroup>

                    {/* New docs section */}
                    <div className='p-2 mb-1 row'>
                        {numOfDocs > 0 ? <div className='mb-2 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfDocs}
                                </Badge> New
                            </Button>
                        </div> : null}
                        {
                            docUrls.length > 0
                                ? docUrls.map((name, index) => (
                                    <div key={index} className="mx-1 mt-1 mb-1 col-12 col-sm-12">
                                        <a href={name} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon className="me-2" icon={faFileLines} transform="grow-10" />
                                            {docNames[index]}
                                        </a>

                                        <Button color="danger" className="ms-2" onClick={(e) => removeDoc(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    {docUrls.length > 0 ? <hr /> : null}

                    {/* /// Add to edit site visits page and projects pages */}
                    {missingInfoMsg !== ""
                        ? <p className='redText'>{missingInfoMsg}</p>
                        : null}

                    <FormGroup
                        check
                        row
                    >
                        <Col
                            sm={{
                                offset: 0,
                                size: 12
                            }}
                        >
                            <Button color="primary" disabled={isLoading} type="submit">
                                {isLoading ? 'Submitting…' : 'Submit'}
                            </Button>
                            <Button className="ms-1" color="danger" onClick={() => cancelAddSiteVisit()}>
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>
                </> : null}
            </Form>
        </div >
    );
}

AddNewSiteVisit.propTypes = {
    CreateAlert: PropTypes.func,
    projectsArray: PropTypes.array,
    toggleVisitChosen: PropTypes.func,
    toggleVisitInfoChanged: PropTypes.func,
    setIsLoadedImages: PropTypes.func,
    setIsLoaded: PropTypes.func,
    setIsLoadedDocs: PropTypes.func,
    setActiveTab: PropTypes.func,
    setShowAddVisitAlerts: PropTypes.func,
    setVisitImagesError: PropTypes.func,
    setProjectCompleteError: PropTypes.func,
    projectCompleteError: PropTypes.string,
    thisUser: PropTypes.string,
    activeTab: PropTypes.string,
    projectMsgFromServer: PropTypes.string,
    canPublish: PropTypes.number,
};

export default AddNewSiteVisit;