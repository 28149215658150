import React from "react"
import { useState, useEffect } from "react";
import { Button, Table, Badge, Modal, Label, Col, Input, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faFileLines } from '@fortawesome/free-solid-svg-icons';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ".../../assets/styles.css";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";
function ViewSingleProject(props)
{
    // Props
    let singleProjectArray = props.singleProjectArray;
    let imgMsgFromServer = props.imgMsgFromServer;
    let projectMsgFromServer = props.projectMsgFromServer;
    let docsMsgFromServer = props.docsMsgFromServer;
    let projectImagesArray = props.projectImagesArray;
    let projectDocsArray = props.projectDocsArray;
    let singleProjectId = props.singleProjectId;
    let setSingleProjectId = props.setSingleProjectId;
    let projectSumArray = props.projectSumArray;
    let projSumMsgFromServer = props.projSumMsgFromServer;
    let isAdmin = props.isAdmin;
    let setProjectId = props.setProjectId;
    let setActiveTab = props.setActiveTab;
    let activeTab = props.activeTab;
    let singleProjInfoChanged = props.singleProjInfoChanged;
    //let setProjectIndex = props.setProjectIndex;
    let singleViewPgDropdownValue = props.singleViewPgDropdownValue;
    let setSingleViewPgDropdownValue = props.setSingleViewPgDropdownValue;
    let setEditPgDropdownValue = props.setEditPgDropdownValue;
    let projectChosen = props.projectChosen;
    let setProjectChosen = props.setProjectChosen;
    let userInfo = props.userInfo;
    let userWards = userInfo.wards;
    let thisUser = userInfo.username;

    // Hooks
    const [showProject, setShowProject] = useState("");
    const [docNames, setDocNames] = useState({});
    const [docUrls, setDocUrls] = useState({});
    const [docPrivacy, setDocPrivacy] = useState([]);
    //const [docArray, setDocArray] = useState([]);
    const [showLightbox, setShowLightbox] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [modal_center, setmodal_center] = useState(false);
    const [images, setImages] = useState([]);
    const [currentProjId, setCurrentProjId] = useState("");
    const [projId, setProjId] = useState("");
    const [projAddress, setProjAddress] = useState("");
    const [projDropdownArray, setProjDropdownArray] = useState([]);
    const [projDescription, setProjDescription] = useState("");

    function removeBodyCss()
    {
        document.body.classList.add("no_padding");
    }

    function tog_center(description)
    {
        setProjDescription(description);
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    const setupImages = (id, address) =>
    {
        let tempArray = [];
        setProjId(id);
        setProjAddress(address);
        setPhotoIndex(0);

        tempArray = projectImagesArray;

        let tempArray2 = [];

        tempArray.forEach((item, i) =>
        {
            if (item.proj_id === id)
            {
                tempArray2.push(item.imageUrlArray);
                return;
            }
        });

        setImages(tempArray2[0]);
        setShowLightbox(true);
    }

    const setDropdownValues = () =>
    {
        let projectReduced = JSON.parse(JSON.stringify(projectSumArray));
        projectReduced = removeSuccessMessage(projectReduced, "Project summaries retrieved successfully.");
        setProjDropdownArray(projectReduced);
    }

    const editAProject = (id) =>
    {
        // let projectArrReduced = JSON.parse(JSON.stringify(projectSumArray));
        // let tempIndex = 0;

        // projectArrReduced.forEach((proj, index) => 
        // {
        //     if (proj.proj_id === id)
        //     {
        //         tempIndex = index;
        //     }
        // });

        // setProjectIndex(tempIndex);
        setProjectId(Number(id));
        setEditPgDropdownValue(id);
        setActiveTab("3");
    }

    const sortProject = async () =>
    {
        let timeStamp;
        let startDate;
        let projEndDate;
        let projExtDate;
        let urls;
        let numOfPics = 0;
        let numOfDocs = 0;

        let projectReduced = JSON.parse(JSON.stringify(singleProjectArray));
        let projectDocsReduced = JSON.parse(JSON.stringify(projectDocsArray));
        let projectImagesReduced = JSON.parse(JSON.stringify(projectImagesArray));

        // Remove server success message from array, if it exists (for projects)
        projectReduced = removeSuccessMessage(projectReduced, "Project retrieved successfully.");
        projectReduced = projectReduced[0];

        // Remove server success message from array, if it exists (for docs)
        let projectDocServerMessages = ["Project documents retrieved successfully.",
            "No project documents have been saved yet.",
            "Something went wrong while finding a project documents!"];
        projectDocsReduced = removeSuccessMessage(projectDocsReduced, projectDocServerMessages);

        // Remove server success message from array, if it exists (for images)
        let projectImageServerMessages = ["Project images retrieved successfully.",
            "Some error occurred while retrieving images.",
            "No project images have been saved yet.",
            "Something went wrong while finding a project images!"]
        projectImagesReduced = removeSuccessMessage(projectImagesReduced, projectImageServerMessages);

        if (projectMsgFromServer === "Project retrieved successfully." && singleProjectId !== 0)
        {
            numOfPics = 0;
            projectImagesReduced.forEach((image, i) =>
            {
                if (image.proj_id === singleProjectId)
                {
                    urls = image.imageUrlArray;
                    numOfPics = urls.length;
                }
            });

            let tempDocUrls = [];
            let tempDocNames = [];
            let tempDocPrivacy = [];
            numOfDocs = 0;

            if (projectDocsReduced !== undefined && projectDocsReduced.length > 0)
            {
                projectDocsReduced = projectDocsReduced.filter(doc => doc.proj_id === singleProjectId);
                projectDocsReduced = projectDocsReduced.length > 0 ? projectDocsReduced[0] : projectDocsReduced;

                if (projectDocsReduced.docArray !== undefined)
                {
                    projectDocsReduced.docArray.forEach((doc, index) =>
                    {
                        tempDocUrls.push(doc.url);
                        tempDocNames.push(doc.name);
                        tempDocPrivacy.push(doc.privacy);
                    });

                    numOfDocs = projectDocsReduced.docArray.length;
                }
                else if (Object.keys(projectDocsReduced).length > 0)
                {
                    tempDocUrls = projectDocsReduced.docUrlArray;
                    tempDocNames = projectDocsReduced.docNamesArray;
                    numOfDocs = tempDocUrls.length;
                }

                if (tempDocUrls.length > 0)
                {
                    setDocUrls(tempDocUrls);
                }

                if (tempDocNames.length > 0)
                {
                    setDocNames(tempDocNames);
                }

                if (tempDocPrivacy.length > 0)
                {
                    setDocPrivacy(tempDocPrivacy);
                }
            }

            timeStamp = new Date(projectReduced.added_timestamp);
            timeStamp = timeStamp.toString();
            timeStamp = timeStamp.replace(" GMT+0200 (South Africa Standard Time)", "");

            startDate = new Date(projectReduced.proj_start_date);
            startDate = startDate.toDateString();

            projEndDate = new Date(projectReduced.proj_end_date_projected);
            projEndDate = projEndDate.toDateString();

            if (projectReduced.proj_ext_date !== undefined) { projExtDate = new Date(projectReduced.proj_ext_date) };

            let projComplDate;
            if (projectReduced.proj_completed_date !== undefined) 
            {
                projComplDate = new Date(projectReduced.proj_completed_date);
                projComplDate = projComplDate.toDateString();
            };

            let projectDescrip = "";
            if (projectReduced.proj_descrip !== undefined) { projectDescrip = projectReduced.proj_descrip }

            let projectDescripLong = "";
            if (projectReduced.proj_descrip_long !== undefined) { projectDescripLong = projectReduced.proj_descrip_long }

            let clerk = '';
            if (projectReduced.clerk_of_works !== undefined) { clerk = projectReduced.clerk_of_works }

            let staffList = [];
            let staffNum = 0;

            if (projectReduced.contract_core_staff !== undefined)
            {
                let names = Object.keys(projectReduced.contract_core_staff);
                staffNum = names.length;
                let types = Object.values(projectReduced.contract_core_staff);

                staffList = names.map((name, index) =>
                {
                    return `${name} - ${types[index]}`;
                });

                staffList = staffList.join(", ");
            }
            else 
            {
                let tempList = projectReduced.contract_core_staff_names;
                staffNum = tempList.length;
                staffList = projectReduced.contract_core_staff_names.join(", ");
            }

            let projectToDisplay = (<>
                {projectReduced.isActive === 0
                    ? <p style={{ color: "red" }}>This project is currently inactive.</p>
                    : null}

                <div key={singleProjectId} className="border border-dark mb-4 table-responsive over">
                    <Table striped className="table table-stripy mb-2">
                        <thead>
                            <tr className="col-12 table-light">
                                <th className="col-3">Description</th>
                                <td className="col-3">{projectDescrip}</td>
                                <th className="col-3"></th>
                                <th className="col-3 text-end">{1}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="col-12">
                                <th className="col-3">Description (long):</th>
                                <td className="col-3" colSpan="3">{projectDescripLong}</td>
                            </tr>
                            <tr className="col-12">
                                <th className="col-3">Proj ID:</th>
                                <td className="col-3">{projectReduced.proj_id}</td>
                                <th className="col-3">Client:</th>
                                <td className="col-3">{projectReduced.client_name}</td>
                            </tr>
                            <tr className="col-12">
                                <th>Address:</th>
                                <td>{projectReduced.proj_address}</td>
                                <th>Location:</th>
                                <td>{projectReduced.map_location}</td>
                            </tr>
                            <tr className="col-12">
                                <th>Added IP:</th>
                                <td>{projectReduced.added_by_ip}</td>
                                <th>Added User:</th>
                                <td>{projectReduced.added_by_user}</td>
                            </tr>
                            <tr className="col-12">
                                <th>Added:</th>
                                <td>{timeStamp}</td>
                                <th>Contractor:</th>
                                <td>{projectReduced.contractor}</td>
                            </tr>
                            <tr className="col-12">
                                <th>Engineer:</th>
                                <td>{projectReduced.proj_engineer}</td>
                                <th>Manager:</th>
                                <td>{projectReduced.proj_manager}</td>
                            </tr>
                            <tr className="col-12">
                                <th>Status:</th>
                                <td>{projectReduced.proj_status}</td>
                                <th>Type:</th>
                                <td>{projectReduced.proj_type}</td>
                            </tr>
                            <tr className="col-12">
                                <th>Start:</th>
                                <td>{startDate}</td>
                                <th>Projected End:</th>
                                <td>{projEndDate}</td>
                            </tr>
                            {
                                projectReduced.proj_ext_date === undefined && projectReduced.proj_ext_date_comment === undefined
                                    ? null
                                    : <tr className="col-12">
                                        <th>Extended End:</th>
                                        <td>{projExtDate.toDateString()}</td>
                                        <th>Extended End (Comment):</th>
                                        <td>{projectReduced.proj_ext_date_comment}</td>
                                    </tr>
                            }
                            {
                                projectReduced.proj_completed_date !== undefined ?
                                    <tr className="col-12">
                                        <th>Completed:</th>
                                        <td>{projComplDate}</td>
                                        <th></th>
                                        <td></td>
                                    </tr>
                                    : null
                            }
                            <tr className="col-12">
                                <th>Municipality:</th>
                                <td>{projectReduced.municipality}</td>
                                <th>Municipality Dept:</th>
                                <td>{projectReduced.municipality_dept}</td>
                            </tr>
                            <tr className="col-12">
                                <th>Ward:</th>
                                <td>{projectReduced.ward}</td>
                                <th>Ward Councillor:</th>
                                <td>{projectReduced.ward_person_name !== undefined ? projectReduced.ward_person_name : ""}</td>
                            </tr>
                            <tr className="col-12">
                                <th>Clerk of Works:</th>
                                <td>{clerk}</td>
                                <th></th>
                                <td></td>
                            </tr>
                            <tr className="col-12">
                                <th>Client Type:</th>
                                <td> {projectReduced.client_type}</td>
                                <th>Local Staff (number):</th>
                                <td>{staffNum}</td>
                            </tr>
                            <tr className="col-12">
                                <th>Local Staff (names):</th>
                                <td>{staffList}</td>
                                <th>Site Camp exists:</th>
                                <td>{projectReduced.proj_site_camp.toString()}</td>
                            </tr>
                            {
                                projectReduced.site_camp_separate === undefined
                                    && projectReduced.site_camp_where === undefined
                                    ? null
                                    : <tr className="col-12">
                                        <th>Site Camp Separate:</th>
                                        <td>{projectReduced.site_camp_separate.toString()}</td>
                                        <th>Site Camp Location:</th>
                                        <td>{projectReduced.site_camp_where}</td>
                                    </tr>
                            }
                        </tbody>
                    </Table>

                    <div className="row col-12">
                        <div className={numOfPics > 0 && numOfDocs > 0
                            ? `col-12 col-sm-4 p-2`
                            : numOfPics > 0 && numOfDocs === 0 || numOfDocs > 0 && numOfPics === 0
                                ? `col-12 col-sm-3 p-2`
                                : `col-12 p-2`}>

                            {numOfPics > 0
                                ? <Button className="ms-2 mb-1" color="primary" outline
                                    onClick={() => { setupImages(projectReduced.proj_id, projectReduced.proj_address) }}>
                                    <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                    <Badge className="ms-2 bg-danger">
                                        {numOfPics}
                                    </Badge>
                                </Button>
                                : null}

                            {numOfDocs > 0
                                ? <Button className="ms-2 mb-1" color="primary" outline
                                    onClick={() => { setCurrentProjId(projectReduced.proj_id); tog_center(projectReduced.proj_descrip); }}>
                                    <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                                    <Badge className="ms-2 bg-danger">
                                        {numOfDocs}
                                    </Badge>
                                </Button> : null}

                            {((thisUser === projectReduced.added_by_user && projectReduced.proj_status !== "Complete") || isAdmin)
                                ? <Button className="ms-2 mb-1" color="primary"
                                    onClick={() => editAProject(projectReduced.proj_id)}>Edit</Button>
                                : null
                            }
                        </div>
                    </div>
                </div></>);

            setShowProject(projectToDisplay);
        }
        else if (projectMsgFromServer === "Caught error")
        {
            setShowProject(<div className="redText">An error occurred while retrieving the project. Please refresh the page.</div>);
        }
    }

    const handleChooseProj = (projId) =>
    {
        if (projId !== -1)
        {
            setSingleViewPgDropdownValue(projId);
            setSingleProjectId(projId);
            setProjectId(projId);
            setProjectChosen(true);
        }
        else
        {
            setSingleViewPgDropdownValue(-1);
            setSingleProjectId(0);
            setProjectChosen(false);
        }
    }

    useEffect(() =>
    {
        if (activeTab !== "4")
        {
            setSingleViewPgDropdownValue(-1);
            setSingleProjectId(0);
            setProjectChosen(false);
        }
    }, [activeTab]);

    useEffect(() =>
    {
        if (projectSumArray.length > 0)
        {
            setDropdownValues();
            sortProject();
        }
    }, [singleProjectId, projectMsgFromServer, singleProjectArray, projectSumArray, singleProjInfoChanged]);

    useEffect(() =>
    {
        if (singleViewPgDropdownValue !== -1)
        {
            setProjectChosen(true);
        }
    }, [singleViewPgDropdownValue]);

    return (
        <div>
            {showLightbox ? (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    enableZoom={true}
                    onCloseRequest={() =>
                    {
                        setShowLightbox(false);
                    }}
                    onMovePrevRequest={() =>
                    {
                        setPhotoIndex((photoIndex + images.length - 1) % images.length);
                    }}
                    onMoveNextRequest={() =>
                    {
                        setPhotoIndex((photoIndex + 1) % images.length);
                    }}
                    imageCaption={"Project " + projId + " - " + projAddress}
                />
            ) : null}

            <Modal
                isOpen={modal_center}
                toggle={() =>
                {
                    tog_center();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Documents for Project {currentProjId} <br /> {projDescription.length > 40 ? projDescription.substring(0, 40) + "..." : projDescription}</h5>
                    <button
                        type="button"
                        onClick={() =>
                        {
                            setmodal_center(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {docNames.length > 0 ? docNames.map((name, index) => (
                        <div className="mb-1 ms-4 p-0 col-10" key={index}>
                            <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                            &nbsp;&nbsp;{index + 1}.&nbsp;{name.length >= 26 ? name.substr(0, 26) + "..." : name}
                            <Button
                                className="ms-1 py-0"
                                color="primary"
                                outline
                                onClick={() => window.open(docUrls[index], `_blank`)} key={index}
                            >
                                View
                            </Button>
                            {docPrivacy[index] !== undefined ? " - " + docPrivacy[index] : null}
                        </div>
                    ))
                        : null}
                </div>
            </Modal>
            {/* <Container fluid> */}
            {/* <div>current single project id: {singleProjectId}, singleViewPgDropdownValue: {singleViewPgDropdownValue}, project chosen: {projectChosen.toString()}, activeTab: {activeTab}</div> */}
            {/* <div>current id: {singleProjectId}, projectId: {projectId}, projectIndex: {projectIndex}</div> */}

            {/* value={`${project.proj_id}-${index}`} */}

            {projDropdownArray.length > 0
                ? <div>
                    <FormGroup row className="mb-4">
                        <Label
                            for="chooseProj"
                            sm={2}
                        >
                            Choose Project to View
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="chooseProj"
                                name="chooseProj"
                                type="select"
                                value={singleViewPgDropdownValue}
                                onChange={(e) => handleChooseProj(Number(e.target.value))}
                            >
                                <option key={"first"} value={-1}>Choose Option</option>
                                {projDropdownArray.map((project, index) => (
                                    <option key={index}
                                        value={project.proj_id}>Project ID: {project.proj_id} - {project.proj_descrip !== undefined && project.proj_descrip !== ""
                                            ? project.proj_descrip.substr(0, 55) + " - "
                                            : null} {project.proj_status}</option>
                                ))}
                            </Input>
                        </Col>
                    </FormGroup>
                </div>
                : projDropdownArray.length === 0 && projSumMsgFromServer === "No projects returned for ward" && userWards.length === 0
                    ? <div className="redText">Please contact your administrator, as you have not yet been assigned to a specific ward.</div>
                    : projDropdownArray.length === 0 && projSumMsgFromServer === "No projects returned for ward" && userWards.length > 0
                        ? <div className="redText">No projects in your ward/s.</div>
                        : projectSumArray.length === 0
                            ? <p className="redText">No projects saved yet.</p>
                            : null}
            {projectChosen
                ? showProject
                : null}
            {/* </Container> */}
        </div>
    )
}

export default ViewSingleProject;

ViewSingleProject.propTypes = {
    projectSumArray: PropTypes.array,
    projSumMsgFromServer: PropTypes.string,
    docsMsgFromServer: PropTypes.string,
    imgMsgFromServer: PropTypes.string,
    singleProjectArray: PropTypes.array,
    projectMsgFromServer: PropTypes.string,
    setProjectMsgFromServer: PropTypes.func,
    projectImagesArray: PropTypes.array,
    projectDocsArray: PropTypes.array,
    singleProjectId: PropTypes.number,
    setSingleProjectId: PropTypes.func,
    projectSumArray: PropTypes.array,
    isAdmin: PropTypes.bool,
    setProjectId: PropTypes.func,
    setActiveTab: PropTypes.func,
    activeTab: PropTypes.string,
    singleProjInfoChanged: PropTypes.bool,
    //setProjectIndex: PropTypes.func,
    singleViewPgDropdownValue: PropTypes.number,
    setSingleViewPgDropdownValue: PropTypes.func,
    setEditPgDropdownValue: PropTypes.func,
    projectChosen: PropTypes.bool,
    setProjectChosen: PropTypes.func,
    userInfo: PropTypes.object,
}