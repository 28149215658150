import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import
{
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"

import getAuth from "../../../pages/Projects/GetAuth"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } })
{
  //console.log("Login button has been clicked.");
  let emailsArray;
  let passwordsArray;

  if (sessionStorage.getItem("emailsArray") !== undefined)
  {
    emailsArray = JSON.parse(sessionStorage.getItem("emailsArray"));
  } else
  {
    //console.log("Emails array is null");
    emailsArray = "";
  }

  if (sessionStorage.getItem("passwordsArray") !== undefined)
  {
    passwordsArray = JSON.parse(sessionStorage.getItem("passwordsArray"));
    //console.log("Passwords Array: " + passwordsArray);
  } else
  {
    //console.log("Passwords array is null");
    passwordsArray = "";
  }

  try
  {
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
      emails: emailsArray,
      pwords: passwordsArray
    })

    //console.log("Login post request. Server says: " + response.message);

    if (response.message !== "Incorrect password!" && response.message !== "Username does not exist")
    {
      localStorage.setItem("token", JSON.stringify(response.message));
      let authObject = yield getAuth(response.message);

      if (authObject !== undefined)
      {
        yield put(loginSuccess(authObject));
      }
    }
    else
    {
      swal("Error", response.message, "error");
      console.log("Failed login. Redirecting to login page.");
      history.push("/login");
    }
  } catch (error)
  {
    yield put(apiError(error))
  }

  history.push("/projects")
}

function* logoutUser({ payload: { history } })
{
  console.log("Logout button has been clicked.");
  try
  {
    localStorage.removeItem("token")
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase")
    {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }

    //yield put(logoutUserSuccess(history))
    //history.push("/manageprojects")
    window.location.assign("/login");

  } catch (error)
  {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } })
{
  try
  {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase")
    {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else
    {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error)
  {
    yield put(apiError(error))
  }
}

function* authSaga()
{
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
