import React from "react";
import { Row, Col, CardBody, Card, Container, Form, Input, Button, Label, FormFeedback } from "reactstrap";
import MetaTags from "react-meta-tags";
import { useState, useEffect } from "react";
import simpleFetch from "pages/SharedFiles/simpleFetch";
import profile from "../../assets/images/profile-img2.jpg";
import logo from "../../assets/images/logo.png";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";
import swal from "sweetalert";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

function resetPassword()
{
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [message, setMessage] = useState("");
    const [inputs, setInputs] = useState({});

    // parse your data in the destination page 
    const getParam = (param) =>
    {
        return new URLSearchParams(window.location.search).get(param);
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password")
                .min(8, 'Password must be 8 characters long')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter')
                .matches(/[^\w]/, 'Password requires a symbol'),
        }),
        onSubmit: (values) =>
        {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (pword) =>
    {
        //handlePass();
        let newObject = { "reset_password_token": null, "reset_password_expires": null }
        let object = { ...inputs, ...newObject, ...pword }
        delete object.email;
        // if (inputs !== undefined && Object.keys(inputs).length !== 0 && inputs.constructor === Object)
        // {

        setIsSubmitting(true);
        let response = await simpleFetch("postupdateuser", object, "update password")
        if (response !== "Caught error")
        {
            setUpdated(true);
            setIsSubmitting(false);
            swal("Success", "Password updated. Try logging in with your new password.", "success");
        }
        else
        {
            setMessage(response);
            setIsSubmitting(false);
            // swal("Error", "Password updated. Try logging in with your new password.", "error");
        }
        // }
    }

    useEffect(async () =>
    {
        let data = getParam("data");
        let token;

        if (data !== undefined && data != "")
        {
            token = getParam("data");
        }

        const result = await simpleFetch("checkresettoken", token, "token");
        let msgFromServer = result !== "Caught error" ? result[result.length - 1] : result;

        setMessage(msgFromServer);

        let messageArray = ["Token is valid.", "Token is expired.", "Something went wrong. Try to reset your password again."];
        let finalResult = removeSuccessMessage(result, messageArray);
        finalResult = finalResult[0];

        setInputs(finalResult);
        setIsLoading(false);
    }, []);

    return (
        <React.Fragment>
            <MetaTags>
                <title>Reset Password - CMS</title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <i className="fas fa-home h2" />
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    {/* <p>Inputs: {JSON.stringify(inputs, 4, null)}</p> */}
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Password Reset</h5>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>

                                <CardBody className="pt-0">
                                    <div>
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img
                                                    src={logo}
                                                    alt=""
                                                    height="34"
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    {isLoading
                                        ? <div>Loading...</div>
                                        : null}

                                    {!updated && message !== "" && message !== "Token is valid."
                                        ?
                                        <div>
                                            <h5 className="redText mb-3">Problem resetting password. Please try again.</h5>
                                            <Button color="primary" className="me-1" onClick={() => window.location.href = "/login"}>Back to Login page</Button>
                                            <Button color="primary" className="" onClick={() => window.location.href = "/forgot-password"}>Forgot password</Button>
                                        </div>
                                        : null}

                                    {!updated && message !== "" && message === "Token is valid."
                                        ?
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) =>
                                            {
                                                e.preventDefault();
                                                validation.handleSubmit(e);
                                                return false;
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">New Password</Label>
                                                <Input
                                                    name="password"
                                                    type="password"
                                                    id="pass"
                                                    placeholder="Enter new password"
                                                    // onChange={handleChange}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.password || ""}
                                                    invalid={
                                                        validation.touched.password && validation.errors.password ? true : false
                                                    }
                                                />
                                                {validation.touched.password && validation.errors.password ? (
                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mt-4">
                                                <button
                                                    className="btn btn-primary btn-block "
                                                    type="submit"
                                                    disabled={isSubmitting ? true : false}
                                                >
                                                    {isSubmitting ? "Updating..." : "Update password"}
                                                </button>
                                            </div>
                                        </Form>
                                        : null}

                                    {updated ?
                                        <div>
                                            <Button color="primary" className="me-1" onClick={() => window.location.href = "/login"}>Go to Login page</Button>
                                        </div>
                                        : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
}

export default resetPassword;