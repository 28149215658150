import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faFileLines, faTimes } from '@fortawesome/free-solid-svg-icons';
import { projTypeOptions, clientType, projStatus, municipalityDeptOptions, localLabourJobTitles, docPrivacyStatus } from "../SharedFiles/listData";
import
{
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Badge,
} from "reactstrap";
import { useState, useEffect } from "react";
import { post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { API_URL } from "../../helpers/ApiUrl";
import swal from "sweetalert";
import resizeImage from "../SharedFiles/resizeImage";
import postInfo from "../SharedFiles/postInfo";
import sortProjectDocs from "./sortProjectDocs";
import { loadStaffArrays } from "./loadStaffArrays";
import { convertDate } from "../SharedFiles/convertDate";
import { handleStaffTypes, addStaffRow, removeStaffRow, handleStaffArray } from "./staffFunctions";
import ".../../assets/styles.css";
import changePhotoHandler from "../SharedFiles/changePhotoHandler";
import changeDocHandler from "../SharedFiles/newChangeDocHandler";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";
import sortProjectImages from "./sortProjectImages";
import simpleFetch from '../SharedFiles/simpleFetch';
//import createDocArray from './createDocArray';

const EditProjectForm = (props) =>
{
    // Props
    let setProjDocsUpdated = props.setProjDocsUpdated;
    let setProjInfoUpdated = props.setProjInfoUpdated;
    let setProjImagesUpdated = props.setProjImagesUpdated;
    let setProjImagesError = props.setProjImagesError;
    let setShowAlerts = props.setShowAlerts;
    let projectId = props.projectId;
    let setProjectId = props.setProjectId;
    let isAdmin = props.isAdmin;
    let isLoaded = props.isLoaded;
    let projectsArray = props.projectsArray;
    let projectImagesArray = props.projectImagesArray;
    let projectDocsArray = props.projectDocsArray;
    let toggleProjectInfoChanged = props.toggleProjectInfoChanged;
    let setIsLoaded = props.setIsLoaded;
    let setIsLoadedImages = props.setIsLoadedImages;
    let setIsLoadedDocs = props.setIsLoadedDocs;
    let loadedStaffArrays = props.loadedStaffArrays;
    let setLoadedStaffArrays = props.setLoadedStaffArrays;
    let toggleSingleProjInfoChanged = props.toggleSingleProjInfoChanged;
    let setProjDocsError = props.setProjDocsError;
    let activeTab = props.activeTab;
    let editPgDropdownValue = props.editPgDropdownValue;
    let setEditPgDropdownValue = props.setEditPgDropdownValue;
    let setProjectCompleteError = props.setProjectCompleteError;
    // let imgMsgFromServer = props.imgMsgFromServer;
    // let docsMsgFromServer = props.docsMsgFromServer;
    let userInfo = props.userInfo;
    let currentUser = userInfo.username;
    let userWards = userInfo.wards;
    let msgFromServer = props.msgFromServer;
    let projectToEdit = props.projectToEdit;
    let isLoadedImages = props.isLoadedImages;
    let isLoadedDocs = props.isLoadedDocs;

    // Constants
    const timerSeconds = 600;
    const timerOff = -1;

    // Misc hooks
    const [isUpdating, setUpdating] = useState(false);
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [canEdit, setCanEdit] = useState(false);
    const [counter, setCounter] = useState(timerOff);

    // Project hooks
    const [projDropdownArray, setProjDropdownArray] = useState([]);

    // Core staff member hooks
    const [staffRows, setStaffRows] = useState(1);
    const [staffArray, setStaffArray] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [staffTypes, setStaffTypes] = useState([]);
    const [staffObject, setStaffObject] = useState({});

    // Project image hooks
    const [imageUrls, setImageUrls] = useState([]);
    const [numOfImages, setNumOfImages] = useState(0);
    const [newImageUrls, setNewImageUrls] = useState([]);
    const [newImgNames, setNewImgNames] = useState([]);
    const [numOfNewPics, setNumOfNewPics] = useState(0);
    const [newImageObjects, setNewImageObjects] = useState([]);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [markedImages, setMarkedImages] = useState([]);

    // Project document hooks
    const [newDocObjects, setNewDocObjects] = useState([]);
    const [newDocArray, setNewDocArray] = useState([]);
    const [numOfNewDocs, setNumOfNewDocs] = useState(0);

    const [numOfDocs, setNumOfDocs] = useState(0);
    const [docArray, setDocArray] = useState([]);
    const [toBeDeletedDocArray, setToBeDeletedDocArray] = useState([]);
    const [numOfDeletedDocs, setNumOfDeletedDocs] = useState(0);
    const [docPrivacyChanged, setDocPrivacyChanged] = useState(false);

    // const [deletedDocNames, setDeletedDocNames] = useState([]);
    // const [deletedDocUrls, setDeletedDocUrls] = useState([]);

    const [projectsExist, setProjectsExist] = useState(false);
    const [projectChosen, setProjectChosen] = useState(false);

    // Was this project saved by the current user? If so, can edit.
    const setCanEditFunction = (user) =>
    {
        if (user !== undefined && user !== "" && projectId !== 0)
        {
            if ((user === currentUser || isAdmin) && (projectToEdit.proj_status !== "Complete" || isAdmin))
            {
                setProjectCompleteError("");
                setCanEdit(true);
            }
            else if (projectToEdit.proj_status === "Complete" && !isAdmin)
            {
                setProjectCompleteError("Project can no longer be edited as it is complete.");
                setCanEdit(false);
            }
            else
            {
                setProjectCompleteError("");
                setCanEdit(false);
            }
        }
    }

    const removeNewImage = (e, index) =>
    {
        let tempImgUrls = newImageUrls;
        tempImgUrls.splice(index, 1);
        setNewImageUrls(tempImgUrls);

        let tempImgObjects = newImageObjects;
        tempImgObjects.splice(index, 1);
        setNewImageObjects(tempImgObjects);
        setNumOfNewPics(newImageUrls.length);

        let tempNewImgNames = newImgNames;
        tempNewImgNames.splice(index, 1);
        setNewImgNames(tempNewImgNames);
    }

    const removeImage = (e, index) => 
    {
        let tempImgUrls = imageUrls;

        // Existing images marked for deletion (red text)
        let imgsMarkedForDeletion = [];
        if (markedImages.length > 0)
        {
            imgsMarkedForDeletion = markedImages;
        }
        imgsMarkedForDeletion.push(tempImgUrls[index]);
        setMarkedImages(imgsMarkedForDeletion);

        tempImgUrls.splice(index, 1);
        setImageUrls(tempImgUrls);

        let tempImageToDelete = imageUrls[index];
        let tempImagesToDelete = [];

        if (imagesToDelete.length > 0) 
        {
            tempImagesToDelete = imagesToDelete;
        }

        tempImagesToDelete.push(tempImageToDelete);
        setImagesToDelete(tempImagesToDelete);
        setNumOfImages(imageUrls.length);
    }

    const removeDoc = (e, index) => 
    {
        let tempDocArray = docArray;
        let tempDocToBeDeleted = docArray[index];
        let tempToBeDeletedDocArray = [];
        let tempDeletedDocArray = [];

        if (toBeDeletedDocArray.length > 0) 
        {
            tempToBeDeletedDocArray = toBeDeletedDocArray;
        }

        tempToBeDeletedDocArray.push(tempDocToBeDeleted);
        setToBeDeletedDocArray(tempToBeDeletedDocArray);

        tempDocArray.splice(index, 1);
        setDocArray(tempDocArray);

        setNumOfDeletedDocs(tempDeletedDocArray.length);
        setNumOfDocs(tempDocArray.length);
    }

    const removeNewDoc = (e, index) =>
    {
        let tempDocObjects = newDocObjects;

        if (tempDocObjects.length > 0)
        {
            tempDocObjects.splice(index, 1);
            setNewDocObjects(tempDocObjects);
        }

        let tempNewDocArray = newDocArray;

        if (tempNewDocArray.length > 0)
        {
            tempNewDocArray.splice(index, 1);
            setNewDocArray(tempNewDocArray);
        }

        setNumOfNewDocs(numOfNewDocs - 1);
    }

    const handleSubmitDoc = (msgFromPhotos) =>
    {
        // if (newDocUrls.length === 0 && docPrivacyArray.length === 0)
        if (newDocArray.length === 0 && docPrivacyChanged === false)
        {
            console.log("No new docs to upload.");

            if (toBeDeletedDocArray.length > 0)
            {
                console.log("some docs to delete");
                return handleSubmit(msgFromPhotos, "docs to delete");
            }

            return handleSubmit(msgFromPhotos, "no docs");
        }
        else if (newDocArray.length === 0 && docPrivacyChanged === true)
        {
            return handleSubmit(msgFromPhotos, "updated privacy");
        }
        else if (newDocArray.length > 0)
        {
            const formData = new FormData();

            newDocObjects.forEach((file, i) =>
            {
                formData.append(i, file)
            });

            postInfo(formData, "projdocsupload", "POST", handleSubmit, msgFromPhotos);
        }
    }

    const cancelEdit = () =>
    {
        setEditPgDropdownValue(-1);
        setCounter(timerOff);
        setProjectChosen(false);

        setProjectId(0);

        setLoadedStaffArrays(false);
        setStaffArray([]);
        setStaffRows(1);
        setInputs({});

        setNumOfNewPics(0);
        setNewImageObjects([]);
        setNewImageUrls([]);
        setNumOfNewDocs(0);
        setNewDocObjects([]);
        setNewDocArray([]);

        setToBeDeletedDocArray([]);
        setNumOfDeletedDocs(0);
        setDocPrivacyChanged(false);

        setImagesToDelete([]);
        setMarkedImages([]);

        let tempNumOfDocs = numOfDocs;
        tempNumOfDocs = tempNumOfDocs + numOfDeletedDocs;
        setNumOfDocs(tempNumOfDocs);
        setNumOfDeletedDocs(0);

        setIsLoaded(false);
        window.location.assign("#top");
    }

    const handleSubmitPhoto = async (e) =>
    {
        setUpdating(true);

        // If there aren't any new photos to upload
        if (newImageUrls.length === 0)
        {
            console.log("No new images to upload.");

            if (imagesToDelete.length > 0)
            {
                console.log("some photos to delete");
                return handleSubmitDoc("photos to delete");
            }

            return handleSubmitDoc("no photos");
        }

        // if there are some new photos to upload
        const formData = new FormData();

        for (const files of newImageObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };
            const resizedImage = await resizeImage(config);
            formData.append("project-image", resizedImage, files.name);
        }

        postInfo(formData, "imageupload", "POST", handleSubmitDoc, "");
    };

    const handleSubmit = async (imgUploadMessage, docUploadMessage) =>
    {
        let myObject = {};
        myObject["proj_id"] = projectId;
        myObject["user"] = currentUser;
        let docsChanged = false;

        // Code to add deleted image urls to db  ------------------------------------
        let imagesToDeleteObject = {};
        imagesToDeleteObject["proj_id"] = projectId;
        imagesToDeleteObject["deleted_by_user"] = currentUser;

        if (imagesToDelete.length > 0) 
        {
            imagesToDeleteObject["deletedImageUrlArray"] = imagesToDelete;

            try
            {
                const response = await post(url.DELETE_PROJECT_IMAGES, imagesToDeleteObject);
                console.log(response);
            }
            catch (error)
            {
                console.log("This is the try catch error msg for deleted images: " + error);
            }
        }
        // ---------------------------------------------------------------

        // code to update project image urls on database ------------------------------------------
        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no photos" || imgUploadMessage !== "Error" && imgUploadMessage === "photos to delete")
        {
            let imgUrlArray = [];
            let imgFailedArray = [];
            let imgInfoObject = {};

            if (imgUploadMessage !== "photos to delete")
            {
                let imagePathsArray = Object.keys(imgUploadMessage);
                let imageUploadStatusArray = Object.values(imgUploadMessage);

                imagePathsArray.forEach((img, i) =>
                {
                    if (imageUploadStatusArray[i] === "Success")
                    {
                        let fullPath = `${API_URL}/${img}`;
                        imgUrlArray.push(fullPath);
                    }
                    else if (imageUploadStatusArray[i] === "Failed")
                    {
                        imgFailedArray.push(img);
                    }
                });
            }

            if (imgFailedArray.length > 0)
            {
                console.log(imgFailedArray.length + " image/s failed to upload.");
                setProjImagesError(imgFailedArray.length + " image/s failed to upload.");
            }
            else
            {
                setProjImagesError("");
            }

            imgInfoObject["proj_id"] = projectId;
            imgInfoObject["added_by_user"] = currentUser;

            if (imgUploadMessage !== "photos to delete")
            {
                let oldImageArray = imageUrls;
                let newImageArray = imgUrlArray;
                const combinedImageArray = [...oldImageArray, ...newImageArray];
                imgInfoObject["imageUrlArray"] = combinedImageArray;
            }
            else
            {
                imgInfoObject["imageUrlArray"] = imageUrls;
            }

            try
            {
                docsChanged = true;
                const response = await post(url.UPDATE_PROJECT_IMAGES, imgInfoObject);

                if (response.message !== undefined && (response.message === "Project images updated!"
                    || response.message === "New project images have been added to database."))
                {
                    setProjImagesUpdated("Project images updated");
                    console.log("Project images updated on db");
                }
                else
                {
                    setProjImagesUpdated("Project images NOT updated. Something went wrong.");
                    console.log("Something went wrong while updating project images");
                }
            }
            catch (error)
            {
                setProjImagesUpdated("Project images NOT updated. Something went wrong.");
                console.log("This is the try catch error msg for updating project images: " + error);
            }
        }
        // --------------------------------------------------------

        // Code to add deleted doc names and urls to db  ------------------------------------
        let docsToDeleteObject = {};

        // if (docUrlsToDelete.length > 0) 
        if (toBeDeletedDocArray.length > 0) 
        {
            // docsToDeleteObject["deletedDocNamesArray"] = docNamesToDelete;
            // docsToDeleteObject["deletedDocUrlArray"] = docUrlsToDelete;

            docsToDeleteObject["proj_id"] = projectId;
            docsToDeleteObject["deleted_by_user"] = currentUser;
            docsToDeleteObject["deletedDocArray"] = toBeDeletedDocArray;

            // let test = docsToDeleteObject;
            // debugger;

            try
            {
                const response = await post(url.DELETE_PROJECT_DOCS, docsToDeleteObject);
                console.log(response);
            }
            catch (error)
            {
                console.log("This is the try catch error msg for deleted docs: " + error);
            }
        }
        // ---------------------------------------------------------------

        // Code to add document urls and names to object ready for posting
        let docUrlArray = [];
        let docInfoObject = {};
        let docFailedArray = [];

        if ((docUploadMessage !== "Error" && docUploadMessage !== "no docs")
            || (docUploadMessage !== "Error" && docUploadMessage === "docs to delete"))
        {
            docInfoObject["proj_id"] = projectId;
            docInfoObject["added_by_user"] = currentUser;

            // No docs deleted
            if (docUploadMessage !== "docs to delete" && docUploadMessage !== "updated privacy")
            {
                let docPathsArray = Object.keys(docUploadMessage);
                let docUploadStatusArray = Object.values(docUploadMessage);

                // Record which docs were successfully saved to server and which failed
                docPathsArray.forEach((doc, i) =>
                {
                    if (docUploadStatusArray[i] === "Success")
                    {
                        let fullPath = `${API_URL}/${doc}`;
                        docUrlArray.push(fullPath);
                    }
                    else if (docUploadStatusArray[i] === "Failed")
                    {
                        docFailedArray.push(doc);
                        docUrlArray.push("-");
                    }
                });

                // Show alert if some docs failed to upload
                if (docFailedArray.length > 0)
                {
                    console.log(docFailedArray.length + " doc/s failed to upload.");
                    setProjDocsError(docFailedArray.length + " doc/s failed to upload.");
                }
                else
                {
                    setProjDocsError("");
                }

                // let oldDocUrlArray = docUrls;
                // let oldDocNamesArray = docNames;

                // let newDocUrlArray = docUrlArray;
                // let newDocNamesArray = newDocNames;

                // const combinedDocUrlArray = [...oldDocUrlArray, ...newDocUrlArray];
                // const combinedDocNamesArray = [...oldDocNamesArray, ...newDocNamesArray];

                let oldDocsArray = docArray;
                let newDocsArray = JSON.parse(JSON.stringify(newDocArray));
                let tempNewDocsArray = JSON.parse(JSON.stringify(newDocArray));

                // Add doc urls on server to object
                tempNewDocsArray.forEach((doc, index) =>
                {
                    newDocsArray[index].url = docUrlArray[index];
                });

                // let test = newDocsArray;
                // debugger;

                const combinedDocArray = [...oldDocsArray, ...newDocsArray];

                if (combinedDocArray.length > 0)
                {
                    //let returnedDocArray = createDocArray("", "", docPrivacyArray, docArray);
                    docInfoObject["docArray"] = combinedDocArray;
                }
                // else
                // {
                //     // docInfoObject["docUrlArray"] = combinedDocUrlArray;
                //     // docInfoObject["docNamesArray"] = combinedDocNamesArray;

                //     let returnedDocArray = createDocArray(combinedDocUrlArray, combinedDocNamesArray, docPrivacyArray, docArray);
                //     debugger;
                //     docInfoObject["docArray"] = returnedDocArray;
                //     debugger;
                // }
            }
            else if (docUploadMessage === "docs to delete")
            // if there were docs deleted
            {
                // debugger;
                // docInfoObject["docUrlArray"] = docUrls;
                // docInfoObject["docNamesArray"] = docNames;

                // let returnedDocArray = createDocArray(docUrls, docNames, docPrivacyArray, docArray);
                // debugger;
                docInfoObject["docArray"] = docArray;
            }

            // if only doc privacy status was changed
            if (docUploadMessage === "updated privacy")
            {
                //let returnedDocArray = createDocArray(docUrls, docNames, docPrivacyArray, docArray);
                docInfoObject["docArray"] = docArray;
            }

            // let test = docInfoObject;
            // debugger;

            try
            {
                docsChanged = true;
                const response = await post(url.UPDATE_PROJECT_DOCS, docInfoObject);

                if ((response.message !== undefined && response.message === "Project documents updated!")
                    || (response.message !== undefined && response.message === "New project documents have been added to database."))
                {
                    setProjDocsUpdated("Project documents updated");
                    console.log(response.message);
                }
                else if (response.message === "Something wrong when updating project documents!" || response.message === "Some error occurred while adding new project documents to database.")
                {
                    setProjDocsUpdated("Project documents NOT updated. Something went wrong.");
                    console.log("Something went wrong with updating documents");
                }
                else
                {
                    console.log("Project docs: an unhandled error occurred.")
                }
            }
            catch (error)
            {
                setProjDocsUpdated("Project documents NOT updated. Something went wrong.");
                console.log("This is the try catch error msg for updated documents: " + error);
            }
        }
        // ---------------------------------------------------------------

        // code to save project data to database
        if (Object.keys(inputs).length !== 0 && inputs.constructor === Object)
        {
            Object.assign(myObject, inputs);
            try
            {
                docsChanged = true;

                const response = await post(url.UPDATE_PROJECT, myObject);

                if (response.message !== undefined && response.message === "Project updated!")
                {
                    setProjInfoUpdated("Project information updated");
                } else
                {
                    setProjInfoUpdated("Project information NOT updated. Something went wrong.");
                }
            }
            catch (error)
            {
                setProjInfoUpdated("Project info NOT updated. Something went wrong.");
                console.log("This is the try catch error msg: " + error);
            }
        }

        if (docsChanged === true)
        {
            setCounter(timerOff);
            setProjectChosen(false);
            setEditPgDropdownValue(-1);
            setProjectId(0);
            //setProjectIndex(0);
            setUpdating(false);
            setInputs({});

            setNumOfImages(0);
            setNumOfNewPics(0);
            setImageUrls([]);
            setNewImageUrls([]);
            setNewImageObjects({});
            setImagesToDelete([]);
            setMarkedImages([]);
            setIsLoadedImages(false);

            setNumOfDocs(0);
            // setDocUrls([]);
            // setDocNames([]);
            setDocArray([]);
            //setDocPrivacyArray([]);

            setNumOfNewDocs(0);
            //setNewDocUrls([]);
            //setNewDocNames([]);
            setNewDocObjects([]);
            setNewDocArray([]);
            //setNewDocPrivacyArray([]);

            //setDeletedDocArray([]);
            setToBeDeletedDocArray([]);
            setNumOfDeletedDocs(0);

            // setDeletedDocUrls([]);
            // setDeletedDocNames([]);
            //setDocUrlsToDelete([]);
            //setDocNamesToDelete([]);
            setNumOfDeletedDocs(0);
            setDocPrivacyChanged(false);
            setIsLoadedDocs(false);

            setShowAlerts(true);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });

            setIsLoaded(false);
            toggleSingleProjInfoChanged();
            toggleProjectInfoChanged();
            console.log("Changes were made.");
        }
        else if (docsChanged === false)
        {
            swal("Error", "Nothing was changed", "error");
            console.log("No changes");
        }

        setUpdating(false);
    }

    const handleChooseProj = (projId) =>
    {
        if (projId !== "-1")
        {
            // let both = e.target.value;
            // let array = both.split("-");
            // let tempId = Number(array[0]);
            // let tempIndex = Number(array[1]);
            setProjectId(Number(projId));
            // setProjectIndex(tempIndex);

            if (canEdit || isAdmin)
            {
                setCounter(timerSeconds);
                setProjectChosen(true);
            }
            else if (!canEdit && !isAdmin)
            {
                setCounter(timerOff);
                setProjectChosen(true);
            }
        }
        else
        {
            setProjectId(0);
            //setProjectIndex(0);
            setCounter(timerOff);
            setProjectChosen(false);
        }
    }

    let staffRow;
    let staffNamesArray = [];

    for (let i = 0; i < staffRows; ++i)
    {
        staffRow = (<div key={i} className='row mb-2 mb-sm-2 mb-md-2'>
            <Col className="col-sm-8 col-12 mb-2 mb-sm-0">
                <Input
                    autoComplete="off"
                    id="coreStaffNames"
                    name="contract_core_staff_names"
                    placeholder="Enter name of staff member"
                    type="text"
                    value={staffArray[i] || ''}
                    onChange={(e) => { handleStaffArray(e.target.value, i, staffTypes, staffArray, staffObject, setInputs, setStaffObject, setStaffArray, staffRows) }}
                />
            </Col>
            <Col className="col-sm-3 col-12">
                <Input
                    id="staff_type"
                    name="staff_type"
                    type="select"
                    onChange={(e) => handleStaffTypes(e.target.value, i, staffArray, staffObject, setInputs, setStaffObject, staffTypes, setStaffTypes)}
                    value={staffTypes[i] || ''}
                >
                    {localLabourJobTitles.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </Input>
            </Col>
            <Col className="my-sm-0 my-2">
                <Button
                    color="danger"
                    onClick={() => removeStaffRow(
                        i,
                        staffRows,
                        setCurrentIndex,
                        setStaffRows,
                        staffArray,
                        setStaffArray,
                        staffTypes,
                        setStaffTypes,
                        staffObject,
                        inputs,
                        setInputs,
                        setStaffObject
                    )}>
                    <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                </Button>
            </Col>
        </div>);

        staffNamesArray.push(staffRow);
    }

    const loadDropdownArray = () =>
    {
        if (projectsArray.length > 0)
        {
            // Pop server success msg off end of array for "Choose projects to edit" dropdown
            let tempArray = JSON.parse(JSON.stringify(projectsArray));
            tempArray = removeSuccessMessage(tempArray, "Projects retrieved successfully.");

            setProjDropdownArray(tempArray);
        }
    }

    const handleDocPrivacyChange = (e, index, newOrOld) =>
    {
        let privacyStatus = e.target.value;
        setDocPrivacyChanged(true);

        if (newOrOld === "old")
        {
            // let tempDocPrivacyArray = docPrivacyArray;
            // tempDocPrivacyArray[index] = privacyStatus;
            // setDocPrivacyArray(tempDocPrivacyArray);

            let tempDocArray = docArray;
            tempDocArray[index].privacy = privacyStatus;
            setDocArray(tempDocArray);
        }
        else if (newOrOld === "new")
        {
            // let tempDocPrivacyArray = newDocPrivacyArray;
            // tempDocPrivacyArray[index] = privacyStatus;
            // setNewDocPrivacyArray(tempDocPrivacyArray);

            let tempNewDocArray = newDocArray;
            tempNewDocArray[index].privacy = privacyStatus;
            setNewDocArray(tempNewDocArray);
        }
    }

    const addWardCouncillor = async () => 
    {
        let personName = projectToEdit.ward_person_name !== undefined ? projectToEdit.ward_person_name : "";

        if (personName === "" && projectToEdit.proj_id === projectId)
        {
            let wardNumber = typeof projectToEdit.ward === "number" ? projectToEdit.ward : projectToEdit.ward[0];
            let wardPersonArray = await simpleFetch("getwardcouncillor", wardNumber);
            wardPersonArray = removeSuccessMessage(wardPersonArray, "Ward councillor info retrieved successfully.");
            let wardCouncillorName = wardPersonArray[0].first_name + " " + wardPersonArray[0].surname;
            setInputs(prevState => ({ ...prevState, ["ward_person_name"]: wardCouncillorName }));
        }
        else if (inputs.ward_person_name !== undefined)
        {
            setInputs(prevState => ({ ...prevState, ["ward_person_name"]: undefined }));
        }
    }

    useEffect(() =>
    {
        if (projectToEdit !== undefined && Object.keys(projectToEdit).length > 0 && activeTab === "3")
        {
            addWardCouncillor();
        }
    }, [projectToEdit, activeTab]);

    useEffect(() =>
    {
        if (activeTab === "3")
        {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }

        if (activeTab !== "3")
        {
            cancelEdit();
        }
    }, [activeTab]);

    useEffect(() =>
    {
        if (isLoadedDocs && activeTab === "3")
        {
            sortProjectDocs(projectDocsArray, projectId, setNumOfDocs, setDocArray, setDocPrivacyChanged);
        }
    }, [projectDocsArray, isLoaded, activeTab]);

    useEffect(() =>
    {
        if (isLoadedImages && activeTab === "3")
        {
            sortProjectImages(isLoadedImages, isLoaded, projectId, projectImagesArray, setImageUrls, setNumOfImages);
        }
    }, [projectImagesArray, isLoaded, isLoadedImages, activeTab]);

    useEffect(() =>
    {
        loadStaffArrays(isLoaded,
            loadedStaffArrays,
            setStaffArray,
            setStaffRows,
            setLoadedStaffArrays,
            setProjectsExist,
            setStaffTypes,
            setStaffObject,
            projectToEdit,
        );

        if (projectsArray.length > 0)
        {
            loadDropdownArray();
        }
    }, [isLoaded, projectsArray, loadedStaffArrays]);

    useEffect(() =>
    {
        setProjectCompleteError("");
        if (projectToEdit !== undefined && Object.keys(projectToEdit).length > 0)
        {
            setCanEditFunction(projectToEdit.added_by_user);
        }

        setNumOfDocs(0);
        setDocArray([]);
        setNumOfNewDocs(0);
        setNewDocArray([]);
        setDocPrivacyChanged(false);
        setToBeDeletedDocArray([]);
        setNumOfDeletedDocs(0);

        setNumOfImages(0);
        setImageUrls([]);
        setIsLoadedDocs(false);
        setIsLoadedImages(false);
        setLoadedStaffArrays(false);
        setIsLoaded(false);
        toggleProjectInfoChanged();

        if (editPgDropdownValue !== -1 && (canEdit || isAdmin))
        {
            setProjectChosen(true);
            setCounter(timerSeconds);
        }
    }, [projectId]);

    useEffect(() =>
    {
        if (editPgDropdownValue !== -1 && (canEdit || isAdmin))
        {
            setProjectChosen(true);
            setCounter(timerSeconds);
        }
    }, [editPgDropdownValue]);

    useEffect(() =>
    {
        if (counter === 120)
        {
            swal("Warning", "2 minutes left", "info");
        }

        let interval = null;
        if (counter === 0)
        {
            cancelEdit();
        }

        if (counter !== timerOff && projectChosen)
        {
            interval = setTimeout(() => setCounter(counter => counter - 1), 1000);
        }
        else if (counter !== 0 && !projectChosen)
        {
            clearTimeout(interval);
        }

        return () => clearTimeout(interval);

    }, [projectChosen, counter]);

    useEffect(() =>
    {
        if (projectChosen && Object.keys(inputs).length !== 0)
        {
            setCounter(timerSeconds);
        }
    }, [inputs]);

    // Converting dates to correct format
    let projectStart = "";
    let projectEnd = "";
    let projectExt = "";
    let projectCompl = "";

    if (projectsExist && projectToEdit !== undefined && Object.keys(projectToEdit).length > 0)
    {
        projectStart = isLoaded && projectsArray && inputs.proj_start_date === undefined
            ? convertDate(projectToEdit.proj_start_date, isLoaded)
            : inputs.proj_start_date !== undefined
                ? convertDate(inputs.proj_start_date, isLoaded)
                : "";

        projectEnd = isLoaded && projectsArray && inputs.proj_end_date_projected === undefined
            ? convertDate(projectToEdit.proj_end_date_projected, isLoaded)
            : inputs.proj_end_date_projected !== undefined
                ? convertDate(inputs.proj_end_date_projected, isLoaded)
                : "";

        projectExt = isLoaded && projectToEdit.proj_ext_date !== undefined && inputs.proj_ext_date === undefined
            ? convertDate(projectToEdit.proj_ext_date, isLoaded)
            : inputs.proj_ext_date !== undefined
                ? convertDate(inputs.proj_ext_date, isLoaded)
                : "";

        projectCompl = isLoaded && projectToEdit.proj_completed_date !== undefined && inputs.proj_completed_date === undefined
            ? convertDate(projectToEdit.proj_completed_date, isLoaded)
            : inputs.proj_completed_date !== undefined
                ? convertDate(inputs.proj_completed_date, isLoaded)
                : "";
    }

    return (
        <div>
            {/* <p>canEdit is: {canEdit.toString()}</p>
            <p>Current user: {currentUser}</p>
            <p>isLoaded: {isLoaded.toString()}</p>
            <p>projectsExist: {projectsExist.toString()}</p> */}
            {/* <p>editPgDropdownValue: {editPgDropdownValue}</p>
            <p>projId: {projectId}</p>
            <p>Project to edit - proj id: {projectToEdit.proj_id !== undefined ? projectToEdit.proj_id : ""}</p> */}
            {/* <p>Counter: {counter}</p>
            <p>Project chosen: {projectChosen.toString()}</p>
            <p>Inputs: {JSON.stringify(inputs)}</p>
            <p>project dropdown array: {JSON.stringify(projDropdownArray)}</p>
            <p>Msg from server: {msgFromServer}</p> */}
            {/* <p>doc privacy changed: {docPrivacyChanged.toString()}</p>
            <p>docArray: {JSON.stringify(docArray)}</p>
            <p>newDocArray: {JSON.stringify(newDocArray)}</p> */}
            {/* <p>deleted doc array: {deletedDocArray.join(", ")}</p>
            <p>Num of docs: {numOfDocs}</p>
            <p>Num of deleted docs: {numOfDeletedDocs}</p> */}

            {/* <p>Inputs: {JSON.stringify(inputs)}</p> */}

            {counter > 0 && counter >= 60
                ? <p className='redText' style={{ textAlign: "right", fontWeight: "bold" }}>Time left: <span style={{ color: 'black' }}>{Math.ceil(counter / 60)} minute/s</span></p>
                : counter > 0
                    ? <p className='redText' style={{ textAlign: "right", fontWeight: "bold" }}>Time left: <span style={{ color: 'black' }}>{counter} seconds</span></p>
                    : null}

            {isLoaded && projectToEdit !== undefined && projectToEdit.isActive === 0 && editPgDropdownValue !== -1
                ? <p style={{ color: "red" }}>This project is currently inactive.</p>
                : null}

            {isLoaded && projDropdownArray.length > 0
                ?
                <div>
                    <FormGroup row className="mb-4">
                        <Label
                            for="projToEdit"
                            sm={2}
                        >
                            Choose Project to Edit
                        </Label>
                        <Col sm={10}>
                            <Input
                                onChange={(e) => { setEditPgDropdownValue(Number(e.target.value)); handleChooseProj(e.target.value); }}
                                id="projToEdit"
                                name="projToEdit"
                                type="select"
                                value={editPgDropdownValue}
                            >
                                <option key={"first"} value={-1}>Choose Option</option>
                                {projDropdownArray.map((project, index) => (
                                    <option key={index}
                                        value={project.proj_id}>Project ID: {project.proj_id} - {project.proj_descrip !== undefined && project.proj_descrip !== ""
                                            ? project.proj_descrip.substr(0, 30) + " - "
                                            : null} {project.proj_address} - {project.proj_status}</option>
                                ))}
                            </Input>

                        </Col>
                    </FormGroup>
                </div>
                : isLoaded && projDropdownArray.length === 0
                    ? <p className="redText">No projects saved yet.</p>
                    : null}

            {numOfImages > 0 && (canEdit || isAdmin) && editPgDropdownValue !== -1
                ? <Button className="ms-1 mb-2" color="primary" outline onClick={() =>
                { window.location.assign("#photoSection") }}>
                    <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                    <Badge className="ms-2 bg-danger">
                        {numOfImages}
                    </Badge>
                </Button>
                : null}

            {(numOfDocs > 0 || docArray.length > 0) && (canEdit || isAdmin) && editPgDropdownValue !== -1
                ? <Button className="ms-1 mb-2" color="primary" outline onClick={() =>
                { window.location.assign("#docSection") }}>
                    <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                    <Badge className="ms-2 bg-danger">
                        {numOfDocs > 0 ? numOfDocs : docArray.length > 0 ? docArray.length : "0"}
                    </Badge>
                </Button>
                : null}

            {/* {isLoaded && projectsArray.length > 0 && projectId !== 0 && projectsExist && (canEdit || isAdmin) && editPgDropdownValue !== "-1" */}
            {isLoaded && projectToEdit !== undefined && Object.keys(projectToEdit).length > 0 && projectsExist && (canEdit || isAdmin) && editPgDropdownValue !== -1
                ?
                <Form
                    name="editProjectForm"
                    onSubmit={(e) => { handleSubmitPhoto(); e.preventDefault(); }}
                >
                    <FormGroup row>
                        <Label
                            for="proj_id"
                            sm={2}
                        >
                            Project ID
                        </Label>
                        <Col className="col-sm-10">
                            <Input
                                id="proj_id"
                                name="proj_id"
                                type="text"
                                disabled
                                value={projectToEdit.proj_id || ''}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_descrip"
                            sm={2}
                        >
                            Description
                        </Label>
                        <Col className="col-sm-10">
                            <Input
                                id="proj_descrip"
                                name="proj_descrip"
                                type="text"
                                value={
                                    inputs.proj_descrip === undefined
                                        ? projectToEdit.proj_descrip
                                        : inputs.proj_descrip !== undefined
                                            ? inputs.proj_descrip
                                            : ''
                                }
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_descrip_long"
                            sm={2}
                        >
                            Description (detailed)
                        </Label>
                        <Col className="col-sm-10">
                            <Input
                                id="proj_descrip_long"
                                name="proj_descrip_long"
                                type="textarea"
                                rows="5"
                                value={
                                    inputs.proj_descrip_long === undefined
                                        ? projectToEdit.proj_descrip_long
                                        : inputs.proj_descrip_long !== undefined
                                            ? inputs.proj_descrip_long
                                            : ''
                                }
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_address"
                            sm={2}
                        >
                            Location (address)
                        </Label>
                        <Col className="col-sm-10">
                            <Input
                                id="proj_address"
                                name="proj_address"
                                type="text"
                                disabled
                                value={projectToEdit.proj_address || ''}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="municipality"
                            sm={2}
                        >
                            Municipality
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="municipality"
                                name="municipality"
                                placeholder="Municipality"
                                type="text"
                                disabled
                                value={projectToEdit.municipality || ''}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="wardNum"
                            sm={2}
                        >
                            Ward
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="wardNum"
                                name="ward"
                                placeholder="Ward"
                                type="number"
                                disabled
                                value={projectToEdit.ward || ''}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="ward_person_name"
                            sm={2}
                        >
                            Ward councillor
                        </Label>
                        <Col sm={10}>
                            <Input
                                name="ward_person_name"
                                placeholder="Ward councillor name"
                                type="text"
                                disabled
                                value={inputs.ward_person_name || projectToEdit.ward_person_name || ''}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="mapCoords"
                            sm={2}
                        >
                            Location (map)
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="mapCoords"
                                name="map_location"
                                placeholder="Latitude and Longitude"
                                type="text"
                                disabled
                                value={projectToEdit.map_location || ''}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.proj_site_camp || ''}
                    >
                        <Label
                            for="proj_site_camp"
                            sm={2}
                        >
                            Site Camp
                        </Label>
                        <legend className="col-form-label col-sm-3">
                            Is there a site camp?
                        </legend>
                        <Col sm={4}>
                            <Button color={projectToEdit.proj_site_camp === true && inputs.proj_site_camp === undefined
                                ? "success"
                                : inputs.proj_site_camp === "true"
                                    ? "success"
                                    : "secondary"} className='me-1' name="proj_site_camp" onClick={(e) => { handleChange(e); }} value={true}>
                                Yes
                            </Button>
                            <Button color={projectToEdit.proj_site_camp === false && inputs.proj_site_camp === undefined
                                ? "danger"
                                : inputs.proj_site_camp === "false"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="proj_site_camp" onClick={(e) => { handleChange(e); }} value={false}>
                                No
                            </Button>
                        </Col>
                    </FormGroup>

                    {projectToEdit.proj_site_camp === true || inputs.proj_site_camp === "true" ?
                        <FormGroup
                            row
                            tag="fieldset"
                            value={inputs.site_camp_separate || ''}
                        >
                            <Label
                                for="site_camp_separate"
                                className="col-sm-2"
                            >
                            </Label>
                            <legend className="col-form-label col-sm-3">
                                Is the site camp separate from the project site?
                            </legend>
                            <Col sm={4}>
                                <Button color={projectToEdit.site_camp_separate === true && inputs.site_camp_separate === undefined
                                    ? "success"
                                    : inputs.site_camp_separate === "true"
                                        ? "success"
                                        : "secondary"} className='me-1' name="site_camp_separate" onClick={handleChange} value={true}>
                                    Yes
                                </Button>
                                <Button color={projectToEdit.site_camp_separate === false && inputs.site_camp_separate === undefined
                                    ? "danger"
                                    : inputs.site_camp_separate === "false"
                                        ? "danger"
                                        : "secondary"} className='me-1' name="site_camp_separate" onClick={handleChange} value={false}>
                                    No
                                </Button>
                            </Col>
                        </FormGroup> : null}

                    {projectToEdit.site_camp_separate === true || inputs.site_camp_separate === "true" ?
                        <FormGroup row>
                            <Label
                                for="site_camp_where"
                                sm={2}
                            >
                                Briefly explain where the site camp is?
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="siteCampLocation"
                                    name="site_camp_where"
                                    type="textarea"
                                    value={inputs.site_camp_where === undefined
                                        ? projectToEdit.site_camp_where
                                        : inputs.site_camp_where !== undefined
                                            ? inputs.site_camp_where
                                            : ''}
                                    onChange={(e) => { handleChange(e); }}
                                />
                            </Col>
                        </FormGroup> : null}

                    <FormGroup row>
                        <Label
                            for="proj_type"
                            sm={2}
                        >
                            Type
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projType"
                                name="proj_type"
                                type="select"
                                value={inputs.proj_type === undefined
                                    ? projectToEdit.proj_type
                                    : inputs.proj_type !== undefined
                                        ? inputs.proj_type
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            >
                                {projTypeOptions.map((option, index) => (
                                    <option key={index} value={option.value}>{option.label}</option>
                                ))}
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <div className="mb-2 col-12 col-sm-5 col-md-6 align-middle">
                            <Label
                                for="contract_core_staff_names"
                                className="col-sm-5 col-8"
                            >
                                Local Staff (names)
                            </Label>
                        </div>
                        {staffNamesArray}
                        <Button className="ms-2 ms-sm-3 col-2 col-sm-1"
                            onClick={() => addStaffRow(staffArray,
                                currentIndex,
                                staffTypes,
                                staffRows,
                                setCurrentIndex,
                                setStaffRows)}>
                            Add
                        </Button>
                    </FormGroup>

                    {/* <div className="redBorder p-2">
                        <p>Staff rows:</p>
                        <pre>
                            {JSON.stringify(staffRows, null, 2)}
                        </pre>
                        <p>Staff array:</p>
                        <pre>
                            {JSON.stringify(staffArray, null, 2)}
                        </pre>
                        <p>Staff object:</p>
                        <pre>
                            {JSON.stringify(staffObject, null, 2)}
                        </pre>
                        <p>inputs object:</p>
                        <pre>
                            {JSON.stringify(inputs, null, 2)}
                        </pre>
                    </div> */}

                    <FormGroup row>
                        <Label
                            for="contract_core_staff_num"
                            sm={2}
                        >
                            Local Staff (number)
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="coreStaffNum"
                                name="contract_core_staff_num"
                                placeholder="How many core staff members are there?"
                                type="number"
                                disabled
                                value={
                                    staffArray.length > 0
                                        ? staffArray.length
                                        : staffArray[0] === "" || staffArray.length === 0
                                            ? "0"
                                            : "0"
                                }
                                onChange={(e) => { handleChange(e); }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="contract_number"
                            sm={2}
                        >
                            Contract Number
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="contractNum"
                                name="contract_number"
                                placeholder="e.g. 1D-45368"
                                type="text"
                                value={inputs.contract_number === undefined
                                    ? projectToEdit.contract_number
                                    : inputs.contract_number !== undefined
                                        ? inputs.contract_number
                                        : ''}
                                onChange={(e) => { handleChange(e); }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="municipality_dept"
                            sm={2}
                        >
                            Municipality Dept
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="muniDept"
                                name="municipality_dept"
                                type="select"
                                value={inputs.municipality_dept === undefined
                                    ? projectToEdit.municipality_dept
                                    : inputs.municipality_dept !== undefined
                                        ? inputs.municipality_dept
                                        : ''}
                                onChange={(e) => { handleChange(e) }}

                            > {municipalityDeptOptions.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="client_type"
                            sm={2}
                        >
                            Client Type
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="clientType"
                                name="client_type"
                                type="select"
                                value={inputs.client_type === undefined
                                    ? projectToEdit.client_type
                                    : inputs.client_type !== undefined
                                        ? inputs.client_type
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            > {clientType.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="client_name"
                            sm={2}
                        >
                            Client
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="clientName"
                                name="client_name"
                                placeholder="What is the client name?"
                                type="text"
                                value={inputs.client_name === undefined
                                    ? projectToEdit.client_name
                                    : inputs.client_name !== undefined
                                        ? inputs.client_name
                                        : ''}
                                onChange={(e) => { handleChange(e); }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_engineer"
                            sm={2}
                        >
                            Engineer
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projEngineer"
                                name="proj_engineer"
                                placeholder="What is the Project Engineer's Name?"
                                type="text"
                                value={inputs.proj_engineer === undefined
                                    ? projectToEdit.proj_engineer
                                    : inputs.proj_engineer !== undefined
                                        ? inputs.proj_engineer
                                        : ''}
                                onChange={(e) => { handleChange(e); }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="clerk_of_works"
                            sm={2}
                        >
                            Clerk of Works
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="clerkWorks"
                                name="clerk_of_works"
                                placeholder="Name of the Clerk of Works?"
                                type="text"
                                value={inputs.clerk_of_works === undefined && projectToEdit.clerk_of_works !== undefined
                                    ? projectToEdit.clerk_of_works
                                    : inputs.clerk_of_works !== undefined
                                        ? inputs.clerk_of_works
                                        : ''}
                                onChange={(e) => { handleChange(e); }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="contractor"
                            sm={2}
                        >
                            Contractor
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="contractor"
                                name="contractor"
                                placeholder="Contractor company name?"
                                type="text"
                                value={inputs.contractor === undefined
                                    ? projectToEdit.contractor
                                    : inputs.contractor !== undefined
                                        ? inputs.contractor
                                        : ''}
                                onChange={(e) => { handleChange(e); }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_manager"
                            sm={2}
                        >
                            Project Manager
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projManager"
                                name="proj_manager"
                                placeholder="What is the Project Manager's name?"
                                type="text"
                                value={inputs.proj_manager === undefined
                                    ? projectToEdit.proj_manager
                                    : inputs.proj_manager !== undefined
                                        ? inputs.proj_manager
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_start_date"
                            sm={2}
                        >
                            Start Date
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projStart"
                                name="proj_start_date"
                                placeholder="What is the start date for the project?"
                                type="date"
                                value={projectStart || ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                            <small className="w-100">E.g. 2022/10/04 is 4 Oct 2022 </small>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_end_date_projected"
                            sm={2}
                        >
                            End Date (projected)
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projEndProjected"
                                name="proj_end_date_projected"
                                placeholder="What is the projected/planned end date for the project?"
                                type="date"
                                value={projectEnd || ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_ext_date"
                            sm={2}
                        >
                            Extended Date (if applicable)
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="projExtendedDate"
                                name="proj_ext_date"
                                placeholder="What is the extended end date for the project (if there is one)?"
                                type="date"
                                value={projectExt || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </FormGroup>

                    {/* <p>inputs object:</p>
                    <pre>
                        {JSON.stringify(inputs, null, 2)}
                    </pre> */}

                    {projectToEdit.proj_ext_date !== undefined
                        ? <FormGroup row>
                            <Label
                                for="proj_ext_date_comment"
                                sm={2}
                            >
                                Comment
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="projExtDateComment"
                                    name="proj_ext_date_comment"
                                    placeholder='Enter comment about project end date being extended...'
                                    type="textarea"
                                    value={inputs.proj_ext_date_comment === undefined
                                        ? projectToEdit.proj_ext_date_comment
                                        : inputs.proj_ext_date_comment !== undefined
                                            ? inputs.proj_ext_date_comment
                                            : ''}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        : null}

                    <FormGroup row className="mb-4">
                        <Label
                            for="proj_status"
                            sm={2}
                        >
                            Status of Project
                        </Label>
                        <Col sm={10}>
                            <Input
                                onChange={(e) => { handleChange(e) }}
                                id="projStatus"
                                name="proj_status"
                                type="select"
                                value={inputs.proj_status === undefined
                                    ? projectToEdit.proj_status
                                    : inputs.proj_status !== undefined
                                        ? inputs.proj_status
                                        : ''}
                            >
                                {projStatus.map((option, index) => (
                                    <option key={index} value={option.value}>{option.label}</option>
                                ))}
                            </Input>
                        </Col>
                    </FormGroup>

                    {/* <p>array: {projectToEdit.proj_status}, input: {inputs.proj_status}</p> */}

                    {(projectToEdit.proj_status === "Complete" && inputs.proj_status === undefined)
                        || inputs.proj_status === "Complete" ?
                        <FormGroup row>
                            <Label
                                for="proj_completed_date"
                                sm={2}
                            >
                                Completion Date
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="projExtendedDate"
                                    name="proj_completed_date"
                                    placeholder="When was the project completed?"
                                    type="date"
                                    value={projectCompl || ''}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        : null}

                    <FormGroup row>
                        <Label
                            for="addPhoto"
                            sm={2}
                        >
                            Upload New Photo/s
                        </Label>
                        <Col className="col-sm-10 col-12 mb-2">
                            <Input
                                id="addNewPhoto"
                                name="addNewPhoto"
                                type="file"
                                onChange={(e) => changePhotoHandler(e, setNumOfNewPics, setNewImageObjects, setNewImageUrls, "")}
                                multiple
                            />
                        </Col>
                    </FormGroup>

                    {/* New images section */}
                    <div className={numOfNewPics > 0 ? 'p-2 mb-1 row' : ""}>
                        {numOfNewPics > 0
                            ? <div className='mb-4 col-12'>
                                <Button className="mb-2" color="primary" outline>
                                    <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                    <Badge className="ms-2 bg-danger">
                                        {numOfNewPics}
                                    </Badge> New
                                </Button>
                            </div>
                            : null}
                        {
                            newImageUrls.length > 0
                                ? newImageUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-2 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y" style={{ right: "0.05rem" }} color="danger" onClick={(e) => removeNewImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                        <div className='p-1'>{newImgNames[index]}</div>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    {newImageUrls.length > 0 ? <hr /> : null}

                    {/* Existing images section */}
                    <div id="photoSection" className={numOfImages > 0 ? 'mb-1 p-2 row' : 'row'}>
                        {numOfImages > 0 ? <div className='mb-4 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfImages}
                                </Badge> Existing
                            </Button></div> : null}
                        {
                            imageUrls.length > 0
                                ? imageUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y"
                                            style={{ right: "0.05rem" }} color="danger" onClick={(e) => removeImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        }

                        {/* Images: {imageUrls.join(", ")} */}

                        {/* Existing images marked for deletion */}
                        {markedImages.length > 0 && numOfImages === 0 ? <div className='mb-4 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfImages}
                                </Badge> Existing
                            </Button></div> : null}
                        {
                            markedImages.length > 0
                                ? markedImages.map((url, index) => (
                                    <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button color="danger" className="col-12" disabled
                                        >
                                            Marked for Deletion
                                        </Button>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    <FormGroup row>
                        <Label
                            for="addNewDoc"
                            sm={2}
                        >
                            Upload New Document/s
                        </Label>
                        <Col className="col-sm-10 col-12 mb-1">
                            <Input
                                id="addNewDoc"
                                name="addNewDoc"
                                type="file"
                                onChange={(e) => changeDocHandler(e, setNumOfNewDocs, setNewDocObjects, setNewDocArray)}
                                multiple
                            />
                        </Col>
                    </FormGroup>

                    {/* New docs section */}
                    <div className='p-2 mb-1 row '>
                        {numOfNewDocs > 0
                            ? <div className='mb-2 col-12'>
                                <Button className="mb-2" color="primary" outline>
                                    <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                                    <Badge className="ms-2 bg-danger">
                                        {numOfNewDocs}
                                    </Badge> New
                                </Button>
                            </div>
                            : null}
                        {/* {
                            newDocUrls.length > 0
                                ? newDocUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mt-1 mb-1 col-12 col-sm-12">
                                        <a href={url} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon className="me-2" icon={faFileLines} transform="grow-10" />
                                            {newDocNames[index]}
                                        </a>
                                        <Button color="danger" className="ms-2" onClick={(e) => removeNewDoc(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        } */}
                        {
                            // newDocUrls.length > 0 && newDocArray.length === 0
                            //     ? newDocUrls.map((url, index) =>
                            //     (
                            //         <div key={index} className="row mt-1 mb-1 col-12 col-sm-12 d-flex align-items-center">
                            //             <Col className='col-12 col-md-3 mb-2 p-2'>
                            //                 <a href={url} target="_blank" rel="noreferrer">
                            //                     <FontAwesomeIcon className="ms-2 me-2" icon={faFileLines} transform="grow-10" />
                            //                     {newDocNames[index].length >= 35 ? newDocNames[index].substr(0, 35) + "..." : newDocNames[index]}
                            //                 </a>
                            //             </Col>

                            //             <div className='col-12 col-md-5 mb-1 row'>
                            //                 <Col className='col-10 col-md-6 mb-1'>
                            //                     <Input
                            //                         onChange={(e) => { handleDocPrivacyChange(e, index, "new") }}
                            //                         name="doc_privacy"
                            //                         type="select"
                            //                         value={newDocPrivacyArray[index] || ''}
                            //                     >  <option key="a" value="" className="py-2" disabled>Document Privacy</option>
                            //                         {docPrivacyStatus.map((option, index) => (
                            //                             <option key={index} value={option.value} className="py-2 px-1">{option.label}</option>
                            //                         ))}
                            //                     </Input>
                            //                 </Col>
                            //                 <Col className='col-2 col-md-3 mb-1'>
                            //                     <Button color="danger" className="ms-2" onClick={(e) => removeNewDoc(e, index)}>
                            //                         <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                            //                     </Button>
                            //                 </Col>
                            //             </div>
                            //         </div>
                            //     ))

                            //     : newDocUrls.length === 0 && 
                            newDocArray.length > 0
                                ? newDocArray.map((doc, index) =>
                                (
                                    <div key={index} className="row mt-1 mb-1 col-12 col-sm-12 d-flex align-items-center">
                                        <Col className='col-12 col-md-3 mb-2 p-2'>
                                            <a href={doc.url} target="_blank" rel="noreferrer">
                                                <FontAwesomeIcon className="ms-2 me-2" icon={faFileLines} transform="grow-10" />
                                                {doc.name.length >= 35 ? doc.name.substr(0, 35) + "..." : doc.name}
                                            </a>
                                        </Col>

                                        <div className='col-12 col-md-5 mb-1 row'>
                                            <Col className='col-10 col-md-6 mb-1'>
                                                <Input
                                                    onChange={(e) => { handleDocPrivacyChange(e, index, "new") }}
                                                    name="doc_privacy"
                                                    type="select"
                                                    value={doc.privacy || ''}

                                                >  <option key="a" value="" className="py-2" disabled>Document Privacy</option>
                                                    {docPrivacyStatus.map((option, index) => (
                                                        <option key={index} value={option.value} className="py-2 px-1">{option.label}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                            <Col className='col-2 col-md-3 mb-1'>
                                                <Button color="danger" className="ms-2" onClick={(e) => removeNewDoc(e, index)}>
                                                    <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                                </Button>
                                            </Col>
                                        </div>
                                    </div>
                                ))

                                : null
                        }
                    </div>

                    {numOfNewDocs > 0 ? <hr /> : null}

                    {/* Existing docs section */}
                    <div id="docSection" className='p-2 mb-1 row'>
                        {numOfDocs > 0 || docArray.length > 0 ? <div className='mb-2 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfDocs > 0 ? numOfDocs : docArray.length > 0 ? docArray.length : "0"}
                                </Badge> Existing
                            </Button>
                        </div> : null}

                        {
                            // docUrls.length > 0 && docArray.length === 0
                            //     ? docUrls.map((url, index) =>
                            //     (
                            //         <div key={index} className="row mt-1 mb-1 col-12 col-sm-12 d-flex align-items-center">
                            //             <Col className='col-12 col-md-3 mb-2 p-2'>
                            //                 <a href={url} target="_blank" rel="noreferrer">
                            //                     <FontAwesomeIcon className="ms-2 me-2" icon={faFileLines} transform="grow-10" />
                            //                     {docNames[index].length >= 35 ? docNames[index].substr(0, 35) + "..." : docNames[index]}
                            //                 </a>
                            //             </Col>

                            //             <div className='col-12 col-md-5 mb-1 row'>
                            //                 <Col className='col-10 col-md-6 mb-1'>
                            //                     <Input
                            //                         onChange={(e) => { handleDocPrivacyChange(e, index, "old") }}
                            //                         name="doc_privacy"
                            //                         type="select"
                            //                         value={docPrivacyArray[index] || ''}

                            //                     >  <option key="a" value="" className="py-2" disabled>Document Privacy</option>
                            //                         {docPrivacyStatus.map((option, index) => (
                            //                             <option key={index} value={option.value} className="py-2 px-1">{option.label}</option>
                            //                         ))}
                            //                     </Input>
                            //                 </Col>
                            //                 <Col className='col-2 col-md-3 mb-1'>
                            //                     <Button color="danger" className="ms-2" onClick={(e) => removeDoc(e, index)}>
                            //                         <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                            //                     </Button>
                            //                 </Col>
                            //             </div>
                            //         </div>
                            //     ))

                            //     : 
                            docArray.length > 0
                                ? docArray.map((doc, index) =>
                                (
                                    <div key={index} className="row mt-1 mb-1 col-12 col-sm-12 d-flex align-items-center">
                                        <Col className='col-12 col-md-3 mb-2 p-2'>
                                            <a href={doc.url} target="_blank" rel="noreferrer">
                                                <FontAwesomeIcon className="ms-2 me-2" icon={faFileLines} transform="grow-10" />
                                                {doc.name.length >= 35 ? doc.name.substr(0, 35) + "..." : doc.name}
                                            </a>
                                        </Col>

                                        <div className='col-12 col-md-5 mb-1 row'>
                                            <Col className='col-10 col-md-6 mb-1'>
                                                <Input
                                                    onChange={(e) => { handleDocPrivacyChange(e, index, "old") }}
                                                    name="doc_privacy"
                                                    type="select"
                                                    value={doc.privacy || ''}

                                                >  <option key="a" value="" className="py-2" disabled>Document Privacy</option>
                                                    {docPrivacyStatus.map((option, index) => (
                                                        <option key={index} value={option.value} className="py-2 px-1">{option.label}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                            <Col className='col-2 col-md-3 mb-1'>
                                                <Button color="danger" className="ms-2" onClick={(e) => removeDoc(e, index)}>
                                                    <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                                </Button>
                                            </Col>
                                        </div>
                                    </div>
                                ))

                                : null
                            // docUrls.length > 0
                            //     ? docUrls.map((url, index) => (
                            //         <div key={index} className="mx-1 mt-1 mb-1 col-12 col-sm-12">
                            //             <a href={url} target="_blank" rel="noreferrer">
                            //                 <FontAwesomeIcon className="me-2" icon={faFileLines} transform="grow-10" />
                            //                 {docNames[index]}
                            //             </a>
                            //             <Button className="ms-2"
                            //                 color="danger" onClick={(e) => removeDoc(e, index)}>
                            //                 <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                            //             </Button>
                            //         </div>
                            //     ))
                            //     : docArray.length > 0
                            //         ? docArray.map((doc, index) => (
                            //             <div key={index} className="mx-1 mt-1 mb-1 col-12 col-sm-12">
                            //                 <a href={doc.url} target="_blank" rel="noreferrer">
                            //                     <FontAwesomeIcon className="me-2" icon={faFileLines} transform="grow-10" />
                            //                     {doc.name}
                            //                 </a>
                            //                 <Button className="ms-2"
                            //                     color="danger" onClick={(e) => removeDoc(e, index)}>
                            //                     <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                            //                 </Button>
                            //             </div>
                            //         ))

                        }
                    </div>

                    <div className="col-12">
                        <Button className="ms-1" color="primary" disabled={isUpdating} type="submit">
                            {isUpdating ? 'Updating…' : 'Update'}
                        </Button>
                        <Button className="ms-1" color="danger" onClick={() => cancelEdit()}>
                            Cancel
                        </Button>
                    </div>
                </Form >
                : isLoaded && projectId !== 0 && projectsExist && (!canEdit && !isAdmin) && projDropdownArray.length > 0
                    ? <div className="redText">You do not have permission to edit this project.</div>
                    : isLoaded && projectsExist && projDropdownArray.length === 0
                        ? <div className="redText">No projects saved yet.</div>
                        : msgFromServer === "No projects in that ward." && userWards.length === 0
                            ? <div className="redText">Please contact your administrator, as you have not yet been assigned to a specific ward.</div>
                            : msgFromServer === "No projects in that ward." && userWards.length > 0
                                ? <div className="redText">No projects in your ward/s.</div>
                                : !isLoaded
                                    ? <div>Loading...</div>
                                    : null}
        </div >);
}

export default EditProjectForm;

EditProjectForm.propTypes = {
    setProjDocsUpdated: PropTypes.func,
    setProjInfoUpdated: PropTypes.func,
    setProjImagesUpdated: PropTypes.func,
    setShowAlerts: PropTypes.func,
    projectId: PropTypes.number,
    setProjectId: PropTypes.func,
    isAdmin: PropTypes.bool,
    isLoaded: PropTypes.bool,
    projectsArray: PropTypes.array,
    projectImagesArray: PropTypes.array,
    projectDocsArray: PropTypes.array,
    toggleProjectInfoChanged: PropTypes.func,
    setIsLoaded: PropTypes.func,
    setIsLoadedImages: PropTypes.func,
    setIsLoadedDocs: PropTypes.func,
    loadedStaffArrays: PropTypes.bool,
    setLoadedStaffArrays: PropTypes.func,
    setSingleProjectId: PropTypes.func,
    toggleSingleProjInfoChanged: PropTypes.func,
    setProjImagesError: PropTypes.func,
    // projectIndex: PropTypes.number,
    // setProjectIndex: PropTypes.func,
    setProjDocsError: PropTypes.func,
    activeTab: PropTypes.string,
    editPgDropdownValue: PropTypes.number,
    setEditPgDropdownValue: PropTypes.func,
    setProjectCompleteError: PropTypes.func,
    // imgMsgFromServer: PropTypes.string,
    // docsMsgFromServer: PropTypes.string,
    userInfo: PropTypes.object,
    msgFromServer: PropTypes.string,
    projectToEdit: PropTypes.object,
    isLoadedImages: PropTypes.bool,
    isLoadedDocs: PropTypes.bool,
}

