import React from "react";
import
{
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledAlert
} from "reactstrap";
import classnames from "classnames";
import MetaTags from "react-meta-tags";
import { useState, useEffect } from "react";
//import GetKey from "../SharedFiles/GetKey";
import Breadcrumb from "../../components/Common/Breadcrumb";
import AddProjects from "./AddProjectForm";
import EditProjects from "./EditProjectsForm";
import fetchInfo from "../SharedFiles/fetchInfo";
import ViewProjectSum from "./ViewProjectSums";
import ViewSingleProject from "./ViewSingleProject";
//import fetchAProject from "./fetchAProject";
import { decryptUser } from "pages/SharedFiles/decryptUser";
import fetchAnItem from "../SharedFiles/fetchAnItem";
import SideArrow from "../SharedFiles/SideArrow";
import fetchWardProjects from "../SharedFiles/fetchWardProjects";
import loadKey from "../SharedFiles/loadKey";

function Projects(props)
{
    // Project hooks
    const [showAlerts, setShowAlerts] = useState(false);
    const [showAddProjAlerts, setShowAddProjAlerts] = useState(false);
    const [projDocsUpdated, setProjDocsUpdated] = useState("");
    const [projInfoUpdated, setProjInfoUpdated] = useState("");
    const [projImagesUpdated, setProjImagesUpdated] = useState("");
    const [projImagesError, setProjImagesError] = useState("");
    const [projDocsError, setProjDocsError] = useState("");
    const [projectId, setProjectId] = useState(0);
    //const [projectIndex, setProjectIndex] = useState(0);
    const [projectCompleteError, setProjectCompleteError] = useState("");

    const [projectsArray, setProjectsArray] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [msgFromServer, setMsgFromServer] = useState("");

    const [isLoadedImages, setIsLoadedImages] = useState(false);
    const [imgMsgFromServer, setImgMsgFromServer] = useState("");
    const [projectImagesArray, setProjectImagesArray] = useState([]);

    const [projectDocsArray, setProjectDocsArray] = useState([]);
    const [isLoadedDocs, setIsLoadedDocs] = useState(false);
    const [docsMsgFromServer, setDocsMsgFromServer] = useState("");

    const [projectInfoChanged, setProjectInfoChanged] = useState(false);
    const [singleProjInfoChanged, setSingleProjInfoChanged] = useState(false);

    const [projectSumArray, setProjectSumArray] = useState([]);
    const [isLoadedProjSums, setIsLoadedProjSums] = useState(false);
    const [projSumMsgFromServer, setProjSumMsgFromServer] = useState("");

    const [singleProjectArray, setSingleProjectArray] = useState([]);
    const [projectMsgFromServer, setProjectMsgFromServer] = useState("");
    const [singleProjectId, setSingleProjectId] = useState(0);

    const [editPgDropdownValue, setEditPgDropdownValue] = useState(-1);
    const [singleViewPgDropdownValue, setSingleViewPgDropdownValue] = useState(-1);
    const [loadedStaffArrays, setLoadedStaffArrays] = useState(false);
    const [showArrow, setShowArrow] = useState(false);
    const [projectChosen, setProjectChosen] = useState(false);
    const [projectToEdit, setProjectToEdit] = useState({});

    // Misc hooks
    const [activeTab, setactiveTab] = useState("1");
    const [placesKey, setPlacesKey] = useState("");
    const [keyLoaded, setKeyLoaded] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [thisUser, setThisUser] = useState("");
    const [showPage, setShowPage] = useState(true);
    const [canPublish, setCanPublish] = useState(0);
    const [userWard, setUserWard] = useState(0);
    const [dataFetched, setDataFetched] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    const toggleProjectInfoChanged = () =>
    {
        setProjectInfoChanged(current => !current);
    };

    const toggleSingleProjInfoChanged = () =>
    {
        setSingleProjInfoChanged(current => !current);
    };

    // const loadKey = async () =>
    // {
    //     if (!keyLoaded)
    //     {
    //         try
    //         {
    //             let key = await GetKey();
    //             //console.log("Google api key is: " + key);
    //             setPlacesKey(key);
    //             setKeyLoaded(true);
    //         }
    //         catch (error)
    //         {
    //             console.log("Error: Failed to fetch Google api key.");
    //         }
    //     }
    // }

    const toggle = tab =>
    {
        if (activeTab !== tab)
        {
            setactiveTab(tab);
        }
    };

    useEffect(() =>
    {
        if (!keyLoaded)
        {
            loadKey(setPlacesKey, setKeyLoaded);
        }
    }, []);

    useEffect(() =>
    {
        let authUser = decryptUser();

        if (authUser !== undefined)
        {
            if (authUser.role !== "admin")
            {
                setIsAdmin(false);
            }
            else
            {
                setIsAdmin(true);
            }

            if (authUser.role === "guest")
            {
                setShowPage(false);
            }

            // Set current logged in user
            setUserWard(authUser.ward);
            setThisUser(authUser.username);
            setCanPublish(authUser.canPublish);
            let tempWards = authUser.wards;
            let wards = authUser.wards !== undefined && tempWards.length !== 0
                ? authUser.wards
                : [];
            let tempUserInfo = {
                "ward": authUser.ward,
                "wards": wards,
                "username": authUser.username,
                "canPublish": authUser.canPublish,
                "canDisplay": authUser.canDisplay,
                "role": authUser.role
            };
            setUserInfo(tempUserInfo);
        }
    }, []);

    useEffect(async () =>
    {
        if (!isAdmin && userInfo.wards !== undefined)
        {
            //fetchWardProjects("getprojectsbyward1", userWard, setProjectsArray, setMsgFromServer, setIsLoaded);
            fetchWardProjects("getprojectsbywardclo", userInfo.wards, setProjectsArray, setMsgFromServer, setIsLoaded, userInfo.username);
            //fetchWardProjects("getprojectsumbyward", userWard, setProjectSumArray, setProjSumMsgFromServer, setIsLoadedProjSums);
            fetchWardProjects("getprojectsumbyward", userInfo.wards, setProjectSumArray, setProjSumMsgFromServer, setIsLoadedProjSums, userInfo.username);
        }
        else if (isAdmin)
        {
            fetchInfo("/getprojects", setIsLoaded, setProjectsArray, setMsgFromServer, "projectsArray");
            fetchInfo("/getprojectsum", setIsLoadedProjSums, setProjectSumArray, setProjSumMsgFromServer, "message");
        }

        fetchInfo("/getprojectimages", setIsLoadedImages, setProjectImagesArray, setImgMsgFromServer, "projectImagesArray");
        fetchInfo("/getprojectdocs", setIsLoadedDocs, setProjectDocsArray, setDocsMsgFromServer, "projDocsArray");

        if (isLoaded && isLoadedProjSums && isLoadedImages && isLoadedDocs)
        {
            setDataFetched(true);
        }

    }, [userWard, isLoaded, isLoadedProjSums, isLoadedImages, isLoadedDocs, projectInfoChanged]);

    useEffect(() =>
    {
        if (singleProjectId !== 0)
        {
            //fetchAProject(singleProjectId, setSingleProjectArray, setProjectMsgFromServer);
            fetchAnItem("project", "getprojectdetail", singleProjectId, setSingleProjectArray, setProjectMsgFromServer, "Project retrieved successfully.");
        }
    }, [singleProjInfoChanged]);

    useEffect(() =>
    {
        if (editPgDropdownValue !== -1)
        {
            let project = projectsArray.filter(project => project.proj_id === editPgDropdownValue);
            setProjectToEdit(project[0])
        }
    }, [editPgDropdownValue]);

    useEffect(() =>
    {
        if ((projectSumArray.length > 5 && activeTab === "1") || activeTab === "4" && projectChosen)
        {
            setShowArrow(true);
        }
        else
        {
            setShowArrow(false);
        }
    }, [projectSumArray, activeTab]);

    return (
        <React.Fragment>
            <div className="page-content" id="top">
                <MetaTags>
                    <title>Projects - CMS</title>
                </MetaTags>
                <Breadcrumb title="Home" breadcrumbItem="Projects" />
                {/* <p>
                    Data fetched: {dataFetched.toString()},
                    isLoaded: {isLoaded.toString()},
                    isLoadedProjSums: {isLoadedProjSums.toString()},
                    isLoadedDocs: {isLoadedDocs.toString()},
                    isLoadedImages: {isLoadedImages.toString()},
                    projects array length: {projectsArray.length}
                </p> */}
                {keyLoaded && showPage && dataFetched
                    ? <Container fluid={true}>
                        <Row className="mb-0">
                            <Col className="col-12 col-sm-12 col-md-12">
                                <Nav pills className="icon-tab nav-justified">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer", color: "secondary" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() =>
                                            {
                                                toggle("1");
                                            }}
                                        >
                                            View
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() =>
                                            {
                                                toggle("2");
                                            }}
                                        >
                                            Add
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer", color: "secondary" }}
                                            className={classnames({
                                                active: activeTab === "3",
                                            })}
                                            onClick={() =>
                                            {
                                                toggle("3");
                                            }}
                                        >
                                            Edit
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer", color: "secondary" }}
                                            className={classnames({
                                                active: activeTab === "4",
                                            })}
                                            onClick={() =>
                                            {
                                                toggle("4");
                                            }}
                                        >
                                            Single
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                        {/* {userInfo.wards ? <div>Wards: {userInfo.wards}</div> : null} */}
                        <Row className="mb-3">
                            <Col className="col-12 col-sm-12 col-md-12">
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <ViewProjectSum
                                            projSumMsgFromServer={projSumMsgFromServer}
                                            projectSumArray={projectSumArray}
                                            setSingleProjectArray={setSingleProjectArray}
                                            // docsMsgFromServer={docsMsgFromServer}
                                            // imgMsgFromServer={imgMsgFromServer}
                                            setProjectMsgFromServer={setProjectMsgFromServer}
                                            setActiveTab={setactiveTab}
                                            setSingleProjectId={setSingleProjectId}
                                            singleProjectId={singleProjectId}
                                            projectImagesArray={projectImagesArray}
                                            projectDocsArray={projectDocsArray}
                                            isAdmin={isAdmin}
                                            setProjectId={setProjectId}
                                            //setProjectIndex={setProjectIndex}
                                            isLoadedProjSums={isLoadedProjSums}
                                            setEditPgDropdownValue={setEditPgDropdownValue}
                                            // singleViewPgDropdownValue={singleViewPgDropdownValue}
                                            setSingleViewPgDropdownValue={setSingleViewPgDropdownValue}
                                            setShowArrow={setShowArrow}
                                            userInfo={userInfo}
                                            activeTab={activeTab}
                                        />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {/* PROJECT ALERTS ------------------------------*/}
                                        {/* Project image upload error */}

                                        {showAddProjAlerts && projImagesError !== ""
                                            ? <UncontrolledAlert color="danger" role="alert">
                                                {projImagesError}
                                            </UncontrolledAlert> : null}

                                        {/* ALERTS end ------------------------------*/}
                                        <AddProjects
                                            ProjectsArray={projectsArray}
                                            placesKey={placesKey}
                                            toggleProjectInfoChanged={toggleProjectInfoChanged}
                                            setIsLoaded={setIsLoaded}
                                            setIsLoadedImages={setIsLoadedImages}
                                            setIsLoadedDocs={setIsLoadedDocs}
                                            setLoadedStaffArrays={setLoadedStaffArrays}
                                            setProjImagesError={setProjImagesError}
                                            setShowAddProjAlerts={setShowAddProjAlerts}
                                            activeTab={activeTab}
                                            userInfo={userInfo}
                                        />
                                    </TabPane>
                                    <TabPane tabId="3">

                                        {/* PROJECT ALERTS ------------------------------*/}
                                        {/* Project info */}
                                        {showAlerts && projInfoUpdated !== "" && projInfoUpdated === "Project information updated"
                                            ? <UncontrolledAlert color="primary" role="alert">
                                                {projInfoUpdated}
                                            </UncontrolledAlert>
                                            : showAlerts && projInfoUpdated !== "" && projInfoUpdated === "Project information NOT updated. Something went wrong."
                                                ? <UncontrolledAlert color="danger" role="alert">
                                                    {projInfoUpdated}
                                                </UncontrolledAlert>
                                                : null}

                                        {/* Project documents */}
                                        {showAlerts && projDocsUpdated !== "" && projDocsUpdated === "Project documents updated"
                                            ? <UncontrolledAlert color="info" role="alert">
                                                {projDocsUpdated}
                                            </UncontrolledAlert>
                                            : showAlerts && projDocsUpdated !== "" && projDocsUpdated === "Project documents NOT updated. Something went wrong."
                                                ? <UncontrolledAlert color="danger" role="alert">
                                                    {projDocsUpdated}
                                                </UncontrolledAlert>
                                                : null}

                                        {/* Project images */}
                                        {showAlerts && projImagesUpdated !== "" && projImagesUpdated === "Project images updated"
                                            ? <UncontrolledAlert color="success" role="alert">
                                                {projImagesUpdated}
                                            </UncontrolledAlert>
                                            : showAlerts && projImagesUpdated !== "" && projImagesUpdated === "Project images NOT updated. Something went wrong."
                                                ? <UncontrolledAlert color="danger" role="alert">
                                                    {projImagesUpdated}
                                                </UncontrolledAlert>
                                                : null}

                                        {/* Project image upload error */}
                                        {showAlerts && projImagesError !== ""
                                            ? <UncontrolledAlert color="danger" role="alert">
                                                {projImagesError}
                                            </UncontrolledAlert> : null}

                                        {/* Project doc upload error */}
                                        {showAlerts && projDocsError !== ""
                                            ? <UncontrolledAlert color="danger" role="alert">
                                                {projDocsError}
                                            </UncontrolledAlert> : null}

                                        {/* Project complete error */}
                                        {projectCompleteError !== ""
                                            ? <UncontrolledAlert color="danger" role="alert">
                                                {projectCompleteError}
                                            </UncontrolledAlert> : null}

                                        {/* ALERTS end ------------------------------*/}

                                        <EditProjects
                                            setProjDocsUpdated={setProjDocsUpdated}
                                            setProjInfoUpdated={setProjInfoUpdated}
                                            setProjImagesUpdated={setProjImagesUpdated}
                                            setShowAlerts={setShowAlerts}
                                            projectId={projectId}
                                            setProjectId={setProjectId}
                                            isAdmin={isAdmin}
                                            isLoaded={isLoaded}
                                            projectsArray={projectsArray}
                                            projectImagesArray={projectImagesArray}
                                            projectDocsArray={projectDocsArray}
                                            toggleProjectInfoChanged={toggleProjectInfoChanged}
                                            setIsLoaded={setIsLoaded}
                                            setIsLoadedImages={setIsLoadedImages}
                                            setIsLoadedDocs={setIsLoadedDocs}
                                            loadedStaffArrays={loadedStaffArrays}
                                            setLoadedStaffArrays={setLoadedStaffArrays}
                                            setSingleProjectId={setSingleProjectId}
                                            toggleSingleProjInfoChanged={toggleSingleProjInfoChanged}
                                            setProjImagesError={setProjImagesError}
                                            // projectIndex={projectIndex}
                                            // setProjectIndex={setProjectIndex}
                                            setProjDocsError={setProjDocsError}
                                            activeTab={activeTab}
                                            editPgDropdownValue={editPgDropdownValue}
                                            setEditPgDropdownValue={setEditPgDropdownValue}
                                            setProjectCompleteError={setProjectCompleteError}
                                            // imgMsgFromServer={imgMsgFromServer}
                                            // docsMsgFromServer={docsMsgFromServer}
                                            userInfo={userInfo}
                                            msgFromServer={msgFromServer}
                                            projectToEdit={projectToEdit}
                                            isLoadedImages={isLoadedImages}
                                            isLoadedDocs={isLoadedDocs}
                                        />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <ViewSingleProject
                                            singleProjectArray={singleProjectArray}
                                            docsMsgFromServer={docsMsgFromServer}
                                            imgMsgFromServer={imgMsgFromServer}
                                            projectMsgFromServer={projectMsgFromServer}
                                            setActiveTab={setactiveTab}
                                            activeTab={activeTab}
                                            projectImagesArray={projectImagesArray}
                                            projectDocsArray={projectDocsArray}
                                            singleProjectId={singleProjectId}
                                            setSingleProjectId={setSingleProjectId}
                                            projectSumArray={projectSumArray}
                                            projSumMsgFromServer={projSumMsgFromServer}
                                            isAdmin={isAdmin}
                                            setProjectId={setProjectId}
                                            thisUser={thisUser}
                                            //setProjectIndex={setProjectIndex}
                                            singleViewPgDropdownValue={singleViewPgDropdownValue}
                                            setSingleViewPgDropdownValue={setSingleViewPgDropdownValue}
                                            setEditPgDropdownValue={setEditPgDropdownValue}
                                            projectChosen={projectChosen}
                                            setProjectChosen={setProjectChosen}
                                            userWard={userWard}
                                            userInfo={userInfo}
                                        />
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Container>
                    : userInfo.role !== "guest"
                        ? "Loading..."
                        : ""}
            </div >
            <SideArrow showArrow={showArrow} />
        </React.Fragment >
    );
}

export default Projects;

// Projects.PropTypes = {
//     setFormTouched: PropTypes.func,
// }