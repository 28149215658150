export const calcTimeBetween = (visitDate) => 
{
    let today = new Date();

    if (visitDate !== undefined)
    {
        var minutes = Math.abs(today - visitDate) / 60000;
        return minutes
    }
}
