const changePhotoHandler = (e, setNumOfPics, setImgObjects, setImgUrls, setImgNames) =>
{
    let array = [];
    let arrayObjects = [];
    let tempUrl;
    let filesLength = e.target.files.length;
    let notImage = false;
    let fileNames = [];
    let files = e.target.files;

    for (let i = 0; i < filesLength; ++i)
    {
        if (e.target.files[i].type.match(/image.*/))
        {
            tempUrl = URL.createObjectURL(e.target.files[i]);
            arrayObjects.push(e.target.files[i]);
            array.push(tempUrl);
        }
        else
        {
            notImage = true;
        }
    }

    if (notImage === true)
    {
        e.target.value = '';
        swal("Error", "Only IMAGE files can be uploaded. Please try again", "error");
    }
    else
    {
        if (setNumOfPics !== "")
        {
            setNumOfPics(arrayObjects.length);
        }

        setImgObjects(arrayObjects);
        setImgUrls(array);

        if (setImgNames !== "")
        {
            for (const img of files)
            {
                fileNames.push(img.name);
            }
            setImgNames(fileNames);
        }
    }
}

export default changePhotoHandler;