import React from "react"
import { useState, useEffect } from "react";
import { Form, Label, FormGroup, Col, Input, Button } from "reactstrap";
import PropTypes from 'prop-types';
import ".../../assets/styles.css";
import swal from "sweetalert";
import postUser from "./postUser";
import resizeImage from '../SharedFiles/resizeImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import postInfo from '../SharedFiles/postInfo';
import { API_URL } from "../../helpers/ApiUrl";
import { addWardRow, handleWards, removeWard, loadWardArray } from "../SharedFiles/WardHelpers";
import changePhotoHandler from "../SharedFiles/changePhotoHandler";
import { userRoles } from "../SharedFiles/listData";

const EditUser = (props) =>
{
    // Hooks
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    // const handleChangeWard = e => setInputs(prevState => ({ ...prevState, [e.target.name]: Number(e.target.value) }));
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Image hooks
    const [imgUrls, setImgUrls] = useState([]);
    const [imgObjects, setImgObjects] = useState([]);
    const [markedImages, setMarkedImages] = useState([]);

    // Ward hooks
    const [wardArray, setWardArray] = useState([]);
    const [wardRows, setWardRows] = useState(1);
    const [wardIndex, setWardIndex] = useState(0);
    const [wardArrayLoaded, setWardArrayLoaded] = useState(false);

    // Props
    let loginsArray = props.loginsArray;
    let currentUser = props.currentUser;
    let toggleLoginsChanged = props.toggleLoginsChanged;
    let showForm = props.showForm;
    let setShowForm = props.setShowForm;
    let activeTab = props.activeTab;
    let setActiveTab = props.setActiveTab;
    let currentIndex = props.currentIndex;

    // const userRoles = [
    //     {
    //         label: "Choose Option",
    //         value: "",
    //     },
    //     {
    //         label: "Admin",
    //         value: "admin",
    //     },
    //     {
    //         label: "CLO",
    //         value: "clo",
    //     },
    //     {
    //         label: "Guest",
    //         value: "guest",
    //     },
    // ];

    const handleSubmit = async (imgUploadMessage) =>
    {
        setIsSubmitting(true);
        let imgUrlArray = [];
        let myObject = {};

        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no images")
        {
            let imagePathsArray = Object.keys(imgUploadMessage);
            let imageUploadStatusArray = Object.values(imgUploadMessage);

            imagePathsArray.forEach((img, i) =>
            {
                if (imageUploadStatusArray[i] === "Success")
                {
                    let fullPath = `${API_URL}/${img}`;
                    imgUrlArray.push(fullPath);
                }
            });

            myObject["image_url"] = imgUrlArray;
        }

        if (markedImages.length > 0 && imgUrlArray.length === 0)
        {
            myObject["image_url"] = [];
        }

        // code to save project data to database
        if (Object.keys(inputs).length === 0 && Object.keys(myObject).length === 0)
        {
            swal("Error", "Nothing changed.", "error");
            setIsSubmitting(false);
        }
        else
        {
            myObject["_id"] = loginsArray[currentIndex]._id;
            myObject["uid"] = loginsArray[currentIndex].uid;
            myObject["updated_by_user"] = currentUser;

            if (Object.keys(inputs).length !== 0)
            {
                Object.assign(myObject, inputs);
            }

            let test = myObject;
            debugger;
            return;

            const response = await postUser(myObject, "POST", "postupdateuser", "Error while updating user info");

            if (response !== undefined && response === "User info updated successfully.")
            {
                setIsSubmitting(false);
                setInputs({});
                setShowForm(false);
                setImgObjects([]);
                setImgUrls([]);
                setMarkedImages([]);
                toggleLoginsChanged();
                swal("Success", "User info updated successfully!", "success");
                setActiveTab("1");
            }
            else
            {
                setIsSubmitting(false);
                setInputs({});
                swal("Error", "Error occurred while updating user.", "error");
            }
        }
    }

    const handleSubmitPhoto = async (e) =>
    {
        setIsSubmitting(true);

        if (imgUrls.length === 0)
        {
            console.log("No images to upload.");
            return handleSubmit("no images");
        }

        const formData = new FormData();

        for (const files of imgObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };

            const resizedImage = await resizeImage(config);
            formData.append("user-image", resizedImage, files.name);
        }

        postInfo(formData, "userimageupload", "POST", handleSubmit, "");
    };

    const removeImage = (e, index) => 
    {
        let tempImgUrls = [];
        if (loginsArray[currentIndex].image_url !== undefined)
        {
            tempImgUrls = JSON.parse(JSON.stringify(loginsArray[currentIndex].image_url));
        }

        // Existing mages marked for deletion (red text)
        let imgsMarkedForDeletion = [];
        imgsMarkedForDeletion.push(tempImgUrls[index]);
        setMarkedImages(imgsMarkedForDeletion);
    }

    const cancelUpdate = () =>
    {
        setInputs({});
        setImgUrls([]);
        setImgObjects([]);
        setMarkedImages([]);
        setShowForm(false);
        setActiveTab("1");
    }

    const addWardRw = (e) =>
    {
        addWardRow(wardArray, wardIndex, wardRows, setWardIndex, setWardRows);
    }

    const removeWd = index =>
    {
        removeWard(wardRows, setWardRows, setWardIndex, index, wardArray, setWardArray, inputs, setInputs);
    }

    const handleWds = (ward, index) =>
    {
        handleWards(index, ward, wardArray, setWardArray, setInputs);
    }

    let wardDivArray = [];
    let wardRow;

    for (let i = 0; i < wardRows; ++i)
    {
        wardRow = (
            <div key={i} className='row col-12 mb-1'>
                <Col className="col-10 col-md-11 ">
                    <Input
                        id="wards"
                        name="wards"
                        type="number"
                        onChange={(e) => { handleWds(e.target.value, i) }}
                        value={wardArray[i] || ''}
                        onWheel={(e) => e.target.blur()}
                    />
                </Col>
                {/* col-sm-1 col-2 my-sm-0 my-2 */}
                <Col className="col-1">
                    <Button color="danger" onClick={() => removeWd(i)}>
                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                    </Button>
                </Col>
            </div>);

        wardDivArray.push(wardRow);
    }

    useEffect(() =>
    {
        if (loginsArray.length > 0 && currentIndex !== -1)
        {
            loadWardArray(loginsArray, currentIndex, setWardArray, setWardRows, setWardArrayLoaded);
        }
    }, [loginsArray, currentIndex]);

    useEffect(() =>
    {
        if (activeTab !== "2")
        {
            cancelUpdate();
        }
    }, [activeTab]);

    return (
        <div>
            {/* <div>
                        Inputs: <pre>{JSON.stringify(inputs)}</pre>
                        currentIndex: {currentIndex},
                        loginsArray canPublish: <pre>{loginsArray.length > 0 && currentIndex !== -1 ? JSON.stringify(loginsArray[currentIndex].canPublish) : null}</pre>
                    </div> */}
            {showForm ?
                <Form
                    name="updateUserForm"
                    onSubmit={(e) => { handleSubmitPhoto(); e.preventDefault(); }}
                    autoComplete="off"
                >
                    <FormGroup row>
                        <Label
                            for="user_id"
                            sm={1}
                        >
                            User ID
                        </Label>
                        <Col sm={11}>
                            <Input
                                name="user_id"
                                placeholder=""
                                type="text"
                                value={inputs.uid || loginsArray[currentIndex].uid || ''}
                                disabled
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label
                            for="username"
                            sm={1}
                        >
                            Username
                        </Label>
                        <Col sm={11}>
                            <Input
                                name="username"
                                placeholder=""
                                type="text"
                                value={loginsArray[currentIndex].username || ''}
                                disabled
                            //onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                                <Label
                                    for="password"
                                    sm={2}
                                >
                                    Password (encrypted)
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        name="password"
                                        placeholder=""
                                        type="text"
                                        value={loginsArray[currentIndex].password || ''}
                                        disabled
                                    />
                                </Col>
                            </FormGroup> */}

                    <FormGroup row>
                        <Label
                            for="email"
                            sm={1}
                        >
                            Email
                        </Label>
                        <Col sm={11}>
                            <Input
                                name="email"
                                placeholder=""
                                type="text"
                                value={loginsArray[currentIndex].email || ''}
                                disabled
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                        <Label
                            for="role"
                            sm={1}
                        >
                            Role
                        </Label>
                        <Col sm={11}>
                            <Input
                                onChange={(e) => { handleChange(e) }}
                                name="role"
                                type="select"
                                value={inputs.role || loginsArray[currentIndex].role || ''}
                            > {userRoles.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                            </Input>
                        </Col>
                    </FormGroup>

                    {/* <FormGroup row className="mb-4">
                                <Label
                                    for="wards"
                                    sm={1}
                                >
                                    Ward
                                </Label>
                                <Col sm={11}>
                                    <Input
                                        name="wards"
                                        placeholder="Enter clo ward number"
                                        type="number"
                                        value={inputs.ward === undefined
                                            ? loginsArray[currentIndex].ward !== undefined
                                                ? loginsArray[currentIndex].ward
                                                : ''
                                            : inputs.ward !== undefined && inputs.ward !== 0
                                                ? inputs.ward
                                                : ''}
                                        onChange={(e) => { handleChangeWard(e) }}
                                    />
                                </Col>
                            </FormGroup> */}

                    <FormGroup row >
                        <Col className="col-12">
                            <Label
                                for="wards"
                            >
                                Wards
                            </Label>
                        </Col>
                        {wardDivArray}
                        <div>
                            <Button className='me-1 mb-2' onClick={addWardRw}>
                                Add
                            </Button>
                        </div>
                    </FormGroup>

                    {/* <FormGroup row >
                                <Label
                                    sm={4}
                                >
                                    Wards
                                </Label>
                                <Col sm={2}>
                                    <Input
                                        id="numOfMgrsOnsite"
                                        placeholder="..."
                                        type="number"
                                        value={inputs.mgrs_onsite || managers}
                                        onWheel={(e) => e.target.blur()}
                                        onChange={(e) =>
                                            addManagers(e.target.value)}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Button className="ms-1 mt-1 mt-sm-0"
                                        color="success"
                                        name="mgrs_onsite"
                                        value={true}
                                        onClick={(e) =>
                                        {
                                            addManagers();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} transform="grow-10" />
                                    </Button>

                                    <Button className="ms-1 mt-1 mt-sm-0"
                                        color="danger"
                                        name="mgrs_onsite"
                                        value={false}
                                        onClick={(e) =>
                                        {
                                            minusManagers();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faMinus} transform="grow-10" />
                                    </Button>
                                </Col>
                            </FormGroup> */}

                    <FormGroup row>
                        <Label
                            for="name"
                            sm={1}
                        >
                            Name
                        </Label>
                        <Col sm={11}>
                            <Input
                                name="name"
                                placeholder=""
                                type="text"
                                value={inputs.name === undefined
                                    ? loginsArray[currentIndex].name !== undefined ? loginsArray[currentIndex].name : ''
                                    : inputs.name !== undefined
                                        ? inputs.name
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="surname"
                            sm={1}
                        >
                            Surname
                        </Label>
                        <Col sm={11}>
                            <Input
                                name="surname"
                                placeholder=""
                                type="text"
                                value={inputs.surname === undefined
                                    ? loginsArray[currentIndex].surname !== undefined ? loginsArray[currentIndex].surname : ''
                                    : inputs.surname !== undefined
                                        ? inputs.surname
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="bio"
                            sm={1}
                        >
                            Bio
                        </Label>
                        <Col sm={11}>
                            <Input
                                name="bio"
                                placeholder=""
                                type="textarea"
                                rows="5"
                                value={inputs.bio === undefined
                                    ? loginsArray[currentIndex].bio !== undefined ? loginsArray[currentIndex].bio : ''
                                    : inputs.bio !== undefined
                                        ? inputs.bio
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4" value={inputs.canPublish || ''}>
                        <Label
                            for="canPublish"
                            sm={1}
                        >
                            Can Publish
                        </Label>
                        <Col sm={4}>
                            <Button color={loginsArray[currentIndex].canPublish === 1 && inputs.canPublish === undefined
                                ? "success"
                                : inputs.canPublish === "1"
                                    ? "success"
                                    : "secondary"} className='me-1' name="canPublish" onClick={(e) => { handleChange(e); }} value={1}>
                                Yes
                            </Button>
                            <Button color={loginsArray[currentIndex].canPublish === 0 && inputs.canPublish === undefined
                                ? "danger"
                                : inputs.canPublish === "0"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="canPublish" onClick={(e) => { handleChange(e); }} value={0}>
                                No
                            </Button>
                        </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4" value={inputs.canDisplay || ''}>
                        <Label
                            for="canDisplay"
                            sm={1}
                        >
                            Can Display
                        </Label>
                        <Col sm={4}>
                            <Button color={loginsArray[currentIndex].canDisplay === 1 && inputs.canDisplay === undefined
                                ? "success"
                                : inputs.canDisplay === "1"
                                    ? "success"
                                    : "secondary"} className='me-1' name="canDisplay" onClick={(e) => { handleChange(e); }} value={1}>
                                Yes
                            </Button>
                            <Button color={loginsArray[currentIndex].canDisplay === 0 && inputs.canDisplay === undefined
                                ? "danger"
                                : inputs.canDisplay === "0"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="canDisplay" onClick={(e) => { handleChange(e); }} value={0}>
                                No
                            </Button>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="addPhoto"
                            sm={2}
                        >
                            Upload/Replace User Photo
                        </Label>
                        <Col className="col-sm-10 col-12 mb-1">
                            <Input
                                id="addPhoto"
                                name="addPhoto"
                                type="file"
                                onChange={(e) => changePhotoHandler(e, "", setImgObjects, setImgUrls, "")}
                            />
                        </Col>
                    </FormGroup>

                    <div id="userPhotoSection" className='row'>

                        {/* New user image section */}
                        {
                            imgUrls.length > 0
                                ? imgUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-2 mt-2 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        {/* <Button className="position-absolute top-0 translate-middle-y"
                                                    style={{ right: "0.05rem" }} color="danger"
                                                    onClick={(e) => removeImage(e, index)}>
                                                    <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                                </Button> */}
                                        {/* <div className='p-1'>{imgNames[index]}</div> */}
                                    </div>
                                ))
                                : null
                        }
                        {imgUrls.length > 0
                            ? <p className="ms-1 mb-4" style={{ color: "blue" }}>New/Updated user image</p>
                            : null}

                        {/* Existing user image section */}
                        {/* {numOfImages > 0 ? <div className='mb-4 col-12'>
                                    <Button className="mb-2" color="primary" outline>
                                        <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                        <Badge className="ms-2 bg-danger">
                                            {numOfImages}
                                        </Badge> Existing
                                    </Button></div> : null} */}
                        {/* <div>loginsArray length: {userImageArray.length}, imgurls length: {imgUrls.length}, markedImages: {markedImages.length}</div> */}
                        {
                            (loginsArray[currentIndex].image_url !== undefined && loginsArray[currentIndex].image_url.length !== 0)
                                && imgUrls.length === 0
                                && markedImages.length === 0
                                ? loginsArray[currentIndex].image_url.map((url, index) => (
                                    <div key={index} className="mx-1 mb-2 mt-2 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y"
                                            style={{ right: "0.05rem" }} color="danger" onClick={(e) => removeImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        }
                        {(loginsArray[currentIndex].image_url !== undefined && loginsArray[currentIndex].image_url.length !== 0)
                            && imgUrls.length === 0
                            && markedImages.length === 0
                            ? <p className="ms-1 mb-4" style={{ color: "blue" }}>Current user image</p>
                            : null}

                        {/* Image marked for deletion section */}
                        {
                            markedImages.length > 0
                                ? markedImages.map((url, index) => (
                                    <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button color="danger" className="col-12" disabled
                                        >
                                            Marked for Deletion
                                        </Button>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    <FormGroup
                        row
                    >
                        <Col
                            sm={{
                                offset: 0,
                                size: 12,
                                marginBottom: "1rem"
                            }}
                        >
                            <Button color="primary" disabled={isSubmitting} type="submit">
                                {isSubmitting ? 'Updating...' : 'Update'}
                            </Button>
                            <Button className="ms-1" color="danger" onClick={() => { cancelUpdate() }}>
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
                : <p style={{ color: "blue" }}>Choose a user to edit on the &quot;View&quot; tab.</p>
            }
        </div>
    );
}

export default EditUser;

EditUser.propTypes = {
    loginsArray: PropTypes.array,
    currentUser: PropTypes.string,
    toggleLoginsChanged: PropTypes.func,
    showForm: PropTypes.bool,
    setShowForm: PropTypes.func,
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
    currentIndex: PropTypes.number,
};