import React from "react"
import { useState, useEffect } from "react";
import { Button, Table, Badge, Modal, Label, Col, Input, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faFileLines } from '@fortawesome/free-solid-svg-icons';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ".../../assets/styles.css";
import { calcTimeBetween } from "pages/SharedFiles/calcTimeBetween";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";
function ViewSingleVisit(props)
{
    // Props
    let visitSumArray = props.visitSumArray;
    let singleVisitArray = props.singleVisitArray;
    let visitMsgFromServer = props.visitMsgFromServer;
    let visitImagesArray = props.visitImagesArray;
    let visitDocsArray = props.visitDocsArray;
    let singleVisitId = props.singleVisitId;
    let setSingleVisitId = props.setSingleVisitId;
    let isAdmin = props.isAdmin;
    let activeTab = props.activeTab;
    let setActiveTab = props.setActiveTab;
    let thisUser = props.thisUser;
    let toggleVisitChosen = props.toggleVisitChosen;
    let setProjectId = props.setProjectId;
    let setVisitDate = props.setVisitDate;
    let visitDate = props.visitDate;
    let setVisitId = props.setVisitId;
    let singleViewPgDropdownValue = props.singleViewPgDropdownValue;
    let setSingleViewPgDropdownValue = props.setSingleViewPgDropdownValue;
    let setEditPgDropdownValue = props.setEditPgDropdownValue;
    let visitChosen = props.visitChosen;
    let setVisitChosen = props.setVisitChosen;

    // Hooks
    const [showVisit, setShowVisit] = useState("");
    const [docNames, setDocNames] = useState({});
    const [docUrls, setDocUrls] = useState({});
    const [showLightbox, setShowLightbox] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [modal_center, setmodal_center] = useState(false);
    const [tidyDate, setTidyDate] = useState("");
    const [projectDescrip, setProjectDescrip] = useState("");
    const [visitDropdownArray, setVisitDropdownArray] = useState([]);

    function removeBodyCss()
    {
        document.body.classList.add("no_padding");
    }

    function tog_center(description)
    {
        setProjectDescrip(description);
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    const setupImages = (descrip) =>
    {
        let tempDate = new Date(visitDate);
        tempDate = tempDate.toDateString();

        setTidyDate(tempDate);
        setProjectDescrip(descrip);
        setPhotoIndex(0);
        setShowLightbox(true);
    }

    const chooseVisit = (id) =>
    {
        if (id !== "-1")
        {
            setSingleVisitId(id);
            setSingleViewPgDropdownValue(id);
            setVisitChosen(true);

            if (visitSumArray.length > 0)
            {
                visitSumArray.forEach((visit, index) =>
                {
                    if (visit._id === id)
                    {
                        setProjectId(visit.proj_id);
                        setVisitDate(visit.timestamp);
                    }
                });
            }

            toggleVisitChosen();
        }
        else
        {
            setSingleViewPgDropdownValue("-1");
            setSingleVisitId("0");
            setVisitChosen(false);
        }
    }

    const setDropdownValues = () =>
    {
        if (visitSumArray.length > 0)
        {
            let visitSumArrayReduced = JSON.parse(JSON.stringify(visitSumArray));

            // Remove server success message from array, if it exists (for single visit)
            visitSumArrayReduced = removeSuccessMessage(visitSumArrayReduced, "Site visit summaries retrieved successfully.");

            // let lastArrayItem = visitSumArrayReduced.length;
            // let msgFromServer = visitSumArrayReduced[lastArrayItem - 1];

            // if (msgFromServer === "Site visit summaries retrieved successfully.") { visitSumArrayReduced.pop(); }

            setVisitDropdownArray(visitSumArrayReduced);
        }
    }

    const editAVisit = (id, projId, date) =>
    {
        setActiveTab("3");
        setEditPgDropdownValue(`${id}///${projId}///${date}`)
        setVisitId(id);
        setProjectId(projId);
        setVisitDate(date);
        toggleVisitChosen();
    }

    const sortVisit = async () =>
    {
        let staffNames;
        let timeStamp;
        let urls;
        let numOfPics = 0;
        let numOfDocs = 0;

        if (visitMsgFromServer === "Site visit retrieved successfully." && singleVisitId !== "0" && singleVisitArray.length > 0)
        {
            let visitReduced = [];
            if (singleVisitArray.length > 0) 
            {
                visitReduced = JSON.parse(JSON.stringify(singleVisitArray));
            }

            let visitDocsReduced = [];
            if (visitDocsArray.length > 0)
            {
                visitDocsReduced = JSON.parse(JSON.stringify(visitDocsArray));
            }

            let visitImagesReduced = [];
            if (visitImagesArray.length > 0)
            {
                visitImagesReduced = JSON.parse(JSON.stringify(visitImagesArray));
            }

            // Remove server success message from array, if it exists (for visits)
            visitReduced = removeSuccessMessage(visitReduced, "Site visit retrieved successfully.");
            visitReduced = visitReduced[0];

            // Remove server success message from array, if it exists (for docs)
            visitDocsReduced = removeSuccessMessage(visitDocsReduced, "Site visit documents retrieved successfully.");

            // Remove server success message from array, if it exists (for images)
            visitImagesReduced = removeSuccessMessage(visitImagesReduced, "Site visit images retrieved successfully.");

            numOfPics = 0;

            visitImagesReduced.forEach((image, i) =>
            {
                if (image.visit_id === visitReduced._id)
                {
                    urls = image.imageUrlArray;
                    numOfPics = urls.length;
                    setImages(urls);
                }
            });

            let tempDocUrls = [];
            let tempDocNames = [];
            let tempDocNamesObject = {};
            let tempDocUrlsObject = [];
            numOfDocs = 0;

            visitDocsReduced.forEach((doc, i) =>
            {
                if (doc.visit_id === visitReduced._id)
                {
                    tempDocUrls = doc.docUrlArray;
                    tempDocNames = doc.docNamesArray;
                    numOfDocs = tempDocUrls.length;
                }
            });

            if (docUrls !== {}) { tempDocUrlsObject = docUrls }

            if (tempDocUrls.length > 0)
            {
                tempDocUrlsObject[singleVisitId] = tempDocUrls;
                setDocUrls(tempDocUrlsObject);
            }

            if (docNames !== {}) { tempDocNamesObject = docNames }

            if (tempDocNames.length > 0)
            {
                tempDocNamesObject[singleVisitId] = tempDocNames;
                setDocNames(tempDocNamesObject);
            }

            let names = Object.keys(visitReduced.staff_register);
            let presence = Object.values(visitReduced.staff_register);

            staffNames = names.map((name, index) =>
            {
                return `${name} - ${presence[index]}`;
            });

            staffNames = staffNames.join(", ");

            let equipNames = [];
            let equipNumbers = [];
            let equipList = [];

            if (visitReduced.plant_equip_list !== undefined)
            {
                equipNames = Object.keys(visitReduced.plant_equip_list);
                equipNumbers = Object.values(visitReduced.plant_equip_list);

                equipList = equipNames.map((name, index) =>
                {
                    return `${name} - ${equipNumbers[index]}`;
                });
            }

            equipList = equipList.join(", ");

            // Check if it was less than 60 mins since site visit was saved, else can't edit it
            let visitTime = new Date(visitReduced.timestamp);
            let minutesElapsed = calcTimeBetween(visitTime);
            minutesElapsed = Math.round(minutesElapsed);

            timeStamp = new Date(visitReduced.timestamp);
            timeStamp = timeStamp.toString();
            timeStamp = timeStamp.replace(" GMT+0200 (South Africa Standard Time)", "");

            let projDescrip = "";
            if (visitReduced.proj_descrip !== undefined) 
            {
                projDescrip = visitReduced.proj_descrip;
            }
            else
            {
                projDescrip = "No description";
            }

            let milestoneTimeStamp = new Date(visitReduced.milestone.Timestamp);
            milestoneTimeStamp = milestoneTimeStamp.toString();
            milestoneTimeStamp = milestoneTimeStamp.replace(" GMT+0200 (South Africa Standard Time)", "");

            let activs = visitReduced.activities_onsite;
            activs = activs.join(", ");

            let equipPhotos;

            if (visitReduced.plant_equip_photos !== undefined) 
            {
                equipPhotos = visitReduced.plant_equip_photos.toString()
            }
            else
            {
                equipPhotos = "false";
            }

            let visitToDisplay = (
                <>
                    {visitReduced.isActive === 0
                        ? <p style={{ color: "red" }}>This site visit is currently inactive.</p>
                        : null}

                    <div key={singleVisitId} className="border border-dark mb-4 table-responsive over">
                        <Table striped className="table table-stripy mb-2">
                            <thead>
                                <tr className="col-12 table-light">
                                    <th className="col-3">Description</th>
                                    <td className="col-3">{projDescrip}</td>
                                    <th className="col-3"></th>
                                    <th className="col-3 text-end"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="col-12">
                                    <th className="col-3">Proj ID:</th>
                                    <td className="col-3">{visitReduced.proj_id}</td>
                                    <th className="col-3">Time added:</th>
                                    <td className="col-3">{timeStamp}</td>
                                </tr>
                                <tr className="col-12">
                                    <th>Added by:</th>
                                    <td>{visitReduced.current_user}</td>
                                    <th>Added by IP:</th>
                                    <td>{visitReduced.ip}</td>
                                </tr>
                                <tr className="col-12">
                                    <th>CLO onsite:</th>
                                    <td> {visitReduced.onsite_today.toString()}</td>
                                    <th>Photos uploaded:</th>
                                    <td>{visitReduced.photos_uploaded.toString()}</td>
                                </tr>
                                <tr className="col-12">
                                    <th>Total Staff:</th>
                                    <td> {visitReduced.total_staff_onsite}</td>
                                    <th>Local Staff:</th>
                                    <td>{visitReduced.local_staff_onsite}</td>
                                </tr>
                                <tr className="col-12">
                                    <th>Management:</th>
                                    <td>{visitReduced.mgrs_onsite}</td>
                                    <th>Staff Register:</th>
                                    <td style={{ wordWrap: "break-word", minWidth: "12rem", maxWidth: "12rem", whiteSpace: "normal" }}> {staffNames}</td>
                                </tr>
                                <tr className="col-12">
                                    <th>Activities:</th>
                                    <td style={{ wordWrap: "break-word", minWidth: "12rem", maxWidth: "12rem", whiteSpace: "normal" }}>{activs}</td>
                                    <th>Health and Safety Check Done:</th>
                                    <td> {visitReduced.health_safety_check.toString()}</td>
                                </tr>
                                <tr className="col-12">
                                    <th>Incident:</th>
                                    <td>{visitReduced.incident_onsite.toString()}</td>
                                    <th></th>
                                    <td></td>
                                </tr>
                                {
                                    visitReduced.incident_onsite === true
                                        ? <>
                                            <tr className="col-12">
                                                <th>Incident Severity:</th>
                                                <td>{visitReduced.incident_severity}</td>
                                                <th>Incident Type:</th>
                                                <td>{visitReduced.incident_type}</td>
                                            </tr>
                                            <tr className="col-12">
                                                <th>Incident Details:</th>
                                                <td style={{ wordWrap: "break-word", minWidth: "12rem", maxWidth: "12rem", whiteSpace: "normal" }}>{visitReduced.incident_details}</td>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                        </>
                                        : null
                                }
                                <tr className="col-12">
                                    <th>Induction Complete:</th>
                                    <td>{visitReduced.induction_done.toString()}</td>
                                    <th>Milestone:</th>
                                    <td style={{ wordWrap: "break-word", minWidth: "12rem", maxWidth: "12rem", whiteSpace: "normal" }}>{visitReduced.milestone.comment}</td>
                                </tr>
                                <tr className="col-12">
                                    <th>Milestone added by:</th>
                                    <td>{visitReduced.milestone['Current User']}</td>
                                    <th>Time added:</th>
                                    <td>{milestoneTimeStamp}</td>
                                </tr>
                                {
                                    visitReduced.plant_equip_list !== undefined
                                        ?
                                        <tr className="col-12">
                                            <th>Plant &#38; Equip: </th>
                                            <td>{equipList}</td>
                                            <th></th>
                                            <td></td>
                                        </tr>

                                        : null
                                }
                                <tr className={visitReduced.plant_equip_list !== undefined ? `col-12` : `col-12`}>
                                    <th>Photos of Equip:</th>
                                    <td>{equipPhotos}</td>
                                    <th></th>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>

                        <div className="row col-12">
                            <div className={numOfPics > 0 && numOfDocs > 0
                                ? `col-12 col-sm-4 p-2`
                                : numOfPics > 0 && numOfDocs === 0 || numOfDocs > 0 && numOfPics === 0
                                    ? `col-12 col-sm-3 p-2`
                                    : `col-12 p-2`}>
                                {
                                    numOfPics > 0
                                        ? <Button className="ms-2 mb-1" color="primary" outline onClick={() => { setupImages(visitReduced.proj_descrip) }}>
                                            <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                            <Badge className="ms-2 bg-danger">
                                                {numOfPics}
                                            </Badge>
                                        </Button>
                                        : null
                                }

                                {numOfDocs > 0
                                    ? <Button className="ms-2 mb-1" color="primary" outline onClick={() => { tog_center(visitReduced.proj_descrip); }}>
                                        <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                                        <Badge className="ms-2 bg-danger">
                                            {numOfDocs}
                                        </Badge>
                                    </Button> : null}

                                {isAdmin || minutesElapsed <= 60
                                    ?
                                    (isAdmin || thisUser === visitReduced.current_user)
                                        ?
                                        <Button
                                            className="ms-2 mb-1"
                                            color="primary"
                                            onClick={() => editAVisit(visitReduced._id, visitReduced.proj_id, visitReduced.timestamp)}
                                        >
                                            Edit
                                        </Button>
                                        : null
                                    : null}
                            </div>
                        </div>
                    </div>
                </>);

            setShowVisit(visitToDisplay);
        }
        else if (visitMsgFromServer === "Caught error")
        {
            setShowVisit(<div className="redText">An error occurred while retrieving the site visit. Please refresh the page.</div>);
        }
    }

    useEffect(() =>
    {
        if (activeTab !== "4")
        {
            setSingleViewPgDropdownValue("-1");
            setSingleVisitId("0");
            setVisitChosen(false);
        }
    }, [activeTab]);

    useEffect(() =>
    {
        setDropdownValues();
    }, [visitSumArray]);

    useEffect(() =>
    {
        sortVisit();
    }, [singleVisitId, visitMsgFromServer, toggleVisitChosen]);

    useEffect(() =>
    {
        if (singleViewPgDropdownValue !== "-1")
        {
            setVisitChosen(true);
        }
    }, [singleViewPgDropdownValue]);

    let array = [];
    if (docNames[singleVisitId] !== undefined) { array = docNames[singleVisitId] };

    return (
        <div>
            {showLightbox ? (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    enableZoom={true}
                    onCloseRequest={() =>
                    {
                        setShowLightbox(false);
                    }}
                    onMovePrevRequest={() =>
                    {
                        setPhotoIndex((photoIndex + images.length - 1) % images.length);
                    }}
                    onMoveNextRequest={() =>
                    {
                        setPhotoIndex((photoIndex + 1) % images.length);
                    }}
                    imageCaption={"Site Visit on " + tidyDate + " - " + projectDescrip}
                />
            ) : null}

            <Modal
                isOpen={modal_center}
                toggle={() =>
                {
                    tog_center();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Documents for site visit on: {tidyDate}{" - " + projectDescrip}</h5>
                    <button
                        type="button"
                        onClick={() =>
                        {
                            setmodal_center(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {array.map((name, index) => (
                        <div className="mb-1 ms-4 p-0 col-10" key={index}>
                            <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                            &nbsp;&nbsp;{index + 1}.&nbsp;{name}
                            <Button className="ms-1 py-0" color="primary" outline onClick={() =>
                                window.open(docUrls[singleVisitId][index], `_blank`)} key={index}>
                                View
                            </Button>
                        </div>
                    ))}
                </div>
            </Modal>
            {/* <div>current id: {singleVisitId}, dropdown array length: {projDropdownArray.length}, msg: {projSumMsgFromServer}</div> */}
            {visitDropdownArray.length > 0
                ? <div>
                    <FormGroup row className="mb-4">
                        <Label
                            for="chooseVis"
                            sm={2}
                        >
                            Choose Site Visit to View
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="chooseVis"
                                name="chooseVis"
                                type="select"
                                value={singleViewPgDropdownValue}
                                onChange={(e) => { chooseVisit(e.target.value); }}
                            >
                                <option key={"first"} value={"-1"}>Choose Option</option>
                                {visitDropdownArray.map((visit, index) => (
                                    <option key={index}
                                        value={visit._id}>Project ID: {visit.proj_id} - {visit.proj_descrip !== undefined && visit.proj_descrip !== ""
                                            ? visit.proj_descrip + " - "
                                            : null} {new Date(visit.timestamp).toString().replace(" GMT+0200 (South Africa Standard Time)", "")}</option>
                                ))}
                            </Input>
                        </Col>
                    </FormGroup>
                </div>
                : visitDropdownArray.length === 0
                    ? <div className="redText">No site visits saved yet.</div>
                    : null}

            {visitChosen && singleVisitArray.length > 0 ? showVisit : null}
        </div>
    )
}

export default ViewSingleVisit;

ViewSingleVisit.propTypes = {
    visitMsgFromServer: PropTypes.string,
    singleVisitArray: PropTypes.array,
    visitMsgFromServer: PropTypes.string,
    setVisitMsgFromServer: PropTypes.func,
    visitImagesArray: PropTypes.array,
    visitDocsArray: PropTypes.array,
    singleVisitId: PropTypes.string,
    setSingleVisitId: PropTypes.func,
    visitSumArray: PropTypes.array,
    projSumMsgFromServer: PropTypes.string,
    isAdmin: PropTypes.bool,
    setProjectId: PropTypes.func,
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
    thisUser: PropTypes.string,
    singleProjInfoChanged: PropTypes.bool,
    toggleVisitChosen: PropTypes.func,
    setVisitDate: PropTypes.func,
    visitDate: PropTypes.string,
    setVisitId: PropTypes.func,
    singleViewPgDropdownValue: PropTypes.string,
    setSingleViewPgDropdownValue: PropTypes.func,
    setEditPgDropdownValue: PropTypes.func,
    visitChosen: PropTypes.bool,
    setVisitChosen: PropTypes.func,
}