async function sortLogins(loginsArray)
{
    //let usernames = [];
    let emails = [];
    let passwords = [];

    loginsArray.forEach(function (item, index)
    {
        if (item.email !== undefined)
        {
            emails.push(item.email);
            passwords.push(item.password);
        }
    });

    return [emails, passwords];
}

export default sortLogins;