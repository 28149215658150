const createDocArray = (urlsArray, namesArray, privacyArray, docArray) =>
{
    let tempDocArray = [];

    if ((urlsArray === "" && namesArray === "") || (urlsArray.length === 0 && namesArray.length === 0))
    {
        docArray.forEach((doc, index) =>
        {
            let tempObject = { name: "", url: "", privacy: "" };
            tempObject.name = doc.name;
            tempObject.url = doc.url;
            tempObject.privacy = privacyArray[index] !== undefined ? privacyArray[index] : "public";
            tempDocArray.push(tempObject);
        });
    }
    else
    {
        urlsArray.forEach((url, index) =>
        {
            let tempObject = { name: "", url: "", privacy: "" };
            tempObject.name = namesArray[index];
            tempObject.url = url;
            tempObject.privacy = privacyArray[index];
            tempDocArray.push(tempObject);
        });
    }


    return tempDocArray;
}

export default createDocArray;