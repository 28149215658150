import React from "react";
import
{
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import MetaTags from "react-meta-tags";
import { useState, useEffect } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import UserForm from "./UserForm";
import { decryptUser } from "../SharedFiles/decryptUser";
import getLogins from '../SharedFiles/getLogins';
import CreateUser from "../Projects/CreateUser";

function Profile()
{
    const [activeTab, setactiveTab] = useState("1");
    const [isLoaded, setIsLoaded] = useState(false);
    const [showPage, setShowPage] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const [userData, setUserData] = useState({});
    const [userInfoChanged, setUserInfoChanged] = useState(false);

    const toggleUserInfoChanged = () =>
    {
        setUserInfoChanged(current => !current);
    };

    const toggle = tab =>
    {
        if (activeTab !== tab)
        {
            setactiveTab(tab);
        }
    };

    const setInfo = async () =>
    {
        let oldUser = decryptUser();
        await CreateUser(oldUser.email);
        let authUser = decryptUser();

        if (authUser !== undefined)
        {
            let name = authUser.name !== undefined ? authUser.name : "";
            let surname = authUser.surname !== undefined ? authUser.surname : "";
            let bio = authUser.bio !== undefined ? authUser.bio : "";
            let image_url = authUser.image_url !== undefined ? authUser.image_url : [];

            // Set current logged in user
            let tempData = { "name": name, "surname": surname, "bio": bio, "image_url": image_url }
            let tempObject = { "username": authUser.username, "_id": authUser._id, "uid": authUser.uid }
            setUserInfo(tempObject);
            setUserData(tempData);
            setIsLoaded(true);
        }
    };

    useEffect(async () =>
    {
        await getLogins();
        setInfo();
    }, [userInfoChanged]);

    return (
        <React.Fragment>
            <div className="page-content" id="top">
                <MetaTags>
                    <title>Profile - CMS</title>
                </MetaTags>
                <Breadcrumb title="Home" breadcrumbItem="User Profile" />
                {showPage ?
                    <>
                        <Row className="mb-0">
                            <Col className="col-12 col-sm-12 col-md-6">
                                <Nav pills className="icon-tab nav-justified">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer", color: "secondary" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() =>
                                            {
                                                toggle("1");
                                            }}
                                        >
                                            Update User Info
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col className="col-12 col-sm-6 col-md-12">
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <UserForm
                                            isLoaded={isLoaded}
                                            userInfo={userInfo}
                                            userData={userData}
                                            toggleUserInfoChanged={toggleUserInfoChanged}
                                        />
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </>
                    : null}
            </div >
        </React.Fragment >
    );
}

export default Profile;