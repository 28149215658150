import { API_URL } from "../../helpers/ApiUrl";

const simpleFetch = async (endpoint, body, type) =>
{
    let postBody = {};

    if (type === "token")
    {
        postBody = { reset_password_token: body }
    }
    else if (type === "update password")
    {
        postBody = body;
    }
    else if (type === undefined)
    {
        postBody = { ward: body };
    }

    let response = await fetch(
        // `${API_URL}/getprojectdetail`,
        `${API_URL}/${endpoint}`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postBody),
        }
    )
        .then((response) => response.json())
        .then((result) =>
        {
            if (type === "update password")
            {
                return result.message;
            }
            return JSON.parse(result.message);
        })
        .catch((error) =>
        {
            console.error('Error:', error.message);
            return "Caught error";
        });

    return response;
}

export default simpleFetch;