import React from "react"
import { Redirect } from "react-router-dom"

// Profile
//import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Projects from "../pages/Projects/index"
import Visits from "../pages/SiteVisits/index"
import Profile from "../pages/Profiles/index"
import Councillors from "../pages/Councillors/index"
import Admin from "../pages/Admin/index"
import Sponsors from "../pages/Sponsors/index"
import Banners from "../pages/Banners/index"
import ResetPwd from "../pages/Authentication/resetPassword"

// Dashboard
//import Dashboard from "../pages/Dashboard/index"

const authProtectedRoutes = [
  // { path: "/dashboard", component: Dashboard },

  // profile
  { path: "/projects", component: Projects },
  { path: "/visits", component: Visits },
  { path: "/profile", component: Profile },
  { path: "/councillor", component: Councillors },
  { path: "/admin", component: Admin },
  { path: "/sponsors", component: Sponsors },
  { path: "/banners", component: Banners },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/projects" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/reset-password", component: ResetPwd },
]

export { publicRoutes, authProtectedRoutes }
