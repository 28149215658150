import React from "react";
import
{
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledAlert
} from "reactstrap";
import classnames from "classnames";
import MetaTags from "react-meta-tags";
import Breadcrumb from "../../components/Common/Breadcrumb";
import AddNewSiteVisit from "./AddNewSiteVisit";
import EditSiteVisitsForm from "./EditSiteVisitsForm";
import { useState, useEffect } from "react";
//import GetKey from "../SharedFiles/GetKey";
import fetchInfo from "pages/SharedFiles/fetchInfo";
//import fetchAVisit from "./fetchAVisit";
import ViewVisitSums from "./ViewVisitSums";
import ViewSingleVisit from "./ViewSingleVisit";
import { decryptUser } from "../SharedFiles/decryptUser";
import fetchAnItem from "../SharedFiles/fetchAnItem";
import SideArrow from "../SharedFiles/SideArrow";
import fetchWardProjects from "../SharedFiles/fetchWardProjects";

function View()
{
    const [activeTab, setActiveTab] = useState("1");
    const [visitDate, setVisitDate] = useState("0");
    const [projectId, setProjectId] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showPage, setShowPage] = useState(true);

    // Site visit hooks
    const [showVisitAlerts, setShowVisitAlerts] = useState(false);
    const [showAddVisitAlerts, setShowAddVisitAlerts] = useState(false);
    const [visitInfoUpdated, setVisitInfoUpdated] = useState("");
    const [chosenFromViewPage, setChosenFromViewPage] = useState(false);
    const [visitInfoChanged, setVisitInfoChanged] = useState(false);
    const [visitImagesError, setVisitImagesError] = useState("");

    const [visitDocsUpdated, setVisitDocsUpdated] = useState("");
    const [isLoadedDocs, setIsLoadedDocs] = useState(false);
    const [docsMsgFromServer, setDocsMsgFromServer] = useState("");
    const [siteVisitDocsArray, setSiteVisitDocsArray] = useState([]);

    const [visitImagesUpdated, setVisitImagesUpdated] = useState("");
    const [isLoadedImages, setIsLoadedImages] = useState(false);
    const [visitImagesArray, setVisitImagesArray] = useState([]);
    const [imgMsgFromServer, setImgMsgFromServer] = useState("");

    // const [placesKey, setPlacesKey] = useState("");
    // const [keyLoaded, setKeyLoaded] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);
    const [projectsArray, setProjectsArray] = useState([]);
    const [isLoadedProjects, setIsLoadedProjects] = useState(false);
    const [projectMsgFromServer, setProjectMsgFromServer] = useState("");

    const [siteVisitsArray, setSiteVisitsArray] = useState([]);
    const [isLoadedVisitSums, setIsLoadedVisitSums] = useState(false);
    const [visitSumArray, setVisitSumArray] = useState([]);
    const [visitSumMsgFromServer, setVisitSumMsgFromServer] = useState("");
    const [singleVisitArray, setSingleVisitArray] = useState([]);
    const [visitMsgFromServer, setVisitMsgFromServer] = useState("");
    const [singleVisitId, setSingleVisitId] = useState("0");
    const [visitId, setVisitId] = useState("0");

    const [thisUser, setThisUser] = useState("none");
    const [projectCompleteError, setProjectCompleteError] = useState("");
    const [editPgDropdownValue, setEditPgDropdownValue] = useState("-1");
    const [singleViewPgDropdownValue, setSingleViewPgDropdownValue] = useState("-1");
    const [canPublish, setCanPublish] = useState(0);
    const [showArrow, setShowArrow] = useState(false);
    const [visitChosen, setVisitChosen] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    const toggleVisitChosen = () =>
    {
        setChosenFromViewPage(current => !current);
    };

    const toggleVisitInfoChanged = () =>
    {
        setVisitInfoChanged(current => !current);
    };

    const toggle = tab =>
    {
        if (activeTab !== tab)
        {
            setActiveTab(tab);
        }
    };

    useEffect(() =>
    {
        let authUser = decryptUser();

        if (authUser !== undefined)
        {
            if (authUser.role !== "admin")
            {
                setIsAdmin(false);
            }
            else
            {
                setIsAdmin(true);
            }

            if (authUser.role === "guest")
            {
                setShowPage(false);
            }

            // Set current logged in user
            setThisUser(authUser.username);
            setCanPublish(authUser.canPublish);
            let tempWards = authUser.wards;
            let wards = authUser.wards !== undefined && tempWards.length !== 0
                ? authUser.wards
                : [];
            let tempUserInfo = {
                "ward": authUser.ward,
                "wards": wards,
                "username": authUser.username,
                "canPublish": authUser.canPublish,
                "canDisplay": authUser.canDisplay,
                "role": authUser.role
            };
            setUserInfo(tempUserInfo);
        }
    }, []);

    useEffect(() =>
    {
        fetchInfo("/getvisits", setIsLoaded, setSiteVisitsArray, setVisitMsgFromServer, "visitsArray");

        if (!isAdmin && userInfo.wards !== undefined)
        {
            fetchWardProjects("getprojectsbywardclo", userInfo.wards, setProjectsArray, setProjectMsgFromServer, setIsLoadedProjects, userInfo.username, true);
            //fetchWardProjects("getprojectsumbyward", userInfo.wards, setProjectsArray, setProjectMsgFromServer, setIsLoadedProjects, userInfo.username);
        }
        else
        {
            fetchInfo("/getprojects", setIsLoadedProjects, setProjectsArray, setProjectMsgFromServer, "projectsArray");
            // fetchInfo("/getactiveprojects", setIsLoadedProjects, setProjectsArray, setProjectMsgFromServer, "projectsArray");
            //fetchInfo("/getprojectsum", setIsLoadedProjects, setProjectsArray, setProjectMsgFromServer, "message");
        }

        fetchInfo("/getvisitimages", setIsLoadedImages, setVisitImagesArray, setImgMsgFromServer, "siteImagesArray");
        fetchInfo("/getvisitdocs", setIsLoadedDocs, setSiteVisitDocsArray, setDocsMsgFromServer, "visitDocsArray");
        fetchInfo("/getvisitsum", setIsLoadedVisitSums, setVisitSumArray, setVisitSumMsgFromServer, "message");
    }, [userInfo, isLoaded, isLoadedProjects, isLoadedImages, isLoadedDocs, isLoadedVisitSums, visitInfoChanged, chosenFromViewPage]);

    useEffect(() =>
    {
        if (singleVisitId !== "0")
        {
            //fetchAVisit(singleVisitId, setSingleVisitArray, setVisitMsgFromServer);
            fetchAnItem("sitevisit", "getvisitdetail", singleVisitId, setSingleVisitArray, setVisitMsgFromServer, "Site visit retrieved successfully.");

            // if (singleVisitId === value)
            // {
            //     setActiveTab("4");
            // }
        }
    }, [singleVisitId]);

    useEffect(() =>
    {
        if ((visitSumArray.length > 5 && activeTab === "1") || activeTab === "4" && visitChosen)
        {
            setShowArrow(true);
        }
        else
        {
            setShowArrow(false);
        }
    }, [visitSumArray, activeTab]);

    return (
        <React.Fragment>
            <div className="page-content" id="top">
                <MetaTags>
                    <title>Site Visits - CMS</title>
                </MetaTags>
                <Breadcrumb title="Home" breadcrumbItem="Site Visits" />
                {showPage ? <Container fluid={true}>
                    <Row className="mb-0">
                        <Col className="col-12 col-sm-12 col-md-12">
                            <Nav pills className="icon-tab nav-justified">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", color: "secondary" }}
                                        className={classnames({
                                            active: activeTab === "1",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle("1");
                                        }}
                                    >
                                        View
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "2",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle("2");
                                        }}
                                    >
                                        Add
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", color: "secondary" }}
                                        className={classnames({
                                            active: activeTab === "3",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle("3");
                                        }}
                                    >
                                        Edit
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", color: "secondary" }}
                                        className={classnames({
                                            active: activeTab === "4",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle("4");
                                        }}
                                    >
                                        Single
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col className="col-12 col-sm-12 col-md-12">
                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                <TabPane tabId="1">
                                    <ViewVisitSums
                                        visitSumMsgFromServer={visitSumMsgFromServer}
                                        visitSumArray={visitSumArray}
                                        setActiveTab={setActiveTab}
                                        setSingleVisitId={setSingleVisitId}
                                        singleVisitId={singleVisitId}
                                        visitImagesArray={visitImagesArray}
                                        visitDocsArray={siteVisitDocsArray}
                                        isAdmin={isAdmin}
                                        setProjectId={setProjectId}
                                        thisUser={thisUser}
                                        setVisitDate={setVisitDate}
                                        toggleVisitChosen={toggleVisitChosen}
                                        visitInfoChanged={visitInfoChanged}
                                        setVisitId={setVisitId}
                                        isLoadedVisitSums={isLoadedVisitSums}
                                        setSingleViewPgDropdownValue={setSingleViewPgDropdownValue}
                                        setShowArrow={setShowArrow}
                                    />
                                </TabPane>
                                <TabPane tabId="2">

                                    {/* PROJECT ALERTS ------------------------------*/}
                                    {/* Project image upload error */}

                                    {showAddVisitAlerts && visitImagesError !== ""
                                        ? <UncontrolledAlert color="danger" role="alert">
                                            {visitImagesError}
                                        </UncontrolledAlert> : null}

                                    {/* Project complete error */}

                                    {projectCompleteError !== ""
                                        ? <UncontrolledAlert color="danger" role="alert">
                                            {projectCompleteError}
                                        </UncontrolledAlert> : null}

                                    {/* ALERTS end ------------------------------*/}

                                    <AddNewSiteVisit
                                        projectsArray={projectsArray}
                                        toggleVisitChosen={toggleVisitChosen}
                                        toggleVisitInfoChanged={toggleVisitInfoChanged}
                                        setIsLoadedImages={setIsLoadedImages}
                                        setIsLoaded={setIsLoaded}
                                        setIsLoadedDocs={setIsLoadedDocs}
                                        setActiveTab={setActiveTab}
                                        setShowAddVisitAlerts={setShowAddVisitAlerts}
                                        setVisitImagesError={setVisitImagesError}
                                        setProjectCompleteError={setProjectCompleteError}
                                        projectCompleteError={projectCompleteError}
                                        thisUser={thisUser}
                                        activeTab={activeTab}
                                        projectMsgFromServer={projectMsgFromServer}
                                        canPublish={canPublish}
                                    />
                                </TabPane>
                                <TabPane tabId="3">

                                    {/* SITE VISIT ALERTS ------------------------------*/}
                                    {/* Site Visit info */}
                                    {showVisitAlerts && visitInfoUpdated !== "" && visitInfoUpdated === "Site visit information updated"
                                        ? <UncontrolledAlert color="primary" role="alert">
                                            {visitInfoUpdated}
                                        </UncontrolledAlert>
                                        : showVisitAlerts && visitInfoUpdated !== "" && visitInfoUpdated === "Site visit information NOT updated. Something went wrong."
                                            ? <UncontrolledAlert color="danger" role="alert">
                                                {visitInfoUpdated}
                                            </UncontrolledAlert>
                                            : null}

                                    {/* Site Visit documents */}
                                    {showVisitAlerts && visitDocsUpdated !== "" && visitDocsUpdated === "Site visit documents updated!"
                                        ? <UncontrolledAlert color="info" role="alert">
                                            {visitDocsUpdated}
                                        </UncontrolledAlert>
                                        : showVisitAlerts && visitDocsUpdated !== "" && visitDocsUpdated === "Site visit documents NOT updated. Something went wrong."
                                            ? <UncontrolledAlert color="danger" role="alert">
                                                {visitDocsUpdated}
                                            </UncontrolledAlert>
                                            : null}

                                    {/* Site Visit images */}
                                    {showVisitAlerts && visitImagesUpdated !== "" && visitImagesUpdated === "Site visit images updated"
                                        ? <UncontrolledAlert color="success" role="alert">
                                            {visitImagesUpdated}
                                        </UncontrolledAlert>
                                        : showVisitAlerts && visitImagesUpdated !== "" && visitImagesUpdated === "Site visit images NOT updated. Something went wrong."
                                            ? <UncontrolledAlert color="danger" role="alert">
                                                {visitImagesUpdated}
                                            </UncontrolledAlert>
                                            : null}

                                    {/* Project image upload error */}
                                    {showVisitAlerts && visitImagesError !== ""
                                        ? <UncontrolledAlert color="danger" role="alert">
                                            {visitImagesError}
                                        </UncontrolledAlert> : null}

                                    {/* ALERTS end ------------------------------ */}

                                    <EditSiteVisitsForm
                                        setActiveTab={setActiveTab}
                                        setVisitDocsUpdated={setVisitDocsUpdated}
                                        setVisitInfoUpdated={setVisitInfoUpdated}
                                        setVisitImagesUpdated={setVisitImagesUpdated}
                                        setShowVisitAlerts={setShowVisitAlerts}
                                        visitDate={visitDate}
                                        setVisitDate={setVisitDate}
                                        projectId={projectId}
                                        setProjectId={setProjectId}
                                        isAdmin={isAdmin}
                                        chosenFromViewPage={chosenFromViewPage}
                                        toggleVisitChosen={toggleVisitChosen}
                                        isLoadedImages={isLoadedImages}
                                        isLoaded={isLoaded}
                                        setIsLoadedImages={setIsLoadedImages}
                                        setIsLoaded={setIsLoaded}
                                        setIsLoadedDocs={setIsLoadedDocs}
                                        toggleVisitInfoChanged={toggleVisitInfoChanged}
                                        siteVisitsArray={siteVisitsArray}
                                        visitImagesArray={visitImagesArray}
                                        siteVisitDocsArray={siteVisitDocsArray}
                                        setVisitImagesError={setVisitImagesError}
                                        thisUser={thisUser}
                                        activeTab={activeTab}
                                        visitId={visitId}
                                        editPgDropdownValue={editPgDropdownValue}
                                        setEditPgDropdownValue={setEditPgDropdownValue}
                                        projectsArray={projectsArray}
                                        projectMsgFromServer={projectMsgFromServer}
                                    />
                                </TabPane>

                                <TabPane tabId="4">
                                    <ViewSingleVisit
                                        visitSumArray={visitSumArray}
                                        singleVisitArray={singleVisitArray}
                                        visitMsgFromServer={visitMsgFromServer}
                                        singleVisitId={singleVisitId}
                                        setSingleVisitId={setSingleVisitId}
                                        toggleVisitChosen={toggleVisitChosen}
                                        visitImagesArray={visitImagesArray}
                                        visitDocsArray={siteVisitDocsArray}
                                        isAdmin={isAdmin}
                                        setProjectId={setProjectId}
                                        setVisitDate={setVisitDate}
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                        visitDate={visitDate}
                                        setVisitId={setVisitId}
                                        singleViewPgDropdownValue={singleViewPgDropdownValue}
                                        setSingleViewPgDropdownValue={setSingleViewPgDropdownValue}
                                        setEditPgDropdownValue={setEditPgDropdownValue}
                                        visitChosen={visitChosen}
                                        setVisitChosen={setVisitChosen}
                                        thisUser={thisUser}
                                    />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container> : null}
            </div >
            <SideArrow showArrow={showArrow} />
        </React.Fragment >
    );
}

export default View;