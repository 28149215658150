const checkForManagers = (
    type,
    arrayOrObject,
    currentStaffObject,
    setCurrentStaffObject,
    addManagers,
    managers,
    minusManagers,
    setInputs,
    setManagers) =>
{
    let tempStaffObject = JSON.parse(JSON.stringify(currentStaffObject));
    let staff = tempStaffObject.contract_core_staff !== undefined ? tempStaffObject.contract_core_staff : tempStaffObject.contract_core_staff_names;
    let result = 0;

    if (type === "add all" && !Array.isArray(staff))
    {
        let mgrs = Object.keys(staff).filter(key => staff[key] === "Store Manager");
        let numberOfManagers = mgrs.length;

        if (numberOfManagers > 0)
        {
            let numOfMgrs = addManagers(numberOfManagers, setInputs, setManagers, managers);
            result = numOfMgrs;
        }
    }

    if (type === "add one" && !Array.isArray(staff))
    {
        let roleOfStaffMember = staff[arrayOrObject];

        let tempNumOfMgrs = managers;
        result = managers;

        if (roleOfStaffMember === "Store Manager")
        {
            tempNumOfMgrs = managers + 1;
            let newMgrs = addManagers(tempNumOfMgrs, setInputs, setManagers, managers);
            result = newMgrs;
        }
    }

    if (type == "subtract one" && !Array.isArray(staff))
    {
        let personRole = staff[arrayOrObject];

        if (personRole === "Store Manager")
        {
            let numOfMgrs = minusManagers(managers, setManagers, setInputs);
            delete tempStaffObject.contract_core_staff[arrayOrObject];
            setCurrentStaffObject(tempStaffObject);
            result = numOfMgrs;
        }
        else
        {
            delete tempStaffObject.contract_core_staff[arrayOrObject];
            setCurrentStaffObject(tempStaffObject);
            result = managers;
        }
    }

    return result;
}

export default checkForManagers;