import React from "react";
import
{
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import MetaTags from "react-meta-tags";
import { useState, useEffect } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { decryptUser } from "../SharedFiles/decryptUser";
import ViewSponsors from "./ViewSponsors";
import EditSponsor from "./EditSponsor";
import AddSponsor from "./AddSponsor";
import GetInfo from "../SharedFiles/GetInfo";
import SideArrow from "../SharedFiles/SideArrow";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";

function Sponsors()
{
    const [activeTab, setActiveTab] = useState("1");
    const [showPage, setShowPage] = useState(true);
    const [sponsorsArray, setSponsorsArray] = useState([]);
    // const [msgFromServer, setMsgFromServer] = useState("");
    const [currentUser, setCurrentUser] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [sponsorsChanged, setSponsorsChanged] = useState(false);
    const [showForm, setShowForm] = useState(false);
    //const [sponsorId, setSponsorId] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showArrow, setShowArrow] = useState(false);

    const toggle = tab =>
    {
        if (activeTab !== tab)
        {
            setActiveTab(tab);
        }
    };

    const toggleSponsorsChanged = () =>
    {
        setSponsorsChanged(current => !current);
    };

    // const toggleBannersChanged = () =>
    // {
    //     setBannersChanged(current => !current);
    // };

    const setPermission = async () =>
    {
        let authUser = decryptUser();

        if (authUser !== undefined)
        {
            setCurrentUser(authUser.username);
            setCurrentUserId(authUser._id);

            if (authUser.role !== "admin")
            {
                setShowPage(false);
            }
        }
    }

    const setSponsors = (result) =>
    {
        let array = JSON.parse(JSON.stringify(result));

        array = removeSuccessMessage(array, "Sponsors retrieved successfully.");

        // let lastArrayItem = array.length;
        // let msgFromServer = array[lastArrayItem - 1];

        // if (msgFromServer === "Sponsors retrieved successfully.")
        // {
        //     array.pop();
        // }

        setSponsorsArray(array);
        setIsLoaded(true);
    }

    const checkIfAdmin = async () =>
    {
        let authUser = decryptUser();

        if (authUser !== undefined && authUser.role !== "admin")
        {
            setShowPage(false);
        }
    }

    useEffect(() =>
    {
        if (activeTab === "1" && isLoaded)
        {
            setShowArrow(true);
        }
        else
        {
            setShowArrow(false);
        }
    }, [isLoaded, activeTab]);

    useEffect(() =>
    {
        if (activeTab === "1")
        {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [activeTab]);

    useEffect(async () =>
    {
        setPermission();
        let result = await GetInfo("getallsponsors", "");

        if (result !== "Error")
        {
            setSponsors(result);
        }
        else
        {
            console.log(result);
        }
    }, [sponsorsChanged]);

    useEffect(() =>
    {
        checkIfAdmin();
    });

    return (<React.Fragment>
        <div className="page-content" id="top">
            <MetaTags>
                <title>Sponsors - CMS</title>
            </MetaTags>
            <Breadcrumb title="Home" breadcrumbItem="Sponsors" />
            {showPage ?
                <>
                    <Row className="mb-0">
                        <Col className="col-12 col-sm-12 col-md-6">
                            <Nav pills className="icon-tab nav-justified">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", color: "secondary" }}
                                        className={classnames({
                                            active: activeTab === "1",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle("1");
                                        }}
                                    >
                                        View
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", color: "secondary" }}
                                        className={classnames({
                                            active: activeTab === "2",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle("2");
                                        }}
                                    >
                                        Add
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", color: "secondary" }}
                                        className={classnames({
                                            active: activeTab === "3",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle("3");
                                        }}
                                    >
                                        Edit
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col className="col-12 col-sm-6 col-md-12">
                            <TabContent activeTab={activeTab} className="p-3 text-muted">

                                <TabPane tabId="1">
                                    <ViewSponsors
                                        setShowForm={setShowForm}
                                        sponsorsArray={sponsorsArray}
                                        setActiveTab={setActiveTab}
                                        setCurrentIndex={setCurrentIndex}
                                        isLoaded={isLoaded}
                                    />
                                </TabPane>

                                <TabPane tabId="2">
                                    <AddSponsor
                                        currentUser={currentUser}
                                        setIsLoaded={setIsLoaded}
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                        toggleSponsorsChanged={toggleSponsorsChanged}
                                    />
                                </TabPane>

                                <TabPane tabId="3">
                                    <EditSponsor
                                        setShowForm={setShowForm}
                                        showForm={showForm}
                                        sponsorsArray={sponsorsArray}
                                        currentUser={currentUser}
                                        toggleSponsorsChanged={toggleSponsorsChanged}
                                        setActiveTab={setActiveTab}
                                        activeTab={activeTab}
                                        currentIndex={currentIndex}
                                        isLoaded={isLoaded}
                                    />
                                </TabPane>

                            </TabContent>
                        </Col>
                    </Row>
                </>
                : null}
        </div >
        <SideArrow showArrow={showArrow} />
    </React.Fragment>
    );
}

export default Sponsors;