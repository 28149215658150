import { API_URL } from "../../helpers/ApiUrl";

const postInfo = async (formData, route, method, handlerFunction, msg) =>
{
    await fetch(
        `${API_URL}/${route}`,
        {
            method: `${method}`,
            body: formData,
        }
    )
        .then((response) => response.json())
        .then((result) =>
        {
            console.log(result);
            if (msg !== "")
            {
                handlerFunction(msg, result);
            }
            else
            {
                handlerFunction(result);
            }

        })
        .catch((error) =>
        {
            console.error('Error:', error.message);

            if (msg !== "")
            {
                handlerFunction(msg, "Error");
            }
            else
            {
                handlerFunction("Error");
            }
        });
}

export default postInfo;