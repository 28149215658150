import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import { API_URL } from "./ApiUrl"

//pass new generated access token here
const token = accessToken

//apply base url for axios
//export const API_URL = ""
//const API_URL = ""
//const API_URL = "https://api.local-link.co.za";

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {})
{
  return await axiosApi.get(url, { ...config }).then(response => response.data).catch((error) =>
  {
    console.log("Error with get request: " + error.message)
  })
}

export async function post(url, data, config = {})
{
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data).catch((error) =>
    {
      console.log("Error with post request: " + error);
    })
}

// export async function get(url, config = {})
// {
//   return await axiosApi.get(url, { ...config }).then(response => response.data)
// }

// export async function post(url, data, config = {})
// {
//   return axiosApi
//     .post(url, { ...data }, { ...config })
//     .then(response => response.data)
// }

export async function put(url, data, config = {})
{
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch((error) =>
    {
      console.log("Error with put request: " + error.message)
    })
}

export async function del(url, config = {})
{
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
