const chooseFilterMedia = (allProjectsOrVisits, mediaObject, defaultFilter, filter, onValue, offValue, projectOrVisit) =>
{
    let idType = projectOrVisit === "project" ? "proj_id" : "_id"
    let projectsOrVisitsWithMedia = [];
    let filteredResult = [];

    if (filter === defaultFilter)
    {
        return allProjectsOrVisits;
    }

    if (Object.keys(mediaObject).length === 0 && filter === onValue)
    {
        return [];
    }
    else if (Object.keys(mediaObject).length === 0 && filter === offValue)
    {

        return allProjectsOrVisits;
    }

    if (filter === onValue)
    {
        allProjectsOrVisits.forEach(project =>
        {
            if (mediaObject[project[idType]] > 0)
            {
                projectsOrVisitsWithMedia.push(project[idType]);
            }
        });
    }
    else if (filter === offValue)
    {
        allProjectsOrVisits.forEach(project =>
        {
            if (mediaObject[project[idType]] === 0 || mediaObject[project[idType]] === undefined)
            {
                projectsOrVisitsWithMedia.push(project[idType]);
            }
        });
    }

    projectsOrVisitsWithMedia.forEach(projectId =>
    {
        let tempProject = allProjectsOrVisits.filter(project => project[idType] === projectId);

        if (tempProject !== undefined)
        {
            filteredResult.push(tempProject[0]);
        }
    });

    return filteredResult;
}

export default chooseFilterMedia;