import React from "react"
import { useState, useEffect } from "react";
import { Table, Button } from "reactstrap";
import PropTypes from 'prop-types';
import ".../../assets/styles.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ViewSponsors = (props) =>
{
    // Props
    let sponsorsArray = props.sponsorsArray;
    let setShowForm = props.setShowForm;
    let setActiveTab = props.setActiveTab;
    let setCurrentIndex = props.setCurrentIndex;
    let isLoaded = props.isLoaded;

    // Hooks
    const [sponsorsToDisplay, setSponsorsToDisplay] = useState([]);
    const [showLightbox, setShowLightbox] = useState(false);
    const [image, setImage] = useState("");
    const [showInactive, setShowInactive] = useState(false);
    const [displayNumOfSponsors, setDisplayNumOfSponsors] = useState("");

    const toggleShowInactive = () =>
    {
        setShowInactive(current => !current);
    }

    const editSponsor = (id, index) =>
    {
        setActiveTab("3");
        setCurrentIndex(index);
        setShowForm(true);
    }

    const sortInfo = () =>
    {
        if (sponsorsArray.length > 0)
        {
            let tempSponsorsArray = JSON.parse(JSON.stringify(sponsorsArray));
            let activeSponsors = tempSponsorsArray.filter(banner => banner.isActive === 1);
            let numOfSponsors = tempSponsorsArray.length;
            let numOfActiveSponsors = activeSponsors.length;
            let numOfInactiveSponsors = numOfSponsors - numOfActiveSponsors;
            let wardStringsArray = [];

            tempSponsorsArray.forEach((sponsor, index) =>
            {
                let wards = sponsor.wards !== undefined ? sponsor.wards : [];
                let wardsAsString = wards.length > 0 ? wards.join(", ") : "-";
                wardStringsArray.push(wardsAsString);
            });

            let numOfSponsorsToDisplay = "";
            if (numOfSponsors > 0)
            {
                numOfSponsorsToDisplay = (<div className="mb-2">
                    Total Sponsors: <b>{numOfSponsors}</b> <br />
                    Active Sponsors: <b>{numOfActiveSponsors}</b> <br />
                    Inactive sponsors: <b>{numOfInactiveSponsors}</b> <span style={showInactive ? { color: "red" } : { color: "black" }}>{showInactive ? "(Red)" : "(Hidden)"}</span>
                </div>);
            }
            else if (numOfSponsors === 0)
            {
                numOfSponsorsToDisplay = (<div className="redText">No sponsors saved yet.</div>);
            }

            setDisplayNumOfSponsors(numOfSponsorsToDisplay);

            let theSponsor = (
                <div className="border border-dark mb-4 table-responsive">
                    <Table striped className="table table-stripy mb-0">
                        <thead>
                            <tr className="col-12 table-light">
                                <th>Sponsor</th>
                                <th>Ward Number/s</th>
                                <th>Logo</th>
                                <th>Website</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tempSponsorsArray.map((company, index) => (
                                    (showInactive || (!showInactive && company.isActive === 1))
                                        ? <tr key={index + "a"}>
                                            <td style={company.isActive === 0 ? { color: 'red' } : null}>{company.company_name}</td>
                                            <td style={company.isActive === 0 ? { color: 'red' } : null}>{wardStringsArray[index]}</td>
                                            <td>{company.logo_url !== "" && company.logo_url !== undefined
                                                ? <span style={{ color: "blue" }}>Yes</span>
                                                : "No"}
                                            </td>
                                            <td style={company.isActive === 0 ? { color: 'red' } : null}>{company.link}</td>
                                            <td>
                                                {company.logo_url !== undefined && company.logo_url !== ""
                                                    ? <Button
                                                        className="py-1 me-1 mb-1"
                                                        color="primary"
                                                        onClick={() => { setImage(company.logo_url); setShowLightbox(true); }}
                                                    >
                                                        View Logo
                                                    </Button>
                                                    : null}

                                                <Button className="py-1 mb-1" color="primary" onClick={() => { editSponsor(company._id, index) }}>Edit</Button>
                                            </td>
                                        </tr>
                                        : null
                                ))
                            }
                            {numOfActiveSponsors === 0 ? <tr><td><span className="redText">No active sponsors.</span></td></tr> : null}
                        </tbody>
                    </Table>
                </div>
            );

            setSponsorsToDisplay(theSponsor);
        }
        else
        {
            let theSponsor = (
                <div className="redText">No sponsors saved yet. Click on the &quot;Add&quot; tab.</div>
            );
            setSponsorsToDisplay(theSponsor);
        }
    }

    useEffect(() =>
    {
        if (sponsorsArray.length > 0)
        {
            sortInfo();
        }
    }, [sponsorsArray, showInactive]);

    return (
        <div>
            {showLightbox ? (
                <Lightbox
                    mainSrc={image}
                    enableZoom={true}
                    onCloseRequest={() =>
                    {
                        setShowLightbox(false);
                    }}
                />
            ) : null}
            {/* <div>
                        Inputs: <pre>{JSON.stringify(inputs)}</pre>
                        currentIndex: {currentIndex},
                        loginsArray canPublish: <pre>{loginsArray.length > 0 ? JSON.stringify(loginsArray[currentIndex].canPublish) : null}</pre>
                    </div> */}

            {isLoaded && sponsorsArray.length > 0
                ? <>
                    <div className="d-flex justify-content-between align-items-center">
                        {displayNumOfSponsors}
                        <Button
                            className="py-1 mb-1"
                            color={showInactive ? "secondary" : "primary"}
                            onClick={() => toggleShowInactive()}>
                            {showInactive ? "Hide inactive" : "Show inactive"}
                        </Button>

                    </div>
                    {sponsorsToDisplay}
                </>
                : isLoaded && sponsorsArray.length === 0
                    ? <p className="redText">No sponsors saved yet.</p>
                    : !isLoaded
                        ? <p>Loading...</p>
                        : null}
        </div>
    );
}

export default ViewSponsors;

ViewSponsors.propTypes = {
    sponsorsArray: PropTypes.array,
    setShowForm: PropTypes.func,
    setActiveTab: PropTypes.func,
    setCurrentIndex: PropTypes.func,
    isLoaded: PropTypes.bool,
};