import { API_URL } from "../../helpers/ApiUrl";

async function GetWardPeople(ward, route, method, errorMsg, setCouncillorArray, setCouncillorLoaded)
{
    if (ward > 0)
    {
        let wardObject = { "ward": ward };

        fetch(
            `${API_URL}/${route}`,
            {
                method: `${method}`,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(wardObject),
            }
        )
            .then((res) => res.json())
            .then(
                (result) =>
                {
                    let array = JSON.parse(result.message);
                    setCouncillorArray(array);
                    setCouncillorLoaded(true);
                },
                (error) =>
                {
                    console.log(`${errorMsg}: `);
                    console.log(error);
                }
            );
    }
}

export default GetWardPeople;