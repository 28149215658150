import React from "react";
import { useState, useEffect } from "react";
import { Button, Table, Col, Input, Row, Form, Label, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import ".../../assets/styles.css";
import fetchAProject from "./fetchAProject";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";
import { projStatusFilterOptions, isActiveFilterOptions, imagesFilterOptions, docsFilterOptions } from "../SharedFiles/projectFilters";
import chooseFilter from "pages/SharedFiles/chooseFilter";
import chooseFilterMedia from "pages/SharedFiles/chooseFilterMedia";
import chooseFilterWard from "../SharedFiles/chooseFilterWard";
import swal from "sweetalert";
function ViewProjectSum(props)
{
    // Props
    let projectSumArray = props.projectSumArray;
    let projSumMsgFromServer = props.projSumMsgFromServer;
    // let docsMsgFromServer = props.docsMsgFromServer;
    // let imgMsgFromServer = props.imgMsgFromServer;
    let setSingleProjectArray = props.setSingleProjectArray;
    let setProjectMsgFromServer = props.setProjectMsgFromServer;
    let setActiveTab = props.setActiveTab;
    let setSingleProjectId = props.setSingleProjectId;
    let singleProjectId = props.singleProjectId;
    let projectImagesArray = props.projectImagesArray;
    let projectDocsArray = props.projectDocsArray;
    let isAdmin = props.isAdmin;
    let setProjectId = props.setProjectId;
    //let setProjectIndex = props.setProjectIndex;
    let isLoadedProjSums = props.isLoadedProjSums;
    let setEditPgDropdownValue = props.setEditPgDropdownValue;
    // let singleViewPgDropdownValue = props.singleViewPgDropdownValue;
    let setSingleViewPgDropdownValue = props.setSingleViewPgDropdownValue;
    let userInfo = props.userInfo;
    let thisUser = userInfo.username;
    let userWards = userInfo.wards;
    let activeTab = props.activeTab;
    let setShowArrow = props.setShowArrow;

    // Hooks
    const [displayNumOfProjects, setDisplayNumOfProjects] = useState("");
    const [showProjects, setShowProjects] = useState("");
    const [showInactive, setShowInactive] = useState(true);
    const [showFilters, setShowFilters] = useState(false);
    const [projStatusFilter, setProjStatusFilter] = useState("");
    const [isActiveFilter, setIsActiveFilter] = useState(-1);
    const [imagesFilter, setImagesFilter] = useState("");
    const [docsFilter, setDocsFilter] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [wardSearchQuery, setWardSearchQuery] = useState("");
    const [displayFilters, setDisplayFilters] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [filterDatesChosen, setFilterDatesChosen] = useState(false);

    const handleWardChange = (wardInput) =>
    {

        setWardSearchQuery(tempValue);
    }

    const toggleShowFilters = () =>
    {
        setShowFilters(current => !current);
    }

    const editAProject = (projId) =>
    {
        //let projId = projectSumArray[id].proj_id;
        let id = 0;
        setActiveTab("3");
        setProjectId(Number(projId));
        //setProjectIndex(id);
        setEditPgDropdownValue(projId);
    }

    const handleViewClicked = (projId) =>
    {
        // let index = 0;
        //let projId = projectSumArray[index].proj_id;
        // let project = projectSumArray.filter(project => project.proj_id === projId);
        // debugger;
        // setSingleViewPgDropdownValue(`${projId}-${index}`);
        setSingleViewPgDropdownValue(projId);
        setSingleProjectId(projId);
        setProjectId(projId);
        setActiveTab("4");
        // setProjectIndex(index);
    }

    const filterDates = (array) =>
    {
        let result = [];

        if (filterDatesChosen && startDateFilter !== "" && endDateFilter !== "")
        {
            let startDate = new Date(startDateFilter);
            let endDate = new Date(endDateFilter);

            result = array.filter(project => new Date(project.proj_start_date) >= startDate && new Date(project.proj_start_date) <= endDate);
        }
        else
        {
            swal("Error", "Both date filters must be filled in.", "error");
            setFilterDatesChosen(false);
        }

        return result;
    }

    const sortProjects = async (msg) =>
    {
        let projectSummariesReduced = [];
        if (projectSumArray.length > 0) 
        {
            projectSummariesReduced = JSON.parse(JSON.stringify(projectSumArray));
        }

        let projectDocsReduced = [];
        if (projectDocsArray.length > 0) 
        {
            projectDocsReduced = JSON.parse(JSON.stringify(projectDocsArray));
        }

        let projectImagesReduced = [];
        if (projectImagesArray.length > 0)
        {
            projectImagesReduced = JSON.parse(JSON.stringify(projectImagesArray));
        }

        // Remove server success message from array, if it exists (for projects)
        projectSummariesReduced = removeSuccessMessage(projectSummariesReduced, "Project summaries retrieved successfully.");

        // Remove server success message from array, if it exists (for docs)
        let projectDocServerMessages = ["Project documents retrieved successfully.",
            "No project documents have been saved yet.",
            "Something went wrong while finding a project documents!"];
        projectDocsReduced = removeSuccessMessage(projectDocsReduced, projectDocServerMessages);

        // Remove server success message from array, if it exists (for images) 
        let projectImageServerMessages = ["Project images retrieved successfully.",
            "Some error occurred while retrieving images.",
            "No project images have been saved yet.",
            "Something went wrong while finding a project images!"]
        projectImagesReduced = removeSuccessMessage(projectImagesReduced, projectImageServerMessages);

        let numOfProjects = projectSummariesReduced.length;
        let NumOfProjectsToDisplay = "";

        if (!isLoadedProjSums)
        {
            setShowProjects(<div>Loading...</div>);
        }
        else if (isLoadedProjSums && projSumMsgFromServer === "Project summaries retrieved successfully.")
        {
            let projects;
            let numOfImagesObj = {};
            let numOfDocsObj = {};
            let blueColour = '#1200ff';

            if (numOfProjects > 0)
            {
                NumOfProjectsToDisplay = (<div className="mb-2 col-6 col-md-2 me-1">
                    Total Projects: <b>{numOfProjects}</b> <br />
                    Inactive projects: <span style={showInactive ? { color: "red" } : { color: "black" }}>{showInactive ? "Red" : "Hidden"}</span>
                </div>);
            }
            else if (numOfProjects === 0)
            {
                NumOfProjectsToDisplay = (<div className="mb-2 col-6 col-md-2 me-1 redText">No projects saved yet.</div>);
            }

            setDisplayNumOfProjects(NumOfProjectsToDisplay);

            // Number of images
            projectImagesReduced.forEach((image, i) =>
            {
                let urls = [];
                { image.imageUrlArray !== undefined && image.imageUrlArray.length > 0 ? urls = image.imageUrlArray : urls = [] };

                let numOfPics = 0;
                { urls.length > 0 ? numOfPics = urls.length : numOfPics = 0 }

                if (numOfPics > 0)
                {
                    numOfImagesObj[image.proj_id] = numOfPics;
                }
            });

            // Number of documents
            projectDocsReduced.forEach((doc, i) =>
            {
                let tempDocUrls = [];
                let numOfDocs = 0;

                if (doc.docArray !== undefined)
                {
                    doc.docArray.forEach((doc, index) =>
                    {
                        tempDocUrls.push(doc.url);
                    });
                }
                else
                {
                    doc.docUrlArray !== undefined && doc.docUrlArray.length > 0 ? tempDocUrls = doc.docUrlArray : tempDocUrls = [];
                }

                tempDocUrls.length > 0 ? numOfDocs = tempDocUrls.length : numOfDocs = 0;

                if (numOfDocs > 0)
                {
                    numOfDocsObj[doc.proj_id] = numOfDocs;
                }
            });

            // Filters
            let filteredProjectSums = [];
            filteredProjectSums = chooseFilter(projectSummariesReduced, "", projStatusFilter, "proj_status");
            filteredProjectSums = chooseFilter(filteredProjectSums, -1, isActiveFilter, "isActive");
            filteredProjectSums = chooseFilterMedia(filteredProjectSums, numOfImagesObj, "", imagesFilter, "images", "no images", "project");
            filteredProjectSums = chooseFilterMedia(filteredProjectSums, numOfDocsObj, "", docsFilter, "docs", "no docs", "project");
            filteredProjectSums = filteredProjectSums.filter(project => project.proj_descrip !== undefined ? project.proj_descrip.includes(searchQuery) : null);
            filteredProjectSums = chooseFilterWard(filteredProjectSums, "", wardSearchQuery, "ward");
            filteredProjectSums = filterDatesChosen === true ? filterDates(filteredProjectSums) : filteredProjectSums;

            projects = (
                <div className="border border-dark mb-4 table-responsive">
                    <Table className="mb-1 table table-stripy">
                        <thead>
                            <tr className="col-lg-12 col-md-12 col-sm-12 table-light">
                                <th>ID</th>
                                <th>Ward</th>
                                <th>Added by</th>
                                <th>Added</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Images</th>
                                <th>Docs</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredProjectSums.length > 0 ?
                                    filteredProjectSums.map((item, index) => (
                                        // (showInactive || (!showInactive && item.isActive === 1)) ?
                                        <tr key={item.proj_id + "a"} style={item.isActive === 0 ? { color: "red" } : null}>
                                            <td>{item.proj_id}</td>
                                            <td>{item.ward}</td>
                                            <td>{item.added_by_user}</td>
                                            <td>{new Date(item.added_timestamp).toString().replace(" GMT+0200 (South Africa Standard Time)", "")}</td>
                                            <td>
                                                {item.proj_descrip !== "" && item.proj_descrip !== undefined
                                                    ? item.proj_descrip.length > 55
                                                        ? item.proj_descrip.substr(0, 55) + "..."
                                                        : item.proj_descrip
                                                    : ""}
                                            </td>
                                            <td
                                                style={item.proj_status === "Complete" && item.isActive !== 0
                                                    ? { color: blueColour }
                                                    : item.proj_status === "Complete" && item.isActive === 0 ?
                                                        { color: "red" }
                                                        : null}>
                                                {item.proj_status}
                                            </td>
                                            <td>
                                                {numOfImagesObj[item.proj_id] !== undefined ? numOfImagesObj[item.proj_id] : "0"}
                                            </td>
                                            <td>
                                                {numOfDocsObj[item.proj_id] !== undefined ? numOfDocsObj[item.proj_id] : "0"}
                                            </td>
                                            <td>
                                                <Button
                                                    className="py-1 me-1 mb-1"
                                                    color="primary"
                                                    onClick={() =>
                                                    {
                                                        handleViewClicked(Number(item.proj_id));
                                                    }}>
                                                    View
                                                </Button>

                                                {(thisUser === item.added_by_user && item.proj_status !== "Complete") || isAdmin
                                                    ? <Button className="py-1 mb-1" color="primary" onClick={() => editAProject(item.proj_id)}>Edit</Button>
                                                    : null
                                                }
                                            </td>
                                        </tr>
                                    ))

                                    : <tr><td className="redText" colSpan="8">&nbsp;No projects match those filter criteria.</td></tr>
                            }
                        </tbody>
                    </Table>
                </div>
            );

            setShowProjects(projects);
        }
        else if (isLoadedProjSums && projSumMsgFromServer === "Something went wrong while finding project summary data!")
        {
            setShowProjects(<div className="redText">An error occurred while retrieving projects. Please refresh the page.</div>);
        }
        else if (isLoadedProjSums && projSumMsgFromServer === "No projects returned for ward" && (userWards.length > 0)) 
        {
            setShowProjects(<div className="redText">No projects in your ward/s.</div>);
        }
        else if (isLoadedProjSums && projSumMsgFromServer === "No projects returned for ward" && (userWards.length === 0)) 
        {
            setShowProjects(<div className="redText">Please contact your administrator, as you have not yet been assigned to a specific ward.</div>);
        }
    }

    const resetDateFilters = () =>
    {
        setFilterDatesChosen(false);
        setEndDateFilter("");
        setStartDateFilter("");
    }

    const resetMainFilters = () =>
    {
        setProjStatusFilter("");
        setIsActiveFilter(-1);
        setImagesFilter("");
        setDocsFilter("");
        setSearchQuery("");
        setWardSearchQuery("");
        let elementRef = document.getElementById("wardSearchId");
        elementRef.value = "";
    }

    const loadFilters = () =>
    {
        setDisplayFilters(
            <Form className="p-0 m-0">
                <Row className="d-flex justify-content-end align-items-center me-2 mb-2">
                    <div className="col-12 row d-flex align-items-center mx-0 mb-2 p-0">
                        <div>Find project started between these dates</div>
                        <div className="col-12 col-md-3 mb-1">
                            <Col>
                                <Input
                                    name="start_date_filter"
                                    type="date"
                                    value={startDateFilter || ''}
                                    onChange={(e) => { setStartDateFilter(e.target.value) }}
                                />
                            </Col>
                        </div>
                        <div className="col-12 col-md-3 mb-1">
                            <Col>
                                <Input
                                    name="end_date_filter"
                                    type="date"
                                    value={endDateFilter || ''}
                                    onChange={(e) => { setEndDateFilter(e.target.value) }}
                                />
                            </Col>
                        </div>
                        {/* <Col className="col-12 col-md-1 mb-1"> */}
                        <div className="row col-12 col-md-6 d-flex align-items-center ms-0">
                            <Button className="col-4 col-md-2 py-1 me-1 mb-1 ms-1" color="primary" onClick={() => setFilterDatesChosen(true)}>Find</Button>
                            <Button className="col-4 col-md-2 py-1 me-1 mb-1" color="primary" onClick={() => resetDateFilters()}>Reset</Button>
                        </div>
                        {/* </Col> */}
                    </div>

                    <div className="col-12 row m-0 p-0 d-flex align-items-center">
                        <Col className="col-12 col-md-2 mb-1">
                            <Input
                                name="ward_search"
                                placeholder="Search ward number"
                                type="text"
                                id="wardSearchId"
                                // value={wardSearchQuery}
                                onChange={(e) => setWardSearchQuery(e.target.value)}
                            />
                        </Col>

                        <Col className="col-12 col-md-3 mb-1">
                            <Input
                                name="search_query"
                                placeholder="Search description"
                                type="text"
                                value={searchQuery || ''}
                                onChange={(e) => { setSearchQuery(e.target.value) }}
                            />
                        </Col>

                        <Col className="col-12 col-md-1 mb-1">
                            <Input
                                onChange={(e) => { setProjStatusFilter(e.target.value) }}
                                name="proj_status_filter"
                                type="select"
                                value={projStatusFilter}
                            >
                                <option key="a" value="" className="py-2" disabled>Status</option>
                                {projStatusFilterOptions.map((option, index) => (
                                    <option key={index} value={option.value} className="py-2 px-1">{option.label}</option>
                                ))}
                            </Input>
                        </Col>

                        <Col className="col-12 col-md-1 mb-1">
                            <Input
                                onChange={(e) => { setIsActiveFilter(Number(e.target.value)) }}
                                name="isActive_filter"
                                type="select"
                                value={isActiveFilter}
                            > <option key="b" value={-1} className="py-2" disabled>Active</option>
                                {isActiveFilterOptions.map((option, index) => (
                                    <option key={index} value={option.value} className="py-2">{option.label}</option>
                                ))}
                            </Input>
                        </Col>

                        <Col className="col-12 col-md-1 mb-1">
                            <Input
                                onChange={(e) => { setImagesFilter(e.target.value) }}
                                name="images_filter"
                                type="select"
                                value={imagesFilter}
                            > <option key="b" value="" className="py-2" disabled>Images</option>
                                {imagesFilterOptions.map((option, index) => (
                                    <option key={index} value={option.value} className="py-2">{option.label}</option>
                                ))}
                            </Input>
                        </Col>

                        <Col className="col-12 col-md-2 mb-1">
                            <Input
                                onChange={(e) => { setDocsFilter(e.target.value) }}
                                name="docs_filter"
                                type="select"
                                value={docsFilter}
                            > <option key="b" value="" className="py-2" disabled>Documents</option>
                                {docsFilterOptions.map((option, index) => (
                                    <option key={index} value={option.value} className="py-2">{option.label}</option>
                                ))}
                            </Input>
                        </Col>

                        {/* <Col className="col-12 col-md-1 mb-1"> */}
                        <Button className="col-4 col-md-1 py-1 me-1 mb-1 ms-3" color="primary" onClick={() => resetMainFilters()}>Reset</Button>
                        {/* </Col> */}
                    </div>
                </Row>
            </Form>
        );
    }

    useEffect(() =>
    {
        if (singleProjectId !== 0 && activeTab === "4")
        {
            fetchAProject(singleProjectId, setSingleProjectArray, setProjectMsgFromServer);
        }
    }, [singleProjectId]);

    useEffect(() =>
    {
        if (isLoadedProjSums && projectSumArray.length > 0)
        {
            loadFilters();
            sortProjects();
        }
    }, [projectSumArray,
        isLoadedProjSums,
        showInactive,
        projStatusFilter,
        isActiveFilter,
        imagesFilter,
        docsFilter,
        searchQuery,
        startDateFilter,
        endDateFilter,
        filterDatesChosen,
        wardSearchQuery
    ]);

    useEffect(() =>
    {
        if (showFilters)
        {
            setShowArrow(false);
        }
        else
        {
            setShowArrow(true);
        }

    }, [showFilters]);

    return (
        <div>
            {/* <p>isAdmin: {isAdmin.toString()}, user ward: {typeof userWard}, isLoadedProjSums: {isLoadedProjSums.toString()}</p> */}
            {/* <p>isActiveFilter: {isActiveFilter}</p> */}
            {/* <p>ward search query: {wardSearchQuery}</p> */}

            {projectSumArray.length > 0
                ? <>
                    <div className="row d-flex justify-content-between align-items-center">
                        {displayNumOfProjects}
                        <Button
                            className="me-3 col-5 col-md-1 mb-1"
                            color={showFilters ? "secondary" : "primary"}
                            onClick={() => toggleShowFilters()}>
                            {showFilters ? "Hide filters" : "Show filters"}
                        </Button>
                    </div>

                    {showFilters ? displayFilters : null}
                    {showProjects}
                </>
                : <p className="redText">No projects saved yet.</p>}
        </div>
    )
}

export default ViewProjectSum;

ViewProjectSum.propTypes = {
    projectSumArray: PropTypes.array,
    projSumMsgFromServer: PropTypes.string,
    // docsMsgFromServer: PropTypes.string,
    // imgMsgFromServer: PropTypes.string,
    setSingleProjectArray: PropTypes.func,
    setProjectMsgFromServer: PropTypes.func,
    setActiveTab: PropTypes.func,
    setSingleProjectId: PropTypes.func,
    singleProjectId: PropTypes.number,
    projectImagesArray: PropTypes.array,
    projectDocsArray: PropTypes.array,
    isAdmin: PropTypes.bool,
    setProjectId: PropTypes.func,
    //setProjectIndex: PropTypes.func,
    isLoadedProjSums: PropTypes.bool,
    setEditPgDropdownValue: PropTypes.func,
    // singleViewPgDropdownValue: PropTypes.number,
    setSingleViewPgDropdownValue: PropTypes.func,
    userInfo: PropTypes.object,
    activeTab: PropTypes.string,
    setShowArrow: PropTypes.func,
}