import React from 'react';
import PropTypes from 'prop-types';
import
{
    Col, Button, Form, FormGroup, Label, Input
} from "reactstrap";
import { useState, useEffect } from "react";
import { post } from "../../helpers/api_helper";
import { API_URL } from "../../helpers/ApiUrl";
import swal from "sweetalert";
import resizeImage from '../SharedFiles/resizeImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ".../../assets/styles.css";
import postInfo from 'pages/SharedFiles/postInfo';
import changePhotoHandler from '../SharedFiles/changePhotoHandler';
import { addWardRow, handleWards, removeWard } from "../SharedFiles/WardHelpers";
import { payIntervalOptions } from "../SharedFiles/listData";

const AddSponsor = (props) =>
{
    // Misc hooks
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [isLoading, setIsLoading] = useState(false);

    // Image hooks
    const [imgObjects, setImgObjects] = useState([]);
    const [imgUrls, setImgUrls] = useState([]);

    // Ward hooks
    const [wardArray, setWardArray] = useState([]);
    const [wardRows, setWardRows] = useState(1);
    const [wardIndex, setWardIndex] = useState(0);
    //const [wardArrayLoaded, setWardArrayLoaded] = useState(false);

    // Props
    let setIsLoaded = props.setIsLoaded;
    let setActiveTab = props.setActiveTab;
    let toggleSponsorsChanged = props.toggleSponsorsChanged;
    let currentUser = props.currentUser;
    let activeTab = props.activeTab;

    const cancelAddSponsor = () =>
    {
        setInputs({});
        setImgObjects([]);
        setImgUrls([]);
        document.forms["addSponsor"].reset();
        window.location.href = "#top";
        if (activeTab === "2")
        {
            setActiveTab("1");
        }
    }

    const removeImage = (e, index) => 
    {
        let tempImgObjects = imgObjects;
        tempImgObjects.splice(index, 1);
        setImgObjects(tempImgObjects);

        let tempImgUrls = imgUrls;
        tempImgUrls.splice(index, 1);
        if (tempImgUrls.length === 0)
        {
            setImgUrls([]);
        }
        else
        {
            setImgUrls(tempImgUrls);
        }
    }

    const handleSubmitPhoto = async (e) =>
    {
        setIsLoading(true);

        if (imgUrls.length === 0)
        {
            console.log("No images to upload.");
            return handleSubmit("no images");
        }

        const formData = new FormData();

        for (const files of imgObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };

            const resizedImage = await resizeImage(config);
            formData.append(resizedImage.size, resizedImage);
        }

        postInfo(formData, "uploadsponsorlogo", "POST", handleSubmit, "");
    };

    const handleSubmit = async (imgUploadMessage) =>
    {
        let sponsorObject = {};
        let imgUrl = "";

        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no images")
        {
            let imagePathsArray = Object.keys(imgUploadMessage);
            let imageUploadStatusArray = Object.values(imgUploadMessage);

            imagePathsArray.forEach((img, i) =>
            {
                if (imageUploadStatusArray[i] === "Success")
                {
                    imgUrl = `${API_URL}/${img}`;
                }
            });

            sponsorObject["logo_url"] = imgUrl;
        }
        else
        {
            sponsorObject["logo_url"] = "";
        }

        if (inputs.link === undefined)
        {
            sponsorObject["link"] = "";
        }

        sponsorObject["current_user"] = currentUser;

        if (inputs.isActive === undefined)
        {
            sponsorObject["isActive"] = 1;
        }

        sponsorObject["added_by_user"] = currentUser;

        if (Object.keys(inputs).length !== 0 && inputs.constructor === Object)
        {
            Object.assign(sponsorObject, inputs);
            //alert(JSON.stringify(sponsorObject, null, 4));

            try
            {
                const response = await post("/addsponsor", sponsorObject);

                if (response.message === "New sponsor has been added to database.")
                {
                    setIsLoading(false);
                    console.log(response.message);
                    setInputs({});
                    setImgObjects([]);
                    setImgUrls([]);
                    document.forms["addSponsor"].reset();
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });

                    setIsLoaded(false);
                    toggleSponsorsChanged();
                    swal("Success", "Sponsor has been added!", "success");
                }
                else
                {
                    setIsLoading(false);
                    console.log("Something went wrong while adding sponsor to db.");
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                    swal("Error", "Something went wrong. Please try again.", "error");
                }
            }
            catch (error)
            {
                setIsLoading(false);
                console.log("This is the try catch error msg for adding new sponsor: " + error);
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                swal("Error", "Something went wrong. Please try again.", "error");
            }
        }
        else
        {
            setIsLoading(false);
            swal("Error", "Nothing filled in!", "error");
        }
    }

    const addWardRw = (e) =>
    {
        addWardRow(wardArray, wardIndex, wardRows, setWardIndex, setWardRows);
    }

    const removeWd = index =>
    {
        removeWard(wardRows, setWardRows, setWardIndex, index, wardArray, setWardArray, inputs, setInputs);
    }

    const handleWds = (ward, index) =>
    {
        handleWards(index, ward, wardArray, setWardArray, setInputs);
    }

    let wardDivArray = [];
    let wardRow;

    for (let i = 0; i < wardRows; ++i)
    {
        wardRow = (
            <div key={i} className='row col-12 mb-1'>
                <Col className="col-10 col-md-11 ">
                    <Input
                        id="wards"
                        name="wards"
                        type="number"
                        onChange={(e) =>
                        { handleWds(e.target.value, i) }}
                        value={wardArray[i] || ''}
                        onWheel={(e) => e.target.blur()}
                    />
                </Col>
                <Col className="col-1">
                    <Button color="danger" onClick={() => removeWd(i)}>
                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                    </Button>
                </Col>
            </div>);

        wardDivArray.push(wardRow);
    }

    useEffect(() =>
    {
        if (activeTab !== "2")
        {
            cancelAddSponsor();
        }
    }, [activeTab]);

    return (
        <div id="top" >
            {/* <div>
                Inputs: <pre>{JSON.stringify(inputs)}</pre>
                imgurls: {JSON.stringify(imgUrls)}
            </div> */}
            <Form
                name="addSponsor"
                onSubmit={(e) => { handleSubmitPhoto(); e.preventDefault(); }}
                autoComplete="off">

                <FormGroup row className="mb-1" value={inputs.isActive || ''}>
                    <Label
                        for="isActive"
                        sm={1}
                    >
                        Is Active
                    </Label>
                    <Col sm={4}>
                        <Button color={inputs.isActive === undefined
                            ? "secondary"
                            : inputs.isActive === "1"
                                ? "success"
                                : "secondary"} className='me-1' name="isActive" onClick={(e) => { handleChange(e); }} value={1}>
                            Yes
                        </Button>
                        <Button color={inputs.isActive === undefined
                            ? "secondary"
                            : inputs.isActive === "0"
                                ? "danger"
                                : "secondary"} className='me-1' name="isActive" onClick={(e) => { handleChange(e); }} value={0}>
                            No
                        </Button>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="company_name"
                        sm={1}
                    >
                        Trading name <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="company_name"
                            placeholder="..."
                            type="text"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row >
                    <Col className="col-12">
                        <Label
                            for="wards"
                        >
                            Wards
                        </Label>
                    </Col>
                    {wardDivArray}
                    <div>
                        <Button className='me-1 mb-2' onClick={addWardRw}>
                            Add
                        </Button>
                    </div>

                </FormGroup>

                <FormGroup row>
                    <Label
                        for="link"
                        sm={1}
                    >
                        Website
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="link"
                            type="text"
                            placeholder="Website address or link to Google maps page for company"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="logo_url"
                        sm={1}
                    >
                        Upload logo
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            id="logo_url"
                            name="logo_url"
                            type="file"
                            onChange={(e) => changePhotoHandler(e, "", setImgObjects, setImgUrls, "")}
                        />
                    </Col>
                </FormGroup>

                {/* New user image section */}
                {
                    imgUrls.length > 0
                        ? <><div className='p-2 mb-1 row'>
                            {imgUrls.map((url, index) => (
                                <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                    <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                    <Button className="position-absolute top-0 translate-middle-y"
                                        style={{ right: "0.05rem" }} color="danger"
                                        onClick={(e) => removeImage(e, index)}>
                                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                    </Button>
                                </div>
                            ))}
                        </div>
                            <hr /></>
                        : null}

                <FormGroup row>
                    <Label
                        for="tel"
                        sm={1}
                    >
                        Telephone
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="tel"
                            placeholder="..."
                            type="tel"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="email"
                        sm={1}
                    >
                        Email
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="email"
                            placeholder="..."
                            type="email"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="phys_address"
                        sm={1}
                    >
                        Physical Address
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="phys_address"
                            placeholder="..."
                            type="textarea"
                            rows="4"
                            cols="50"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="post_address"
                        sm={1}
                    >
                        Postal Address
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="post_address"
                            placeholder="..."
                            type="textarea"
                            rows="4"
                            cols="50"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="reg_name"
                        sm={1}
                    >
                        Registered name
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="reg_name"
                            placeholder="..."
                            type="text"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="reg_num"
                        sm={1}
                    >
                        Registration Number
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="reg_num"
                            placeholder="..."
                            type="text"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="vat_num"
                        sm={1}
                    >
                        VAT Number
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="vat_num"
                            placeholder="..."
                            type="number"
                            onChange={(e) => handleChange(e)}
                            onWheel={(e) => e.target.blur()}
                        />
                    </Col>
                </FormGroup>

                <div className='col-12' >Banking Details <hr /></div>

                <FormGroup row>
                    <Label
                        for="bank_name"
                        sm={1}
                    >
                        Bank name
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="bank_name"
                            placeholder="..."
                            type="text"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="branch_name"
                        sm={1}
                    >
                        Branch name
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="branch_name"
                            placeholder="..."
                            type="text"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="branch_code"
                        sm={1}
                    >
                        Branch code
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="branch_code"
                            placeholder="..."
                            type="number"
                            onChange={(e) => handleChange(e)}
                            onWheel={(e) => e.target.blur()}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="acc_name"
                        sm={1}
                    >
                        Account name
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="acc_name"
                            placeholder="..."
                            type="text"
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="acc_num"
                        sm={1}
                    >
                        Account number
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="acc_num"
                            placeholder="..."
                            type="number"
                            onChange={(e) => handleChange(e)}
                            onWheel={(e) => e.target.blur()}
                        />
                    </Col>
                </FormGroup>

                <div className='col-12' >Payment Details <hr /></div>

                <FormGroup row>
                    <Label
                        for="pay_interval"
                        sm={1}
                    >
                        Payment interval
                    </Label>
                    <Col className="col-md-11">
                        <Input
                            name="pay_interval"
                            type="select"
                            onChange={(e) => handleChange(e)}
                        >
                            {payIntervalOptions.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup
                    check
                    row
                >
                    <Col
                        sm={{
                            offset: 0,
                            size: 12
                        }}
                    >
                        <Button color="primary" disabled={isLoading} type="submit">
                            {isLoading ? 'Submitting…' : 'Submit'}
                        </Button>
                        <Button className="ms-1" color="danger"
                            onClick={() => cancelAddSponsor()}
                        >
                            Cancel
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        </div >
    );
}

export default AddSponsor;

AddSponsor.propTypes = {
    setIsLoaded: PropTypes.func,
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
    toggleSponsorsChanged: PropTypes.func,
    currentUser: PropTypes.string,
}