import React from 'react';
import PropTypes from 'prop-types';
import
{
    Col, Button, Form, FormGroup, Label, Input
} from "reactstrap";
import { useState } from "react";
import { post } from "../../helpers/api_helper";
import { API_URL } from "../../helpers/ApiUrl";
import swal from "sweetalert";
import resizeImage from '../SharedFiles/resizeImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import postInfo from '../SharedFiles/postInfo';
import ".../../assets/styles.css";
import changePhotoHandler from '../SharedFiles/changePhotoHandler';

const UserForm = (props) =>
{
    // Misc hooks
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [isLoading, setIsLoading] = useState(false);

    // Image hooks
    const [imgObjects, setImgObjects] = useState([]);
    const [imgUrls, setImgUrls] = useState([]);
    const [markedImages, setMarkedImages] = useState([]);

    // Props
    let isLoaded = props.isLoaded;
    let userInfo = props.userInfo;
    let userData = props.userData;
    let toggleUserInfoChanged = props.toggleUserInfoChanged;

    const cancelAddUserInfo = () =>
    {
        setInputs({});
        setImgObjects([]);
        setImgUrls([]);
        setMarkedImages([]);
        document.forms["userProfile"].reset();
        window.location.href = "#top";
    }

    const removeImage = (e) => 
    {
        setImgObjects([]);
        setImgUrls([]);

        let tempImgUrls = [];
        if (userData.image_url !== undefined)
        {
            tempImgUrls = JSON.parse(JSON.stringify(userData.image_url));
        }

        // Existing mages marked for deletion (red text)
        let imgsMarkedForDeletion = [];
        imgsMarkedForDeletion.push(tempImgUrls[0]);
        setMarkedImages(imgsMarkedForDeletion);
    }

    const handleSubmit = async (imgUploadMessage) =>
    {
        let imgUrlArray = [];
        let myObject = {};

        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no images")
        {
            let imagePathsArray = Object.keys(imgUploadMessage);
            let imageUploadStatusArray = Object.values(imgUploadMessage);

            imagePathsArray.forEach((img, i) =>
            {
                if (imageUploadStatusArray[i] === "Success")
                {
                    let fullPath = `${API_URL}/${img}`;
                    imgUrlArray.push(fullPath);
                }
            });

            myObject["image_url"] = imgUrlArray;
        }

        if (markedImages.length > 0 && imgUrlArray.length === 0)
        {
            myObject["image_url"] = [];
        }

        if (Object.keys(inputs).length === 0 && Object.keys(myObject).length === 0)
        {
            swal("Error", "Nothing changed.", "error");
            setIsLoading(false);
        }
        else
        {
            myObject["updated_by_user"] = userInfo.username;
            myObject["_id"] = userInfo._id;
            myObject["uid"] = userInfo.uid;

            if (Object.keys(inputs).length !== 0)
            {
                Object.assign(myObject, inputs);
            }

            const response = await post("postupdateuser", myObject);

            if (response.message === "User info updated successfully.")
            {
                setIsLoading(false);
                console.log(response.message);
                setInputs({});
                setImgObjects([]);
                setImgUrls([]);
                setMarkedImages([]);
                document.forms["userProfile"].reset();
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                toggleUserInfoChanged();
                swal("Success", "User profile info has been added.", "success");
            }
            else
            {
                setIsLoading(false);
                console.log("Something went wrong while adding user profile info.");
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                swal("Error", "Something went wrong. Please try again.", "error");
            }
        }
    }

    const handleSubmitPhoto = async (e) =>
    {
        setIsLoading(true);

        if (imgUrls.length === 0)
        {
            console.log("No images to upload.");
            return handleSubmit("no images");
        }

        const formData = new FormData();

        for (const files of imgObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };

            const resizedImage = await resizeImage(config);
            formData.append(resizedImage.size, resizedImage);
        }

        postInfo(formData, "userimageupload", "POST", handleSubmit, "");
    };

    return (
        <div id="top" >
            {/* <p>Inputs: {JSON.stringify(inputs)}</p> */}
            {isLoaded
                ? <Form
                    name="userProfile"
                    onSubmit={(e) => { handleSubmitPhoto(); e.preventDefault(); }}
                    autoComplete="off">
                    <Button className="ms-1 mb-3" color="primary" onClick={() => window.location.href = "/forgot-password"}>
                        Reset password
                    </Button>
                    <FormGroup row>
                        <Label
                            for="name"
                            sm={1}
                        >
                            Name
                        </Label>
                        <Col className="col-sm-7 col-md-11">
                            <Input
                                name="name"
                                placeholder="Enter your first name"
                                type="text"
                                value={inputs.name === undefined
                                    ? userData.name
                                    : inputs.name !== undefined
                                        ? inputs.name
                                        : ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label
                            for="surname"
                            sm={1}
                        >
                            Surname
                        </Label>
                        <Col className="col-sm-7 col-md-11">
                            <Input
                                name="surname"
                                placeholder="Enter your surname"
                                type="text"
                                value={inputs.surname === undefined
                                    ? userData.surname
                                    : inputs.surname !== undefined
                                        ? inputs.surname
                                        : ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label
                            for="content"
                            sm={1}
                        >
                            Short Bio
                        </Label>
                        <Col className="col-sm-7 col-md-11">
                            <Input
                                name="bio"
                                placeholder="Write a few short sentences about yourself"
                                type="textarea"
                                rows="5"
                                value={inputs.bio === undefined
                                    ? userData.bio
                                    : inputs.bio !== undefined
                                        ? inputs.bio
                                        : ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="user_image"
                            sm={2}
                        >
                            Upload/Update User Image
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="photoUpload"
                                name="user_image"
                                type="file"
                                onChange={(e) => changePhotoHandler(e, "", setImgObjects, setImgUrls, "")}
                            />
                        </Col>
                    </FormGroup>

                    <div className='m-2 row'>

                        {/* New user image section */}
                        {
                            imgUrls.length > 0
                                ? imgUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-2 mt-2 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                    </div>
                                ))
                                : null
                        }
                        {imgUrls.length > 0
                            ? <p className="ms-1 mb-4" style={{ color: "blue" }}>New/Updated user image</p>
                            : null}

                        {/* Existing user image section */}
                        {
                            (userData.image_url !== undefined && userData.image_url.length !== 0)
                                && imgUrls.length === 0
                                && markedImages.length === 0
                                ?
                                <div key="c" className="mx-1 mb-2 mt-2 col-12 col-sm-3">
                                    <img key="a" className="col-12 p-0 m-0" src={userData.image_url[0]} alt="img" />
                                    <Button className="position-absolute top-0 translate-middle-y"
                                        style={{ right: "0.05rem" }} color="danger" onClick={(e) => removeImage(e)}>
                                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                    </Button>
                                </div>
                                : null
                        }
                        {(userData.image_url !== undefined && userData.image_url.length !== 0)
                            && imgUrls.length === 0
                            && markedImages.length === 0
                            ? <p className="ms-1 mb-4" style={{ color: "blue" }}>Current user image</p>
                            : null}

                        {/* Image marked for deletion section */}
                        {
                            markedImages.length > 0
                                ? markedImages.map((url, index) => (
                                    <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button color="danger" className="col-12" disabled
                                        >
                                            Marked for Deletion
                                        </Button>
                                    </div>
                                ))
                                : null
                        }
                    </div>
                    {/* <div>userdata length: {userData.length}, imgurls length: {imgUrls.length}, markedImages: {markedImages.length}</div> */}

                    <FormGroup
                        check
                        row
                    >
                        <Col
                            sm={{
                                offset: 0,
                                size: 12
                            }}
                        >
                            <Button color="primary" disabled={isLoading} type="submit">
                                {isLoading ? 'Submitting…' : 'Submit'}
                            </Button>
                            <Button className="ms-1" color="danger"
                                onClick={() => cancelAddUserInfo()}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
                : null}
        </div>
    );
}

export default UserForm;

UserForm.propTypes = {
    isLoaded: PropTypes.bool,
    userInfo: PropTypes.object,
    userData: PropTypes.object,
    toggleUserInfoChanged: PropTypes.func,
}