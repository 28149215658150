import useCrypto from "../SharedFiles/useCrypto";

async function CreateUser(email)
{
    let loginsArray = [];
    let emailAddress = email;
    let user = {};

    if (sessionStorage.getItem("loginsArray") !== undefined)
    {
        loginsArray = useCrypto("decrypt", sessionStorage.getItem("loginsArray"));
        user = loginsArray.find(item => item.email === emailAddress);

        let encryptedUser = useCrypto("encrypt", JSON.stringify(user));
        localStorage.setItem("authUser", encryptedUser);
    }

    return localStorage.getItem("authUser");
}

export default CreateUser;