import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import '../../assets/styles.css';

const SideArrow = (props) =>
{
    // Props
    let showArrow = props.showArrow;

    return (
        <div className="arrow">
            {showArrow ?
                <FontAwesomeIcon icon={faChevronCircleRight} className="arrow-position arrow-style" />
                : null}
        </div>
    );
};
export default SideArrow;

SideArrow.propTypes = {
    showArrow: PropTypes.bool,
    projectChosen: PropTypes.bool,
}