import React from "react";
import
{
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import MetaTags from "react-meta-tags";
import { useState, useEffect } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { decryptUser } from "../SharedFiles/decryptUser";
import FindCouncillor from "./FindCouncillor";
//import GetKey from "../SharedFiles/GetKey";
import loadKey from "../SharedFiles/loadKey";
import SideArrow from "../SharedFiles/SideArrow";

function Councillors()
{
    const [activeTab1, setactiveTab1] = useState("1");
    const [showPage, setShowPage] = useState(false);
    const [placesKey, setPlacesKey] = useState("");
    const [keyLoaded, setKeyLoaded] = useState(false);
    const [showArrow, setShowArrow] = useState(false);

    const toggle1 = tab =>
    {
        if (activeTab1 !== tab)
        {
            setactiveTab1(tab);
        }
    };

    // const loadKey = async () =>
    // {
    //     if (!keyLoaded)
    //     {
    //         try
    //         {
    //             let key = await GetKey();
    //             //console.log("Google api key is: " + key);
    //             setPlacesKey(key);
    //             setKeyLoaded(true);
    //         }
    //         catch (error)
    //         {
    //             console.log("Error: Failed to fetch Google api key.");
    //         }
    //     }
    // }

    useEffect(() =>
    {
        if (!keyLoaded)
        {
            loadKey(setPlacesKey, setKeyLoaded);
        }
    }, []);

    useEffect(() =>
    {
        let authUser = decryptUser();

        if (authUser !== undefined)
        {
            if (authUser.role !== "admin" && authUser.role !== "clo")
            {
                setShowPage(false);
            }
            else
            {
                setShowPage(true);
            }
        }
    }, []);

    return (<React.Fragment>
        <div className="page-content" id="top">
            <MetaTags>
                <title>Ward Councillors - CMS</title>
            </MetaTags>
            <Breadcrumb title="Home" breadcrumbItem="Councillor" />
            {showPage ?
                <>
                    <Row className="mb-0">
                        <Col className="col-12 col-sm-12 col-md-6">
                            <Nav pills className="icon-tab nav-justified">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", color: "secondary" }}
                                        className={classnames({
                                            active: activeTab1 === "1",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle1("1");
                                        }}
                                    >
                                        Ward Councillor Details
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col className="col-12 col-sm-6 col-md-12">
                            <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                <TabPane tabId="1">
                                    {keyLoaded ?
                                        <FindCouncillor placesKey={placesKey} setShowArrow={setShowArrow} />
                                        : null}
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </>
                : null}
        </div>
        <SideArrow showArrow={showArrow} />
    </React.Fragment>
    );
}

export default Councillors;