import { API_URL } from "../../helpers/ApiUrl";

const fetchWardProjects = async (endpoint, idOrWard, setArray, setMsgFromServer, setIsLoaded, username, onlyActive) =>
{
    let postBody = {};

    if (username !== undefined && onlyActive === undefined)
    {
        postBody = { ward: idOrWard, order: 1, onlyActive: false, username: username };
    }
    else if (username !== undefined && onlyActive === true)
    {
        postBody = { ward: idOrWard, order: 1, onlyActive: true, username: username };
    }
    else if (username === undefined)
    {
        postBody = { ward: idOrWard, order: 1, onlyActive: false };
    }

    await fetch(
        // `${API_URL}/getprojectdetail`,
        `${API_URL}/${endpoint}`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postBody),
        }
    )
        .then((response) => response.json())
        .then((result) =>
        {
            if (result.message === "No projects in that ward.")
            {
                setMsgFromServer(result.message);
                setArray([]);
                setIsLoaded(true);
            }
            else
            {
                let array = JSON.parse(result.message);
                let lastArrayItem = array.length;
                let msgFromServer = array[lastArrayItem - 1];
                setMsgFromServer(msgFromServer);
                setArray(array);
                setIsLoaded(true);
            }
        })
        .catch((error) =>
        {
            console.error('Error:', error.message);
            setMsgFromServer("Caught error");
        });
}

export default fetchWardProjects;