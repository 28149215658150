import swal from "sweetalert";

export const handleStaffType = (type, index, staffTypes, setInputs, setStaffTypes) =>
{
    let array = [];
    if (type !== undefined)
    {
        if (staffTypes.length === 0)
        {
            array[index] = type;
            setInputs(prevState => ({ ...prevState, ["contract_core_staff_types"]: array }));
            setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: array.length }));
            setStaffTypes(array);
        }
        else
        {
            array = staffTypes;
            array[index] = type;
            setStaffTypes(array);
            setInputs(prevState => ({ ...prevState, ["contract_core_staff_types"]: array }));
            setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: array.length }));
        }
    }
}

export const handleStaffArray = (name, index, staffTypes, staffArray, staffObject, setInputs, setStaffObject, setStaffArray, staffRows) =>
{
    let tempArray = [];
    let object = {};
    let personType = staffTypes[index];
    let count = 0;

    if (staffRows > 1)
    {
        staffArray.forEach((staffname, index) =>
        {
            if (staffname === name)
            {
                ++count;
            }
        });
    }

    if (Object.keys(staffObject).length === 0 && name !== undefined)
    {
        object[name] = personType;
        let length = Object.keys(object).length;
        setInputs(prevState => ({ ...prevState, ["contract_core_staff"]: object }));
        setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: length }));
        setStaffObject(object);
    }
    else if (Object.keys(staffObject).length === index)
    {
        object = staffObject;
        object[name] = personType;
        let length = Object.keys(object).length;
        setInputs(prevState => ({ ...prevState, ["contract_core_staff"]: object }));
        setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: length }));
        setStaffObject(prevState => ({ ...prevState, [staffArray[index]]: personType }));
    }
    else
    {
        if (count === 0 && staffArray[index] !== undefined && name !== undefined)
        {
            object = staffObject;
            object[name] = object[staffArray[index]];
            delete object[staffArray[index]];
            let length = Object.keys(object).length;
            setInputs(prevState => ({ ...prevState, ["contract_core_staff"]: object }));
            setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: length }));
            setStaffObject(object);
        }
    }

    if (staffArray.length > 0)
    {
        tempArray = staffArray;
    }

    if (count === 0)
    {
        tempArray[index] = name;
        setStaffArray(tempArray);
    }
}

export const handleStaffTypes = (type, index, staffArray, staffObject, setInputs, setStaffObject, staffTypes, setStaffTypes) =>
{
    let personName = staffArray[index];

    if (personName !== undefined && personName !== "" && type !== "")
    {
        let object = {};

        if (Object.keys(staffObject).length === 0)
        {
            object[personName] = type;
            let length = Object.keys(object).length;
            setInputs(prevState => ({ ...prevState, ["contract_core_staff"]: object }));
            setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: length }));
            setStaffObject(object);
        }
        else if (Object.keys(staffObject).length === index)
        {
            object = staffObject;
            object[personName] = type;
            let length = Object.keys(object).length;
            setInputs(prevState => ({ ...prevState, ["contract_core_staff"]: object }));
            setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: length }));
            setStaffObject(prevState => ({ ...prevState, [personName]: type }));
        }
        else if (personName !== undefined)
        {
            object = staffObject;
            object[personName] = type;
            let length = Object.keys(object).length;
            setInputs(prevState => ({ ...prevState, ["contract_core_staff"]: object }));
            setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: length }));
            setStaffObject(object);
        }
    }

    let tempStaffTypes = [];

    if (staffTypes.length > 0) 
    {
        tempStaffTypes = staffTypes;
    }

    tempStaffTypes[index] = type;
    setStaffTypes(tempStaffTypes);
}

export const addStaffRow = (staffArray, currentIndex, staffTypes, staffRows, setCurrentIndex, setStaffRows) =>
{
    if (staffArray.length > 0)
    {
        if (
            ((staffArray[currentIndex] === "" || staffArray[currentIndex] === undefined) && (staffTypes[currentIndex] === "" || staffTypes[currentIndex] === undefined))
            ||
            ((staffArray[currentIndex] !== "" && staffArray[currentIndex] !== undefined) && (staffTypes[currentIndex] === "" || staffTypes[currentIndex] === undefined))
            ||
            ((staffArray[currentIndex] === "" || staffArray[currentIndex] === undefined) && (staffTypes[currentIndex] !== "" && staffTypes[currentIndex] !== undefined))
        )
        {
            swal("Error", "Please enter a staff member name AND type before moving on.", "error");
        }
        else
        {
            let row = staffRows;
            row++;
            setCurrentIndex(row - 1);
            setStaffRows(row);
        }
    }
    else if (staffArray.length === 0) 
    {
        swal("Error", "Please enter a staff member name first.", "error");
    }
}

export const removeStaffRow = (index, staffRows, setCurrentIndex, setStaffRows, staffArray, setStaffArray, staffTypes, setStaffTypes, staffObject, inputs, setInputs, setStaffObject) =>
{
    if (staffRows > 1)
    {
        let row = staffRows;
        row--;
        if (index - 1 > 0)
        {
            setCurrentIndex(index - 1);
        }
        else
        {
            setCurrentIndex(0);
        }

        setStaffRows(row);
        let objectKey = 0;
        objectKey = staffArray[index];

        let tempstaffarray = staffArray;
        tempstaffarray.splice(index, 1);
        setStaffArray(tempstaffarray);

        let tempStaffTypes = staffTypes;
        tempStaffTypes.splice(index, 1);
        setStaffTypes(tempStaffTypes);

        let tempStaffObject = {};
        tempStaffObject = staffObject;
        delete tempStaffObject[objectKey];

        let length = Object.keys(tempStaffObject).length;
        setInputs(prevState => ({ ...prevState, ["contract_core_staff"]: tempStaffObject }));
        setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: length }));
        setStaffObject(tempStaffObject);
    }
    else if (staffRows === 1 && staffArray.length >= 0)
    {
        let objectKey = 0;
        objectKey = staffArray[index];

        let select_box = document.getElementById("staff_type");
        select_box.selectedIndex = 0;
        setStaffArray([]);

        let tempStaffObject;
        tempStaffObject = staffObject;

        delete tempStaffObject[objectKey];
        setStaffObject(tempStaffObject);
        setStaffTypes([]);

        if (inputs.contract_core_staff)
        {
            setInputs(prevState => ({ ...prevState, ["contract_core_staff"]: undefined }));
            setInputs(prevState => ({ ...prevState, ["contract_core_staff_num"]: undefined }));
        }
        setCurrentIndex(0);
    }
}

