import React from "react"
import { useState, useEffect } from "react";
import { Table, Button } from "reactstrap";
import PropTypes from 'prop-types';
import ".../../assets/styles.css";
import swal from "sweetalert";
import postUser from "./postUser";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";

const ViewUsers = (props) =>
{
    // Hooks
    const [usersToDisplay, setUsersToDisplay] = useState([]);

    // Props
    let loginsArray = props.loginsArray;
    let currentUser = props.currentUser;
    let currentUserId = props.currentUserId;
    let toggleLoginsChanged = props.toggleLoginsChanged;
    let setShowForm = props.setShowForm;
    let setActiveTab = props.setActiveTab;
    let setCurrentIndex = props.setCurrentIndex;
    let setIsDeactivating = props.setIsDeactivating;

    const updateUser = (index) =>
    {
        setActiveTab("2");
        setShowForm(true);
        setCurrentIndex(index);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const deactivateUser = async (_id, email) =>
    {
        setIsDeactivating(true);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        let myObject = {};
        myObject["_id"] = _id;
        myObject["user_email"] = email;
        myObject["deactivated_by_user"] = currentUser;

        const response = await postUser(myObject, "POST", "postremoveuser", "Error while deactivating user");

        if (response !== undefined
            && (response === "User deactivated successfully."
                || response === "Something went wrong when adding entry to loginsDeactivated db, but user status successfully updated to inactive."))
        {
            swal("Success", "User deactivated successfully.", "success");
            setIsDeactivating(false);
            toggleLoginsChanged();
        }
        else if (response === "Something went wrong when updating user status to: Deactivated.")
        {
            swal("Error", "Error occurred while deactivating user.", "error");
            setIsDeactivating(false);
            toggleLoginsChanged();
        }
    }

    const sortInfo = () =>
    {
        if (loginsArray.length > 0)
        {
            let tempLoginsArray = JSON.parse(JSON.stringify(loginsArray));

            tempLoginsArray = removeSuccessMessage(tempLoginsArray, "Logins retrieved successfully.");

            // lastElement = tempLoginsArray.length - 1;
            // if (tempLoginsArray[lastElement] === "Logins retrieved successfully.")
            // {
            //     tempLoginsArray.pop();
            // }

            let tempUsersToDisplay = tempLoginsArray.map((user, index) =>
            {
                let wardsList = "";
                let wards = user.wards !== undefined ? user.wards : [];

                if (wards.length > 0)
                {
                    wardsList = wards.join(", ");
                }

                let theUser;
                theUser = (
                    <div key={index} className="border border-dark mb-4 table-responsive over">
                        <Table striped className="table table-stripy mb-2">
                            <tbody>
                                <tr className="col-12">
                                    <th className="col-2">User ID</th>
                                    <td className="col-3">{user.uid}</td>
                                    <th className="col-2"></th>
                                    <td className="col-3"></td>
                                    {/* <th className="col-2">Status</th>
                                    <td className="col-3">{status === "active" ? <span style={{ color: "blue" }}>{status}</span> : <span style={{ color: "red" }}>{status}</span>}</td> */}
                                </tr>

                                <tr className="col-12">
                                    <th className="col-2">Username</th>
                                    <td className="col-3">{user.username}</td>
                                    <th className="col-2">Email</th>
                                    <td className="col-3">{user.email}</td>
                                </tr>

                                <tr className="col-12">
                                    <th className="col-2">Role</th>
                                    <td className="col-3">{user.role}</td>
                                    <th className="col-2">Ward</th>
                                    <td className="col-3">{wardsList !== ""
                                        ? wardsList
                                        : "-"}</td>
                                </tr>
                                <tr className="col-12">
                                    <th className="col-2">Name</th>
                                    <td className="col-3">{user.name !== undefined && user.name !== "" ? user.name : "-"}</td>
                                    <th className="col-2">Surname</th>
                                    <td className="col-3">{user.surname !== undefined && user.surname !== "" ? user.surname : "-"}</td>
                                </tr>
                                <tr className="col-12">
                                    <th className="col-2">Bio</th>
                                    <td className="col-3">{user.bio !== undefined && user.bio !== "" ? user.bio : "-"}</td>
                                    <th className="col-2">User image?</th>
                                    <td className="col-3">{(user.image_url !== undefined && user.image_url.length !== 0)
                                        ? <span style={{ color: "blue" }}>Yes</span>
                                        : <span style={{ color: "red" }}>No</span>}</td>
                                </tr>
                                <tr className="col-12">
                                    <th className="col-2">Can Publish?</th>
                                    <td className="col-3">{user.canPublish === 1 ? <span style={{ color: "blue" }}>Yes</span> : <span style={{ color: "red" }}>No</span>}</td>
                                    <th className="col-2">Can Display (live)?</th>
                                    <td className="col-3">{user.canDisplay === 1 ? <span style={{ color: "blue" }}>Yes</span> : <span style={{ color: "red" }}>No</span>}</td>
                                </tr>
                            </tbody>
                        </Table>
                        {currentUserId !== user._id ?
                            <div className="p-1">
                                <Button className="py-1" color="primary" onClick={() => updateUser(index)}>Edit</Button>
                                <Button className="ms-1 py-1" color="danger" onClick={() => { deactivateUser(user._id, user.email) }}>
                                    Deactivate
                                </Button>
                            </div>
                            : <div className="p-1">
                                <Button className="py-1" color="primary" onClick={() => updateUser(index)}>Edit</Button>
                                <br />
                                <span style={{ color: "blue", fontStyle: "italic" }}>Currently logged in user</span>
                            </div>
                        }
                    </div>
                );

                return theUser;
            });

            setUsersToDisplay(tempUsersToDisplay);
        }
    }

    useEffect(() =>
    {
        sortInfo();
    }, [loginsArray]);

    return (
        <div>
            {/* {isDeactivating ? "Deactivating..." : null} */}
            {/* <div>
                        Inputs: <pre>{JSON.stringify(inputs)}</pre>
                        currentIndex: {currentIndex},
                        loginsArray canPublish: <pre>{loginsArray.length > 0 ? JSON.stringify(loginsArray[currentIndex].canPublish) : null}</pre>
                    </div> */}
            {usersToDisplay}
        </div>
    );
}

export default ViewUsers;

ViewUsers.propTypes = {
    loginsArray: PropTypes.array,
    currentUser: PropTypes.string,
    currentUserId: PropTypes.string,
    toggleLoginsChanged: PropTypes.func,
    setShowForm: PropTypes.func,
    setActiveTab: PropTypes.func,
    setCurrentIndex: PropTypes.func,
    setIsDeactivating: PropTypes.func,
};