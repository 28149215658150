import { API_URL } from "../../helpers/ApiUrl";

const fetchAnItem = async (type, endpoint, idOrWard, setArray, setMsgFromServer, msg) =>
{
    let postBody = {};

    if (type === "project")
    {
        postBody = { proj_id: idOrWard };
    }
    else if (type === "sitevisit")
    {
        postBody = { id: idOrWard };
    }
    else if (type === "projects in ward")
    {
        postBody = { ward: idOrWard, order: 1 };
    }

    await fetch(
        // `${API_URL}/getprojectdetail`,
        `${API_URL}/${endpoint}`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postBody),
        }
    )
        .then((response) => response.json())
        .then((result) =>
        {
            let array = JSON.parse(result.message);
            let lastArrayItem = array.length;
            let msgFromServer = array[lastArrayItem - 1];
            setMsgFromServer(msgFromServer);
            setArray(array);
        })
        .catch((error) =>
        {
            console.error('Error:', error.message);
            setMsgFromServer("Caught error");
        });
}

export default fetchAnItem;