import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faFileLines, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button, Form, FormGroup, Label, Input, Badge } from "reactstrap";
import { incidentType, siteActivitiesOptions, plantEquipmentOptions } from "../SharedFiles/listData";
import resizeImage from "../SharedFiles/resizeImage";
import postInfo from 'pages/SharedFiles/postInfo';
import { post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { API_URL } from "../../helpers/ApiUrl";
import ".../../assets/styles.css";
import { addActivityRow, handleActivity, removeActivity, loadActivityArray } from "./ActivityHelpers";
import { handleStaffRegisterButton, handleStaffArray, addStaffRow, addAllStaffRows, removeStaffRow, addManagers, minusManagers } from "./staffFunctions";
import { incrementLocalStaff } from "./incrementStaff";
import { addRow, removeRow, handleEquipArray, handleEquipCount, loadEquipmentArray } from "./equipFunctions";
import changePhotoHandler from "../SharedFiles/changePhotoHandler";
import changeDocHandler from "../SharedFiles/changeDocHandler";
import checkForManagers from '../SharedFiles/checkForManagers';
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";

const EditSiteVisitsForm = (props) =>
{
    // Constants
    const timerSeconds = 600;
    const timerOff = -1;

    // Props
    let setVisitDocsUpdated = props.setVisitDocsUpdated;
    let setVisitInfoUpdated = props.setVisitInfoUpdated;
    let setVisitImagesUpdated = props.setVisitImagesUpdated;
    let setShowVisitAlerts = props.setShowVisitAlerts;
    let projectId = props.projectId;
    let setProjectId = props.setProjectId;
    let visitDate = props.visitDate;
    let setVisitDate = props.setVisitDate;
    let isAdmin = props.isAdmin;
    let chosenFromViewPage = props.chosenFromViewPage;
    let toggleVisitChosen = props.toggleVisitChosen;
    let isLoaded = props.isLoaded;
    let setIsLoadedImages = props.setIsLoadedImages
    let setIsLoaded = props.setIsLoaded;
    let setIsLoadedDocs = props.setIsLoadedDocs;
    let toggleVisitInfoChanged = props.toggleVisitInfoChanged;
    let siteVisitsArray = props.siteVisitsArray;
    let siteVisitImagesArray = props.visitImagesArray;
    let siteVisitDocsArray = props.siteVisitDocsArray;
    let setVisitImagesError = props.setVisitImagesError;
    let currentUser = props.thisUser;
    let activeTab = props.activeTab;
    let visitId = props.visitId;
    let editPgDropdownValue = props.editPgDropdownValue;
    let setEditPgDropdownValue = props.setEditPgDropdownValue;
    let projectsArray = props.projectsArray;
    let projectMsgFromServer = props.projectMsgFromServer;

    // Misc hooks
    const [isUpdating, setUpdating] = useState(false);
    const [dropdownOn, setDropdownOn] = useState(false);
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [canEditCondition1, setCanEditCondition1] = useState(false);
    const [canEditCondition2, setCanEditCondition2] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [counter, setCounter] = useState(timerOff);

    // Core staff member hooks
    const [staffRows, setStaffRows] = useState(1);
    const [staffArray, setStaffArray] = useState([]);
    const [loadedStaffArrays, setLoadedStaffArrays] = useState(false);
    const [staffNamesArray, setStaffNamesArray] = useState([]);
    const [staffPresenceArray, setStaffPresenceArray] = useState([]);
    const [staffObject, setStaffObject] = useState({});
    const [totalStaff, setTotalStaff] = useState(0);
    const [localStaff, setLocalStaff] = useState(0);
    const [managers, setManagers] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentStaffObject, setCurrentStaffObject] = useState({});

    // Document hooks
    const [docUrls, setDocUrls] = useState([]);
    const [docNames, setDocNames] = useState([]);
    const [newDocUrls, setNewDocUrls] = useState([]);
    const [newDocNames, setNewDocNames] = useState([]);
    const [numOfDocs, setNumOfDocs] = useState(0);
    const [numOfNewDocs, setNumOfNewDocs] = useState(0);
    const [docUrlsToDelete, setDocUrlsToDelete] = useState([]);
    const [docNamesToDelete, setDocNamesToDelete] = useState([]);
    const [newDocObjects, setNewDocObjects] = useState([]);

    // Image hooks
    const [imageUrls, setImageUrls] = useState([]);
    const [newImageUrls, setNewImageUrls] = useState([]);
    const [numOfImages, setNumOfImages] = useState(0);
    const [numOfNewImages, setNumOfNewImages] = useState(0);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [imageObjects, setImageObjects] = useState([]);
    const [newImageObjects, setNewImageObjects] = useState([]);
    const [imgNames, setImgNames] = useState([]);
    const [markedImages, setMarkedImages] = useState([]);

    // Site visit hooks
    const [visitsDropdownArray, setVisitsDropdownArray] = useState([]);
    const [chosenVisitArray, setChosenVisitArray] = useState([]);
    const [visitDates, setVisitDates] = useState([]);
    const [visitChanged, setVisitChanged] = useState(false);
    const [dateAdded, setDateAdded] = useState("");
    const [visitChosen, setVisitChosen] = useState(false);

    // Activity hooks
    const [activityArray, setActivityArray] = useState([]);
    const [activityRows, setActivityRows] = useState(1);
    const [activityArrayLoaded, setActivityArrayLoaded] = useState(false);
    const [currentActivIndex, setCurrentActivIndex] = useState(0);

    // Equipment hooks
    const [equipRows, setEquipRows] = useState(1);
    const [equipCountArray, setEquipCountArray] = useState([]);
    const [equipArray, setEquipArray] = useState([]);
    const [equipObject, setEquipObject] = useState({});
    const [currentEquipIndex, setCurrentEquipIndex] = useState(0);

    const loadCurrentStaffObject = () =>
    {
        if (projectsArray.length > 0)
        {
            let projectsReduced = JSON.parse(JSON.stringify(projectsArray));

            if (projectMsgFromServer === "Projects retrieved successfully.")
            {
                projectsReduced = removeSuccessMessage(projectsReduced, "Projects retrieved successfully.");
            }
            else if (projectMsgFromServer === "Project summaries retrieved successfully.")
            {
                projectsReduced = removeSuccessMessage(projectsReduced, "Project summaries retrieved successfully.");
            }

            // let lastArrayItem = projectsReduced.length;
            // let msgFromServer = projectsReduced[lastArrayItem - 1];

            // if (msgFromServer === "Projects retrieved successfully." || msgFromServer === "Project summaries retrieved successfully.") { projectsReduced.pop(); }

            let currentProject = projectsReduced.find(project => project.proj_id === projectId);

            let array = currentProject !== undefined && currentProject.contract_core_staff !== undefined
                ? Object.keys(currentProject.contract_core_staff)
                : currentProject !== undefined
                    ? currentProject.contract_core_staff_names
                    : [];

            setStaffNamesArray(array);

            if (currentProject !== undefined)
            {
                setCurrentStaffObject(currentProject);
            }
            else
            {
                setCurrentStaffObject({});
            }
        }
    }

    const toggleVisitChanged = () =>
    {
        setVisitChanged(current => !current);
    };

    const handleChooseVisitFromDropdown = (e) =>
    {
        if (e.target.value !== "-1")
        {
            if (canEdit || isAdmin)
            {
                setVisitChosen(true);
                setCounter(timerSeconds);
            }

            console.log("Visit chosen from dropdown menu.");
            setDropdownOn(false);
            let chosenVisit = e.target.value;
            let chosenOptionArray = chosenVisit.split("///");
            let chosenDate = chosenOptionArray[2];

            let chosenDateString = new Date(chosenDate);
            chosenDateString = chosenDateString.toDateString();

            setVisitDate(chosenDate);
            setProjectId(Number(chosenOptionArray[1]));

            // let tempChosenVisitArray = [];

            // ---------------------------------------------
            loadVisitArray(chosenOptionArray[0], chosenDate);
            // siteVisitsArray.forEach((visit, index) =>
            // {
            //     if (visit._id === chosenOptionArray[0])
            //     {
            //         tempChosenVisitArray.push(visit);
            //     }
            // });

            // // Can edit - condition 1 of 2
            // setCanEditFunction1(chosenDate);
            // setDateOfVisit(tempChosenVisitArray[0].timestamp);

            // // Can edit - condition 2 of 2
            // setCanEditFunction2(tempChosenVisitArray[0].current_user);
            //setChosenVisitArray(tempChosenVisitArray);

            // if (Object.keys(tempChosenVisitArray).length > 0) 
            // {
            //     setTotalStaff(tempChosenVisitArray[0].total_staff_onsite);
            //     setLocalStaff(tempChosenVisitArray[0].local_staff_onsite);
            //     setManagers(tempChosenVisitArray[0].mgrs_onsite);
            // }
            // -----------------------------------------------------

            setNumOfDocs(0);
            setDocUrls([]);
            setDocNames([]);
            setImageUrls([]);
            setNumOfImages(0);
            setIsLoadedDocs(false);
            setIsLoadedImages(false);
            setLoadedStaffArrays(false);
            toggleVisitChanged();
        }
        else
        {
            setVisitChosen(false);
            setCounter(timerOff);
            setVisitDate("0");
            setProjectId(0);
            setChosenVisitArray([]);
        }
    }

    const loadVisitArray = (visitId, visitDate) =>
    {
        if (visitId !== "0")
        {
            //console.log("Load visit array has run");
            let tempChosenVisitArray = [];

            siteVisitsArray.forEach((visit, index) =>
            {
                if (visit._id === visitId)
                {
                    tempChosenVisitArray.push(visit);
                }
            });

            setCanEditFunction1(visitDate);
            setDateOfVisit(tempChosenVisitArray[0].timestamp);
            { tempChosenVisitArray[0].current_user !== "" ? setCanEditFunction2(tempChosenVisitArray[0].current_user) : null }
            setChosenVisitArray(tempChosenVisitArray);

            if (Object.keys(tempChosenVisitArray[0]).length > 0) 
            {
                setTotalStaff(tempChosenVisitArray[0].total_staff_onsite);
                setLocalStaff(tempChosenVisitArray[0].local_staff_onsite);
                setManagers(tempChosenVisitArray[0].mgrs_onsite);
            }
        }
    }

    // const loadVisitArray = () =>
    // {
    //     if (visitId !== "0")
    //     {
    //         //console.log("Load visit array has run");
    //         let tempChosenVisitArray = [];

    //         siteVisitsArray.forEach((visit, index) =>
    //         {
    //             if (visit._id === visitId)
    //             {
    //                 tempChosenVisitArray.push(visit);
    //             }
    //         });

    //         setCanEditFunction1(visitDate);
    //         setDateOfVisit(tempChosenVisitArray[0].timestamp);
    //         { currentUser !== "" ? setCanEditFunction2(currentUser) : null }
    //         setChosenVisitArray(tempChosenVisitArray);

    //         if (Object.keys(tempChosenVisitArray).length > 0) 
    //         {
    //             setTotalStaff(tempChosenVisitArray[0].total_staff_onsite);
    //             setLocalStaff(tempChosenVisitArray[0].local_staff_onsite);
    //             setManagers(tempChosenVisitArray[0].mgrs_onsite);
    //         }
    //     }
    // }

    const handleVisitChosenFromViewPage = () =>
    {
        console.log("Visit chosen from view page.");
        loadVisitArray(visitId, visitDate);
        setNumOfDocs(0);
        setDocUrls([]);
        setDocNames([]);
        setImageUrls([]);
        setNumOfImages(0);
        setIsLoadedDocs(false);
        setIsLoadedImages(false);
        setLoadedStaffArrays(false);
        toggleVisitChanged();
        setDropdownOn(false);
        let tempValue = `${visitId}///${projectId}///${visitDate}`;
        setEditPgDropdownValue(tempValue);

        if (canEdit || isAdmin)
        {
            setVisitChosen(true);
            setCounter(timerSeconds);
        }
    }

    const setDateOfVisit = (time) =>
    {
        let tempDateAdded = new Date(time);
        tempDateAdded = tempDateAdded.toString();
        tempDateAdded = tempDateAdded.replace(" GMT+0200 (South Africa Standard Time)", "");
        setDateAdded(tempDateAdded);
    }

    const sortVisitImages = () =>
    {
        if (siteVisitImagesArray.length > 0 && chosenVisitArray.length > 0)
        {
            let images = JSON.parse(JSON.stringify(siteVisitImagesArray));
            images = removeSuccessMessage(images, "Site visit images retrieved successfully.");

            // let length = images.length;
            // if (images[length - 1] === "Site visit images retrieved successfully.")
            // {
            //     images.pop();
            // }

            images.forEach((image, i) =>
            {
                let numOfPics = 0;

                if (image.visit_id === chosenVisitArray[0]._id)
                {
                    let urls = image.imageUrlArray;
                    numOfPics = urls.length;
                    setImageUrls(urls);
                    setNumOfImages(numOfPics);
                }
            });
        }
    }

    const sortSiteVisitDocs = () =>
    {
        if (siteVisitDocsArray.length > 0 && chosenVisitArray.length > 0)
        {
            let docs = JSON.parse(JSON.stringify(siteVisitDocsArray));
            docs = removeSuccessMessage(docs, "Site visit documents retrieved successfully.");

            // let length = docs.length;
            // if (docs[length - 1] === "Site visit documents retrieved successfully.")
            // {
            //     docs.pop();
            // }

            docs.forEach((doc, i) =>
            {
                let numOfDocs = 0;

                if (doc.visit_id === chosenVisitArray[0]._id)
                {
                    let tempDocUrls = doc.docUrlArray;
                    numOfDocs = tempDocUrls.length;
                    setDocUrls(tempDocUrls);
                    setDocNames(doc.docNamesArray);
                    setNumOfDocs(numOfDocs);
                }
            });
        }
    }

    // Has it been less than 60 mins since site visit was created? If so, can edit.
    const setCanEditFunction1 = (theDate) =>
    {
        if (theDate !== undefined && theDate !== "" && theDate !== "0")
        {
            let time = new Date(theDate);
            let timeBetween = calcTimeBetween(time);
            timeBetween = Math.round(timeBetween);
            let tempCanEdit;

            if (timeBetween <= 60)
            {
                tempCanEdit = true;
            }
            else
            {
                tempCanEdit = false;
            }

            setCanEditCondition1(tempCanEdit);
        }
    }

    // Was this site visit saved by the current user? If so, can edit.
    const setCanEditFunction2 = (user) =>
    {
        if (user !== undefined || user !== "")
        {
            if (user === currentUser)
            {
                setCanEditCondition2(true);
            }
            else
            {
                setCanEditCondition2(false);
            }
        }
    }

    const loadDropdownArray = () =>
    {
        // Pop server success msg off end of array for "Choose site visit to edit" dropdown
        let tempArray = JSON.parse(JSON.stringify(siteVisitsArray));
        tempArray = removeSuccessMessage(tempArray, "Visits retrieved successfully.");

        // let length = tempArray.length;
        // if (tempArray[length - 1] === "Visits retrieved successfully.")
        // {
        //     tempArray.pop();
        // }

        setVisitsDropdownArray(tempArray);

        let visitDay;
        let visitDays = tempArray.map((visit, index) =>
        {
            visitDay = new Date(visit.timestamp);
            visitDay = visitDay.toString()
            visitDay = visitDay.replace(" GMT+0200 (South Africa Standard Time)", "");

            return visitDay;
        });

        setVisitDates(visitDays);
    }

    const loadStaffArrays = () =>
    {
        if (!loadedStaffArrays && chosenVisitArray.length > 0)
        {
            setStaffArray([]);
            setStaffPresenceArray([]);
            setStaffRows(1);
            setStaffObject({});

            console.log("Loaded staff arrays has run");
            let object = chosenVisitArray[0].staff_register;

            // let loadedStaffObject = {};
            // Object.assign(loadedStaffObject, object);
            setStaffObject(object);

            let tempStaffArray = Object.keys(object);

            setStaffRows(tempStaffArray.length);
            setStaffArray(tempStaffArray);

            let tempStaffPresenceArray = Object.values(object);

            setStaffPresenceArray(tempStaffPresenceArray);
            setLoadedStaffArrays(true);
        }
    }

    const removeNewImage = (e, index) =>
    {
        let tempImgUrls = newImageUrls;
        tempImgUrls.splice(index, 1);
        setNewImageUrls(tempImgUrls);
        let tempImgObjects = newImageObjects;
        tempImgObjects.splice(index, 1);
        setNewImageObjects(tempImgObjects);
        setNumOfNewImages(newImageUrls.length);
        let tempImgNames = imgNames;
        tempImgNames.splice(index, 1);
        setImgNames(tempImgNames);
        updatePhotosUploaded("remove-new");
    }

    const removeImage = (e, index) => 
    {
        let tempImgUrls = imageUrls;

        // Existing mages marked for deletion (red text)
        let imgsMarkedForDeletion = [];
        if (markedImages.length > 0)
        {
            imgsMarkedForDeletion = markedImages;
        }
        imgsMarkedForDeletion.push(tempImgUrls[index]);
        setMarkedImages(imgsMarkedForDeletion);

        let tempImageToDelete = tempImgUrls[index];
        let tempImagesToDelete = [];

        if (imagesToDelete.length > 0) 
        {
            tempImagesToDelete = imagesToDelete;
        }

        tempImagesToDelete.push(tempImageToDelete);
        setImagesToDelete(tempImagesToDelete);

        tempImgUrls.splice(index, 1);
        setImageUrls(tempImgUrls);

        let tempImgObjects = imageObjects;
        tempImgObjects.splice(index, 1);
        setImageObjects(tempImgObjects);
        setNumOfImages(imageUrls.length);

        updatePhotosUploaded("remove-old");
    }

    const handleSubmitPhoto = async (e) =>
    {
        setUpdating(true);

        // If there aren't any new photos to upload
        if (newImageUrls.length === 0)
        {
            console.log("No new images to upload.");

            if (imagesToDelete.length > 0)
            {
                console.log("some photos to delete");
                return handleSubmitDoc("photos to delete");
            }

            return handleSubmitDoc("no photos");
        }

        // if there are some new photos to upload
        const formData = new FormData();

        for (const files of newImageObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };
            const resizedImage = await resizeImage(config);
            formData.append("site-visit-image", resizedImage, files.name);
        }

        postInfo(formData, "visitimageupload", "POST", handleSubmitDoc, "");
    };

    const handleStaffRegButton = (event, index) =>
    {
        handleStaffRegisterButton(
            event,
            index,
            staffArray,
            staffObject,
            setInputs,
            setStaffObject,
            staffPresenceArray,
            setStaffPresenceArray);
    }

    // const loadStaffNames = () =>
    // {
    //     if (chosenVisitArray.length > 0)
    //     {
    //         let object = chosenVisitArray[0].staff_register;
    //         let array = Object.keys(object);
    //         debugger;
    //         setStaffNamesArray(array);
    //     }
    // }

    const handleStaffArr = (name, index) =>
    {
        handleStaffArray(
            name,
            index,
            staffPresenceArray,
            staffArray,
            staffObject,
            setInputs,
            setStaffObject,
            incrementLocalStaff,
            setStaffArray,
            staffRows,
            chckForManagers);
    }

    const addStaffRw = () =>
    {
        addStaffRow(
            staffArray,
            currentIndex,
            staffPresenceArray,
            staffRows,
            setCurrentIndex,
            setStaffRows)
    }

    const addAllStaffRws = () =>
    {
        addAllStaffRows(
            staffNamesArray,
            setStaffArray,
            setStaffRows,
            setCurrentIndex,
            setInputs,
            setStaffObject,
            setStaffPresenceArray,
            incrementLocalStaff,
            chckForManagers);
    }

    const removeStaffRw = (index) =>
    {
        removeStaffRow(
            index,
            staffRows,
            setCurrentIndex,
            setStaffRows,
            staffArray,
            incrementLocalStaff,
            setStaffArray,
            staffPresenceArray,
            setStaffPresenceArray,
            staffObject,
            inputs,
            setInputs,
            setStaffObject,
            setLocalStaff,
            chckForManagers,
            setTotalStaff);
    }

    const loadActivArray = () =>
    {
        loadActivityArray(dropdownOn, chosenVisitArray, setActivityArray, setActivityRows, setActivityArrayLoaded);
    }

    const addActivRow = e =>
    {
        addActivityRow(activityArray, currentActivIndex, activityRows, setCurrentActivIndex, setActivityRows);
    }

    const removeActiv = index =>
    {
        removeActivity(activityRows, setActivityRows, setCurrentActivIndex, index, activityArray, setActivityArray, inputs, setInputs);
    }

    const handleActiv = (activity, index) =>
    {
        handleActivity(index, activity, activityArray, setActivityArray, setInputs);
    }

    let activityDivArray = [];
    let activRow;

    if (activityArrayLoaded && activityArray.length >= 0)
    {
        for (let i = 0; i < activityRows; ++i)
        {
            activRow = (
                <div key={i} className='row mb-1 mb-sm-1 mb-md-1'>
                    <Col sm={11}>
                        <Input
                            id="siteActivities"
                            name="activities_onsite"
                            type="select"
                            onChange={(e) =>
                            { handleActiv(e.target.value, i) }}
                            value={activityArray[i] || ''}
                        >
                            {siteActivitiesOptions.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                        </Input>
                    </Col>
                    <Col className="col-sm-1 col-2 my-sm-0 my-2">
                        <Button color="danger" onClick={() => removeActiv(i)}>
                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                        </Button>
                    </Col>
                </div>);
            activityDivArray.push(activRow);
        }
    }

    let staffDivArray = [];
    let staffRow;

    if (staffNamesArray.length > 0 & staffArray.length > 0)
    {
        for (let i = 0; i < staffRows; ++i)
        {
            staffRow = (
                <div key={i} className='row mb-1 mb-sm-1 mb-md-1'>
                    <Col className="col-sm-8 col-12 col-md-7">
                        <Input
                            id="staff_register"
                            name="staff_register"
                            type="select"
                            onChange={(e) => { handleStaffArr(e.target.value, i) }}
                            value={staffArray[i] || ''}
                        >
                            {staffNamesArray.length > 0
                                ? <option index="0" value="">Choose staff member name</option>
                                : <option index="0" value="">First choose a project above</option>}
                            {staffNamesArray.map((item, index) => (
                                <option key={index + 1} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Input>
                    </Col>
                    <Col
                        className="mt-2 mt-sm-0 col-12 col-sm-4 col-md-5"
                    >
                        <Button name="staffPresence"
                            color={staffPresenceArray[i] === "full day"
                                ? "success"
                                : "secondary"} className='me-1 mb-1' onClick={(e) => { handleStaffRegButton(e, i) }} value="full day">
                            Full Day
                        </Button>

                        <Button name="staffPresence"
                            color={staffPresenceArray[i] === "half day"
                                ? "warning"
                                : "secondary"} className='me-1 mb-1' onClick={(e) => { handleStaffRegButton(e, i) }} value="half day">
                            Half Day
                        </Button>

                        <Button name="staffPresence"
                            color={staffPresenceArray[i] === "absent"
                                ? "danger"
                                : "secondary"} className='me-1 mb-1' onClick={(e) => { handleStaffRegButton(e, i) }} value="absent">
                            Absent
                        </Button>

                        <Button color="danger" className='me-1 mb-1' onClick={() => removeStaffRw(i)}>
                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                        </Button>
                    </Col>
                </div>
            );
            staffDivArray.push(staffRow);
        }
    }

    const handleEquipCnt = (count, index) =>
    {
        handleEquipCount(count, index, equipArray, equipObject, setInputs, setEquipObject, equipCountArray, setEquipCountArray)
    }

    const handleEquipArr = (name, index) =>
    {
        handleEquipArray(name, index, equipCountArray, equipArray, equipObject, setInputs, setEquipObject, setEquipArray);
    }

    const addEquipRw = () =>
    {
        addRow(equipArray, currentEquipIndex, equipCountArray, equipRows, setCurrentEquipIndex, setEquipRows)
    }

    const removeEquipRw = (index) =>
    {
        removeRow(
            index,
            equipRows,
            setCurrentEquipIndex,
            setEquipRows,
            equipArray,
            setEquipArray,
            equipCountArray,
            setEquipCountArray,
            equipObject,
            setInputs,
            setEquipObject,
            inputs);
    }

    let equipmentRow;
    let equipmentArray = [];

    for (let i = 0; i < equipRows; ++i)
    {
        equipmentRow = (
            <div key={i} className='row mb-1 mb-sm-1 mb-md-1'>
                <Col className="col-sm-8 col-12 col-md-7">
                    <Input
                        id="plant_equip_list"
                        name="plant_equip_list"
                        type="select"
                        value={equipArray[i] || ''}
                        onChange={(e) => handleEquipArr(e.target.value, i)}
                    > {plantEquipmentOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                    </Input>
                </Col>
                <Col className="mt-2 mt-sm-0 col-6 col-sm-4 col-md-3">
                    <Input
                        id="plant_equip_num"
                        name="plant_equip_num"
                        placeholder="How many"
                        type="number"
                        value={equipCountArray[i] || ""}
                        onChange={(e) => handleEquipCnt(e.target.value, i)}
                        onWheel={(e) => e.target.blur()}
                    />
                </Col>
                <Col className="mt-2 mt-sm-0 col-6 col-sm-4 col-md-2">
                    <Button color="danger" onClick={() => removeEquipRw(i)}>
                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                    </Button>
                </Col>
            </div>);

        equipmentArray.push(equipmentRow);
    }

    const removeDoc = (e, index) => 
    {
        let tempDocUrls = docUrls;
        let tempDocNames = docNames;
        let tempDocUrlToDelete = docUrls[index];
        let tempDocNameToDelete = docNames[index];
        let tempDocUrlsToDelete = [];
        let tempDocNamesToDelete = [];

        if (docUrlsToDelete.length > 0) 
        {
            tempDocUrlsToDelete = docUrlsToDelete;
        }

        if (docNamesToDelete.length > 0) 
        {
            tempDocNamesToDelete = docNamesToDelete;
        }

        tempDocUrlsToDelete.push(tempDocUrlToDelete);
        tempDocNamesToDelete.push(tempDocNameToDelete);
        setDocUrlsToDelete(tempDocUrlsToDelete);
        setDocNamesToDelete(tempDocNamesToDelete);

        tempDocUrls.splice(index, 1);
        setDocUrls(tempDocUrls);

        tempDocNames.splice(index, 1);
        setDocNames(tempDocNames);
        setNumOfDocs(docUrls.length);
    }

    const removeNewDoc = (e, index) =>
    {
        let tempDocUrls = newDocUrls;
        tempDocUrls.splice(index, 1);
        setNewDocUrls(tempDocUrls);
        setNumOfNewDocs(tempDocUrls.length);

        let tempDocObjects = newDocObjects;
        tempDocObjects.splice(index, 1);
        setNewDocObjects(tempDocObjects);

        let tempNewDocNames = newDocNames;
        tempNewDocNames.splice(index, 1);
        setNewDocNames(tempNewDocNames);
    }

    const cancelEdit = () =>
    {
        setCounter(timerOff);
        setVisitChosen(false);
        setChosenVisitArray([]);
        setEditPgDropdownValue("-1");
        setLoadedStaffArrays(false);
        setStaffArray([]);
        setStaffRows(1);
        setInputs({});

        setNumOfNewImages(0);
        setNewImageObjects([]);
        setNewImageUrls([]);
        setImgNames([]);
        setNumOfImages(0);
        setImagesToDelete([]);
        setMarkedImages([]);

        setNewDocNames([]);
        setNumOfNewDocs(0);
        setNewDocObjects([]);
        setNewDocUrls([]);
        setDocUrls([]);
        setDocNames([]);
        setNumOfDocs(0);

        setIsLoaded(false);
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const handleSubmitDoc = (msgFromPhotos) =>
    {
        if (newDocUrls.length === 0)
        {
            console.log("No new docs to upload.");

            if (docUrlsToDelete.length > 0)
            {
                console.log("some docs to delete");
                return handleSubmit(msgFromPhotos, "docs to delete");
            }

            return handleSubmit(msgFromPhotos, "no docs");
        }

        const formData = new FormData();

        newDocObjects.forEach((file, i) =>
        {
            formData.append(i, file)
        });

        postInfo(formData, "visitdocsupload", "POST", handleSubmit, msgFromPhotos);
    }

    const handleSubmit = async (imgUploadMessage, docUploadMessage) =>
    {
        setVisitImagesUpdated("");
        setVisitDocsUpdated("");
        setVisitInfoUpdated("");
        setShowVisitAlerts(false);

        let myObject = {};
        myObject["proj_id"] = projectId;
        myObject["visit_id"] = visitId;
        myObject["current_user"] = currentUser;
        myObject["timestamp"] = chosenVisitArray[0].timestamp;
        let docsChanged = false;

        let milestoneObject = {};
        if (inputs.milestone !== undefined && inputs.milestone !== "")
        {
            milestoneObject["comment"] = inputs.milestone;
            milestoneObject["Current User"] = currentUser;
            milestoneObject["Timestamp"] = new Date();
            inputs.milestone = milestoneObject;
        }

        // Code to add deleted image urls to db  ------------------------------------
        let imagesToDeleteObject = {};
        imagesToDeleteObject["proj_id"] = projectId;
        imagesToDeleteObject["deleted_by_user"] = currentUser;
        imagesToDeleteObject["site_visit_added"] = chosenVisitArray[0].timestamp;
        imagesToDeleteObject["visit_id"] = chosenVisitArray[0]._id;

        if (imagesToDelete.length > 0) 
        {
            imagesToDeleteObject["deletedImageUrlArray"] = imagesToDelete;

            try
            {
                const response = await post(url.DELETE_VISIT_IMAGES, imagesToDeleteObject);
                console.log(response);
            }
            catch (error)
            {
                console.log("This is the try catch error msg for deleted images: " + error);
            }
        }
        // ---------------------------------------------------------------

        // code to update site visit image urls on database ------------------------------------------
        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no photos" || imgUploadMessage !== "Error" && imgUploadMessage === "photos to delete")
        {
            let imgUrlArray = [];
            let imgInfoObject = {};
            let imgFailedArray = [];

            if (imgUploadMessage !== "photos to delete")
            {
                let imagePathsArray = Object.keys(imgUploadMessage);
                let imageUploadStatusArray = Object.values(imgUploadMessage);

                imagePathsArray.forEach((img, i) =>
                {
                    if (imageUploadStatusArray[i] === "Success")
                    {
                        let fullPath = `${API_URL}/${img}`;
                        imgUrlArray.push(fullPath);
                    }
                    else if (imageUploadStatusArray[i] === "Failed")
                    {
                        imgFailedArray.push(img);
                    }
                });
            }

            if (imgFailedArray.length > 0)
            {
                console.log(imgFailedArray);
                console.log(imgFailedArray.length + " image/s failed to upload.");
                setVisitImagesError(imgFailedArray.length + " image/s failed to upload.");
            }
            else
            {
                setVisitImagesError("");
            }

            imgInfoObject["proj_id"] = myObject.proj_id;
            imgInfoObject["added_by_user"] = currentUser;
            imgInfoObject["added_timestamp"] = chosenVisitArray[0].timestamp;
            imgInfoObject["visit_id"] = chosenVisitArray[0]._id;

            if (imgUploadMessage !== "photos to delete")
            {
                let oldImageArray = imageUrls;
                let newImageArray = imgUrlArray;
                const combinedImageArray = [...oldImageArray, ...newImageArray];
                imgInfoObject["imageUrlArray"] = combinedImageArray;
            }
            else
            {
                imgInfoObject["imageUrlArray"] = imageUrls;
            }

            try
            {
                docsChanged = true;
                const response = await post(url.UPDATE_VISIT_IMAGES, imgInfoObject);

                if ((response.message !== undefined && response.message === "Site visit images updated!")
                    || (response.message !== undefined && response.message === "New site visit images have been added to database."))
                {
                    setVisitImagesUpdated("Site visit images updated");
                    console.log("Site visit images updated on db");
                }
                else
                {
                    setVisitImagesUpdated("Site visit images NOT updated. Something went wrong.");
                    console.log("Something went wrong while updating site visit images");
                }
            }
            catch (error)
            {
                setVisitImagesUpdated("Site visit images NOT updated. Something went wrong.");
                console.log("This is the try catch error msg for updating site visit images: " + error);
            }
        }
        // --------------------------------------------------------

        // Code to add deleted doc names and urls to db  ------------------------------------
        let docsToDeleteObject = {};

        if (docUrlsToDelete.length > 0) 
        {
            docsToDeleteObject["deletedDocNamesArray"] = docNamesToDelete;
            docsToDeleteObject["deletedDocUrlArray"] = docUrlsToDelete;
            docsToDeleteObject["proj_id"] = projectId;
            docsToDeleteObject["site_visit_added"] = chosenVisitArray[0].timestamp;
            docsToDeleteObject["deleted_by_user"] = currentUser;
            docsToDeleteObject["visit_id"] = chosenVisitArray[0]._id;

            try
            {
                const response = await post(url.DELETE_VISIT_DOCS, docsToDeleteObject);
                console.log(response);
            }
            catch (error)
            {
                console.log("This is the try catch error msg for deleted docs: " + error);
            }
        }
        // ---------------------------------------------------------------

        // Code to save uploaded document urls and names to database
        let docUrlArray = [];
        let docInfoObject = {};

        if (docUploadMessage !== "Error" && docUploadMessage !== "no docs" || docUploadMessage !== "Error" && docUploadMessage === "docs to delete")
        {
            docInfoObject["proj_id"] = myObject.proj_id;
            docInfoObject["added_by_user"] = currentUser;
            docInfoObject["added_timestamp"] = chosenVisitArray[0].timestamp;
            docInfoObject["visit_id"] = chosenVisitArray[0]._id;

            if (docUploadMessage !== "docs to delete")
            {
                let docPathsArray = Object.keys(docUploadMessage);
                let docUploadStatusArray = Object.values(docUploadMessage);

                docPathsArray.forEach((doc, i) =>
                {
                    if (docUploadStatusArray[i] === "Success")
                    {
                        let fullPath = `${API_URL}/${doc}`;
                        docUrlArray.push(fullPath);
                    }
                });

                let oldDocUrlArray = docUrls;
                let oldDocNamesArray = docNames;

                let newDocUrlArray = docUrlArray;
                let newDocNamesArray = newDocNames;

                const combinedDocUrlArray = [...oldDocUrlArray, ...newDocUrlArray];
                const combinedDocNamesArray = [...oldDocNamesArray, ...newDocNamesArray];

                docInfoObject["docUrlArray"] = combinedDocUrlArray;
                docInfoObject["docNamesArray"] = combinedDocNamesArray;
            }
            else
            // if there are docs to delete
            {
                docInfoObject["docUrlArray"] = docUrls;
                docInfoObject["docNamesArray"] = docNames;
            }

            try
            {
                docsChanged = true;
                const response = await post(url.UPDATE_VISIT_DOCS, docInfoObject);

                if ((response.message !== undefined && response.message === "Site visit documents updated!")
                    || (response.message !== undefined && response.message === "New site visit documents have been added to database."))
                {
                    setVisitDocsUpdated("Site visit documents updated!");
                    console.log(response.message);
                }
                else
                {
                    setVisitDocsUpdated("Site visit documents NOT updated. Something went wrong.");
                    console.log("Something went wrong with updating site visit documents");
                }
            }
            catch (error)
            {
                setVisitDocsUpdated("Site visit documents NOT updated. Something went wrong.");
                console.log("This is the try catch error msg for updated site visit documents: " + error);
            }
        }

        // code to save project data to database
        if (Object.keys(inputs).length !== 0 && inputs.constructor === Object)
        {
            Object.assign(myObject, inputs);

            try
            {
                docsChanged = true;
                const response = await post(url.UPDATE_VISIT, myObject);

                if (response.message !== undefined && response.message === "Site visit updated!")
                {
                    setVisitInfoUpdated("Site visit information updated");
                    console.log(response.message);
                } else
                {
                    setVisitInfoUpdated("Site visit information NOT updated. Something went wrong.");
                    console.log("Site visit information NOT updated. Something went wrong.");
                }
            }
            catch (error)
            {
                setVisitInfoUpdated("Site visit information NOT updated. Something went wrong.");
                console.log("This is the try catch error msg: " + error);
            }
        }

        if (docsChanged === true)
        {
            setCounter(timerOff);
            setInputs({});

            setNumOfImages(0);
            setNumOfNewImages(0);
            setImageUrls([]);
            setNewImageUrls([]);
            setNewImageObjects({});
            setImagesToDelete([]);
            setImgNames([]);
            setMarkedImages([]);
            setIsLoadedImages(false);

            setNumOfDocs(0);
            setDocUrls([]);
            setDocNames([]);
            setNumOfNewDocs(0);
            setNewDocUrls([]);
            setNewDocNames([]);
            setNewDocObjects([]);
            setDocUrlsToDelete([]);
            setDocNamesToDelete([]);
            setDocUrlsToDelete([]);
            setIsLoadedDocs(false);

            setShowVisitAlerts(true);
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });

            setIsLoaded(false);
            toggleVisitChosen();
            toggleVisitInfoChanged();
            console.log("Changes were made.");
        }
        else if (docsChanged === false)
        {
            swal("Error", "Nothing was changed", "error");
            console.log("No changes");
        }

        setUpdating(false);
    }

    // const addManagers = (value) =>
    // {
    //     if (value !== undefined)
    //     {
    //         value = Number(value);
    //     }

    //     if (value !== undefined && value >= 0)
    //     {
    //         setInputs(prevState => ({ ...prevState, ["mgrs_onsite"]: value }));
    //         setManagers(value);
    //     }

    //     if (managers + 1 >= 0 && value === undefined)
    //     {
    //         setInputs(prevState => ({ ...prevState, ["mgrs_onsite"]: managers + 1 }));
    //         setManagers(managers + 1);
    //     }
    // }

    // const minusManagers = () =>
    // {
    //     if (managers - 1 >= 0)
    //     {
    //         setInputs(prevState => ({ ...prevState, ["mgrs_onsite"]: managers - 1 }));
    //         setManagers(managers - 1);
    //     }
    // }

    // const changeTotalStaff = () =>
    // {
    //     let allStaff = managers + localStaff;

    //     if (loadedStaffArrays && allStaff !== totalStaff)
    //     {
    //         setInputs(prevState => ({ ...prevState, ["total_staff_onsite"]: allStaff }));
    //         setTotalStaff(allStaff);
    //     }
    // }

    // const changeLocalStaff = () =>
    // {
    //     let number = staffArray.length;

    //     if (loadedStaffArrays && number !== localStaff)
    //     {
    //         setInputs(prevState => ({ ...prevState, ["local_staff_onsite"]: number }));
    //         setLocalStaff(number)
    //     }
    // }

    const calcTimeBetween = (visitDate) => 
    {
        let today = new Date();

        if (visitDate !== undefined)
        {
            var minutes = Math.abs(today - visitDate) / 60000;
            return minutes
        }
    }

    const setIncidentFalse = () =>
    {
        setInputs(prevState => ({ ...prevState, ["incident_severity"]: "" }));
        setInputs(prevState => ({ ...prevState, ["incident_type"]: "" }));
        setInputs(prevState => ({ ...prevState, ["incident_details"]: "" }));
    }

    const updatePhotosUploaded = (from) =>
    {
        if (from === "add"
            && (chosenVisitArray[0].photos_uploaded === false
                || chosenVisitArray[0].photos_uploaded === undefined))
        {
            setInputs(prevState => ({ ...prevState, ["photos_uploaded"]: true }));
        }
        else if (inputs.photos_uploaded === false)
        {
            setInputs(prevState => ({ ...prevState, ["photos_uploaded"]: true }));
        }

        if (from === "remove-old" && imageUrls.length === 0)
        {
            setInputs(prevState => ({ ...prevState, ["photos_uploaded"]: false }));
        }

        if (from === "remove-new"
            && newImageUrls.length === 0
            && imageUrls.length === 0
            && (chosenVisitArray[0].photos_uploaded === true
                || inputs.photos_uploaded === true))
        {
            setInputs(prevState => ({ ...prevState, ["photos_uploaded"]: false }));
        }
    }

    const chckForManagers = (type, array) =>
    {
        let result = checkForManagers(
            type,
            array,
            currentStaffObject,
            setCurrentStaffObject,
            addManagers,
            managers,
            minusManagers,
            setInputs,
            setManagers);
        return result;
    }

    useEffect(() =>
    {
        if (projectId !== 0 && projectsArray.length > 0)
        {
            loadCurrentStaffObject();
        }
    }, [managers, visitChanged, projectsArray]);

    useEffect(() =>
    {
        if (activeTab === "3")
        {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }

        if (activeTab !== "3")
        {
            cancelEdit();
        }
    }, [activeTab]);

    useEffect(() =>
    {
        handleVisitChosenFromViewPage();
    }, [chosenFromViewPage]);

    useEffect(() =>
    {
        if (siteVisitsArray.length > 0)
        {
            loadDropdownArray();
        }
    }, [siteVisitsArray, visitChanged]);

    useEffect(() =>
    {
        if (chosenVisitArray.length > 0)
        {
            //loadStaffNames();
            loadActivArray();
            loadEquipmentArray(chosenVisitArray, setEquipArray, setEquipRows, setEquipObject, setEquipCountArray);
            loadStaffArrays();
        }
    }, [chosenVisitArray, visitChanged, loadedStaffArrays]);

    useEffect(() =>
    {
        sortSiteVisitDocs();
        sortVisitImages();
    }, [siteVisitImagesArray, chosenVisitArray, visitChanged]);

    useEffect(() =>
    {
        if (editPgDropdownValue !== "-1" && (canEdit || isAdmin))
        {
            setVisitChosen(true);
            setCounter(timerSeconds);
        }

        setNumOfImages(0);
        setNumOfNewImages(0);
        setImageUrls([]);
        setNewImageUrls([]);
        setNewImageObjects({});
        setImagesToDelete([]);
        setImgNames([]);
        setMarkedImages([]);
        setIsLoadedImages(false);

        setNumOfDocs(0);
        setDocUrls([]);
        setDocNames([]);
        setIsLoadedDocs(false);

        setLoadedStaffArrays(false);
        setStaffArray([]);
        setStaffRows(1);

        setActivityArray([]);
        setActivityRows(1);

        setEquipRows(1);
        setEquipCountArray([]);
        setEquipArray([]);
        setEquipObject({});

        setIsLoaded(false);
        toggleVisitChanged();
    }, [visitDate]);

    useEffect(() =>
    {
        if (counter === 0)
        {
            cancelEdit();
        }

        if (counter === 120)
        {
            swal("Warning", "2 minutes left", "info");
        }

        let interval = null;
        if (counter !== timerOff && visitChosen)
        {
            interval = setTimeout(() => setCounter(counter => counter - 1), 1000);
        }
        else if (!visitChosen && counter !== 0)
        {
            clearTimeout(interval);
        }

        return () => clearTimeout(interval);

    }, [counter, visitChosen]);

    useEffect(() =>
    {
        if (visitChosen && Object.keys(inputs).length !== 0)
        {
            setCounter(timerSeconds);
        }
    }, [inputs]);

    useEffect(() =>
    {
        if (canEditCondition1 && canEditCondition2)
        {
            setCanEdit(true);
        }
        else
        {
            setCanEdit(false);
        }
    }, [canEditCondition1, canEditCondition2]);

    return (
        <div>
            {/* <div>Visit id: {visitId}, visitDate: {visitDate}, projId: {projectId}</div> */}

            {/* <div>
                isloaded:
                <pre>{JSON.stringify(isLoaded)}</pre>
                visitDate:
                <pre>{visitDate}</pre>
                numOfDocs:
                <pre>{numOfDocs}</pre>
                visitChanged:
                <pre>{JSON.stringify(visitChanged)}</pre>
                Inputs object
                <pre>{JSON.stringify(inputs, null, 4)}</pre>
                ChosenVisitArray
                <pre>{JSON.stringify(chosenVisitArray, null, 4)}</pre>
                <p>canEdit: {canEdit.toString()}</p>
            </div> */}

            <div>
                {/* Inputs object
                <pre>{JSON.stringify(inputs, null, 4)}</pre>
                Staff array
                <pre>{JSON.stringify(staffArray, null, 4)}</pre>
                Staff names array
                <pre>{JSON.stringify(staffNamesArray, null, 4)}</pre>
                Total staff; {totalStaff}, local Staff: {localStaff}, managers: {managers} */}
                {/* <p>Counter: {counter}</p>
                <p>VisitChosen: {visitChosen.toString()}</p>
                <p>image url array length:</p>
                <p>marked images length: {markedImages.length}</p> */}
            </div>

            {counter > 0 && counter >= 60
                ? <p className='redText' style={{ textAlign: "right", fontWeight: "bold" }}>Time left: <span style={{ color: 'black' }}>{Math.ceil(counter / 60)} minute/s</span></p>
                : counter > 0
                    ? <p className='redText' style={{ textAlign: "right", fontWeight: "bold" }}>Time left: <span style={{ color: 'black' }}>{counter} seconds</span></p>
                    : null}

            {isLoaded && visitsDropdownArray.length > 0
                ? <div>
                    <FormGroup row className="mb-4">
                        <Label
                            for="projToEdit"
                            sm={2}
                        >
                            Choose Site Visit to Edit
                        </Label>
                        <Col sm={10}>
                            <Input
                                onChange={(e) => { setEditPgDropdownValue(e.target.value); handleChooseVisitFromDropdown(e); }}
                                id="visitToEdit"
                                name="visitToEdit"
                                type="select"
                                value={editPgDropdownValue}
                            >
                                <option key={visitsDropdownArray[0].timestamp} value={"-1"}>Choose Option</option>
                                {visitsDropdownArray.map((visit, index) => (
                                    <option key={index}
                                        value={`${visit._id}///${visit.proj_id}///${visit.timestamp}`}>Project ID: {visit.proj_id} - {visitDates !== undefined
                                            ? visitDates[index]
                                            : null} {visit.proj_descrip !== undefined && visit.proj_descrip !== "" ? "- " + visit.proj_descrip : null}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </FormGroup>
                </div>
                : null}

            {isLoaded && chosenVisitArray.length > 0 && chosenVisitArray[0].isActive === 0 && editPgDropdownValue !== "-1"
                ? <p style={{ color: "red" }}>This site visit is currently inactive.</p>
                : null}

            {numOfImages > 0 && (canEdit || isAdmin)
                ? <Button className="ms-1 mb-2" color="primary" outline onClick={() =>
                { window.location.assign("#visitPhotoSection") }}>
                    <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                    <Badge className="ms-2 bg-danger">
                        {numOfImages}
                    </Badge>
                </Button>
                : null}

            {numOfDocs > 0 && (canEdit || isAdmin)
                ? <Button className="ms-1 mb-2" color="primary" outline onClick={() =>
                { window.location.assign("#visitDocSection") }}>
                    <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                    <Badge className="ms-2 bg-danger">
                        {numOfDocs}
                    </Badge>
                </Button>
                : null}

            {isLoaded && chosenVisitArray.length > 0 && (canEdit || isAdmin)
                ?
                <Form
                    name="editSiteVisitsForm"
                    onSubmit={(e) => { handleSubmitPhoto(); e.preventDefault(); }}
                >
                    <FormGroup row>
                        <Label
                            for="proj_id"
                            sm={2}
                        >
                            Project ID
                        </Label>
                        <Col className="col-sm-10">
                            <Input
                                id="proj_id"
                                name="proj_id"
                                type="text"
                                disabled
                                value={chosenVisitArray[0].proj_id || ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="proj_descrip"
                            sm={2}
                        >
                            Project Description (name)
                        </Label>
                        <Col className="col-sm-10">
                            <Input
                                id="proj_descrip"
                                name="proj_descrip"
                                type="text"
                                value={
                                    inputs.proj_descrip === undefined
                                        ? chosenVisitArray[0].proj_descrip
                                        : inputs.proj_descrip !== undefined
                                            ? inputs.proj_descrip
                                            : ''
                                }
                                disabled
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            sm={2}
                        >
                            Date Added
                        </Label>
                        <Col className="col-sm-10">
                            <Input
                                id="timestamp"
                                type="text"
                                disabled
                                value={dateAdded || ''}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.onsite_today || ''}
                    >
                        <Label
                            for="onsite_today"
                            sm={2}
                        >
                            Are you on site today?
                        </Label>
                        <Col sm={4}>
                            <Button color={chosenVisitArray[0].onsite_today === true && inputs.onsite_today === undefined
                                ? "success"
                                : inputs.onsite_today === "true"
                                    ? "success"
                                    : "secondary"} className='me-1' name="onsite_today" onClick={(e) => { handleChange(e); }} value={true}>
                                Yes
                            </Button>
                            <Button color={chosenVisitArray[0].onsite_today === false && inputs.onsite_today === undefined
                                ? "danger"
                                : inputs.onsite_today === "false"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="onsite_today" onClick={(e) => { handleChange(e); }} value={false}>
                                No
                            </Button>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="addNewPhoto"
                            sm={2}
                        >
                            Upload New Photo/s
                        </Label>
                        <Col className="col-sm-10 col-12 mb-2">
                            <Input
                                id="addNewPhoto"
                                name="addNewPhoto"
                                type="file"
                                onChange={(e) => { updatePhotosUploaded("add"); changePhotoHandler(e, setNumOfNewImages, setNewImageObjects, setNewImageUrls, setImgNames); }}
                                multiple
                            />
                        </Col>
                    </FormGroup>

                    {/* New images section */}
                    <div className={numOfNewImages > 0 ? 'p-2 mb-1 row' : ""}>
                        {numOfNewImages > 0 ? <div className='mb-4 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfNewImages}
                                </Badge> New
                            </Button>
                        </div> : null}

                        {
                            newImageUrls.length > 0
                                ? newImageUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-2 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y" style={{ right: "0.05rem" }} color="danger" onClick={(e) => removeNewImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                        <div className='p-1'>{imgNames[index]}</div>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    {/* Existing images section */}
                    <div id="visitPhotoSection" className={numOfImages > 0 ? 'mb-1 p-2 row' : 'row'}>
                        {numOfImages > 0 ? <div className='mb-4 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfImages}
                                </Badge> Existing
                            </Button></div> : null}
                        {
                            imageUrls.length > 0
                                ? imageUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button className="position-absolute top-0 translate-middle-y"
                                            style={{ right: "0.05rem" }} color="danger" onClick={(e) => removeImage(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        }

                        {/* Existing images marked for deletion */}
                        {markedImages.length > 0 && numOfImages === 0 ? <div className='mb-4 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faCameraRetro} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfImages}
                                </Badge> Existing
                            </Button></div> : null}
                        {
                            markedImages.length > 0
                                ? markedImages.map((url, index) => (
                                    <div key={index} className="mx-1 mb-4 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        <Button color="danger" className="col-12" disabled
                                        >
                                            Marked for Deletion
                                        </Button>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    <FormGroup row>
                        <Label
                            for="addNewDoc"
                            sm={2}
                        >
                            Upload New Document/s
                        </Label>
                        <Col className="col-sm-10 col-12 mb-1">
                            <Input
                                id="addNewDoc"
                                name="addNewDoc"
                                type="file"
                                onChange={(e) => changeDocHandler(e, setNewDocNames, setNumOfNewDocs, setNewDocObjects, setNewDocUrls)}
                                multiple
                            />
                        </Col>
                    </FormGroup>

                    {/* New docs section */}
                    <div className={numOfNewDocs > 0 ? 'p-2 mb-1 row' : ""}>
                        {numOfNewDocs > 0 ? <div className='mb-2 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfNewDocs}
                                </Badge> New
                            </Button>
                        </div> : null}
                        {
                            newDocUrls.length > 0
                                ? newDocUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mt-1 mb-1 col-12 col-sm-12">
                                        <a href={url} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon className="me-2" icon={faFileLines} transform="grow-10" />
                                            {newDocNames[index]}
                                        </a>
                                        <Button color="danger" className="ms-2" onClick={(e) => removeNewDoc(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    {numOfNewDocs > 0 ? <hr /> : null}

                    {/* Existing docs section */}
                    <div id="visitDocSection" className={numOfDocs > 0 ? 'p-2 mb-1 row' : ""}>
                        {numOfDocs > 0 ? <div className='mb-2 col-12'>
                            <Button className="mb-2" color="primary" outline>
                                <FontAwesomeIcon icon={faFileLines} transform="grow-10" />
                                <Badge className="ms-2 bg-danger">
                                    {numOfDocs}
                                </Badge> Existing
                            </Button>
                        </div> : null}
                        {
                            docUrls.length > 0
                                ? docUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mt-1 mb-1 col-12 col-sm-12">
                                        <a href={url} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon className="me-2" icon={faFileLines} transform="grow-10" />
                                            {docNames[index]}
                                        </a>
                                        <Button className="ms-2"
                                            color="danger" onClick={(e) => removeDoc(e, index)}>
                                            <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                        </Button>
                                    </div>
                                ))
                                : null
                        }
                    </div>

                    {/* <div>
                        <p>Current index:</p>
                        <pre>{currentIndex}</pre>
                        <p>Staff rows:</p>
                        <pre>{staffRows}</pre>
                        <p>StaffPresenceArray:</p>
                        <pre>{JSON.stringify(staffPresenceArray, null, 4)}</pre>
                        <p>staffArray:</p>
                        <pre>{JSON.stringify(staffArray, null, 4)}</pre>
                        <p>staffnamesArray:</p>
                        <pre>{JSON.stringify(staffNamesArray, null, 4)}</pre>
                        <p>staffObject:</p>
                        <pre>{JSON.stringify(staffObject, null, 4)}</pre>
                        <p>Inputs:</p>
                        <pre>{JSON.stringify(inputs, null, 4)}</pre>
                        <p>totalStaff, localStaff, managers :</p>
                        <pre>{totalStaff}, {localStaff}, {managers}</pre>
                    </div> */}

                    <FormGroup row>

                        <Label
                            for="staff_register"
                            sm={2}
                        >
                            Local Staff Head Count
                        </Label>

                        {staffDivArray}
                        <Col>
                            <Button className='me-1 mb-2' onClick={addStaffRw}>
                                Add
                            </Button>
                            <Button className='me-1 mb-2' onClick={addAllStaffRws}>
                                Add All
                            </Button>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="mgrs_onsite"
                            sm={4}
                        >
                            How many of those were management?
                        </Label>
                        <Col sm={2}>
                            <Input
                                id="mgrs_onsite"
                                name="mgrs_onsite"
                                placeholder="..."
                                type="number"
                                value={inputs.mgrs_onsite || managers}
                                disabled
                            // onWheel={(e) => e.target.blur()}
                            // onChange={(e) =>
                            //     addManagers(e.target.value)}
                            />
                        </Col>
                        {/* <Col sm={2}>
                            <Button className="ms-1 mt-1 mt-sm-0"
                                color="success"
                                onClick={() =>
                                {
                                    addManagers();
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} transform="grow-10" />
                            </Button>

                            <Button className="ms-1 mt-1 mt-sm-0"
                                color="danger"
                                onClick={() =>
                                {
                                    minusManagers();
                                }}
                            >
                                <FontAwesomeIcon icon={faMinus} transform="grow-10" />
                            </Button>
                        </Col> */}
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="local_staff_onsite"
                            sm={4}
                        >
                            How many of those were local employees?
                        </Label>
                        <Col sm={2}>
                            <Input
                                id="local_staff_onsite"
                                name="local_staff_onsite"
                                placeholder="..."
                                type="number"
                                value={inputs.local_staff_onsite || localStaff}
                                // value={staffArray.length === 0
                                //     ? 0
                                //     : staffArray.length > 0
                                //         ? staffArray.length
                                //         : ''}
                                onWheel={(e) => e.target.blur()}
                                readOnly
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="total_staff_onsite"
                            sm={4}
                        >
                            How many staff were on site today (in total)?
                        </Label>
                        <Col sm={2}>
                            <Input
                                id="total_staff_onsite"
                                name="total_staff_onsite"
                                placeholder="..."
                                type="number"
                                value={inputs.total_staff_onsite || totalStaff}
                                onWheel={(e) => e.target.blur()}
                                readOnly
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="milestone"
                            sm={4}
                        >
                            What milestones were achieved today?
                        </Label>
                        <Col sm={8}>
                            <Input
                                id="milestones"
                                name="milestone"
                                placeholder="Quick status update"
                                type="textarea"
                                value={inputs.milestone === undefined
                                    ? chosenVisitArray[0].milestone.comment
                                    : inputs.milestone !== undefined
                                        ? inputs.milestone
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.induction_done || ''}
                    >
                        <legend className="col-form-label col-sm-4">
                            Was the induction completed today?
                        </legend>
                        <Col sm={4}>
                            <Button color={chosenVisitArray[0].induction_done === true && inputs.induction_done === undefined
                                ? "success"
                                : inputs.induction_done === "true"
                                    ? "success"
                                    : "secondary"} className='me-1' name="induction_done" onClick={(e) => { handleChange(e); }} value={true}>
                                Yes
                            </Button>

                            <Button color={chosenVisitArray[0].induction_done === false && inputs.induction_done === undefined
                                ? "danger"
                                : inputs.induction_done === "false"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="induction_done" onClick={(e) => { handleChange(e); }} value={false}>
                                No
                            </Button>
                        </Col>
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.health_safety_check || chosenVisitArray[0].health_safety_check || ''}
                    >
                        <legend className="col-form-label col-sm-4">
                            Was the health and safety check completed today?
                        </legend>
                        <Col sm={4}>
                            <Button color={chosenVisitArray[0].health_safety_check === "yes" && inputs.health_safety_check === undefined
                                ? "success"
                                : inputs.health_safety_check === "yes"
                                    ? "success"
                                    : "secondary"} className='me-1' name="health_safety_check" onClick={(e) => { handleChange(e); }} value="yes">
                                Yes
                            </Button>

                            <Button color={chosenVisitArray[0].health_safety_check === "no" && inputs.health_safety_check === undefined
                                ? "danger"
                                : inputs.health_safety_check === "no"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="health_safety_check" onClick={(e) => { handleChange(e); }} value="no">
                                No
                            </Button>

                            <Button color={chosenVisitArray[0].health_safety_check === "partly" && inputs.health_safety_check === undefined
                                ? "warning"
                                : inputs.health_safety_check === "partly"
                                    ? "warning"
                                    : "secondary"} className='me-1' name="health_safety_check" onClick={(e) => { handleChange(e); }} value="partly">
                                Partly
                            </Button>
                        </Col>
                    </FormGroup>

                    <FormGroup
                        row
                        tag="fieldset"
                        value={inputs.incident_onsite || ''}
                    >
                        <legend className="col-form-label col-sm-4">
                            Were there any incidents onsite today?
                        </legend>
                        <Col sm={4}>
                            <Button color={chosenVisitArray[0].incident_onsite === true && inputs.incident_onsite === undefined
                                ? "success"
                                : inputs.incident_onsite === "true"
                                    ? "success"
                                    : "secondary"} className='me-1' name="incident_onsite" onClick={(e) => { handleChange(e); }} value={true}>
                                Yes
                            </Button>

                            <Button color={chosenVisitArray[0].incident_onsite === false && inputs.incident_onsite === undefined
                                ? "danger"
                                : inputs.incident_onsite === "false"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="incident_onsite" onClick={(e) => { setIncidentFalse(); handleChange(e); }} value={false}>
                                No
                            </Button>
                        </Col>
                    </FormGroup>

                    {(inputs.incident_onsite === undefined && chosenVisitArray[0].incident_onsite === true) || inputs.incident_onsite === "true" ?
                        <FormGroup
                            row
                            tag="fieldset"
                            value={inputs.incident_severity || ''}
                        >
                            <legend className="col-form-label col-sm-3">
                                Was it a major incident or a minor incident?
                            </legend>
                            <Col sm={4}>
                                <Button color={chosenVisitArray[0].incident_severity === "major" && inputs.incident_severity === undefined
                                    ? "danger"
                                    : inputs.incident_severity === "major"
                                        ? "danger"
                                        : "secondary"} className='me-1' name="incident_severity" onClick={(e) => { handleChange(e); }} value="major">
                                    Major
                                </Button>
                                <Button color={chosenVisitArray[0].incident_severity === "minor" && inputs.incident_severity === undefined
                                    ? "warning"
                                    : inputs.incident_severity === "minor"
                                        ? "warning"
                                        : "secondary"} className='me-1' name="incident_severity" onClick={(e) => { handleChange(e); }} value="minor">
                                    Minor
                                </Button>
                            </Col>
                        </FormGroup> : null}

                    {(inputs.incident_onsite === undefined && chosenVisitArray[0].incident_onsite === true) || inputs.incident_onsite === "true"
                        ? <FormGroup row>
                            <Label
                                for="incident_type"
                                sm={2}
                            >
                                What type of incident was it?
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="incidentType"
                                    name="incident_type"
                                    type="select"
                                    onChange={(e) => { handleChange(e); }}
                                    value={inputs.incident_type === undefined
                                        ? chosenVisitArray[0].incident_type
                                        : inputs.incident_type
                                        || ''}
                                > {incidentType.map((option, index) => (
                                    <option key={index} value={option.value}>{option.label}</option>
                                ))}
                                </Input>
                            </Col>
                        </FormGroup>
                        : null}

                    {(inputs.incident_onsite === undefined && chosenVisitArray[0].incident_onsite === true) || inputs.incident_onsite === "true"
                        ? <FormGroup row>
                            <Label
                                for="incident_details"
                                sm={2}
                            >
                                Explain the incident in detail
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="incidentDetails"
                                    name="incident_details"
                                    placeholder="What happened?"
                                    type="textarea"
                                    onChange={handleChange}
                                    value={inputs.incident_details === undefined
                                        ? chosenVisitArray[0].incident_details
                                        : inputs.incident_details !== undefined
                                            ? inputs.incident_details
                                            : ''}
                                />
                            </Col>
                        </FormGroup>
                        : null}

                    {/* <div>
                        <p>Activity array:</p>
                        <pre>{JSON.stringify(activityArray, null, 4)}</pre>
                        <p>Inputs:</p>
                        <pre>{JSON.stringify(inputs, null, 4)}</pre>
                    </div> */}

                    <FormGroup row >
                        <Row>
                            <Label
                                for="activities_onsite"
                                className="col-sm-2 col-md-3"
                            >
                                What kinds of activities were there on site today?
                            </Label>
                            <Col>
                                <Button className='me-1 mb-2' onClick={addActivRow}>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                        {activityDivArray}
                    </FormGroup>

                    {/* <div>
                        <p>Current index: {currentEquipIndex}</p>
                        <p>Inputs:</p>
                        <pre>{JSON.stringify(inputs, null, 4)}</pre>
                        <p>equipArray:</p>
                        <pre>{JSON.stringify(equipArray, null, 4)}</pre>
                        <p>equipObject:</p>
                        <pre>{JSON.stringify(equipObject, null, 4)}</pre>
                        <p>equipCountArray:</p>
                        <pre>{JSON.stringify(equipCountArray, null, 4)}</pre>
                    </div> */}

                    <FormGroup row>
                        <div className="col-12 col-sm-5 col-md-8 mb-2">
                            <Label
                                for="plant_equip_list"
                                className="col-sm-6 col-8 col-md-5 align-middle"
                            >
                                Plant and Equipment: What was seen during your visit?
                            </Label>
                            <Button className="ms-3 " onClick={addEquipRw}>Add</Button>
                        </div>
                        {equipmentArray}
                    </FormGroup>

                    <div className="col-12">
                        <Button className="ms-1" color="primary" disabled={isUpdating} type="submit">
                            {isUpdating ? 'Updating…' : 'Update'}
                        </Button>
                        <Button className="ms-1" color="danger" onClick={() => cancelEdit()}>
                            Cancel
                        </Button>
                    </div>

                </Form>
                : isLoaded && chosenVisitArray.length > 0 && !canEdit
                    ? <div className="redText">You do not have permission to edit this site visit.</div>
                    : visitId === "0" && isLoaded && siteVisitsArray.length === 0
                        ? <div className="redText">No site visits saved yet.</div>
                        : !isLoaded
                            ? <div>Loading...</div>
                            : null
            }
        </div>
    );
}

export default EditSiteVisitsForm;

EditSiteVisitsForm.propTypes = {
    setActiveTab: PropTypes.func,
    setVisitDocsUpdated: PropTypes.func,
    setVisitInfoUpdated: PropTypes.func,
    setVisitImagesUpdated: PropTypes.func,
    setShowVisitAlerts: PropTypes.func,
    projectId: PropTypes.number,
    setProjectId: PropTypes.func,
    visitDate: PropTypes.string,
    setVisitDate: PropTypes.func,
    isAdmin: PropTypes.bool,
    chosenFromViewPage: PropTypes.bool,
    toggleVisitChosen: PropTypes.func,
    setIsLoadedImages: PropTypes.func,
    setIsLoaded: PropTypes.func,
    setIsLoadedDocs: PropTypes.func,
    isLoadedImages: PropTypes.bool,
    isLoaded: PropTypes.bool,
    toggleVisitInfoChanged: PropTypes.func,
    siteVisitsArray: PropTypes.array,
    setSiteVisitsArray: PropTypes.func,
    msgFromServer: PropTypes.string,
    visitImagesArray: PropTypes.array,
    siteVisitDocsArray: PropTypes.array,
    setVisitImagesError: PropTypes.func,
    thisUser: PropTypes.string,
    activeTab: PropTypes.string,
    visitId: PropTypes.string,
    editPgDropdownValue: PropTypes.string,
    setEditPgDropdownValue: PropTypes.func,
    projectsArray: PropTypes.array,
    projectMsgFromServer: PropTypes.string,
}