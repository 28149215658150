import React from "react";
import
{
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Alert
} from "reactstrap";
import classnames from "classnames";
import MetaTags from "react-meta-tags";
import { useState, useEffect } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { decryptUser } from "../SharedFiles/decryptUser";
import ViewUsers from "./ViewUsers";
import useCrypto from "pages/SharedFiles/useCrypto";
import getLogins from 'pages/SharedFiles/getLogins';
import CreateUser from "../Projects/CreateUser";
import EditUser from "./EditUser";

function Admin()
{
    const [activeTab, setActiveTab] = useState("1");
    const [showPage, setShowPage] = useState(true);
    const [loginsArray, setLoginsArray] = useState([]);
    const [currentUser, setCurrentUser] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [loginsChanged, setLoginsChanged] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [isDeactivating, setIsDeactivating] = useState(false);

    const toggle = tab =>
    {
        if (activeTab !== tab)
        {
            setActiveTab(tab);
        }
    };

    const toggleLoginsChanged = () =>
    {
        setLoginsChanged(current => !current);
    };

    const setLogins = async () =>
    {
        let oldUser = decryptUser();
        await CreateUser(oldUser.email);
        let authUser = decryptUser();

        if (authUser !== undefined)
        {
            //console.log("Role is: " + authUser.role);
            setCurrentUser(authUser.username);
            setCurrentUserId(authUser._id);

            if (authUser.role !== "admin")
            {
                setShowPage(false);
            }

            let tempLoginsArray = useCrypto("decrypt", sessionStorage.getItem("loginsArray"));
            setLoginsArray(tempLoginsArray);
        }
    }

    useEffect(() =>
    {
        if (activeTab === "1")
        {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }

    }, [activeTab]);

    useEffect(async () =>
    {
        await getLogins();
        setLogins();
    }, [loginsChanged]);

    return (<React.Fragment>
        <div className="page-content" id="top">
            <MetaTags>
                <title>Admin - CMS</title>
            </MetaTags>
            <Breadcrumb title="Home" breadcrumbItem="Admin" />
            {showPage ?
                // <Container fluid={true}>
                <>
                    <Row className="mb-0">
                        <Col className="col-12 col-sm-12 col-md-6">
                            <Nav pills className="icon-tab nav-justified">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", color: "secondary" }}
                                        className={classnames({
                                            active: activeTab === "1",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle("1");
                                        }}
                                    >
                                        View
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", color: "secondary" }}
                                        className={classnames({
                                            active: activeTab === "2",
                                        })}
                                        onClick={() =>
                                        {
                                            toggle("2");
                                        }}
                                    >
                                        Edit
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col className="col-12 col-sm-6 col-md-12">
                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                <TabPane tabId="1">
                                    {isDeactivating
                                        ? <Alert color="danger" role="alert">
                                            Deactivating...
                                        </Alert> : null}
                                    <ViewUsers
                                        setShowForm={setShowForm}
                                        loginsArray={loginsArray}
                                        currentUser={currentUser}
                                        toggleLoginsChanged={toggleLoginsChanged}
                                        currentUserId={currentUserId}
                                        setActiveTab={setActiveTab}
                                        setCurrentIndex={setCurrentIndex}
                                        setIsDeactivating={setIsDeactivating}
                                    />

                                </TabPane>
                                <TabPane tabId="2">
                                    <EditUser
                                        setShowForm={setShowForm}
                                        showForm={showForm}
                                        loginsArray={loginsArray}
                                        currentUser={currentUser}
                                        toggleLoginsChanged={toggleLoginsChanged}
                                        setActiveTab={setActiveTab}
                                        currentIndex={currentIndex}
                                        activeTab={activeTab}
                                    />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                    {/* </Container> */}
                </> : null}
        </div >
    </React.Fragment>
    );
}

export default Admin;