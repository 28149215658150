export const handleStaffRegisterButton = (event, index, staffArray, staffObject, setInputs, setStaffObject, staffPresenceArray, setStaffPresenceArray) =>
{
    let presence = event.target.value;
    let personName = staffArray[index];

    if (personName !== undefined && personName !== "" && presence !== "")
    {
        let object = {};

        if (Object.keys(staffObject).length === 0)
        {
            object[personName] = presence;
            setInputs(prevState => ({ ...prevState, ["staff_register"]: object }));
            setStaffObject(object);
        }
        else if (Object.keys(staffObject).length === index)
        {
            object = staffObject;
            object[personName] = presence;
            setInputs(prevState => ({ ...prevState, ["staff_register"]: object }));
            setStaffObject(prevState => ({ ...prevState, [personName]: presence }));
        }
        else if (personName !== undefined)
        {
            object = staffObject;
            object[personName] = presence;
            setInputs(prevState => ({ ...prevState, ["staff_register"]: object }));
            setStaffObject(object);
        }
    }

    let tempStaffPresenceArray = [];

    if (staffPresenceArray.length > 0) 
    {
        tempStaffPresenceArray = staffPresenceArray;
    }

    tempStaffPresenceArray[index] = event.target.value;
    setStaffPresenceArray(tempStaffPresenceArray);
}

export const handleStaffArray = (
    name,
    index,
    staffPresenceArray,
    staffArray,
    staffObject,
    setInputs,
    setStaffObject,
    incrementLocalStaff,
    setStaffArray,
    staffRows,
    checkForManagers) =>
{
    let tempArray = [];
    let object = {};
    let personPresence = staffPresenceArray[index];
    let count = 0;

    // Check if person has been selected already
    if (staffRows > 1)
    {
        staffArray.forEach((staffname, index) =>
        {
            if (staffname === name)
            {
                ++count;
            }
        });
    }

    if (personPresence !== undefined && personPresence !== "" && name !== "" && name !== undefined)
    {
        if (Object.keys(staffObject).length === 0)
        {
            object[name] = personPresence;
            setInputs(prevState => ({ ...prevState, ["staff_register"]: object }));
            setStaffObject(object);
        }
        else if (Object.keys(staffObject).length === index)
        {
            object = staffObject;
            object[name] = personPresence;
            setInputs(prevState => ({ ...prevState, ["staff_register"]: object }));
            setStaffObject(prevState => ({ ...prevState, [staffArray[index]]: personPresence }));
        }
        else
        {
            if (count === 0 && staffArray[index] !== undefined && name !== undefined)
            {
                object = staffObject;
                object[name] = object[staffArray[index]];
                delete object[staffArray[index]];
                setInputs(prevState => ({ ...prevState, ["staff_register"]: object }));
                setStaffObject(object);
            }
        }
    }

    if (staffArray.length > 0)
    {
        tempArray = staffArray;
    }

    if (count === 0)
    {
        tempArray[index] = name;
        setStaffArray(tempArray);
        let numOfManagers = checkForManagers("add one", tempArray[index]);
        incrementLocalStaff(tempArray.length, setInputs, numOfManagers);
    }
}

export const addStaffRow = (staffArray, currentIndex, staffPresenceArray, staffRows, setCurrentIndex, setStaffRows) =>
{
    if (staffArray.length > 0)
    {
        if (
            ((staffArray[currentIndex] === "" || staffArray[currentIndex] === undefined) && (staffPresenceArray[currentIndex] === "" || staffPresenceArray[currentIndex] === undefined))
            ||
            ((staffArray[currentIndex] !== "" && staffArray[currentIndex] !== undefined) && (staffPresenceArray[currentIndex] === "" || staffPresenceArray[currentIndex] === undefined))
            ||
            ((staffArray[currentIndex] === "" || staffArray[currentIndex] === undefined) && (staffPresenceArray[currentIndex] !== "" && staffPresenceArray[currentIndex] !== undefined))
        )
        {
            swal("Error", "Please a choose staff member AND record their presence at work (Full day, Half day, Absent) before moving on.", "error");
        }
        else
        {
            let row = staffRows;
            row++;
            setCurrentIndex(row - 1);
            setStaffRows(row);
        }
    }
    else if (staffArray.length === 0) 
    {
        swal("Error", "Please select a staff member name first.", "error");
    }
}

export const addAllStaffRows = (
    staffNamesArray,
    setStaffArray,
    setStaffRows,
    setCurrentIndex,
    setInputs,
    setStaffObject,
    setStaffPresenceArray,
    incrementLocalStaff,
    checkForManagers) =>
{
    let tempStaffArray = [];
    if (staffNamesArray.length > 0)
    {
        tempStaffArray = [...staffNamesArray];
    }

    setStaffArray(tempStaffArray);
    setStaffRows(staffNamesArray.length);
    setCurrentIndex(staffNamesArray.length - 1);

    let tempObject = {};
    let tempArray = [];

    for (let i = 0; i <= tempStaffArray.length - 1; i++)
    {
        tempObject[tempStaffArray[i]] = "full day";
        tempArray.push("full day");
    }

    setInputs(prevState => ({ ...prevState, ["staff_register"]: tempObject }));
    setStaffObject(tempObject);
    setStaffPresenceArray(tempArray);

    let numOfManagers = checkForManagers("add all", tempStaffArray.length);
    incrementLocalStaff(tempArray.length, setInputs, numOfManagers);
}

export const removeStaffRow = (
    index,
    staffRows,
    setCurrentIndex,
    setStaffRows,
    staffArray,
    incrementLocalStaff,
    setStaffArray,
    staffPresenceArray,
    setStaffPresenceArray,
    staffObject,
    inputs,
    setInputs,
    setStaffObject,
    setLocalStaff,
    checkForManagers,
    setTotalStaff,
    setStaffTouched) =>
{
    if (staffRows > 1)
    {
        let row = staffRows;
        row--;
        if (index - 1 > 0)
        {
            setCurrentIndex(index - 1);
        }
        else
        {
            setCurrentIndex(0);
        }

        setStaffRows(row);
        let objectKey = 0;
        objectKey = staffArray[index];

        let tempstaffarray = staffArray;
        tempstaffarray.splice(index, 1);

        setStaffArray(tempstaffarray);
        let numOfManagers = checkForManagers("subtract one", objectKey);
        incrementLocalStaff(tempstaffarray.length, setInputs, numOfManagers);

        let tempStaffPresenceArray = staffPresenceArray;
        tempStaffPresenceArray.splice(index, 1);
        setStaffPresenceArray(tempStaffPresenceArray);

        let tempStaffObject = {};
        tempStaffObject = staffObject;
        delete tempStaffObject[objectKey];

        setInputs(prevState => ({ ...prevState, ["staff_register"]: tempStaffObject }));
        setStaffObject(tempStaffObject);
    }
    else if (staffRows === 1 && staffArray.length >= 0)
    {
        setStaffRows(1);
        let objectKey = 0;
        objectKey = staffArray[index];

        let select_box = document.getElementById("staff_register");
        select_box.selectedIndex = 0;
        setStaffArray([]);

        let tempStaffObject;
        tempStaffObject = staffObject;

        delete tempStaffObject[objectKey];
        setStaffObject(tempStaffObject);
        setStaffPresenceArray([]);

        if (inputs.staff_register)
        {
            setInputs(prevState => ({ ...prevState, ["staff_register"]: undefined }));
            setStaffTouched !== undefined ? setStaffTouched(true) : null;
        }

        setCurrentIndex(0);
        setLocalStaff(0);
        setTotalStaff(0);

        let numOfManagers = checkForManagers("subtract one", objectKey);
        incrementLocalStaff(0, setInputs, numOfManagers);
    }
}

export const addManagers = (value, setInputs, setManagers, managers) =>
{
    value = Number(value);

    if (value !== undefined && value >= 0)
    {
        setInputs(prevState => ({ ...prevState, ["mgrs_onsite"]: value }));
        setManagers(value);
    }
    else if (value !== undefined && managers + 1 >= 0)
    {
        setInputs(prevState => ({ ...prevState, ["mgrs_onsite"]: managers + 1 }));
        setManagers(managers + 1);
        value = managers + 1;
    }

    return value;
}

export const minusManagers = (managers, setManagers, setInputs) =>
{
    let numOfManagers = 0;

    if (managers - 1 >= 0)
    {
        if (managers - 1 === 0 || managers - 1 < 0)
        {
            setManagers(0);
            setInputs(prevState => ({ ...prevState, ["mgrs_onsite"]: undefined }));
        }
        else
        {
            numOfManagers = managers - 1;
            setInputs(prevState => ({ ...prevState, ["mgrs_onsite"]: managers - 1 }));
            setManagers(managers - 1);
        }
    }

    return numOfManagers;
}

