export const incrementLocalStaff = (totalStaff, setInputs, managers) =>
{
    if (totalStaff !== undefined && totalStaff !== 0)
    {
        let locals = totalStaff - managers >= 0 ? totalStaff - managers : 0;

        if (locals === 0)
        {
            setInputs(prevState => ({ ...prevState, ["local_staff_onsite"]: 0 }));
        }
        else
        {
            setInputs(prevState => ({ ...prevState, ["local_staff_onsite"]: locals }));
        }

        let currentTotal = locals + managers;

        if (currentTotal === 0)
        {
            setInputs(prevState => ({ ...prevState, ["total_staff_onsite"]: 0 }));
        }
        else
        {
            setInputs(prevState => ({ ...prevState, ["total_staff_onsite"]: currentTotal }));
        }
    }
    else if (totalStaff === 0)
    {
        setInputs(prevState => ({ ...prevState, ["local_staff_onsite"]: 0 }));
        setInputs(prevState => ({ ...prevState, ["total_staff_onsite"]: 0 }));
        setInputs(prevState => ({ ...prevState, ["mgrs_onsite"]: 0 }));
    }
}