import createUser from "./CreateUser";
import { API_URL } from "../../helpers/ApiUrl";

async function GetAuth(token)
{
    const response = await fetch(`${API_URL}/get-jwt-auth`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((res) => res.json())
        .then(
            (result) =>
            {
                if (result.message !== "Invalid token")
                {
                    let authObj = createUser(result.email);
                    return authObj;
                } else
                {
                    console.log("Failed auth: " + result.message);
                    return "Failed";
                }
            },
            (error) =>
            {
                return error.message;
            }
        );
    return response;
}

export default GetAuth;