import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/full-logo.png";
import logoLightPng from "../../assets/images/full-logo.png";
import logoLightSvg from "../../assets/images/full-logo.png";
import logoDark from "../../assets/images/full-logo.png";

const Sidebar = props =>
{
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              {/* <img src={logo} alt="" height="22" /> */}
              <img src={logo} alt="" height="50" />
            </span>
            <span className="logo-lg">
              {/* <img src={logoDark} alt="" height="17" /> */}
              <img src={logoDark} alt="" height="50" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              {/* <img src={logoLightSvg} alt="" height="22" /> */}
              <img src={logoLightSvg} alt="" height="50" />
            </span>
            <span className="logo-lg">
              {/* <img src={logoLightPng} alt="" height="30" /> */}
              <img src={logoLightPng} alt="" height="48" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent toggleBoth={props.toggleBoth} isOpen={props.isOpen} /> : <SidebarContent toggleBoth={props.toggleBoth} isOpen={props.isOpen} />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
  toggleBoth: PropTypes.func,
  isOpen: PropTypes.bool,
}

const mapStatetoProps = state =>
{
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
