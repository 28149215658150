import { get } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import useCrypto from "./useCrypto";
import sortLogins from "./sortLogins";
import GetInfo from "./GetInfo";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";

const getLogins = async () =>
{
    try
    {
        const loginData = await get(url.GET_LOGINS);
        let loginsArray = JSON.parse(loginData.loginsArray);
        let msgArray = ["Some error occurred while retrieving logins.", "Logins retrieved successfully."];
        loginsArray = removeSuccessMessage(loginsArray, msgArray);

        let encryptedLogins = useCrypto("encrypt", JSON.stringify(loginsArray));
        if (sessionStorage.getItem("loginsArray") !== undefined)
        {
            sessionStorage.removeItem("loginsArray");
        }
        sessionStorage.setItem("loginsArray", encryptedLogins);

        let arrays = await sortLogins(loginsArray);
        sessionStorage.setItem("emailsArray", JSON.stringify(arrays[0]));
        sessionStorage.setItem("passwordsArray", JSON.stringify(arrays[1]));

        let numOfUsers = await GetInfo("gettotalnumusers", "");
        sessionStorage.setItem("numOfUsers", JSON.stringify(numOfUsers));

    } catch (error)
    {
        console.log("Get logins error: Message from server is: " + error);
    }
}
export default getLogins;