const removeSuccessMessage = (array, expectedSuccessMessage) =>
{
    if (array === undefined || array.length === 0)
    {
        return [];
    }

    let lastArrayItem = array.length;
    let msgFromServer = array[lastArrayItem - 1];

    // if there is only one server message provided
    if (typeof expectedSuccessMessage === "string" && msgFromServer === expectedSuccessMessage)
    {
        array.pop();
        return array;
    }

    if (Array.isArray(expectedSuccessMessage))
    {
        // if there is an array of server messages provided
        expectedSuccessMessage.forEach((msg) =>
        {
            if (msg === msgFromServer)
            {
                array.pop();
            }
        });
    }

    return array;
}

export default removeSuccessMessage;