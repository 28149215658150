import { API_URL } from "../../helpers/ApiUrl";

async function postUser(inputs, method, route, errorMsg)
{
    if (inputs !== undefined && Object.keys(inputs).length !== 0)
    {
        const response = await fetch(
            `${API_URL}/${route}`,
            {
                method: `${method}`,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(inputs),
            }
        )
            .then((res) => res.json())
            .then(
                (result) =>
                {
                    let array = result.message;
                    return array;
                },
                (error) =>
                {
                    console.log(`${errorMsg}: ${error}`);
                    return "Error";
                }
            );

        return response;
    }
    else
    {
        return "No info";
    }
}

export default postUser;