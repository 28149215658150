export const addRow = (equipArray, currentEquipIndex, equipCountArray, equipRows, setCurrentEquipIndex, setEquipRows) =>
{
    if (equipArray.length > 0)
    {
        if (
            ((equipArray[currentEquipIndex] === "" || equipArray[currentEquipIndex] === undefined) && (equipCountArray[currentEquipIndex] === "" || equipCountArray[currentEquipIndex] === undefined))
            ||
            ((equipArray[currentEquipIndex] !== "" && equipArray[currentEquipIndex] !== undefined) && (equipCountArray[currentEquipIndex] === "" || equipCountArray[currentEquipIndex] === undefined))
            ||
            ((equipArray[currentEquipIndex] === "" || equipArray[currentEquipIndex] === undefined) && (equipCountArray[currentEquipIndex] !== "" && equipCountArray[currentEquipIndex] !== undefined))
        )
        {
            swal("Error", "Please choose equipment name AND how many you saw on site before moving on.", "error");
        }
        else
        {
            let row = equipRows;
            row++;
            setCurrentEquipIndex(row - 1);
            setEquipRows(row);
        }
    }
    else if (equipArray.length === 0) 
    {
        swal("Error", "Please select equipment first.", "error");
    }
}

export const removeRow = (
    index,
    equipRows,
    setCurrentEquipIndex,
    setEquipRows,
    equipArray,
    setEquipArray,
    equipCountArray,
    setEquipCountArray,
    equipObject,
    setInputs,
    setEquipObject,
    inputs) =>    
{
    if (equipRows > 1)
    {
        let row = equipRows;
        row--;
        setCurrentEquipIndex(row - 1);
        setEquipRows(row);

        let objectKey = 0;
        objectKey = equipArray[index];

        let tempequiparray = equipArray;
        tempequiparray.splice(index, 1);
        setEquipArray(tempequiparray);

        let tempEquipCountArray = equipCountArray;
        tempEquipCountArray.splice(index, 1);
        setEquipCountArray(tempEquipCountArray);

        let tempEquipObject = {};

        tempEquipObject = equipObject;
        delete tempEquipObject[objectKey];

        setInputs(prevState => ({ ...prevState, ["plant_equip_list"]: tempEquipObject }));
        setEquipObject(tempEquipObject);

    } else if (equipRows === 1 && equipArray.length > 0)
    {
        let objectKey = 0;
        objectKey = equipArray[index];

        let select_box = document.getElementById("plant_equip_list");
        select_box.selectedIndex = 0;
        setEquipArray([]);

        let tempEquipObject;
        tempEquipObject = equipObject;

        delete tempEquipObject[objectKey];
        setEquipObject(tempEquipObject);
        setEquipCountArray([]);
        setEquipObject('');
        setEquipArray([]);

        if (inputs.plant_equip_list)
        {
            setInputs(prevState => ({ ...prevState, ["plant_equip_list"]: undefined }));
        }
    }
}

export const handleEquipArray = (name, index, equipCountArray, equipArray, equipObject, setInputs, setEquipObject, setEquipArray) =>
{
    let object = {};
    let tempArray = [];
    let counter = 0;
    let count = equipCountArray[index];

    if (name !== undefined && name !== "")
    {
        // Prevents duplicate equipment being added to list
        equipArray.forEach((equipname, index) =>
        {
            if (equipname === name)
            {
                ++counter;
            }
        });

        if (count !== undefined && count !== "" && Number(count) > 0 && counter === 0)
        {
            if (Object.keys(equipObject).length === 0)
            {
                object[name] = count;
                setInputs(prevState => ({ ...prevState, ["plant_equip_list"]: object }));
                setEquipObject(object);
            }
            else if (Object.keys(equipObject).length === index)
            {
                object = equipObject;
                object[name] = count;
                setInputs(prevState => ({ ...prevState, ["plant_equip_list"]: object }));
                setEquipObject(prevState => ({ ...prevState, [equipArray[index]]: count }));
            }
            else
            {
                if (counter === 0 && equipArray[index] !== undefined)
                {
                    object = equipObject;
                    object[name] = object[equipArray[index]];
                    delete object[equipArray[index]];
                    setEquipObject(object);
                    setInputs(prevState => ({ ...prevState, ["plant_equip_list"]: object }));
                }
            }
        }

        if (equipArray.length > 0)
        {
            tempArray = equipArray;
        }

        if (counter === 0)
        {
            tempArray[index] = name;
            setEquipArray(tempArray);
        }
    }
}

export const handleEquipCount = (count, index, equipArray, equipObject, setInputs, setEquipObject, equipCountArray, setEquipCountArray) =>
{
    let object = {};
    let tempArray = [];
    let name = equipArray[index];

    if (count !== undefined && count !== "" && Number(count) > 0)
    {
        if (name !== "" && name !== undefined)
        {
            if (Object.keys(equipObject).length === 0)
            {
                object[name] = count;
                setInputs(prevState => ({ ...prevState, ["plant_equip_list"]: object }));
                setEquipObject(object);
            }
            else
            {
                object = equipObject;
                object[name] = count;
                setInputs(prevState => ({ ...prevState, ["plant_equip_list"]: object }));
                setEquipObject(prevState => ({ ...prevState, [name]: count }));
            }
        }

        if (equipCountArray.length > 0)
        {
            tempArray = equipCountArray;
        }

        tempArray[index] = count;
        setEquipCountArray(tempArray);
    }
}

export const loadEquipmentArray = (chosenVisitArray, setEquipArray, setEquipRows, setEquipObject, setEquipCountArray) => 
{
    if (chosenVisitArray.length > 0)
    {
        if (chosenVisitArray[0].plant_equip_list !== undefined)
        {
            let object = chosenVisitArray[0].plant_equip_list;
            let tempEquipArray = Object.keys(object);
            setEquipArray(tempEquipArray);
            setEquipRows(tempEquipArray.length);
            setEquipObject(object);
            let tempEquipCountArray = Object.values(object);
            setEquipCountArray(tempEquipCountArray);
        }
        else
        {
            setEquipArray([]);
            setEquipRows(1);
            setEquipObject({});
            setEquipCountArray([]);
        }
    }
}