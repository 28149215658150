import React from "react"
import { useState, useEffect } from "react";
import { Table, Label, FormGroup, Col, Input, Button, Form, Alert } from "reactstrap";
import GetWardPerson from "./GetWardPerson";
import ".../../assets/styles.css";
import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import FetchWardNum from "../Projects/FetchWardNum";
import PropTypes from 'prop-types';
import swal from "sweetalert";

const FindCouncillor = (props) =>
{
    const [councillorLoaded, setCouncillorLoaded] = useState(false);
    const [councillorArray, setCouncillorArray] = useState([]);
    const [councillorToDisplay, setCouncillorToDisplay] = useState([]);
    const [wardNum, setWardNum] = useState(0);
    const [wardNumError, setWardNumError] = useState("");

    // Google Place Autocomplete hooks
    const [LatLong, setLatLong] = useState([]);
    const [Lat, setLat] = useState("");
    const [Long, setLong] = useState("");
    const [value, setValue] = useState(null);
    // const [address, setAddress] = useState('');
    // const [geoResult, setGeoResult] = useState('');
    //const [addressChosen, setAddressChosen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    // const [geoResultLoaded, setGeoResultLoaded] = useState(false);
    // const [latLongLoaded, setLatLongLoaded] = useState(false);

    // Props
    let key = props.placesKey;
    let setShowArrow = props.setShowArrow;
    // let val = "";
    // let val2 = "";

    const sortInfo = () =>
    {
        let tempCouncillorArray = JSON.parse(JSON.stringify(councillorArray));
        tempCouncillorArray = removeSuccessMessage(tempCouncillorArray, "Ward councillor info retrieved successfully.");

        let tempCouncillorToDisplay = tempCouncillorArray.map((councillor, index) =>
        {
            let tel1 = councillor.tel1 !== undefined && councillor.tel1 !== null ? "0" + councillor.tel1 : "";
            let tel2 = councillor.tel2 !== undefined && councillor.tel2 !== null ? "0" + councillor.tel2 : "";
            let email1 = councillor.email1 !== undefined && councillor.email1 !== null ? councillor.email1 : "";
            let email2 = councillor.email2 !== undefined && councillor.email2 !== null ? councillor.email2 : "";

            let eachPerson = (
                <div key={index} className="border border-dark mb-4 table-responsive over">
                    <Table striped className="table table-bordered mb-2">
                        <tbody>
                            <tr className="col-12">
                                <th className="col-2">Ward</th>
                                <td className="col-3">{councillor.ward}</td>
                                <th className="col-2"></th>
                                <td className="col-3"></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr className="col-12 table-light">
                                <th className="col-2">Name</th>
                                <td className="col-3">{councillor.first_name}</td>
                                <th className="col-2">Surname</th>
                                <td className="col-3">{councillor.surname}</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr className="col-12">
                                <th className="col-2">Telephone 1</th>
                                <td className="col-3">{tel1}</td>
                                <th className="col-2">Telephone 2</th>
                                <td className="col-3">{tel2}</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr className="col-12 table-light">
                                <th className="col-2">Email 1</th>
                                <td className="col-3">{email1}</td>
                                <th className="col-2">Email 2</th>
                                <td className="col-3">{email2}</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr className="col-12">
                                <th className="col-2">Area</th>
                                <td className="col-3">{councillor.area}</td>
                                <th className="col-2">Party</th>
                                <td className="col-3">{councillor.party}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            );

            return eachPerson;
        });

        setCouncillorToDisplay(tempCouncillorToDisplay);
        setShowArrow(true);
    }

    const handleChange = (ward) =>
    {
        let wardNumber = ward !== undefined ? ward : wardNum;

        if (wardNumber > 0 && wardNumber <= 111)
        {
            setWardNumError("");
            GetWardPerson(wardNumber, "getwardcouncillor", "POST", "Error while fetching ward councillor info", setCouncillorArray, setCouncillorLoaded);
        }
        else
        {
            setCouncillorLoaded(false);
            setWardNumError("Invalid ward number. Must be between 1 and 111");
        }
    }

    const getGeoResult = () =>
    {
        if (value !== null)
        {
            //setAddress(value.label);
            geocodeByPlaceId(`${value.value.place_id}`)
                .then(results =>
                {
                    setCoords(results);
                })
                .catch(error => console.error("Geocode error is: " + error));
        }
    }

    const setCoords = (georesult) =>
    {
        if (georesult !== "")
        {
            let tempvalue = georesult[0].geometry.location;
            // let val = tempvalue.lat;
            // let val2 = tempvalue.lng;
            // let array = [];
            // array.push(tempvalue.lat);
            // array.push(tempvalue.lng);
            // setLatLong(array);
            setLat(tempvalue.lat);
            setLong(tempvalue.lng);
            // setLatLongLoaded(true);
        }
    }

    const clearAddress = () =>
    {
        setValue(null);
        // setAddress('');
        setCouncillorLoaded(false);
        //setGeoResult('');
        setLatLong([]);
        setLat('');
        setLong('');
        setWardNum(0);
        setShowArrow(false);
        //setAddressChosen(false);
        //setGeoResultLoaded(false);
        //setLatLongLoaded(false);
    }

    const GetWardNum = async () =>
    {
        setLoading(true);

        if (Lat !== "")
        {
            let myArray = [];
            let wardResult = "";

            try
            {
                wardResult = await FetchWardNum(Lat, Long);
                myArray = wardResult.split("|");
                let wardnum = myArray[0].trim();
                setWardNum(Number(wardnum));

                // setInputs(prevState => ({ ...prevState, ["ward"]: Number(wardnum) }));
                // let muni = `${myArray[1].trim()}, ${myArray[2].trim()}`
                // setInputs(prevState => ({ ...prevState, ["municipality"]: muni }));
                // let map = `${Lat}, ${Long}`;
                // setInputs(prevState => ({ ...prevState, ["map_location"]: map }));
                // setInputs(prevState => ({ ...prevState, ["proj_address"]: address }));

                setLoading(false);
                // setAddressChosen(true);
                handleChange(Number(wardnum));
            }
            catch (error)
            {
                console.log("Error: Failed to fetch ward num from api.");
            }
        }
        else
        {
            swal("Error", "No such place. Please try again.", "error");
            setLoading(false);
        }
    }

    const clearWardSearch = () =>
    {
        setWardNum(0);
        setCouncillorLoaded(false);
        setShowArrow(false);
    }

    useEffect(() =>
    {
        getGeoResult();
    }, [value]);

    useEffect(() =>
    {
        if (councillorLoaded)
        {
            sortInfo();
        }
    }, [councillorLoaded, councillorArray]);

    return (
        <div>
            {/* Invalid ward number error */}
            {wardNumError !== ""
                ? <Alert color="danger" role="alert">
                    {wardNumError}
                </Alert> : null}

            {/* <div>Ward num: {wardNum}, councillorArray length: {councillorArray.length}, councillorLoaded: {councillorLoaded.toString()}</div> */}

            <Form onSubmit={(e) => { handleChange(); e.preventDefault(); }} >
                <FormGroup row>
                    <Label
                        for="projAddress"
                        className="col-sm-2 col-md-1 d-flex align-items-center"
                    >
                        Street Address
                    </Label>
                    <Col className="col-sm-10 col-md-7 col-12">
                        <GooglePlacesAutocomplete
                            apiKey={key}
                            name="projAddress"
                            selectProps={{
                                value,
                                onChange: setValue,
                            }}
                            apiOptions={{ language: 'en', region: 'ZA' }}
                            onLoadFailed={(error) => (
                                console.error("Could not inject Google script:", error))}
                        />
                    </Col>
                    <Col className="col-sm-2 col-8 col-md-3 mt-sm-0 mt-2">
                        <Button
                            color="primary"
                            className='me-1 mb-1'
                            disabled={isLoading}
                            onClick={() => GetWardNum()}
                        >
                            {isLoading ? 'Loading…' : 'Use Address'}
                        </Button>
                        <Button
                            color="danger"
                            className='me-1 mb-1'
                            onClick={() => clearAddress()}
                        >
                            Clear
                        </Button>
                    </Col>
                </FormGroup>

                <div><p><b>OR</b></p></div>

                <FormGroup row className="mb-4">
                    <Label
                        for="ward_num"
                        className="col-sm-2 col-md-1"
                    >
                        Ward Number
                    </Label>
                    <Col className="col-sm-10 col-md-7 col-6">
                        <Input
                            onChange={(e) => { setWardNum(Number(e.target.value)) }}
                            id="ward_num"
                            name="ward_num"
                            type="number"
                            value={wardNum || ''}
                        >
                        </Input>
                    </Col>
                    <Col className="mt-sm-0 mt-0 col-md-4 col-6">
                        <Button
                            color="primary"
                            className='me-1 mb-1'
                            type="submit"
                        >
                            Search
                        </Button>
                        <Button
                            color="danger"
                            className='me-1 mb-1'
                            onClick={() => { clearWardSearch() }}
                        >
                            Clear
                        </Button>
                    </Col>
                </FormGroup>
            </Form>

            {councillorLoaded
                ? councillorToDisplay
                : null}
        </div>
    );
}

FindCouncillor.propTypes = {
    placesKey: PropTypes.string,
    setShowArrow: PropTypes.func,
}

export default FindCouncillor;