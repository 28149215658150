let token;
if (localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== "no token")
{
  token = JSON.parse(localStorage.getItem("token"));
} else
{
  token = "";
}

const accessToken =
  `Bearer ${token}`;

export default accessToken
