import React from "react"
import { useState, useEffect } from "react";
import { Form, Label, FormGroup, Col, Input, Button } from "reactstrap";
import PropTypes from 'prop-types';
import ".../../assets/styles.css";
import swal from "sweetalert";
import resizeImage from '../SharedFiles/resizeImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import postInfo from '../SharedFiles/postInfo';
import { API_URL } from "../../helpers/ApiUrl";
import { post } from "../../helpers/api_helper";
import changePhotoHandler from "../SharedFiles/changePhotoHandler";
import { addWardRow, handleWards, removeWard, loadWardArray } from "../SharedFiles/WardHelpers";
import { payIntervalOptions } from "../SharedFiles/listData";

const EditSponsor = (props) =>
{
    // Hooks
    const [inputs, setInputs] = useState({});
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Image hooks
    const [imgUrls, setImgUrls] = useState([]);
    const [imgObjects, setImgObjects] = useState([]);
    const [markedImage, setMarkedImage] = useState("");

    // Ward hooks
    const [wardArray, setWardArray] = useState([]);
    const [wardRows, setWardRows] = useState(1);
    const [wardIndex, setWardIndex] = useState(0);
    const [wardArrayLoaded, setWardArrayLoaded] = useState(false);

    // Props
    let sponsorsArray = props.sponsorsArray;
    let currentUser = props.currentUser;
    let toggleSponsorsChanged = props.toggleSponsorsChanged;
    let showForm = props.showForm;
    let setShowForm = props.setShowForm;
    let activeTab = props.activeTab;
    let setActiveTab = props.setActiveTab;
    let currentIndex = props.currentIndex;
    let isLoaded = props.isLoaded;

    const handleSubmit = async (imgUploadMessage) =>
    {
        let imgUrl = "";
        let myObject = {};

        if (imgUploadMessage !== "Error" && imgUploadMessage !== "no images")
        {
            let imagePathsArray = Object.keys(imgUploadMessage);
            let imageUploadStatusArray = Object.values(imgUploadMessage);

            imagePathsArray.forEach((img, i) =>
            {
                if (imageUploadStatusArray[i] === "Success")
                {
                    imgUrl = `${API_URL}/${img}`;
                }
            });

            myObject["logo_url"] = imgUrl;
        }

        // If there are deleted images
        if (markedImage.length > 0 && imgUrls.length === 0)
        {
            myObject["logo_url"] = "";
        }

        if (inputs.isActive === "1")
        {
            myObject["isExpired"] = false;
        }
        else if (inputs.isActive === "0")
        {
            myObject["isExpired"] = true;
        }

        // code to save project data to database
        if (Object.keys(inputs).length === 0 && Object.keys(myObject).length === 0)
        {
            swal("Error", "Nothing changed.", "error");
            setIsSubmitting(false);
        }
        else
        {
            myObject["updated_by_user"] = currentUser;
            myObject["sponsor_id"] = sponsorsArray[currentIndex]._id;

            Object.assign(myObject, inputs);

            const response = await post("/updatesponsor", myObject);

            if (response !== undefined && response.message === "Sponsor updated!")
            {
                setIsSubmitting(false);
                setInputs({});
                setShowForm(false);
                setImgObjects([]);
                setImgUrls([]);
                setMarkedImage("");
                toggleSponsorsChanged();
                swal("Success", "Sponsor updated successfully!", "success");
                setActiveTab("1");
            }
            else
            {
                setIsSubmitting(false);
                setInputs({});
                swal("Error", "Error occurred while updating sponsor.", "error");
            }
        }
    }

    const handleSubmitPhoto = async (e) =>
    {
        setIsSubmitting(true);

        if (imgUrls.length === 0)
        {
            console.log("No images to upload.");
            return handleSubmit("no images");
        }

        const formData = new FormData();

        for (const files of imgObjects)
        {
            const config = {
                file: files,
                maxSize: 1080,
                quality: 85
            };

            const resizedImage = await resizeImage(config);
            formData.append("sponsor-logo", resizedImage, files.name);
        }

        postInfo(formData, "uploadsponsorlogo", "POST", handleSubmit, "");
    };

    const removeImage = () => 
    {
        let tempImgUrl = "";
        if (sponsorsArray[currentIndex].logo_url !== undefined)
        {
            tempImgUrl = sponsorsArray[currentIndex].logo_url;
        }

        // Existing images marked for deletion (red text)
        setMarkedImage(tempImgUrl);
    }

    const cancelUpdate = () =>
    {
        setInputs({});
        setImgUrls([]);
        setImgObjects([]);
        setMarkedImage("");
        setShowForm(false);
    }

    const addWardRw = (e) =>
    {
        addWardRow(wardArray, wardIndex, wardRows, setWardIndex, setWardRows);
    }

    const removeWd = index =>
    {
        removeWard(wardRows, setWardRows, setWardIndex, index, wardArray, setWardArray, inputs, setInputs);
    }

    const handleWds = (ward, index) =>
    {
        handleWards(index, ward, wardArray, setWardArray, setInputs);
    }

    let wardDivArray = [];
    let wardRow;

    for (let i = 0; i < wardRows; ++i)
    {
        wardRow = (
            <div key={i} className='row col-12 mb-1'>
                <Col className="col-10 col-md-11 ">
                    <Input
                        id="wards"
                        name="wards"
                        type="number"
                        onChange={(e) =>
                        { handleWds(e.target.value, i) }}
                        value={wardArray[i] || ''}
                        onWheel={(e) => e.target.blur()}
                    />
                </Col>
                {/* col-sm-1 col-2 my-sm-0 my-2 */}
                <Col className="col-1">
                    <Button color="danger" onClick={() => removeWd(i)}>
                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                    </Button>
                </Col>
            </div>);

        wardDivArray.push(wardRow);
    }

    useEffect(() =>
    {
        if (activeTab !== "3")
        {
            cancelUpdate();
        }
    }, [activeTab]);

    useEffect(() =>
    {
        if (sponsorsArray.length > 0 && currentIndex !== -1)
        {
            loadWardArray(sponsorsArray, currentIndex, setWardArray, setWardRows, setWardArrayLoaded);
        }
    }, [sponsorsArray, currentIndex]);

    return (
        <div>
            {/* <Container fluid>
                <div> */}
            {/* <div>
                Inputs: <pre>{JSON.stringify(inputs)}</pre>
                currentIndex: {currentIndex},
                sponsorArray company name: <pre>{sponsorsArray.length > 0 ? JSON.stringify(sponsorsArray[currentIndex].company_name) : null}</pre>
                isLoaded: {isLoaded.toString()}, showForm: {showForm.toString()}
            </div> */}
            {showForm && isLoaded && sponsorsArray.length > 0 && sponsorsArray[currentIndex].isActive === 0
                ? <p style={{ color: "red" }}>This sponsor is currently inactive.</p>
                : null}

            {showForm && isLoaded ?
                <Form
                    name="updateUserForm"
                    onSubmit={(e) => { handleSubmitPhoto(); e.preventDefault(); }}
                    autoComplete="off"
                >
                    <FormGroup row className="mb-1" value={inputs.isActive || ''}>
                        <Label
                            for="isActive"
                            sm={1}
                        >
                            Is Active
                        </Label>
                        <Col className="col-md-11">
                            <Button color={sponsorsArray[currentIndex].isActive === 1 && inputs.isActive === undefined
                                ? "success"
                                : inputs.isActive === "1"
                                    ? "success"
                                    : "secondary"} className='me-1' name="isActive" onClick={(e) => { handleChange(e); }} value={1}>
                                Yes
                            </Button>
                            <Button color={sponsorsArray[currentIndex].isActive === 0 && inputs.isActive === undefined
                                ? "danger"
                                : inputs.isActive === "0"
                                    ? "danger"
                                    : "secondary"} className='me-1' name="isActive" onClick={(e) => { handleChange(e); }} value={0}>
                                No
                            </Button>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="company_name"
                            sm={1}
                        >
                            Trading name <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="company_name"
                                placeholder=""
                                type="text"
                                value={inputs.company_name === undefined
                                    ? sponsorsArray[currentIndex].company_name
                                    : inputs.company_name !== undefined
                                        ? inputs.company_name
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    {/* <FormGroup row>
                                <Label
                                    for="ward"
                                    sm={2}
                                >
                                    Ward Number/s <span style={{ color: "red" }}>*</span>
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        name="ward"
                                        placeholder=""
                                        type="number"
                                        value={inputs.ward === undefined
                                            ? sponsorsArray[currentIndex].ward
                                            : inputs.ward !== undefined
                                                ? inputs.ward
                                                : ''}
                                        onChange={(e) => { handleChange(e) }}
                                    />
                                </Col>
                            </FormGroup> */}

                    <FormGroup row >
                        <Col className="col-12">
                            <Label
                                for="wards"
                            >
                                Wards
                            </Label>
                        </Col>
                        {wardDivArray}
                        <div>
                            <Button className='me-1 mb-2' onClick={addWardRw}>
                                Add
                            </Button>
                        </div>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="link"
                            sm={1}
                        >
                            Website
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="link"
                                placeholder="e.g. http://www.google.com"
                                type="text"
                                value={inputs.link === undefined && sponsorsArray[currentIndex].link !== undefined
                                    ? sponsorsArray[currentIndex].link
                                    : inputs.link !== undefined
                                        ? inputs.link
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="addPhoto"
                            sm={1}
                        >
                            Upload logo
                        </Label>
                        <Col className="col-md-11 mb-1">
                            <Input
                                id="addPhoto"
                                name="addPhoto"
                                type="file"
                                onChange={(e) => changePhotoHandler(e, "", setImgObjects, setImgUrls, "")}
                            />
                        </Col>
                    </FormGroup>

                    <div id="userPhotoSection" className='row'>

                        {/* New user image section */}
                        {
                            imgUrls.length > 0
                                ? imgUrls.map((url, index) => (
                                    <div key={index} className="mx-1 mb-2 mt-2 col-12 col-sm-3">
                                        <img key={index} className="col-12 p-0 m-0" src={url} alt="img" />
                                        {/* <Button className="position-absolute top-0 translate-middle-y"
                                                    style={{ right: "0.05rem" }} color="danger"
                                                    onClick={(e) => removeImage(e, index)}>
                                                    <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                                </Button> */}
                                        {/* <div className='p-1'>{imgNames[index]}</div> */}
                                    </div>
                                ))
                                : null
                        }
                        {imgUrls.length > 0
                            ? <p className="ms-1 mb-4" style={{ color: "blue" }}>New/Updated company logo</p>
                            : null}

                        {/* Existing user image section */}
                        {
                            (sponsorsArray[currentIndex].logo_url !== undefined && sponsorsArray[currentIndex].logo_url !== "")
                                && imgUrls.length === 0
                                && markedImage === ""
                                ? <div className="mx-1 mb-2 mt-2 col-12 col-sm-3">
                                    <img className="col-12 p-0 m-0" src={sponsorsArray[currentIndex].logo_url} alt="img" />
                                    <Button className="position-absolute top-0 translate-middle-y"
                                        style={{ right: "0.05rem" }} color="danger" onClick={() => removeImage()}>
                                        <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                                    </Button>
                                </div>
                                : null
                        }

                        {(sponsorsArray[currentIndex].logo_url !== undefined && sponsorsArray[currentIndex].logo_url !== "")
                            && imgUrls.length === 0
                            && markedImage === ""
                            ? <p className="ms-1 mb-4" style={{ color: "blue" }}>Current company logo</p>
                            : null}

                        {/* Image marked for deletion section */}
                        {
                            markedImage !== ""
                                ?
                                <div className="mx-1 mb-4 col-12 col-sm-3">
                                    <img className="col-12 p-0 m-0" src={markedImage} alt="img" />
                                    <Button color="danger" className="col-12" disabled
                                    >
                                        Marked for Deletion
                                    </Button>
                                </div>
                                : null
                        }
                    </div>

                    {imgUrls.length > 0 || markedImage !== "" ? <div><hr /></div> : null}

                    <FormGroup row>
                        <Label
                            for="reg_name"
                            sm={1}
                        >
                            Registered name
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="reg_name"
                                placeholder=""
                                type="text"
                                value={inputs.reg_name === undefined && sponsorsArray[currentIndex].reg_name !== undefined
                                    ? sponsorsArray[currentIndex].reg_name
                                    : inputs.reg_name !== undefined
                                        ? inputs.reg_name
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="reg_num"
                            sm={1}
                        >
                            Registration number
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="reg_num"
                                placeholder=""
                                type="text"
                                value={inputs.reg_num === undefined && sponsorsArray[currentIndex].reg_num !== undefined
                                    ? sponsorsArray[currentIndex].reg_num
                                    : inputs.reg_num !== undefined
                                        ? inputs.reg_num
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="vat_num"
                            sm={1}
                        >
                            VAT number
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="vat_num"
                                placeholder=""
                                type="text"
                                value={inputs.vat_num === undefined && sponsorsArray[currentIndex].vat_num !== undefined
                                    ? sponsorsArray[currentIndex].vat_num
                                    : inputs.vat_num !== undefined
                                        ? inputs.vat_num
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="tel"
                            sm={1}
                        >
                            Telephone
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="tel"
                                placeholder=""
                                type="tel"
                                value={inputs.tel === undefined && sponsorsArray[currentIndex].tel !== undefined
                                    ? sponsorsArray[currentIndex].tel
                                    : inputs.tel !== undefined
                                        ? inputs.tel
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="email"
                            sm={1}
                        >
                            Email
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="email"
                                placeholder=""
                                type="email"
                                value={inputs.email === undefined && sponsorsArray[currentIndex].email !== undefined
                                    ? sponsorsArray[currentIndex].email
                                    : inputs.email !== undefined
                                        ? inputs.email
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="phys_address"
                            sm={1}
                        >
                            Physical address
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="phys_address"
                                placeholder=""
                                type="textarea"
                                rows="4"
                                cols="50"
                                value={inputs.phys_address === undefined && sponsorsArray[currentIndex].phys_address !== undefined
                                    ? sponsorsArray[currentIndex].phys_address
                                    : inputs.phys_address !== undefined
                                        ? inputs.phys_address
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="post_address"
                            sm={1}
                        >
                            Postal address
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="post_address"
                                placeholder=""
                                type="textarea"
                                rows="4"
                                cols="50"
                                value={inputs.post_address === undefined && sponsorsArray[currentIndex].post_address !== undefined
                                    ? sponsorsArray[currentIndex].post_address
                                    : inputs.post_address !== undefined
                                        ? inputs.post_address
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <div className='col-12'>Banking Details <hr /></div>

                    <FormGroup row>
                        <Label
                            for="bank_name"
                            sm={1}
                        >
                            Bank name
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="bank_name"
                                placeholder=""
                                type="text"
                                value={inputs.bank_name === undefined && sponsorsArray[currentIndex].bank_name !== undefined
                                    ? sponsorsArray[currentIndex].bank_name
                                    : inputs.bank_name !== undefined
                                        ? inputs.bank_name
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="branch_name"
                            sm={1}
                        >
                            Branch name
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="branch_name"
                                placeholder=""
                                type="text"
                                value={inputs.branch_name === undefined && sponsorsArray[currentIndex].branch_name !== undefined
                                    ? sponsorsArray[currentIndex].branch_name
                                    : inputs.branch_name !== undefined
                                        ? inputs.branch_name
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="branch_code"
                            sm={1}
                        >
                            Branch code
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="branch_code"
                                placeholder=""
                                type="number"
                                value={inputs.branch_code === undefined && sponsorsArray[currentIndex].branch_code !== undefined
                                    ? sponsorsArray[currentIndex].branch_code
                                    : inputs.branch_code !== undefined
                                        ? inputs.branch_code
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                                onWheel={(e) => e.target.blur()}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="acc_name"
                            sm={1}
                        >
                            Account name
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="acc_name"
                                placeholder=""
                                type="text"
                                value={inputs.acc_name === undefined && sponsorsArray[currentIndex].acc_name !== undefined
                                    ? sponsorsArray[currentIndex].acc_name
                                    : inputs.acc_name !== undefined
                                        ? inputs.acc_name
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label
                            for="acc_num"
                            sm={1}
                        >
                            Account number
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="acc_num"
                                placeholder=""
                                type="text"
                                value={inputs.acc_num === undefined && sponsorsArray[currentIndex].acc_num !== undefined
                                    ? sponsorsArray[currentIndex].acc_num
                                    : inputs.acc_num !== undefined
                                        ? inputs.acc_num
                                        : ''}
                                onChange={(e) => { handleChange(e) }}
                            />
                        </Col>
                    </FormGroup>

                    <div className='col-12' >Payment Details <hr /></div>

                    <FormGroup row>
                        <Label
                            for="pay_interval"
                            sm={1}
                        >
                            Payment interval
                        </Label>
                        <Col className="col-md-11">
                            <Input
                                name="pay_interval"
                                type="select"
                                onChange={(e) => handleChange(e)}
                                value={inputs.pay_interval === undefined && sponsorsArray[currentIndex].pay_interval !== undefined
                                    ? sponsorsArray[currentIndex].pay_interval
                                    : inputs.pay_interval !== undefined
                                        ? inputs.pay_interval
                                        : ''}
                            >
                                {payIntervalOptions.map((option, index) => (
                                    <option key={index} value={option.value}>{option.label}</option>
                                ))}
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup
                        row
                    >
                        <Col
                            sm={{
                                offset: 0,
                                size: 12,
                                marginBottom: "1rem"
                            }}
                        >
                            <Button color="primary" disabled={isSubmitting} type="submit">
                                {isSubmitting ? 'Updating...' : 'Update'}
                            </Button>
                            <Button className="ms-1" color="danger" onClick={() => { cancelUpdate() }}>
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
                : <p style={{ color: "blue" }}>Choose a sponsor to edit on the &quot;View&quot; tab.</p>
            }
            {/* </div>
            </Container> */}
        </div>
    );
}

export default EditSponsor;

EditSponsor.propTypes = {
    sponsorsArray: PropTypes.array,
    currentUser: PropTypes.string,
    toggleSponsorsChanged: PropTypes.func,
    showForm: PropTypes.bool,
    setShowForm: PropTypes.func,
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
    currentIndex: PropTypes.number,
    isLoaded: PropTypes.bool,
};