const changeDocHandler = (e, setDocNames, setNumOfDocs, setDocObjects, setDocUrls, setDocPrivacyArray) =>
{
    let array = [];
    let arrayObjects = [];
    let tempUrl;
    let files = e.target.files;
    let fileNames = [];
    let filesLength = files.length;
    let notPdf = false;

    for (let i = 0; i < filesLength; ++i)
    {
        if (files[i].type !== "application/pdf")
        {
            notPdf = true;
        }
        else
        {
            tempUrl = e.target.files[i].name;
            arrayObjects.push(e.target.files[i]);
            array.push(tempUrl);
        }
    }

    if (notPdf === true)
    {
        e.target.value = '';
        swal("Error", "Only PDF documents can be uploaded. Please try again", "error");
    }
    else
    {
        setNumOfDocs(arrayObjects.length);
        setDocObjects(arrayObjects);
        setDocUrls(array);
        for (const doc of files)
        {
            fileNames.push(doc.name);
        }

        setDocNames(fileNames);

        if (setDocPrivacyArray !== undefined)
        {
            let tempDocPrivacyArray = [];
            array.forEach((doc, index) =>
            {
                tempDocPrivacyArray.push("public");
            });
            setDocPrivacyArray(tempDocPrivacyArray);
        }
    }
};

export default changeDocHandler