import removeSuccessMessage from "pages/SharedFiles/removeSuccessMessage";

const sortProjectDocs = (projectDocsArray, projectId, setNumOfDocs, setDocArray, setDocPrivacyChanged) =>
{
    if (projectDocsArray.length > 0)
    {
        let docs = JSON.parse(JSON.stringify(projectDocsArray));
        let projectDocServerMessages = ["Project documents retrieved successfully.",
            "No project documents have been saved yet.",
            "Something went wrong while finding a project documents!"];
        docs = removeSuccessMessage(docs, projectDocServerMessages);
        docs = docs.filter(doc => doc.proj_id === projectId);
        docs = docs.length > 0 ? docs[0] : docs;

        if (Object.keys(docs).length > 0 && docs.docArray !== undefined)
        {
            setDocArray(docs.docArray);
            // setDocUrls([]);
            // setDocNames([]);
            // let tempDocPrivacyArray = [];
            // docs.docArray.forEach(element =>
            // {
            //     tempDocPrivacyArray.push(element.privacy);
            // });

            // setDocPrivacyArray(tempDocPrivacyArray);
            setNumOfDocs(docs.docArray.length);
        }
        else if (Object.keys(docs).length > 0 && (docs.docArray === undefined || docs.docArray.length === 0))
        {
            let urls = docs.docUrlArray;
            let names = docs.docNamesArray;
            let numOfDocs = urls.length;
            let tempDocArray = [];

            urls.forEach((url, index) =>
            {
                let object = {};
                object.url = url;
                object.name = names[index];
                object.privacy = "public";
                tempDocArray.push(object);
            });

            // setDocUrls(urls);
            // setDocNames(names);
            setNumOfDocs(numOfDocs);
            setDocArray(tempDocArray);
            setDocPrivacyChanged(true);
            // let tempDocPrivacyArray = [];
            // for (let i = 0; i <= numOfDocs; i++)
            // {
            //     tempDocPrivacyArray.push("public");
            // }
            // setDocPrivacyArray(tempDocPrivacyArray);
            // debugger;
        }
    }
}

export default sortProjectDocs;